<template>
  <BaseDialog :error="error" :dialog="dialog" @close="close($event)">
    <template v-slot:title> {{ $tc("title") }} </template>
    <template v-slot:text>
      <div class="mb-5">
        {{ $tc("description") }}
      </div>

      <DynamicForm
        :fields="fields"
        :formData.sync="formData"
        @is-valid-changed="updateFormValid($event)"
        ref="DynamicForm"
      />
    </template>
    <template v-slot:actionsRight>
      <BaseButton
        :loading="isSaving"
        :text="true"
        @click="close()"
        color="black"
      >
        {{ $t("label.cancel") }}
      </BaseButton>
      <BaseButton :disabled="!formValid" :loading="isSaving" @click="save()">
        {{ $t("label.add") }}
      </BaseButton>
    </template>
  </BaseDialog>
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import DynamicForm from "@/components/base/DynamicForm.vue";
import gql from "graphql-tag";

const initialData = () => ({
  error: null,
  fields: [],
  formData: {},
});

export default {
  name: "create-sharepoint-dialog",
  components: {
    BaseButton,
    BaseDialog,
    DynamicForm,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      isSaving: false,
      fields: [],
      formData: {},
      formValid: false,
    };
  },

  computed: {},

  watch: {
    dialog(val) {
      // reset the form to it's original state and reset the validation when dialog closes
      if (!val) {
        this.$emit("update:item", null);
        this.resetData();
        this.$refs.DynamicForm.resetForm();
      } else {
        this.setFormFields();
        this.setFormData(this.item);
      }
    },
  },

  created() {},

  mounted() {},

  methods: {
    close() {
      this.$emit("update:dialog", false);
    },

    resetData() {
      // reset the data to it's initial state
      const data = initialData();
      Object.keys(data).forEach((k) => (this[k] = data[k]));
    },

    setFormFields() {
      // set the field from the config from the DB
      const fieldsJson = `
      [
          {
              "component": "v-autocomplete",
              "model": "resourceGroup",
              "label": "Resource group",
              "cols": 12,
              "rules": [
                  "required"
              ],
              "query": "query allResourceGroups { allResourceGroups(orderBy: [\\"name\\"], first: 1000) { edges { node { id name } } } }",
              "itemKey": "id",
              "itemValue": "name",
              "resultKey": "allResourceGroups",
              "multiple": false,
              "returnObject": true
          }
      ]
      `;
      this.fields = JSON.parse(fieldsJson);
    },

    setFormData(item) {
      // edit, not used since item is not used for add ons
      if (item) {
        this.formData = {};
      }
      // add
      else {
        const formDataJson = `
        {
          "resourceGroup": null
        }
        `;
        this.formData = JSON.parse(formDataJson);
      }
    },

    updateFormValid(isValid) {
      this.formValid = isValid;
    },

    save() {
      if (!this.formValid) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        resourceGroup: this.formData.resourceGroup.id
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createSharepoint($input: CreateSharepointInput!) {
              createSharepoint(input: $input) {
                clientMutationId
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Successfully created sharepoint`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$emit("changed", response);

          this.close();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "title": "Create Sharepoint",
      "description": "Select a Resource Group, from there a set of steps will be executed. 1. Check if there is a key vault. 2. Create a Sharepoint members security group if not exists. 3. Create a ext. user and add it to the key vault. 4. Add the ext. user and requesting user to the RG security group. 5. Create a Microsoft 365 group, which creates a sharepoint, with dynamic config. 6. Return the Sharepoint url. If all this is done, the Logic App Excel deployment from the Targets can be run."
    },
    "nl": {
        "title": "Sharepoint aanmaken",
        "description": "Selecteer een Resource Group, van daaruit wordt een reeks stappen uitgevoerd. 1. Controleer of er een key vault is. 2. Maak een Sharepoint security group aan als deze niet bestaat. 3. Maak een externe gebruiker aan en voeg deze toe aan de key vault. 4. Voeg de externe gebruiker en de aanvragende gebruiker toe aan de security group van de RG. 5. Maak een Microsoft 365-groep aan, die een Sharepoint maakt met dynamische configuratie. 6. Geef de Sharepoint-URL terug. Als dit alles is voltooid, kan de Logic App Excel-implementatie van de Targets worden uitgevoerd."
    },
    "de": {
        "title": "Sharepoint Erstellen",
        "description": "Wählen Sie eine Ressourcengruppe aus, von dort aus werden eine Reihe von Schritten ausgeführt. 1. Überprüfen Sie, ob ein Key Vault vorhanden ist. 2. Erstellen Sie eine Sharepoint-Mitglieder-Sicherheitsgruppe, falls diese nicht existiert. 3. Erstellen Sie einen externen Benutzer und fügen Sie ihn dem Key Vault hinzu. 4. Fügen Sie den externen Benutzer und den anfragenden Benutzer der Sicherheitsgruppe der Ressourcengruppe hinzu. 5. Erstellen Sie eine Microsoft 365-Gruppe, die ein Sharepoint mit dynamischer Konfiguration erstellt. 6. Geben Sie die Sharepoint-URL zurück. Wenn all dies erledigt ist, kann die Logic App Excel-Bereitstellung der Targets ausgeführt werden."
    }
  }
</i18n>

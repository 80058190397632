import helper from "@/utils/helper.js";
import i18n from "@/i18n.js";

const zxcvbn = require("zxcvbn");

export default {
	email: (v) => /.+@.+\..+/.test(v) || i18n.t("validation.email"),
	listRequired: (v) => (v || "").length >= 1 || i18n.t("validation.required"),
	matching: (comparison) => (v) =>
		v === comparison || i18n.t("validation.matching"),
	maxLength: (len) => (v) =>
		(v || "").length <= len || i18n.t("validation.maxLength", { len: len }),
	minLength: (len) => (v) =>
		(v || "").length >= len || i18n.t("validation.minLength", { len: len }),
	required: (v) => !helper.isEmpty(v) || i18n.t("validation.required"),
	strength: (v) => zxcvbn(v).score >= 3 || i18n.t("validation.strength"),
};

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center pl-3"},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":"/"}}),_c('v-spacer')],1),_c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.lgAndDown}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',[_vm._v(_vm._s(_vm.$tc("label.report", 2)))]),_c('v-tab',[_c('v-badge',{attrs:{"color":_vm.allUserTasks?.tasksRunning?.totalCount > 0
                  ? 'info'
                  : _vm.allUserTasks?.tasksSuccess?.totalCount > 0
                  ? 'success'
                  : '',"value":_vm.allUserTasks?.tasksRunning?.totalCount > 0 ||
                _vm.allUserTasks?.tasksSuccess?.totalCount > 0
                  ? true
                  : false}},[_vm._v(_vm._s(_vm.$tc("label.export", 2)))])],1),(_vm.hasPermission('dashboards.view_usermapping'))?_c('v-tab',[_vm._v(_vm._s(_vm.$tc("label.usermapping", 2)))]):_vm._e(),(_vm.hasPermission('dashboards.view_usermapping'))?_c('v-tab',[_vm._v(_vm._s(_vm.$tc("label.report", 1))+_vm._s(_vm.$tc("label.group", 2)))]):_vm._e(),(_vm.me.isSuperuser)?_c('v-tab',[_vm._v(_vm._s(_vm.$tc("label.error", 2)))]):_vm._e(),_c('v-spacer'),_c('v-btn',{staticClass:"mt-2",attrs:{"disabled":(!_vm.tabs == 0 || _vm.nrOfSelectedReports <= 0 || !_vm.me.isSuperuser) &&
              (!_vm.tabs == 1 || _vm.nrOfSelectedUsermappings <= 0) &&
              (!_vm.tabs == 2 || _vm.nrOfSelectedReportGroups <= 0),"color":"error","dark":"","outlined":"","rounded":"","small":""},on:{"click":function($event){return _vm.deleteSelected()}}},[_vm._v(_vm._s(_vm.$t("label.delete")))]),(
              (_vm.me.isSuperuser && _vm.tabs != 1 && _vm.tabs != 4) ||
              _vm.tabs == 2 ||
              _vm.tabs == 3
            )?_c('v-btn',{staticClass:"mt-2 ml-2",attrs:{"color":"primary","dark":"","outlined":"","rounded":"","small":""},on:{"click":function($event){return _vm.add()}}},[_vm._v(_vm._s(_vm.$t("label.add")))]):_vm._e()],1),_c('v-divider'),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab-item',[_c('ReportsList',{ref:"ReportsList",on:{"updateNrOfSelectedItems":_vm.onUpdateNrOfSelectedReports}})],1),_c('v-tab-item',[_c('ExportsList',{ref:"ExportsList"})],1),(_vm.hasPermission('dashboards.view_usermapping'))?_c('v-tab-item',[_c('UsermappingsList',{ref:"UsermappingsList",on:{"updateNrOfSelectedItems":_vm.onUpdateNrOfSelectedUsermappings}})],1):_vm._e(),_c('v-tab-item',[_c('ReportGroupsList',{ref:"ReportGroupsList",on:{"updateNrOfSelectedItems":_vm.onUpdateNrOfSelectedReportGroups}})],1),(_vm.me.isSuperuser)?_c('v-tab-item',[_c('ErrorsList',{ref:"ErrorsList"})],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
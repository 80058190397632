<template>
  <component
    :class="field.class"
    :disabled="field.disabled || false"
    :is="field.component"
    :item-text="
      field.component === 'v-autocomplete' ? field.itemValue : undefined
    "
    :item-value="
      field.component === 'v-autocomplete' ? field.itemKey : undefined
    "
    :items="
      field.component == 'v-autocomplete' ? autocompleteItems : field?.items
    "
    :label="field.label"
    :loading="$apollo.queries.items.loading"
    :multiple="field.multiple"
    :return-object="
      field.component === 'v-autocomplete' && field.returnObject
        ? true
        : undefined
    "
    :rules="mapRules(field.rules)"
    :type="field.type"
    outlined
    v-model="localValue"
  ></component>
</template>

<script>
import gql from "graphql-tag";
import {
  VAutocomplete,
  VBtn,
  VChip,
  VDatePicker,
  VSelect,
  VSwitch,
  VTextarea,
  VTextField,
} from "vuetify/lib";
import rules from "@/utils/rules.js";

export default {
  name: "dynamic-form-field",
  props: {
    field: Object,
    value: null,
  },
  components: {
    VAutocomplete,
    VBtn,
    VChip,
    VDatePicker,
    VSelect,
    VSwitch,
    VTextarea,
    VTextField,
  },

  apollo: {
    items: {
      query() {
        return gql`
          ${this.field.query}
        `;
      },
      skip() {
        return !this.field.query;
      },
      update: (data) => data.items,
      result({ data }) {
        const resultData = data[this.field.resultKey];

        if (resultData?.edges) {
          this.autocompleteItems = resultData.edges.map(edge => edge.node);
        }
        else {
          this.autocompleteItems = resultData[this.field.listKey].map(edge => edge);
        }
      },
    },
  },

  data: function () {
    return {
      autocompleteItems: [],
      loading: false,
      localValue: this.value,
      items: [],
    };
  },

  computed: {},

  watch: {
    // emit the changes made on this child to the parent
    localValue(val) {
      this.$emit("value-changed", val);
    },
    // update this component based on changes of the DynamicForm parent
    value(val) {
      this.localValue = val;
    },
  },

  created() {},

  mounted() {},

  methods: {
    mapRules(ruleDefinitions) {
      if (ruleDefinitions) {
        return ruleDefinitions.map((rule) => {
          if (typeof rule === "string") {
            return rules[rule]; // Map to the corresponding function in rules.js
          } else if (
            typeof rule === "object" &&
            rule.name &&
            rules[rule.name]
          ) {
            return rules[rule.name](rule.params); // Call rule function with parameters
          } else {
            return rule; // Return as is if not a recognized rule format
          }
        });
      }
    },
  },
};
</script>

<template>
	<v-row justify="center">
		<v-dialog max-width="500px" persistent v-model="dialog">
			<v-card>
				<v-alert tile type="error" v-if="error">{{ error }}</v-alert>
				<v-card-title class="primary white--text">{{
					formTitle
				}}</v-card-title>
				<v-card-text class="pt-5">
					<v-form ref="form" onSubmit="return false;">
						<v-row>
							<v-col cols="12">
								<v-text-field
									:append-icon="
										showPassword
											? 'visibility'
											: 'visibility_off'
									"
									:rules="[rules.required]"
									:type="showPassword ? 'text' : 'password'"
									@click:append="showPassword = !showPassword"
									@keydown.enter="save"
									@submit.prevent
									outlined
									prepend-icon="lock"
									:label="$tc('label.password', 1)"
									v-model="password"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn @click="close" rounded text>{{
						$t("label.cancel")
					}}</v-btn>
					<v-btn
						:loading="isSaving"
						@click="save"
						color="primary"
						outlined
						rounded
						>{{ $t("label.save") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
	name: "tfa-diable-dialog",
	props: ["dialog"],
	data() {
		return {
			error: null,
			isSaving: false,
			password: null,
			showPassword: false,
		};
	},
	computed: {
		formTitle() {
			return "Disable 2FA";
		},
	},
	watch: {
		dialog() {
			this.resetForm();
		},
	},
	created() {
		this.rules = rules;
	},
	methods: {
		close() {
			this.$emit("update:dialog", false);
		},

		resetForm() {
			this.error = null;
			this.isSaving = false;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			this.password = null;
			this.showPassword = false;
		},

		save() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.error = null;
			this.isSaving = true;

			var payload = {
				password: this.password,
			};

			this.$apollo
				.mutate({
					mutation: gql`
						mutation disable2fa($input: Disable2FAInput!) {
							disable2fa(input: $input) {
								success
							}
						}
					`,
					variables: {
						input: payload,
					},
				})
				.then((response) => {
					this.close();
					this.$emit("changed", response);

					const payload = {
						color: "success",
						message: `Password successfully edited`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					this.error = error.graphQLErrors[0].message;
					this.isSaving = false;
				})
				.finally(() => {});
		},
	},
};
</script>

const onBehalfOfTenant = localStorage.getItem("onBehalfOfTenant")
  ? JSON.parse(localStorage.getItem("onBehalfOfTenant"))
  : null;

const state = {
  onBehalfOfTenant: onBehalfOfTenant,
};

const mutations = {
  SET_ON_BEHALF_OF_TENANT(state, payload) {
    state.onBehalfOfTenant = payload;
  },
};

const actions = {
  setOnBehalfOfTenant(context, payload) {
    if (payload) {
      localStorage.setItem("onBehalfOfTenant", JSON.stringify(payload));
    } else {
      localStorage.removeItem("onBehalfOfTenant");
    }

    context.commit("SET_ON_BEHALF_OF_TENANT", payload);
  },
};

const getters = {
  getOnBehalfOfTenant(state) {
    return state.onBehalfOfTenant;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <v-container fluid class="my-1">
    <v-expansion-panel class="mb-2">
      <v-expansion-panel-header>{{
        $tc("label.tap", 2)
      }}</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12" md="3" lg="4">
            <ControlPanelCard
              :title="$tc('connectionRequest.title')"
              :text="$tc('connectionRequest.description')"
              @open="connectionRequest($event)"
            />
          </v-col>
        </v-row>
        <v-row v-if="me.isSuperuser">
          <v-col cols="12">
            <TapsDataTable />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>


    <ConnectionRequestDialog :dialog.sync="dialog.connectionRequest" />
  </v-container>
</template>

<script>
import ConnectionRequestDialog from "@/components/integrations/controlPanel/taps/ConnectionRequestDialog.vue";
import ControlPanelCard from "@/components/integrations/controlPanel/ControlPanelCard.vue";
import TapsDataTable from "@/components/integrations/controlPanel/taps/TapsDataTable.vue";

export default {
  name: "taps-panel",
  props: [],
  components: {
    ConnectionRequestDialog,
    ControlPanelCard,
    TapsDataTable,
  },

  apollo: {},

  data() {
    return {
      dialog: {
        connectionRequest: false,
      },
    };
  },
  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {},
  created() {},
  methods: {
    connectionRequest() {
      console.log("connectionRequest");
      this.dialog.connectionRequest = true;
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "connectionRequest": {
        "title": "Tap for other tenant",
        "description": "Here, you can request a tap on behalf of another tenant. Since you create the Tap, you'll receive the email updates."
      }
    },
    "nl": {
      "connectionRequest": {
        "title": "Tap voor andere tenant",
        "description": "Hier kun je een Tap aanvragen namens een andere tenant. Aangezien jij de Tap aanvraagt, ontvang jij de email updates."
      }
    },
    "de": {
      "connectionRequest": {
        "title": "Tap für einen anderen tenant",
        "description": "Hier können Sie einen Tap im Namen eines anderen tenant anfordern. Da Sie den Tap erstellen, erhalten Sie die E-Mail-Updates."
      }
    }
  }
</i18n>

import gql from "graphql-tag";
import msalAuth from "@/utils/msalAuth.js";
import store from "../store/index.js";

const zxcvbn = require("zxcvbn");

export default {
  typeOf(obj) {
    return {}.toString.call(obj).split(" ")[1].slice(0, -1).toLowerCase();
  },

  isEmpty(input) {
    // array
    if (this.typeOf(input) === "array") {
      return input.length === 0;
    }

    // int
    if (this.typeOf(input) === "number") {
      if (input === 0) {
        return false;
      }

      return !input;
    }

    // undefined, null, string, object
    return !input || Object.keys(input).length === 0;
  },

  hasPermission(codename) {
    if (!store.state.user.me) {
      return false;
    }

    if (store.state.user.me.require2fa && !store.state.user.me.enabled2fa) {
      return false;
    }

    const _permissions = store.state.user.me.allPermissions;

    let _check_permission = _permissions.includes(codename);

    return _check_permission ? true : false;
    // return codename;
  },

  async fetchFile(url, name) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not OK");
      }
      const myBlob = await response.blob();
      var file = new File([myBlob], name);

      return file;
    } catch (error) {
      console.error(
        "There has been a problem with your fetch operation:",
        error
      );
    }
  },

  downloadUrl(url) {
    window.open(`${url}`, "_blank");
  },

  setupMsalAuth() {
    // Basic setup of MSAL helper with client id, or give up
    if (process.env.VUE_APP_MSAL_CLIENT_ID) {
      msalAuth.configure(process.env.VUE_APP_MSAL_CLIENT_ID, false);

      // Restore any cached or saved local user
      this.msalUser = msalAuth.user();
      // console.log(`configured ${msalAuth.isConfigured()}`);
    } else {
      this.error =
        "VUE_APP_MSAL_CLIENT_ID is not set, the app will not function!";
    }
  },

  btnSize(size) {
    return { [size]: true };
  },

  orderByList(sortBy, sortDesc) {
    var orderByList = [];
    sortBy.forEach(function (item, index) {
      let field = item.replace("node.", "").replace(/\./g, "__");
      let direction = sortDesc[index];
      if (direction === true) {
        field = "-" + field;
      }
      orderByList.push(field);
    });

    return orderByList;
  },

  strengthScore(text) {
    const result = zxcvbn(text);

    switch (result.score) {
      case 4:
        return {
          color: "light-blue",
          value: 100,
        };
      case 3:
        return {
          color: "light-green",
          value: 75,
        };
      case 2:
        return {
          color: "yellow",
          value: 50,
        };
      case 1:
        return {
          color: "orange",
          value: 25,
        };
      default:
        return {
          color: "red",
          value: 0,
        };
    }
  },

  updateMailchimpTags(listId, tags) {
    var payload = {
      listId: listId,
      tags: tags,
    };

    this.$apollo
      .mutate({
        mutation: gql`
          mutation updateMailchimpTags($input: UpdateMailchimpTagsInput!) {
            updateMailchimpTags(input: $input) {
              clientMutationId
            }
          }
        `,
        variables: {
          input: payload,
        },
      })
      .then((response) => {})
      .catch((error) => {})
      .finally(() => {});
  },

  capitalizeFirstLetter(item) {
    if (item == "de") {
      var text = "en";
    } else {
      var text = item;
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  },

  async copyValue(value) {
    try {
      await navigator.clipboard.writeText(value);
      // show snackbar
      const payload = {
        color: "success",
        message: "Copied value",
      };
      store.dispatch("snackbar/showMessage", payload);
    } catch ($e) {
      // show snackbar
      const payload = {
        color: "error",
        message: "Error copying value",
      };
      store.dispatch("snackbar/showMessage", payload);
    }
  },
};

<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="1000px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          <v-icon color="error" class="mr-2" large>warning</v-icon>
          {{ $t("label.delete") }} Tap
        </v-card-title>

        <v-card-text>
          {{ $t("title") }}
          <v-row :class="['my-0', 'py-0']">
            <v-col :class="['my-0', 'py-0']" cols="12">
              <v-checkbox
                :label="$t('deleteAzure')"
                color="error"
                hide-details
                v-model="deleteAzureResourceGroup"
              ></v-checkbox>
            </v-col>
            <v-col :class="['my-0', 'py-0']" cols="12">
              <v-checkbox
                :label="$t('deletePBI')"
                color="error"
                hide-details
                v-model="deletePowerBiGroup"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text @click.right.prevent @copy.prevent @paste.prevent>
          {{ $t("subtitle", { name: connection?.tap?.name }) }}
          <v-form ref="form" onSubmit="return false;" v-model="isFormValid">
            <v-row :class="['mt-5', 'py-0']">
              <v-col :class="['my-0', 'py-0']" cols="6">
                <v-text-field
                  :label="`${$tc('label.tap', 1)} ${$tc('label.name', 1)}`"
                  :rules="[
                    rules.required,
                    rules.matching(this.connection?.tap?.name),
                  ]"
                  outlined
                  v-model="tapNameConfirm"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="save()"
            :class="['black--text', 'mr-2']"
            :disabled="!isFormValid"
            :loading="isSaving"
            rounded
            small
            text
          >
            {{ $t("label.confirm") }}
          </v-btn>
          <v-btn
            @click="close()"
            :color="'success'"
            :loading="isSaving"
            rounded
            small
          >
            {{ $t("label.back") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
  name: "tap-delete-dialog",
  components: {},
  props: ["connection", "dialog"],

  apollo: {},

  data() {
    return {
      deleteAzureResourceGroup: false,
      deletePowerBiGroup: false,
      error: null,
      isFormValid: false,
      isSaving: false,
      tapNameConfirm: null,
    };
  },
  computed: {
    me() {
      return this.$store.state.user.me || {};
    },
  },
  watch: {
    dialog(val) {
      this.resetForm();
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    resetForm() {
      this.deleteAzureResourceGroup = false;
      this.deletePowerBiGroup = false;
      this.error = null;
      this.isSaving = false;
      this.tapNameConfirm = null;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        id: this.connection.id,
        deletePowerBiGroup: this.deletePowerBiGroup,
        deleteAzureResourceGroup: this.deleteAzureResourceGroup,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteConnection($input: DeleteConnectionInput!) {
              deleteConnection(input: $input) {
                connection {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Connection successfully deleted`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$emit("deleted");
          this.close();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Are you sure you want to delete this Tap? Please indicate below what items should be deleted immediately.",
    "subtitle": "Enter the Tap name \"{name}\" to confirm.",
    "deleteAzure": "Delete complete Azure resource group",
    "deletePBI": "Delete complete PBI group"
  },
  "nl": {
    "title": "Weet je zeker dat je deze Tap wilt verwijderen? Geef hieronder aan welke zaken er direct mee verwijderd moeten worden.",
    "subtitle": "Voer de Tap naam \"{name}\" in om te bevestigen.",
    "deleteAzure": "Verwijder volledige Azure resourcegroep",
    "deletePBI": "Verwijder de volledige PBI groep"
  },
  "de": {
    "title": "Sind Sie sicher, dass Sie diesen Tap löschen möchten? Bitte geben Sie unten an, welche Elemente sofort gelöscht werden sollen.",
    "subtitle": "Geben Sie den Tap-Namen \"{name}\" ein, um zu bestätigen.",
    "deleteAzure": "Löschen Sie die komplette Azure-Ressourcengruppe",
    "deletePBI": "Löschen Sie die komplette PBI-Gruppe"
  }
}
</i18n>

import * as types from "../types";
import {
	ACCESS_TOKEN_KEY,
	REFRESH_TOKEN_KEY,
	apolloClient,
	onLogin,
	onLogout,
} from "@/vue-apollo";

import {
	ALL_USER_TASKS_QUERY,
	OVERALL_STATUS_QUERY,
	LOGIN_MUTATION,
	LOGOUT_MUTATION,
	ME_QUERY,
	LOGIN_AS_OTHER_USER_MUTATION,
	LOGOUT_AS_OTHER_USER_MUTATION,
	LOGIN_WITH_AZURE_AD_MUTATION,
} from "@/graphql/user.js";

const token = localStorage.getItem(ACCESS_TOKEN_KEY);
const me = JSON.parse(localStorage.getItem("me"));
const original_token = localStorage.getItem("original_" + ACCESS_TOKEN_KEY);

const state = token
	? {
			loggedIn: true,
			me: me,
			loggedInAsOtherUser: original_token ? true : false,
			searchUsers: "",
			searchTenants: "",
			searchGroups: "",
			searchThemes: "",
			allUserTasks: null,
			overallStatus: null,
	  }
	: {
			loggedIn: false,
			me: null,
			loggedInAsOtherUser: false,
			searchUsers: "",
			searchTenants: "",
			searchGroups: "",
			searchThemes: "",
			allUserTasks: null,
			overallStatus: null,
	  };

const getters = {};

const mutations = {
	[types.LOGIN](state) {
		state.loggedIn = true;
	},
	[types.LOGOUT](state) {
		state.loggedIn = false;
		state.me = null;
	},
	[types.SET_ME](state, me) {
		state.me = me;
	},
	[types.LOGIN_AS_OTHER_USER](state) {
		state.loggedInAsOtherUser = true;
	},
	[types.LOGOUT_AS_OTHER_USER](state) {
		state.loggedInAsOtherUser = false;
	},
	[types.LOGIN_WITH_AZURE_AD](state) {
		state.loggedIn = true;
	},
	[types.SET_ALL_USER_TASKS](state, allUserTasks) {
		state.allUserTasks = allUserTasks;
	},
	[types.SET_OVERALL_STATUS](state, overallStatus) {
		state.overallStatus = overallStatus;
	},
	setSearchUsers(state, search) {
		state.searchUsers = search;
	},
	setSearchTenants(state, search) {
		state.searchTenants = search;
	},
	setSearchGroups(state, search) {
		state.searchGroups = search;
	},
	setSearchThemes(state, search) {
		state.searchThemes = search;
	},
};
const actions = {
	async login(context, payload) {
		return new Promise((resolve, reject) => {
			apolloClient
				.mutate({
					mutation: LOGIN_MUTATION,
					variables: {
						email: payload.email,
						password: payload.password,
						tfaCode: payload.tfaCode,
					},
				})
				.then((response) => {
					const { token, refreshToken } = response.data.tokenAuth;

					localStorage.setItem(ACCESS_TOKEN_KEY, token);
					localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

					// reset apollo client state
					onLogin(apolloClient);

					// commit vuex store state
					context.commit(types.LOGIN);

					resolve();
				})
				.catch((error) => {
					onLogout(apolloClient);

					console.log("login failed!", error);
					reject(error);
				});
		});
	},

	async fetchMe(context) {
		return new Promise((resolve, reject) => {
			apolloClient
				.query({
					query: ME_QUERY,
					fetchPolicy: "network-only",
				})
				.then((response) => {
					localStorage.setItem(
						"me",
						JSON.stringify(response.data.me)
					);
					context.commit(types.SET_ME, { ...response.data.me });

					resolve();
				})
				.catch((error) => {
					onLogout(apolloClient);

					console.log("fetchme failed!", error);
					reject(error);
				});
		});
	},

	async fetchAllUserTasks(context) {
		return new Promise((resolve, reject) => {
			apolloClient
				.query({
					query: ALL_USER_TASKS_QUERY,
					fetchPolicy: "network-only",
				})
				.then((response) => {
					context.commit(types.SET_ALL_USER_TASKS, {
						...response.data,
					});

					resolve();
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	
	async fetchOverallStatus(context) {
		return new Promise((resolve, reject) => {
			apolloClient
				.query({
					query: OVERALL_STATUS_QUERY,
					fetchPolicy: "network-only",
				})
				.then((response) => {
					context.commit(types.SET_OVERALL_STATUS, {
						...response.data.allSystems.edges[0].node.state,
					});

					resolve();
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},

	async loginAsOtherUser(context, payload) {
		return new Promise((resolve, reject) => {
			const input = {
				userId: payload.userId,
			};

			apolloClient
				.mutate({
					mutation: LOGIN_AS_OTHER_USER_MUTATION,
					variables: {
						input: input,
					},
				})
				.then((response) => {
					// backup original tokens
					localStorage.setItem(
						"original_" + ACCESS_TOKEN_KEY,
						localStorage.getItem(ACCESS_TOKEN_KEY)
					);
					localStorage.setItem(
						"original_" + REFRESH_TOKEN_KEY,
						localStorage.getItem(REFRESH_TOKEN_KEY)
					);

					// replace tokens
					const { token, refreshToken } =
						response.data.loginAsOtherUser;

					localStorage.setItem(ACCESS_TOKEN_KEY, token);
					localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

					// TODO: clear apollo client cache

					// // apolloClient.clearStore();
					// return new Promise(()=> {
					//     apolloClient.clearStore()
					// });
					// // reset apollo client state
					// onLogin(apolloClient);
					return true;
				})
				.then(() => {
					return context.dispatch("fetchMe");
				})
				.then(() => {
					context.commit("LOGIN_AS_OTHER_USER");
					resolve();
					// // reset apollo client state
					// onLogin(apolloClient);
				})
				.catch((error) => {
					onLogout(apolloClient);

					console.log("login as other user failed!", error);
					reject(error);
				});
		});
	},

	async logoutAsOtherUser(context) {
		return new Promise((resolve, reject) => {
			const input = {
				refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
			};

			apolloClient
				.mutate({
					mutation: LOGOUT_AS_OTHER_USER_MUTATION,
					variables: {
						input: input,
					},
				})
				.then(() => {
					// restore original tokens
					localStorage.setItem(
						ACCESS_TOKEN_KEY,
						localStorage.getItem("original_" + ACCESS_TOKEN_KEY)
					);
					localStorage.setItem(
						REFRESH_TOKEN_KEY,
						localStorage.getItem("original_" + REFRESH_TOKEN_KEY)
					);

					// remove backup tokens
					localStorage.removeItem("original_" + ACCESS_TOKEN_KEY);
					localStorage.removeItem("original_" + REFRESH_TOKEN_KEY);

					// reset apollo client state
					// await onLogin(apolloClient);
					apolloClient.clearStore();
					return;
					// context.commit(types.LOGIN);
				})
				.then(() => {
					context.dispatch("fetchMe");
					context.commit("LOGOUT_AS_OTHER_USER");

					resolve();
				})
				.catch((error) => {
					// localStorage.removeItem("token");
					onLogout(apolloClient);

					console.log("logout as other user failed!", error);
					reject(error);
				});
		});
	},

	async loginWithAzureAd(context, payload) {
		return new Promise((resolve, reject) => {
			const input = {
				msalAccessToken: payload.msalAccessToken,
			};

			apolloClient
				.mutate({
					mutation: LOGIN_WITH_AZURE_AD_MUTATION,
					variables: {
						input: input,
					},
				})
				.then((response) => {
					const { token, refreshToken } =
						response.data.loginWithAzureAd;

					localStorage.setItem(ACCESS_TOKEN_KEY, token);
					localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

					// reset apollo client state
					onLogin(apolloClient);

					// commit vuex store state
					context.commit(types.LOGIN_WITH_AZURE_AD);

					resolve();
				})
				.catch((error) => {
					onLogout(apolloClient);

					console.log("login failed!", error);
					reject(error);
				});
		});
	},

	async logout(context) {
		return new Promise((resolve, reject) => {
			const input = {
				refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
			};

			apolloClient
				.mutate({
					mutation: LOGOUT_MUTATION,
					variables: {
						input: input,
					},
				})
				.then(() => {
					// remove token from local storage
					localStorage.removeItem("me");
					localStorage.removeItem(ACCESS_TOKEN_KEY);
					localStorage.removeItem(REFRESH_TOKEN_KEY);
					// removed backup tokens
					localStorage.removeItem("original_" + ACCESS_TOKEN_KEY);
					localStorage.removeItem("original_" + REFRESH_TOKEN_KEY);

					context.commit(types.LOGOUT);
					context.commit(types.LOGOUT_AS_OTHER_USER);
				})
				.then(() => {
					// // reset apollo client state
					onLogout(apolloClient);
					//   console.log("login succeed! ", token);
					resolve();
				})
				.catch((error) => {
					// localStorage.removeItem("token");
					onLogout(apolloClient);

					console.log("logout failed!", error);
					reject(error);
				});
		});
	},

	searchUsers(context, search) {
		context.commit("setSearchUsers", search);
	},

	searchTenants(context, search) {
		context.commit("setSearchTenants", search);
	},

	searchGroups(context, search) {
		context.commit("setSearchGroups", search);
	},

	searchThemes(context, search) {
		context.commit("setSearchThemes", search);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};

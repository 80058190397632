var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('label.search'),"clearable":"","dense":"","hide-details":"","outlined":"","required":""},on:{"click:append-outer":function($event){return _vm.refresh()},"click:clear":function($event){return _vm.clearSearch()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.refresh()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.me.isSuperuser || _vm.me.isPartnerUser)?_c('v-col',{attrs:{"cols":"12","md":"2","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.tenants.edges,"label":_vm.$tc('label.tenant', 1),"loading":_vm.$apollo.queries.tenants.loading,"clearable":"","dense":"","hide-details":"","item-text":"node.tenantName","item-value":"node.id","outlined":""},on:{"change":function($event){return _vm.refresh()}},model:{value:(_vm.selectedTenant),callback:function ($$v) {_vm.selectedTenant=$$v},expression:"selectedTenant"}})],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"2","align":"right"}},[_c('v-btn',{attrs:{"color":"primary","dark":"","rounded":"","small":"","text":""},on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v(" "+_vm._s(_vm.$t("label.clearFilters"))+" ")])],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{attrs:{"footer-props":{
				'disable-pagination':
					_vm.$apollo.queries.allReportGroups.loading,
				'items-per-page-options': [10, 25, 50, 75, 100],
			},"headers":_vm.me.isSuperuser || _vm.me.isPartnerUser
					? _vm.headersSuperuser
					: _vm.headers,"items":_vm.allReportGroups.edges,"loading":_vm.$apollo.queries.allReportGroups.loading,"options":_vm.dataTableOptions,"server-items-length":_vm.allReportGroups.totalCount,"show-select":true,"item-key":"node.id"},on:{"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:`item.action`,fn:function({ item }){return [_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.edit(item.node)}}},[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1),_c('DeleteDialog',{attrs:{"dialog":_vm.deleteDialog},on:{"update:dialog":function($event){_vm.deleteDialog=$event},"confirmed":_vm.onDeleteConfirmed}}),_c('ReportGroupEditDialog',{attrs:{"dialog":_vm.editDialog,"object":_vm.editedItem},on:{"update:dialog":function($event){_vm.editDialog=$event},"update:object":function($event){_vm.editedItem=$event},"changed":_vm.onChanged}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
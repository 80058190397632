<template>
  <v-form ref="form" v-model="isValid">
    <v-row class="my-0 py-0">
      <v-col
        :cols="field.cols || 12"
        :key="index"
        class="my-0 py-0"
        v-for="(field, index) in filteredFields"
      >
        <DynamicFormField
          :field="field"
          :value="formData[field.model]"
          @value-changed="updateFieldValue(field.model, $event)"
          ref="DynamicFormField"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import DynamicFormField from "@/components/base/DynamicFormField.vue";

export default {
  name: "dynamic-form",
  props: {
    fields: Array,
    formData: Object,
  },
  components: {
    DynamicFormField,
  },

  data: function () {
    return {
      error: null,
      isValid: false,
    };
  },

  computed: {
    filteredFields() {
      return this.fields.filter(field => {
        if (!field.condition) return true; // No condition, always show field
        try {
          return new Function('formData', `return ${field.condition};`)(this.formData);
        } catch (e) {
          console.error(`Error evaluating condition: ${field.condition}`, e);
          return false;
        }
      });
    },
  },

  watch: {
    isValid(val) {
      this.$emit("is-valid-changed", val);
    },
  },

  created() {},

  mounted() {},

  methods: {
    updateFieldValue(model, value) {
      this.$set(this.formData, model, value);
    },

    resetForm() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

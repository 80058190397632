const state = {
	tabs: [],
};

const mutations = {
	SET_TABS(state, payload) {
		state.tabs = payload;
	},
};

const actions = {
	setTabs(context, payload) {
		context.commit("SET_TABS", payload);
	},
};

const getters = {
	getTabs(state) {
		return state.tabs;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};

export const resourceGroup = {
  $schema:
    "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
  contentVersion: "1.0.0.0",
  outputs: {},
  parameters: {
    resource_group: { type: "string" },
    resource_group_location: { type: "string" },
    resource_tags: { type: "object" },
  },
  resources: [
    {
      apiVersion: "2021-04-01",
      location: "[parameters('resource_group_location')]",
      name: "[parameters('resource_group')]",
      properties: {},
      tags: "[parameters('resource_tags')]",
      type: "Microsoft.Resources/resourceGroups",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "lockDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2016-09-01",
              name: "RGLock",
              properties: {
                level: "CanNotDelete",
                notes:
                  "Resource group and its resources should not be deleted.",
              },
              type: "Microsoft.Authorization/locks",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
  ],
  variables: {},
};

export const keyVault = {
  $schema:
    "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
  contentVersion: "1.0.0.0",
  outputs: {},
  parameters: {
    key_vault: { type: "string" },
    resource_group: { type: "string" },
    resource_group_location: { type: "string" },
    resource_tags: { type: "object" },
  },
  resources: [
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "vaultDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-07-01",
              location: "[parameters('resource_group_location')]",
              name: "[parameters('key_vault')]",
              properties: {
                accessPolicies: [],
                enableRbacAuthorization: true,
                enableSoftDelete: true,
                enabledForDeployment: false,
                enabledForDiskEncryption: false,
                enabledForTemplateDeployment: false,
                provisioningState: "Succeeded",
                publicNetworkAccess: "Enabled",
                sku: { family: "A", name: "Standard" },
                softDeleteRetentionInDays: 90,
                tenantId: "ce278e4a-fec6-419a-bde1-407f367aed79",
              },
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.KeyVault/vaults",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
  ],
  variables: {},
};

export const dataFactory = {
  $schema:
    "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
  contentVersion: "1.0.0.0",
  outputs: {},
  parameters: {
    action_group: { type: "string" },
    data_factory: { type: "string" },
    resource_group: { type: "string" },
    resource_group_location: { type: "string" },
    resource_tags: { type: "object" },
  },
  resources: [
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "dataFactoryDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2018-06-01",
              identity: { type: "SystemAssigned" },
              location: "[parameters('resource_group_location')]",
              name: "[parameters('data_factory')]",
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.DataFactory/factories",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: ["dataFactoryDeployment"],
      name: "alertDeploymentDataFactory",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              type: "microsoft.insights/metricAlerts",
              apiVersion: "2018-03-01",
              name: "[concat(parameters('data_factory'), ' failed')]",
              location: "global",
              properties: {
                severity: 1,
                enabled: true,
                scopes: [
                  "[concat('/subscriptions/aa86c9a9-d98a-474c-9634-5eb69681a197/resourceGroups/', parameters('resource_group'), '/providers/Microsoft.DataFactory/factories/', parameters('data_factory'))]",
                ],
                evaluationFrequency: "PT1M",
                windowSize: "PT5M",
                criteria: {
                  allOf: [
                    {
                      threshold: 0,
                      name: "Metric1",
                      metricNamespace: "Microsoft.DataFactory/factories",
                      metricName: "PipelineFailedRuns",
                      operator: "GreaterThan",
                      timeAggregation: "Total",
                      skipMetricValidation: false,
                      criterionType: "StaticThresholdCriterion",
                    },
                  ],
                  "odata.type":
                    "Microsoft.Azure.Monitor.SingleResourceMultipleMetricCriteria",
                },
                autoMitigate: false,
                targetResourceType: "Microsoft.DataFactory/factories",
                targetResourceRegion: "westeurope",
                actions: [
                  {
                    actionGroupId: "[parameters('action_group')]",
                    webHookProperties: {},
                  },
                ],
              },
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
  ],
  variables: {},
};

export const database = {
  $schema:
    "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
  contentVersion: "1.0.0.0",
  outputs: {},
  parameters: {
    key_vault: { type: "string" },
    resource_group: { type: "string" },
    resource_group_location: { type: "string" },
    resource_tags: { type: "object" },
    sql_server: { type: "string" },
    sql_database: { type: "string" },
    sql_user: { type: "string" },
    sql_password: { type: "SecureString" },
    sql_server_location: { type: "string" },
  },
  resources: [
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "vaultDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-07-01",
              location: "[parameters('resource_group_location')]",
              name: "[parameters('key_vault')]",
              properties: {
                accessPolicies: [],
                enableRbacAuthorization: true,
                enableSoftDelete: true,
                enabledForDeployment: false,
                enabledForDiskEncryption: false,
                enabledForTemplateDeployment: false,
                provisioningState: "Succeeded",
                publicNetworkAccess: "Enabled",
                sku: { family: "A", name: "Standard" },
                softDeleteRetentionInDays: 90,
                tenantId: "ce278e4a-fec6-419a-bde1-407f367aed79",
              },
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.KeyVault/vaults",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "serverDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-02-01-preview",
              kind: "v12.0",
              location: "[parameters('sql_server_location')]",
              name: "[parameters('sql_server')]",
              properties: {
                administratorLogin: "[parameters('sql_user')]",
                administratorLoginPassword: "[parameters('sql_password')]",
                administrators: {
                  administratorType: "ActiveDirectory",
                  azureADOnlyAuthentication: false,
                  login: "admin@taptarget.io",
                  principalType: "Group",
                  sid: "1ba19d7e-9603-487e-adf2-eca66d138695",
                  tenantId: "ce278e4a-fec6-419a-bde1-407f367aed79",
                },
                publicNetworkAccess: "Enabled",
                restrictOutboundNetworkAccess: "Disabled",
                version: "12.0",
              },
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.Sql/servers",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: ["serverDeployment"],
      name: "serverFirewallDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-02-01-preview",
              name: "[concat(parameters('sql_server'), '/AllowAllWindowsAzureIps')]",
              properties: {
                endIpAddress: "0.0.0.0",
                startIpAddress: "0.0.0.0",
              },
              type: "Microsoft.Sql/servers/firewallRules",
            },
            {
              apiVersion: "2022-02-01-preview",
              name: "[concat(parameters('sql_server'), '/TapTarget')]",
              properties: {
                endIpAddress: "185.26.57.130",
                startIpAddress: "185.26.57.130",
              },
              type: "Microsoft.Sql/servers/firewallRules",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: ["serverDeployment"],
      name: "databaseDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-02-01-preview",
              kind: "v12.0,user",
              location: "[parameters('sql_server_location')]",
              name: "[concat(parameters('sql_server'), '/', parameters('sql_database'))]",
              properties: {
                catalogCollation: "SQL_Latin1_General_CP1_CI_AS",
                collation: "SQL_Latin1_General_CP1_CI_AS",
                isLedgerOn: false,
                maintenanceConfigurationId:
                  "/subscriptions/aa86c9a9-d98a-474c-9634-5eb69681a197/providers/Microsoft.Maintenance/publicMaintenanceConfigurations/SQL_Default",
                maxSizeBytes: 268435456000,
                readScale: "Disabled",
                requestedBackupStorageRedundancy: "Geo",
                zoneRedundant: false,
              },
              sku: {
                capacity: 10,
                name: "Standard",
                tier: "Standard",
              },
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.Sql/servers/databases",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: ["vaultDeployment"],
      name: "secretDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-07-01",
              name: "[concat(parameters('key_vault'), '/AzureSQL')]",
              properties: {
                contentType: "text/plain",
                value: "[parameters('sql_password')]",
              },
              tags: {
                Type: "Conection String",
                Url: "[concat(parameters('sql_server'), '.database.windows.net')]",
                Username: "[parameters('sql_user')]",
              },
              type: "Microsoft.KeyVault/vaults/secrets",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
  ],
  variables: {},
};

export const storageAccount = {
  $schema:
    "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
  contentVersion: "1.0.0.0",
  outputs: {},
  parameters: {
    resource_group: { type: "string" },
    resource_group_location: { type: "string" },
    resource_tags: { type: "object" },
    storage_account: { type: "string" },
  },
  resources: [
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "storageDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2022-05-01",
              kind: "StorageV2",
              location: "[parameters('resource_group_location')]",
              name: "[parameters('storage_account')]",
              properties: {
                accessTier: "Hot",
                allowBlobPublicAccess: true,
                allowCrossTenantReplication: true,
                allowSharedKeyAccess: true,
                defaultToOAuthAuthentication: false,
                encryption: {
                  keySource: "Microsoft.Storage",
                  services: {
                    blob: {
                      enabled: true,
                      keyType: "Account",
                    },
                    file: {
                      enabled: true,
                      keyType: "Account",
                    },
                  },
                },
                minimumTlsVersion: "TLS1_2",
                networkAcls: {
                  bypass: "AzureServices",
                  defaultAction: "Allow",
                  ipRules: [],
                  virtualNetworkRules: [],
                },
                supportsHttpsTrafficOnly: true,
              },
              sku: {
                name: "Standard_LRS",
                tier: "Standard",
              },
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.Storage/storageAccounts",
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
  ],
  variables: {},
};

export const logicApp = {
  $schema:
    "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
  contentVersion: "1.0.0.0",
  outputs: {},
  parameters: {
    action_group: { type: "string" },
    logic_app: { type: "string" },
    resource_group: { type: "string" },
    resource_group_location: { type: "string" },
    resource_tags: { type: "object" },
  },
  resources: [
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "logicAppDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2016-10-01",
              location: "[parameters('resource_group_location')]",
              name: "[parameters('logic_app')]",
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.Logic/workflows",
              properties: {
                state: "Enabled",
                definition: {
                  contentVersion: "1.0.0.0",
                  parameters: {},
                  actions: {},
                  triggers: {},
                  outputs: {},
                  $schema:
                    "https://schema.management.azure.com/providers/Microsoft.Logic/schemas/2016-06-01/workflowdefinition.json#",
                },
              },
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: ["logicAppDeployment"],
      name: "alertDeploymentLogicApp",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              type: "microsoft.insights/metricAlerts",
              apiVersion: "2018-03-01",
              name: "[concat(parameters('logic_app'), ' failed')]",
              location: "global",
              properties: {
                severity: 1,
                enabled: true,
                scopes: [
                  "[concat('/subscriptions/aa86c9a9-d98a-474c-9634-5eb69681a197/resourceGroups/', parameters('resource_group'), '/providers/Microsoft.Logic/workflows/', parameters('logic_app'))]",
                ],
                evaluationFrequency: "PT1M",
                windowSize: "PT5M",
                criteria: {
                  allOf: [
                    {
                      threshold: 0,
                      name: "Metric1",
                      metricNamespace: "Microsoft.Logic/workflows",
                      metricName: "RunsFailed",
                      operator: "GreaterThan",
                      timeAggregation: "Total",
                      skipMetricValidation: false,
                      criterionType: "StaticThresholdCriterion",
                    },
                  ],
                  "odata.type":
                    "Microsoft.Azure.Monitor.SingleResourceMultipleMetricCriteria",
                },
                autoMitigate: false,
                targetResourceType: "Microsoft.Logic/workflows",
                targetResourceRegion: "westeurope",
                actions: [
                  {
                    actionGroupId: "[parameters('action_group')]",
                    webHookProperties: {},
                  },
                ],
              },
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
  ],
  variables: {},
};

export const logicAppExcel = {
  $schema:
    "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
  contentVersion: "1.0.0.0",
  outputs: {},
  parameters: {
    action_group: { type: "string" },
    logic_app: { type: "string" },
    resource_group: { type: "string" },
    resource_group_location: { type: "string" },
    resource_tags: { type: "object" },
    storage_account_name: { type: "string" },
    storage_access_key: { type: "string" },
    blob_connection_name: { type: "string" },
    data_factory_connection_name: { type: "string" },
    data_factory_email: { type: "string" },
    data_factory_name: { type: "string" },
    data_factory_pipeline_name: { type: "string" },
    sharepoint_connection_name: { type: "string" },
    sharepoint_email: { type: "string" },
    sharepoint_site_url: { type: "string" },
  },
  resources: [
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "azureblobConnectionDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2016-06-01",
              location: "[parameters('resource_group_location')]",
              name: "[parameters('blob_connection_name')]",
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.Web/connections",
              properties: {
                api: {
                  id: "[concat('subscriptions/', subscription().subscriptionId, '/providers/Microsoft.Web/locations/', parameters('resource_group_location'), '/managedApis/azureblob')]",
                },
                displayName: "[parameters('storage_account_name')]",
                parameterValues: {
                  accountName: "[parameters('storage_account_name')]",
                  accessKey: "[parameters('storage_access_key')]",
                },
              },
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "dataFactoryConnectionDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2016-06-01",
              location: "[parameters('resource_group_location')]",
              name: "[parameters('data_factory_connection_name')]",
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.Web/connections",
              properties: {
                api: {
                  id: "[concat('subscriptions/', subscription().subscriptionId, '/providers/Microsoft.Web/locations/', parameters('resource_group_location'), '/managedApis/azuredatafactory')]",
                },
                displayName: "[parameters('data_factory_email')]",
              },
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "[resourceId('Microsoft.Resources/resourceGroups/', parameters('resource_group'))]",
      ],
      name: "sharepointConnectionDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2016-06-01",
              location: "[parameters('resource_group_location')]",
              name: "[parameters('sharepoint_connection_name')]",
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.Web/connections",
              properties: {
                api: {
                  id: "[concat('subscriptions/', subscription().subscriptionId, '/providers/Microsoft.Web/locations/', parameters('resource_group_location'), '/managedApis/sharepointonline')]",
                },
                displayName: "[parameters('sharepoint_email')]",
              },
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: [
        "azureblobConnectionDeployment",
        "dataFactoryConnectionDeployment",
        "sharepointConnectionDeployment",
      ],
      name: "logicAppExcelDeployment",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              apiVersion: "2017-07-01",
              location: "[parameters('resource_group_location')]",
              name: "[parameters('logic_app')]",
              tags: "[parameters('resource_tags')]",
              type: "Microsoft.Logic/workflows",
              properties: {
                state: "Enabled",
                definition: {
                  contentVersion: "1.0.0.0",
                  parameters: {
                    $connections: {
                      defaultValue: {},
                      type: "Object",
                    },
                    sharepoint_site_url: {
                      type: "String",
                    },
                    subscription_id: {
                      type: "String",
                    },
                    resource_group: {
                      type: "String",
                    },
                    data_factory_name: {
                      type: "String",
                    },
                    data_factory_pipeline_name: {
                      type: "String",
                    },
                  },
                  actions: {
                    Create_a_pipeline_run: {
                      runAfter: {
                        For_each: ["Succeeded"],
                      },
                      type: "ApiConnection",
                      inputs: {
                        host: {
                          connection: {
                            name: "@parameters('$connections')['azuredatafactory']['connectionId']",
                          },
                        },
                        method: "post",
                        path: "/subscriptions/@{encodeURIComponent(parameters('subscription_id'))}/resourcegroups/@{encodeURIComponent(parameters('resource_group'))}/providers/Microsoft.DataFactory/factories/@{encodeURIComponent(parameters('data_factory_name'))}/pipelines/@{encodeURIComponent(parameters('data_factory_pipeline_name'))}/CreateRun",
                        queries: {
                          "x-ms-api-version": "2017-09-01-preview",
                        },
                      },
                    },
                    For_each: {
                      foreach: "@body('List_folder')",
                      actions: {
                        Condition: {
                          actions: {},
                          else: {
                            actions: {
                              "Create_blob_(V2)": {
                                runAfter: {
                                  Get_file_content_using_path: ["Succeeded"],
                                },
                                type: "ApiConnection",
                                inputs: {
                                  host: {
                                    connection: {
                                      name: "@parameters('$connections')['azureblob']['connectionId']",
                                    },
                                  },
                                  method: "post",
                                  body: "@body('Get_file_content_using_path')",
                                  headers: {
                                    ReadFileMetadataFromServer: true,
                                  },
                                  path: "/v2/datasets/@{encodeURIComponent(encodeURIComponent('AccountNameFromSettings'))}/files",
                                  queries: {
                                    folderPath: "/dwh/data/",
                                    name: "@items('For_each')?['DisplayName']",
                                    queryParametersSingleEncoded: true,
                                  },
                                },
                                runtimeConfiguration: {
                                  contentTransfer: {
                                    transferMode: "Chunked",
                                  },
                                },
                              },
                              Get_file_content_using_path: {
                                type: "ApiConnection",
                                inputs: {
                                  host: {
                                    connection: {
                                      name: "@parameters('$connections')['sharepointonline']['connectionId']",
                                    },
                                  },
                                  method: "get",
                                  path: "/datasets/@{encodeURIComponent(encodeURIComponent(parameters('sharepoint_site_url')))}/GetFileContentByPath",
                                  queries: {
                                    path: "@items('For_each')?['Path']",
                                    inferContentType: true,
                                    queryParametersSingleEncoded: true,
                                  },
                                },
                              },
                            },
                          },
                          expression: {
                            and: [
                              {
                                equals: [
                                  "@items('For_each')?['IsFolder']",
                                  "@true",
                                ],
                              },
                            ],
                          },
                          type: "If",
                        },
                      },
                      runAfter: {
                        List_folder: ["Succeeded"],
                      },
                      type: "Foreach",
                    },
                    List_folder: {
                      runAfter: {},
                      metadata: {
                        "%252fGedeelde%2bdocumenten%252fData":
                          "/Gedeelde documenten/Data",
                        "%252fShared%2bDocuments%252fData":
                          "/Shared Documents/Data",
                      },
                      type: "ApiConnection",
                      inputs: {
                        host: {
                          connection: {
                            name: "@parameters('$connections')['sharepointonline']['connectionId']",
                          },
                        },
                        method: "get",
                        path: "/datasets/@{encodeURIComponent(encodeURIComponent(parameters('sharepoint_site_url')))}/folders/@{encodeURIComponent('%252fGedeelde%2bdocumenten%252fData')}",
                      },
                    },
                  },
                  triggers: {
                    Recurrence: {
                      recurrence: {
                        frequency: "Day",
                        interval: 1,
                        schedule: {
                          hours: ["1"],
                          minutes: [0],
                        },
                        startTime: "2023-12-01 00:00:00",
                        timeZone: "W. Europe Standard Time",
                      },
                      type: "Recurrence",
                    },
                  },
                  outputs: {},
                  $schema:
                    "https://schema.management.azure.com/providers/Microsoft.Logic/schemas/2016-06-01/workflowdefinition.json#",
                },
                parameters: {
                  $connections: {
                    value: {
                      azuredatafactory: {
                        id: "[concat('subscriptions/', subscription().subscriptionId, '/providers/Microsoft.Web/locations/', parameters('resource_group_location'), '/managedApis/azuredatafactory')]",
                        connectionId:
                          "[resourceId(subscription().subscriptionId, parameters('resource_group'), 'Microsoft.Web/connections', parameters('data_factory_connection_name'))]",
                        connectionName:
                          "[parameters('data_factory_connection_name')]",
                      },
                      azureblob: {
                        id: "[concat('subscriptions/', subscription().subscriptionId, '/providers/Microsoft.Web/locations/', parameters('resource_group_location'), '/managedApis/azureblob')]",
                        connectionId:
                          "[resourceId(subscription().subscriptionId, parameters('resource_group'), 'Microsoft.Web/connections', parameters('blob_connection_name'))]",
                        connectionName: "[parameters('blob_connection_name')]",
                      },
                      sharepointonline: {
                        id: "[concat('subscriptions/', subscription().subscriptionId, '/providers/Microsoft.Web/locations/', parameters('resource_group_location'), '/managedApis/sharepointonline')]",
                        connectionId:
                          "[resourceId(subscription().subscriptionId, parameters('resource_group'), 'Microsoft.Web/connections', parameters('sharepoint_connection_name'))]",
                        connectionName:
                          "[parameters('sharepoint_connection_name')]",
                      },
                    },
                  },
                  sharepoint_site_url: {
                    value: "[parameters('sharepoint_site_url')]"
                  },
                  subscription_id: {
                    value: "[subscription().subscriptionId]"
                  },
                  resource_group: {
                    value: "[parameters('resource_group')]"
                  },
                  data_factory_name: {
                    value: "[parameters('data_factory_name')]"
                  },
                  data_factory_pipeline_name: {
                    value: "[parameters('data_factory_pipeline_name')]"
                  }
                },
              },
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
    {
      apiVersion: "2021-04-01",
      dependsOn: ["logicAppExcelDeployment"],
      name: "alertDeploymentLogicAppExcel",
      properties: {
        mode: "Incremental",
        template: {
          $schema:
            "https://schema.management.azure.com/schemas/2019-04-01/deploymentTemplate.json#",
          contentVersion: "1.0.0.0",
          outputs: {},
          parameters: {},
          resources: [
            {
              type: "microsoft.insights/metricAlerts",
              apiVersion: "2018-03-01",
              name: "[concat(parameters('logic_app'), ' failed')]",
              location: "global",
              properties: {
                severity: 1,
                enabled: true,
                scopes: [
                  "[concat('/subscriptions/aa86c9a9-d98a-474c-9634-5eb69681a197/resourceGroups/', parameters('resource_group'), '/providers/Microsoft.Logic/workflows/', parameters('logic_app'))]",
                ],
                evaluationFrequency: "PT1M",
                windowSize: "PT5M",
                criteria: {
                  allOf: [
                    {
                      threshold: 0,
                      name: "Metric1",
                      metricNamespace: "Microsoft.Logic/workflows",
                      metricName: "RunsFailed",
                      operator: "GreaterThan",
                      timeAggregation: "Total",
                      skipMetricValidation: false,
                      criterionType: "StaticThresholdCriterion",
                    },
                  ],
                  "odata.type":
                    "Microsoft.Azure.Monitor.SingleResourceMultipleMetricCriteria",
                },
                autoMitigate: false,
                targetResourceType: "Microsoft.Logic/workflows",
                targetResourceRegion: "westeurope",
                actions: [
                  {
                    actionGroupId: "[parameters('action_group')]",
                    webHookProperties: {},
                  },
                ],
              },
            },
          ],
          variables: {},
        },
      },
      resourceGroup: "[parameters('resource_group')]",
      type: "Microsoft.Resources/deployments",
    },
  ],
  variables: {},
};

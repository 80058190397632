<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="1300px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          {{ formTitle }}
          <v-spacer></v-spacer>
          <v-btn icon @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-row class="my-0 py-0">
          <v-col :class="['my-0', 'py-0']" cols="12">
            <v-stepper flat v-model="step" vertical>
              <v-stepper-step :complete="step > 1" step="1">
                Selecteer tenant
                <small v-if="step > 1">{{
                  selectedTenant?.node?.tenantName
                }}</small>
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-card flat>
                  <v-card-text>
                    <v-row class="my-0 py-0">
                      <v-col cols="12" md="4" class="my-0 py-0">
                        <v-autocomplete
                          :items="tenants.edges"
                          :label="$tc('label.tenant', 1)"
                          :loading="$apollo.queries.tenants.loading"
                          :rules="[rules.required]"
                          item-text="node.tenantName"
                          item-value="node.id"
                          outlined
                          return-object
                          v-model="selectedTenant"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :color="'primary'"
                      :disabled="!selectedTenant"
                      @click="step = 2"
                      rounded
                      small
                    >
                      {{ $t("label.next") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-step :complete="step > 2" step="2">
                Selecteer dashboards
                <small v-if="step > 2"
                  >{{ selectedDashboards.length }} dashboards
                  geselecteerd</small
                >
              </v-stepper-step>

              <v-stepper-content step="2">
                <v-card flat>
                  <v-card-text>
                    <v-row
                      class="fill-height"
                      align-content="center"
                      justify="center"
                      v-if="isSyncing"
                    >
                      <v-col class="text-center" cols="12">
                        Power BI wordt gesynct
                      </v-col>
                      <v-col cols="6">
                        <v-progress-linear
                          indeterminate
                          rounded
                          height="6"
                        ></v-progress-linear>
                      </v-col>
                    </v-row>

                    <v-row class="my-0 py-0" v-else>
                      <v-col cols="12" class="my-0 py-0">
                        <v-autocomplete
                          :items="dashboards.edges"
                          :label="$tc('label.dashboard', 1)"
                          :loading="$apollo.queries.dashboards.loading"
                          :rules="[rules.required]"
                          item-text="node.name"
                          item-value="node.id"
                          multiple
                          outlined
                          v-model="selectedDashboards"
                        >
                          <template slot="selection" slot-scope="{ item }">
                            <v-chip small>
                              {{ item.node.resource.name }} -
                              {{ item.node.name }}
                            </v-chip>
                          </template>
                          <template slot="item" slot-scope="{ item }">
                            <v-icon color="primary" class="mr-2">
                              {{
                                item.node.subTypeExtension === "PowerBIReport"
                                  ? "dashboard"
                                  : "layers"
                              }}
                            </v-icon>
                            {{ item.node.resource.name }} - {{ item.node.name }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :class="['black--text', 'mr-2']"
                      @click="step = 1"
                      rounded
                      small
                      text
                    >
                      {{ $t("label.back") }}
                    </v-btn>
                    <v-btn
                      :color="'primary'"
                      :disabled="!selectedDashboards"
                      @click="step = 3"
                      rounded
                      small
                    >
                      {{ $t("label.next") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-step :complete="step > 3" step="3">
                Attributen
              </v-stepper-step>

              <v-stepper-content step="3">
                <v-card flat>
                  <v-card-text>
                    <v-row class="my-0 py-0">
                      <v-col cols="12" md="2" class="my-0 py-0">
                        <v-switch
                          :label="$t('label.editable')"
                          hide-details
                          v-model="isEditable"
                        >
                        </v-switch>
                      </v-col>
                      <v-col cols="12" md="2" class="my-0 py-0">
                        <v-switch
                          hide-details
                          label="Direct Query"
                          v-model="isDirectQuery"
                        >
                        </v-switch>
                      </v-col>
                      <v-col cols="12" md="2" class="my-0 py-0">
                        <v-switch
                          :label="$t('label.standard')"
                          hide-details
                          v-model="isStandard"
                        >
                        </v-switch>
                      </v-col>
                      <v-col cols="12" md="2" class="my-0 py-0">
                        <v-btn
                          :class="['mt-4']"
                          :color="'primary'"
                          @click="openBind()"
                          rounded
                          small
                        >
                          Dataset binden
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="4" class="my-0 py-0">
                        <v-text-field
                          :label="'Binded dataset'"
                          :value="bindedDataset?.name"
                          disabled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="my-0 py-0">
                        <v-text-field
                          :label="$tc('label.tenant', 1)"
                          :value="
                            bindedDataset
                              ? bindedTenant?.tenantName
                              : selectedTenant?.node?.tenantName
                          "
                          disabled
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="my-0 py-0">
                        <v-text-field
                          :label="$t('label.pbiDateTable')"
                          outlined
                          v-model="pbiDateTable"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="my-0 py-0">
                        <v-text-field
                          :label="$t('label.pbiDateColumn')"
                          outlined
                          v-model="pbiDateColumn"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" class="my-0 py-0">
                        <v-autocomplete
                          :item-text="
                            (item) => `${item.node.pk} - ${item.node.tap.name}`
                          "
                          :items="allConnections.edges"
                          :label="$tc('label.tap', 1)"
                          :loading="$apollo.queries.allConnections.loading"
                          clearable
                          item-value="node.id"
                          outlined
                          v-model="selectedConnection"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :class="['black--text', 'mr-2']"
                      @click="step = 2"
                      rounded
                      small
                      text
                    >
                      {{ $t("label.back") }}
                    </v-btn>
                    <v-btn :color="'primary'" @click="save()" rounded small>
                      {{ $t("label.save") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper>
          </v-col>
        </v-row>

        <ReportBindDialog
          :dialog.sync="bindDialog"
          v-on:binded="onBinded"
        ></ReportBindDialog>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";
import ReportBindDialog from "@/components/reports/ReportBindDialog.vue";

export default {
  name: "report-add-dialog",
  components: {
    ReportBindDialog,
  },
  props: ["dialog"],

  apollo: {
    tenants: {
      query: gql`
        query tenants {
          tenants(orderBy: ["tenantName"]) {
            edges {
              node {
                id
                tenantName
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenants,
    },

    dashboards: {
      query: gql`
        query dashboards(
          $first: Int
          $tenant: ID!
          $type_In: String!
          $subType_In: String!
        ) {
          allAssets(
            first: $first
            tenant: $tenant
            type_In: $type_In
            subType_In: $subType_In
            orderBy: ["name"]
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                pk
                name
                parent {
                  id
                  pk
                  name
                }
                subTypeExtension
                resource {
                  id
                  pk
                  name
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allAssets,
      skip: true,
      result() {},
    },

    allConnections: {
      query: gql`
        query allConnections($tenant: ID!) {
          allConnections(tenant: $tenant) {
            edges {
              node {
                id
                pk
                tap {
                  name
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          tenant: this.selectedTenant.node.id,
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allConnections,
      skip() {
        return !this.selectedTenant;
      },
    },
  },

  data() {
    return {
      allConnections: {},
      bindDialog: false,
      bindedDataset: null,
      bindedTenant: null,
      dashboards: {},
      error: null,
      isDirectQuery: false,
      isEditable: false,
      isSaving: false,
      isStandard: false,
      isSyncing: false,
      pbiDateColumn: null,
      pbiDateTable: null,
      selectedDashboards: [],
      selectedConnection: null,
      selectedTenant: null,
      step: 1,
      tenants: {},
    };
  },
  computed: {
    formTitle() {
      return `${this.$t("label.add")} ${this.$tc("label.report", 1)}`;
    },

    me() {
      return this.$store.state.user.me || {};
    },
  },
  watch: {
    dialog(val) {
      this.resetForm();
    },

    selectedTenant: {
      deep: true,
      handler(val) {
        if (val) {
          this.step = 2;
          this.selectedDashboards = [];
          this.selectedConnection = null;
          this.$apollo.queries.allConnections.refresh();
          this.syncResourcesAndAssets();
        }
      },
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    onBinded(dataset, tenant) {
      // console.log(tenant, dataset);
      this.bindedDataset = dataset.node;
      this.bindedTenant = tenant.node;
    },

    openBind() {
      this.bindDialog = true;
    },

    refresh() {
      this.$apollo.queries.dashboards.setVariables({
        first: 100,
        tenant: this.selectedTenant.node.id,
        type_In: "Power BI",
        subType_In: "Report",
      });

      this.$apollo.queries.dashboards.skip = false;
      this.$apollo.queries.dashboards.refresh();
    },

    resetForm() {
      this.error = null;
      this.isSaving = false;

      this.bindedDataset = null;
      this.bindedTenant = null;
      this.isDirectQuery = false;
      this.isEditable = false;
      this.isStandard = false;
      this.isSyncing = false;
      this.pbiDateColumn = null;
      this.pbiDateTable = null;
      this.selectedDashboards = [];
      this.selectedConnection = null;
      this.selectedTenant = null;
      this.step = 1;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    save() {
      this.error = null;
      this.isSaving = true;

      var payload = {
        bindedDataset: this.bindedDataset?.id,
        connection: this.selectedConnection,
        isDirectQuery: this.isDirectQuery,
        isEditable: this.isEditable,
        isStandard: this.isStandard,
        pbiDateColumn: this.pbiDateColumn,
        pbiDateTable: this.pbiDateTable,
        reports: this.selectedDashboards,
        tenant: this.bindedDataset
          ? this.bindedTenant?.id
          : this.selectedTenant?.node?.id,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createReports($input: CreateReportsInput!) {
              createReports(input: $input) {
                report {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Report successfully added`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$emit("changed", response);
          this.close();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    syncResourcesAndAssets() {
      this.isSyncing = true;

      var payload = {
        tenant: this.selectedTenant.node.id,
        syncAzure: false,
        syncOrchest: false,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation syncResourcesAndAssets(
              $input: SyncResourcesAndAssetsInput!
            ) {
              syncResourcesAndAssets(input: $input) {
                clientMutationId
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          this.refresh();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSyncing = false;
        });
    },
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .my-stepper .v-stepper__step {
    flex-grow: 1;
  }
}
</style>

<i18n>
  {}
</i18n>

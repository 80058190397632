<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>
    <v-col cols="12" md="2" sm="6">
      <v-text-field
        :label="$t('label.search')"
        @click:append-outer="refresh()"
        @click:clear="clearSearch()"
        @keydown.enter="refresh()"
        clearable
        dense
        hide-details
        outlined
        required
        v-model="search"
      ></v-text-field>
    </v-col>
    <v-spacer />
    <v-col cols="12" md="2" align="right">
      <v-btn @click="resetFilters()" color="primary" dark rounded small text>
        {{ $t("label.clearFilters") }}
      </v-btn>
    </v-col>
    <v-col cols="12" md="12">
      <v-data-table
        :expanded.sync="expanded"
        :footer-props="{
          'disable-pagination': $apollo.queries.allUserTasks.loading,
          'items-per-page-options': [10, 25, 50, 75, 100],
        }"
        :headers="headers"
        :items="allUserTasks.edges"
        :loading="$apollo.queries.allUserTasks.loading"
        :options.sync="dataTableOptions"
        :server-items-length="allUserTasks.totalCount"
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
        item-key="node.id"
        single-expands
        show-expand
      >
        <template v-slot:[`item.node.pk`]="{ item }">
          #TASK{{ item.node.pk }}
        </template>
        <template v-slot:[`item.node.createdAt`]="{ item }">
          {{ item.node.createdAt | moment("YYYY-MM-DD HH:mm") }}
        </template>
        <template v-slot:[`item.node.taskResult.status`]="{ item }">
          <v-chip
            :color="
              item.node.taskResult.status == 'STARTED'
                ? 'info'
                : item.node.taskResult.status == 'SUCCESS'
                ? 'success'
                : item.node.taskResult.status == 'FAILURE'
                ? 'error'
                : ''
            "
            small
          >
            <v-progress-circular
              :size="14"
              :width="1"
              class="ml-n1 mr-1"
              color="white"
              indeterminate
              v-if="item.node.taskResult.status == 'STARTED'"
            ></v-progress-circular>
            <v-icon left small v-else>
              {{
                item.node.taskResult.status == "SUCCESS"
                  ? "check_circle"
                  : item.node.taskResult.status == "FAILURE"
                  ? "cancel"
                  : ""
              }}
            </v-icon>
            {{ item.node.taskResult.status }}
          </v-chip>
        </template>
        <template v-slot:[`item.node.isDownloaded`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                :color="item.node.isDownloaded ? 'success' : 'error'"
                v-on="on"
                >{{
                  item.node.isDownloaded ? "check_circle" : "cancel"
                }}</v-icon
              >
            </template>
            <span>
              {{ item.node.isDownloaded }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:item.data-table-expand="{ item, isExpanded }">
          <td
            v-if="item.node.taskResult.status == 'SUCCESS'"
            class="text-start"
          >
            <v-btn
              icon
              class="v-data-table__expand-icon"
              :class="{
                'v-data-table__expand-icon--active': isExpanded,
              }"
            >
              <v-icon>expand_more</v-icon>
            </v-btn>
          </td>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-data-table
              :headers="headersFile"
              :hide-default-footer="true"
              :hide-default-header="true"
              :items="item.node.userfileSet.edges"
              :items-per-page="100"
              @click:row="(item) => downloadFile(item.node.id)"
              item-key="item.node.id"
            >
              <template v-slot:[`item.node.pk`]="{ item }">
                #FILE{{ item.node.pk }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon color="primary">
                      <v-icon>file_download</v-icon>
                    </v-btn>
                  </template>
                  <span> Download </span>
                </v-tooltip>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
  name: "tasks-list",
  components: {},

  apollo: {
    allUserTasks: {
      query: gql`
        query allUserTasks(
          $first: Int
          $last: Int
          $before: String
          $after: String
          $orderBy: [String]
          $search: String
        ) {
          allUserTasks(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: $orderBy
            search: $search
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                createdAt
                isDownloaded
                name
                pk
                taskResult {
                  status
                }
                userfileSet {
                  edges {
                    node {
                      id
                      pk
                      privateFileName
                    }
                  }
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allUserTasks,
      skip: true,
      pollInterval: 30000,
    },

    userFile: {
      query: gql`
        query userFile($id: ID!) {
          userFile(id: $id) {
            privateFileName
            privateFilePath
            userTask {
              id
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      update: (data) => data.userFile,
      skip: true,
      error(error) {
        console.error("We've got an error!", error);
      },
    },
  },

  data: function () {
    return {
      dataTableOptions: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: true,
        mustSort: false,
        page: 1,
        sortBy: ["node.createdAt"],
		sortDesc: [true],
      },
	  search: null,
      allUserTasks: {},
      error: null,
      expanded: [],
      headers: [
        {
          text: this.$tc("label.pk", 1),
          value: "node.pk",
          sortable: false,
        },
        {
          text: this.$t("label.created"),
          value: "node.createdAt",
          sortable: true,
        },
        {
          text: this.$tc("label.name", 1),
          value: "node.name",
          sortable: true,
        },
        {
          text: this.$t("label.status"),
          value: "node.taskResult.status",
          sortable: false,
        },
        {
          text: this.$t("isDownloaded"),
          value: "node.isDownloaded",
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
      ],
      headersFile: [
        {
          text: this.$tc("label.pk", 1),
          value: "node.pk",
          sortable: false,
        },
        {
          text: this.$tc("label.name", 1),
          value: "node.privateFileName",
          sortable: false,
        },
        {
          text: this.$t("label.action"),
          value: "action",
          align: "right",
          sortable: false,
        },
      ],
      userFile: {},
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },

  watch: {
    dataTableOptions() {
      this.refresh();
    },

    userFile(val) {
      if (val) {
        this.updateUserTask(val.userTask.id);
        this.downloadDocument(val.privateFileName, val.privateFilePath);
      }
    },
  },

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;
  },

  mounted() {},

  methods: {
    clearSearch() {
      this.search = null;
      this.refresh();
    },

    async downloadDocument(fileName, filePath) {
      try {
        const response = await fetch(filePath);
        const blob = await response.blob();
        const url = await URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        console.log({ err });
      }
    },

    downloadFile(id) {
      this.$apollo.queries.userFile.setVariables({
        id: id,
      });

      this.$apollo.queries.userFile.skip = false;
      this.$apollo.queries.userFile.refresh();
    },

    onChanged() {
      this.$apollo.queries.allUserTasks.refresh();
    },

    refresh() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;

      var _first = itemsPerPage;
      var _last = null;
      var _before = null;
      var _after = null;

      if (page < this.page) {
        _first = null;
        _last = itemsPerPage;
        _before = this.allUserTasks.pageInfo.startCursor;
        _after = null;
      }

      if (page > this.page) {
        _first = itemsPerPage;
        _last = null;
        _before = null;
        _after = this.allUserTasks.pageInfo.endCursor;
      }

      var orderByList = this.orderByList(sortBy, sortDesc);

      this.$apollo.queries.allUserTasks.setVariables({
        first: _first,
        last: _last,
        before: _before,
        after: _after,
        orderBy: orderByList,
        search: this.search,
      });

      this.$apollo.queries.allUserTasks.skip = false;
      this.$apollo.queries.allUserTasks.refresh();

      this.page = page;
    },

    resetFilters() {
      this.clearSearch();
    },

    updateUserTask(userTaskId) {
      this.error = null;

      var payload = {
        id: userTaskId,
        isDownloaded: true,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateUserTask($input: UpdateUserTaskInput!) {
              updateUserTask(input: $input) {
                userTask {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          this.$apollo.queries.allUserTasks.refresh();

          this.$store
            .dispatch("user/fetchAllUserTasks")
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          this.error = error.graphQLErrors[0].message;

          const payload = {
            color: "error",
            message: this.error,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .finally(() => {});
    },
  },
};
</script>

<i18n>
{
  "en": {
	"isDownloaded": "Downloaded by user"
  },
  "nl": {
	"isDownloaded": "Gedownload door gebruiker"
  },
  "de": {
	"isDownloaded": "Wurde vom Benutzer heruntergeladen"
  }
}
</i18n>

<style scoped>
.v-data-table
  >>> .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>

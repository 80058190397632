var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[(_vm.isVerifyingToken)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"mx-2",attrs:{"indeterminate":"","color":"primary"}})],1)]):_c('v-col',{attrs:{"cols":"12","sm":"8","md":"6","lg":"4"}},[(!_vm.isTokenValid)?_c('v-card',{staticClass:"elevation-1 pa-10"},[_c('h3',[_c('v-icon',{staticClass:"mr-5",attrs:{"large":""}},[_vm._v("error_outline")]),_vm._v(" "+_vm._s(_vm.$t("invalidLink"))+" "),_c('a',{staticClass:"ml-10",attrs:{"href":"/#/login"}},[_vm._v(_vm._s(_vm.$t("label.back")))])],1)]):(_vm.isPasswordReset)?_c('v-card',{staticClass:"elevation-1 pa-10"},[_c('h3',[_c('v-icon',{staticClass:"mr-5",attrs:{"large":"","size":"100","color":"primary"}},[_vm._v("beach_access")]),_vm._v(" "+_vm._s(_vm.$t("resetSuccessful"))+" "),_c('a',{staticClass:"ml-10",attrs:{"href":"/#/login"}},[_vm._v(_vm._s(_vm.$t("label.login")))])],1)]):_c('v-card',{staticClass:"elevation-1"},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("title")))]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"mt-10 pb-0"},[_c('v-form',{ref:"resetPasswordForm"},[_c('v-text-field',{attrs:{"id":"newPassword","label":`${_vm.$t('label.new')} ${_vm.$tc(
								'label.password',
								1
							)}`,"prepend-icon":"lock_open","type":_vm.showNewPassword ? 'text' : 'password',"rules":[
								_vm.rules.maxLength(100),
								_vm.rules.required,
								_vm.rules.strength,
							],"outlined":"","error-messages":_vm.resetPasswordError,"autofocus":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary","tabindex":"-1"},on:{"click":function($event){_vm.showNewPassword = !_vm.showNewPassword}}},[_vm._v(_vm._s(_vm.showNewPassword ? "visibility" : "visibility_off"))])]},proxy:true}]),model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('v-progress-linear',{class:['mb-7'],attrs:{"color":_vm.strengthScore(_vm.newPassword).color,"value":_vm.strengthScore(_vm.newPassword).value}}),_c('v-text-field',{attrs:{"id":"newPasswordConfirm","label":`${_vm.$t('label.confirm')} ${_vm.$t(
								'label.new'
							)} ${_vm.$tc('label.password', 1)}`,"prepend-icon":"lock_open","type":_vm.showNewPassword ? 'text' : 'password',"rules":[
								_vm.rules.required,
								_vm.rules.matching(this.newPassword),
							],"outlined":"","error-messages":_vm.resetPasswordError},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.resetPassword.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary","tabindex":"-1"},on:{"click":function($event){_vm.showNewPassword = !_vm.showNewPassword}}},[_vm._v(_vm._s(_vm.showNewPassword ? "visibility" : "visibility_off"))])]},proxy:true}]),model:{value:(_vm.newPasswordConfirm),callback:function ($$v) {_vm.newPasswordConfirm=$$v},expression:"newPasswordConfirm"}})],1)],1),_c('v-card-actions',{staticClass:"pb-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","rounded":"","disabled":_vm.newPassword.length == 0 ||
							_vm.newPassword !== _vm.newPasswordConfirm,"loading":_vm.isResettingPassword,"color":"primary"},on:{"click":function($event){return _vm.resetPassword()}}},[_vm._v(_vm._s(_vm.$t("label.save")))]),_c('v-spacer')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
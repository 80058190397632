<template>
  <v-row justify="center">
    <v-dialog max-width="500px" persistent v-model="dialog">
      <v-card>
        <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
        <v-card-title class="primary white--text">{{ formTitle }}</v-card-title>
        <v-card-text class="pt-5">
          <v-form ref="form" onSubmit="return false;">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :counter="200"
                  :label="$tc('label.name', 1)"
                  :rules="[rules.maxLength(200), rules.required]"
                  outlined
                  v-model="editedItem.themeName"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  :counter="7"
                  :label="$tc('label.color', 1)"
                  :rules="[rules.maxLength(7), rules.required]"
                  outlined
                  v-model="editedItem.primaryColor"
                >
                  <template v-slot:append>
                    <v-menu
                      :close-on-content-click="false"
                      nudge-bottom="105"
                      nudge-left="16"
                      top
                      v-model="menu"
                    >
                      <template v-slot:activator="{ on }">
                        <div :style="swatchStyle" v-on="on" />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                            flat
                            mode="hexa"
                            v-model="editedItem.primaryColor"
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  :label="$tc('label.logo', 1)"
                  @change="setThemeLogoChanged"
                  accept="image/*"
                  clearable
                  outlined
                  truncate-length="255"
                  v-model="editedItem.themeLogo"
                ></v-file-input>
              </v-col>
              <v-col cols="12" class="pt-0">
                <label
                  ><h4>
                    {{ $tc("label.preview", 1) }}
                  </h4></label
                >
                <v-app-bar :color="editedItem.primaryColor" dark dense>
                  <v-app-bar-nav-icon></v-app-bar-nav-icon>
                  <v-toolbar-title
                    class="ml-0 pl-4 font-weight-bold"
                    style="width: 300px"
                    v-if="$vuetify.breakpoint.mdAndUp"
                  >
                    <span>
                      {{ editedItem.themeName }}
                    </span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon large>
                    <v-avatar item size="32px">
                      <v-img :src="editedItem.themeLogoPath"></v-img>
                    </v-avatar>
                  </v-btn>
                </v-app-bar>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-5 pr-5">
          <v-spacer></v-spacer>
          <v-btn @click="close" rounded text>{{ $t("label.cancel") }}</v-btn>
          <v-btn
            :loading="isSaving"
            @click="save"
            color="primary"
            outlined
            rounded
            >{{ $t("label.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
  name: "theme-edit-dialog",
  props: ["dialog", "object"],
  data() {
    return {
      editedItem: {
        id: null,
        primaryColor: null,
        themeLogo: null,
        themeLogoPath: null,
        themeName: null,
      },
      error: null,
      isSaving: false,
      menu: false,
      themeLogoChanged: false,
    };
  },
  computed: {
    formTitle() {
      return this.object
        ? `${this.$t("label.edit")} ${this.$tc("label.theme", 1)}`
        : `${this.$t("label.add")} ${this.$tc("label.theme", 1)}`;
    },

    swatchStyle() {
      return {
        backgroundColor: this.editedItem.primaryColor,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: this.menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
  watch: {
    dialog() {
      this.resetForm();
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },

    resetForm() {
      this.error = null;
      this.isSaving = false;
      this.themeLogoChanged = false;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      if (this.dialog && this.object) {
        this.editedItem.id = this.object.id;
        this.editedItem.primaryColor = this.object.primaryColor;
        this.editedItem.themeLogo = this.object.themeLogo
          ? new File(["foo"], this.object.themeLogoName, {
              type: "text/plain",
            })
          : null;
        this.editedItem.themeLogoPath = this.object.themeLogoPath;
        this.editedItem.themeName = this.object.themeName;
      } else {
        this.editedItem = {
          id: null,
          primaryColor: "#000000",
          themeLogo: null,
          themeLogoPath: null,
          themeName: null,
        };
      }
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        primaryColor: this.editedItem.primaryColor,
        themeLogo: this.themeLogoChanged ? this.editedItem.themeLogo : null,
        themeName: this.editedItem.themeName,
      };

      if (this.object) {
        payload.id = this.editedItem.id;

        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateTheme($input: UpdateThemeInput!) {
                updateTheme(input: $input) {
                  theme {
                    id
                  }
                }
              }
            `,
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            this.$emit("changed", response);
            this.close();

            const payload = {
              color: "success",
              message: `Theme successfully edited`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);
          })
          .catch((error) => {
            this.error = error.graphQLErrors[0].message;
            this.isSaving = false;
          })
          .finally(() => {});
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createTheme($input: CreateThemeInput!) {
                createTheme(input: $input) {
                  theme {
                    id
                  }
                }
              }
            `,
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            this.$emit("changed", response);
            this.close();

            const payload = {
              color: "success",
              message: `Theme successfully added`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);
          })
          .catch((error) => {
            console.log(error);

            this.error = error.graphQLErrors
              .map((error) => error.message)
              .join(", ");
            this.isSaving = false;
          })
          .finally(() => {});
      }
    },

    setThemeLogoChanged() {
      this.themeLogoChanged = true;
      this.editedItem.themeLogoPath = URL.createObjectURL(
        this.editedItem.themeLogo
      );
    },
  },
};
</script>

import gql from "graphql-tag";

//////////////////////////////////////////////////
// Users
/////////////////////////////////////////////////

export const ME_QUERY = gql`
  query {
    me {
      id
      email
      firstName
      lastName
      isSuperuser
      isActive
      require2fa
      enabled2fa
      verified2fa
      passed2fa
      tenant {
        id
        tenantName
        tenantLogoPath
        theme {
          id
          themeName
          primaryColor
          themeLogoPath
        }
      }
      allPermissions
      identityProvider
      isPartnerUser
      partner {
        id
        name
        type
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation TokenAuth($email: String!, $password: String!, $tfaCode: String) {
    tokenAuth(email: $email, password: $password, tfaCode: $tfaCode) {
      token
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;

export const REVOKE_TOKEN_MUTATION = gql`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation Logout($input: LogoutInput!) {
    logout(input: $input) {
      revoked
    }
  }
`;

export const LOGIN_AS_OTHER_USER_MUTATION = gql`
  mutation LoginAsOtherUser($input: LoginAsOtherUserInput!) {
    loginAsOtherUser(input: $input) {
      token
      refreshToken
    }
  }
`;

export const LOGIN_WITH_AZURE_AD_MUTATION = gql`
  mutation LoginWithAzureAd($input: LoginWithAzureAdInput!) {
    loginWithAzureAd(input: $input) {
      token
      refreshToken
    }
  }
`;

export const LOGOUT_AS_OTHER_USER_MUTATION = gql`
  mutation LogoutAsOtherUser($input: LogoutAsOtherUserInput!) {
    logoutAsOtherUser(input: $input) {
      revoked
    }
  }
`;

export const ALL_USER_TASKS_QUERY = gql`
  query allUserTasks {
    tasksSuccess: allUserTasks(
      taskResult_Status_In: "SUCCESS"
      isDownloaded: false
    ) {
      totalCount
    }
    tasksRunning: allUserTasks(taskResult_Status_In: "PENDING,STARTED") {
      totalCount
    }
    tasksFailure: allUserTasks(taskResult_Status_In: "FAILURE") {
      totalCount
    }
  }
`;

export const OVERALL_STATUS_QUERY = gql`
  query overallState {
    allSystems(first: 1, orderBy: ["state__index"]) {
      edges {
        node {
          state {
            id
            nameEn
            nameNl
            index
            icon
            color
          }
        }
      }
    }
  }
`;

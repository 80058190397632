<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" persistent max-width="800px">
			<v-card>
				<v-card-title class="title">
					<v-icon color="info" class="mr-2">info</v-icon>
					<slot name="title">{{ $t("label.log") }}</slot>
				</v-card-title>

				<v-card-text>
					<v-timeline dense class="mt-0 pt-0 ml-n9">
						<v-timeline-item
							v-for="log in allTaskResultLogs.edges"
							:key="log.node.id"
							small
							class="pa-0 pt-1"
						>
							<v-row justify="space-between">
								<v-col cols="6">
									<small>{{ log.node.logMessage }}</small>
								</v-col>
								<v-col class="text-right" cols="4">
									<v-tooltip left>
										<template v-slot:activator="{ on }">
											<small v-on="on">{{
												log.node.createdAt
													| moment("HH:mm")
											}}</small>
										</template>
										<span>
											{{
												log.node.createdAt
													| moment(
														"YYYY-MM-DD HH:mm:ss"
													)
											}}
										</span></v-tooltip
									>
								</v-col>
							</v-row>
						</v-timeline-item>
					</v-timeline>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn text rounded @click="close()">{{
						$t("label.cancel")
					}}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog></v-row
	>
</template>

<script>
import gql from "graphql-tag";

export default {
	name: "task-result-log-dialog",
	props: ["dialog", "taskResult"],

	apollo: {
		allTaskResultLogs: {
			query: gql`
				query allTaskResultLogs($taskResult: ID!) {
					allTaskResultLogs(
						taskResult: $taskResult
						first: 100
						orderBy: ["-createdAt"]
					) {
						edges {
							node {
								id
								createdAt
								logMessage
							}
						}
					}
				}
			`,
			variables() {
				return {
					taskResult: this.taskResult.id,
				};
			},
			update: (data) => data.allTaskResultLogs,
			skip: true,
		},
	},

	data: function () {
		return {
			allTaskResultLogs: {},
		};
	},
	watch: {
		dialog(val) {
			if (val) {
				this.$apollo.queries.allTaskResultLogs.skip = false;
			}
		},
	},
	methods: {
		close() {
			this.$emit("update:dialog", false);
		},
	},
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":_vm.showOldPassword
										? 'visibility'
										: 'visibility_off',"counter":50,"label":`${_vm.$t('label.old')} ${_vm.$tc(
									'label.password',
									1
								)}`,"rules":[
									_vm.rules.maxLength(50),
									_vm.rules.required,
								],"type":_vm.showOldPassword ? 'text' : 'password',"outlined":"","prepend-icon":"lock"},on:{"click:append":function($event){_vm.showOldPassword = !_vm.showOldPassword}},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":_vm.showNewPassword
										? 'visibility'
										: 'visibility_off',"counter":50,"label":`${_vm.$t('label.new')} ${_vm.$tc(
									'label.password',
									1
								)}`,"rules":[
									_vm.rules.maxLength(50),
									_vm.rules.required,
									_vm.rules.strength,
								],"type":_vm.showNewPassword ? 'text' : 'password',"outlined":"","prepend-icon":"lock_open"},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('v-progress-linear',{class:['mb-7'],attrs:{"color":_vm.strengthScore(_vm.newPassword).color,"value":_vm.strengthScore(_vm.newPassword).value}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"append-icon":_vm.showNewPassword
										? 'visibility'
										: 'visibility_off',"counter":50,"label":`${_vm.$t('label.confirm')} ${_vm.$tc(
									'label.password',
									1
								)}`,"rules":[
									_vm.rules.required,
									_vm.rules.matching(this.newPassword),
								],"type":_vm.showNewPassword ? 'text' : 'password',"outlined":"","prepend-icon":"lock_open"},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.save.apply(null, arguments)},"submit":function($event){$event.preventDefault();}},model:{value:(_vm.newPasswordConfirm),callback:function ($$v) {_vm.newPasswordConfirm=$$v},expression:"newPasswordConfirm"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pb-5 pr-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","text":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("label.cancel")))]),_c('v-btn',{attrs:{"loading":_vm.isSaving,"color":"primary","outlined":"","rounded":""},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("label.save")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
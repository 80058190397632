<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="1000px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          {{ $t("label.start") }} {{ $tc("label.run", 1) }}
          <v-spacer />
          <v-btn icon @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" onSubmit="return false;" v-model="isFormValid">
            <v-row class="my-0 py-0">
              <v-col
                cols="12"
                class="mb-2 py-0"
                v-if="refreshTokensLeft.tokensLeft <= 0"
              >
                <v-alert
                  :color="'error'"
                  border="left"
                  elevation="2"
                  colored-border
                  icon="bolt"
                  dense
                >
                  <v-row align="center">
                    <v-col class="grow">
                      {{ $t("noTokensLeft") }}
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                        @click="infoDialog = true"
                        color="primary"
                        dark
                        rounded
                        x-small
                      >
                        {{ $t("label.more") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>

              <v-col cols="12" class="my-0 py-0">
                <v-text-field
                  :label="$tc('label.type', 1)"
                  disabled
                  outlined
                  :value="object?.type"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="my-0 py-0">
                <v-text-field
                  :label="$tc('label.resource', 1)"
                  disabled
                  outlined
                  :value="object?.resource?.name"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="my-0 py-0">
                <v-text-field
                  :label="$tc('label.asset', 1)"
                  disabled
                  outlined
                  :value="object?.name"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="my-0 py-0">
                {{ $t("disclaimer") }}
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="save()"
            :color="'success'"
            :disabled="!isFormValid"
            :loading="isSaving"
            rounded
            small
          >
            {{ $t("label.start") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <TokenInfoDialog :dialog.sync="infoDialog" />
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import TokenInfoDialog from "@/components/integrations/taps/runs/TokenInfoDialog.vue";

export default {
  name: "run-create-dialog",
  components: {
    TokenInfoDialog,
  },
  props: ["dialog", "object", "refreshTokensLeft"],

  apollo: {},

  data() {
    return {
      isFormValid: false,
      infoDialog: false,
      isSaving: false,
    };
  },
  computed: {
    me() {},
  },
  watch: {
    dialog(val) {
      this.resetForm();
    },
  },
  created() {},
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },

    resetForm() {
      this.error = null;
      this.isSaving = false;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        asset: this.object.id,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createRun($input: CreateRunInput!) {
              createRun(input: $input) {
                clientMutationId
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Run successfully started`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$emit("changed", response);

          this.close();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "disclaimer": "Disclaimer: By initiating a refresh of your data source, please be aware that it may lead to performance impacts on the source system. This process involves retrieving and updating data, which can temporarily increase the load on the source servers.",
      "noTokensLeft": "Starting the job with no tokens left will incur charges."
    },
    "nl": {
      "disclaimer": "Disclaimer: Bij het starten van een vernieuwing van de gegevensbron, wees ervan bewust dat dit kan leiden tot prestatieproblemen op het bronsysteem. Dit proces omvat het ophalen en bijwerken van gegevens, wat tijdelijk de belasting op de bronservers kan verhogen.",
      "noTokensLeft": "Het starten van de job zonder tokens resulteert in kosten."
    },
    "de": {
      "disclaimer": "Disclaimer: Durch die Initiierung einer Aktualisierung Ihrer Datenquelle ist zu beachten, dass dies zu Leistungseinbußen auf dem Ursprungssystem führen kann. Dieser Prozess beinhaltet das Abrufen und Aktualisieren von Daten, was vorübergehend die Last auf den Ursprungsservern erhöhen kann.",
      "noTokensLeft": "Das Starten des Job ohne verfügbare Tokens wird berechnet."
    }
  }
  </i18n>

<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" lg="5" xl="4">
        <v-card class="elevation-1 rounded-xl">
          <v-toolbar color="primary"
            ><v-btn icon large>
              <v-avatar size="32px" item>
                <v-img src="/static/taptarget-logo-white.png"> </v-img>
              </v-avatar>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title class="white--text font-weight-bold">
              TapTarget
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu
              :nudge-bottom="10"
              offset-y
              origin="center center"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-btn dark icon v-on="on">
                  <v-icon> translate </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :key="item.locale"
                  @click="changeLocale(item.locale)"
                  v-for="item in locales"
                >
                  <v-list-item-title>{{ item.localeName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>

          <v-card-title
            :class="['font-weight-medium', 'text-h5', 'mx-5 mt-5']"
            style="word-break: break-word"
          >
            {{ $t("label.login") }}
          </v-card-title>

          <v-card-subtitle :class="['mx-5']">
            {{ $t("continueToAccount") }}
          </v-card-subtitle>

          <v-card-text>
            <v-form onSubmit="return false;" ref="loginForm">
              <v-row class="mx-2">
                <v-col :class="['mb-0', 'pb-0']" cols="12">
                  <v-text-field
                    :class="['mt-3']"
                    :disabled="
                      !this.$apollo.queries.userLookup.skip ||
                      this.userLookup?.identityProvider == 'TAPTARGET'
                    "
                    :error-messages="error"
                    :label="$t('label.email')"
                    :rules="[rules.required]"
                    @keydown.enter="lookup()"
                    name="login"
                    outlined
                    type="email"
                    v-model="email"
                  ></v-text-field>
                </v-col>

                <v-col :class="['my-0', 'py-0']" cols="12">
                  <v-text-field
                    :error-messages="error"
                    :label="$tc('label.password', 1)"
                    :rules="[rules.required]"
                    :type="showPassword ? 'text' : 'password'"
                    @keydown.enter="login()"
                    autofocus
                    outlined
                    ref="password"
                    v-if="this.userLookup?.identityProvider == 'TAPTARGET'"
                    v-model="password"
                  >
                    <template v-slot:append>
                      <v-icon
                        @click="showPassword = !showPassword"
                        color="primary"
                        tabindex="-1"
                        >{{
                          showPassword ? "visibility" : "visibility_off"
                        }}</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col :class="['mt-0', 'pt-0']" cols="12">
                  <v-btn
                    :disabled="!email.includes('@')"
                    :loading="
                      this.$apollo.queries.userLookup.loading || this.loading
                    "
                    @click="lookup()"
                    block
                    color="primary"
                    large
                    rounded
                    v-if="!this.userLookup?.identityProvider"
                  >
                    <v-icon class="pr-2" left> mail </v-icon>
                    {{ $t("continueWithEmail") }}
                  </v-btn>

                  <v-btn
                    :disabled="password.length < 2"
                    :loading="loading"
                    @click="login()"
                    block
                    color="primary"
                    large
                    rounded
                    v-else
                  >
                    {{ $t("label.login") }}
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-row align="center">
                    <v-divider></v-divider>
                    <v-btn class="text-lowercase" disabled text>
                      <span> {{ $t("label.or") }} </span>
                    </v-btn>
                    <v-divider></v-divider>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    :disabled="
                      this.userLookup?.identityProvider == 'TAPTARGET'
                    "
                    :loading="
                      this.$apollo.queries.userLookup.loading || this.loading
                    "
                    @click="loginWithAzureAD('')"
                    block
                    color="primary"
                    large
                    rounded
                    outlined
                  >
                    <v-btn :class="['pa-2', 'mr-1']" icon>
                      <v-img
                        src="https://ltpbiportal.blob.core.windows.net/media/images/microsoft-logo.svg"
                        width="20"
                      ></v-img>
                    </v-btn>
                    {{ $t("continueWithMicrosoft") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <div
              @click="backToLookup()"
              class="mt-5 mx-5 row-pointer text-decoration-underline"
              v-if="this.userLookup?.identityProvider"
            >
              {{ $t("backToLogin") }}
            </div>

            <div
              @click="forgotPasswordDialog = true"
              class="mt-1 mx-5 row-pointer text-decoration-underline"
              v-if="this.userLookup?.identityProvider"
            >
              {{ $t("forgotPassword") }}
            </div>

            <div @click="goToSignUp()" class="mt-5 mx-5" v-else>
              {{ $t("newToTapTarget") }}
              <span class="blue--text row-pointer text-decoration-underline">{{
                $t("getStarted")
              }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- 2FA dialog -->
    <v-dialog max-width="400px" persistent v-model="tfaDialog">
      <v-card>
        <v-card-title class="primary white--text">
          {{ $t("2faTitle") }}
          <v-spacer></v-spacer>
          <v-btn @click="close2FADialog()" class="mr-n3" dark icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-5">
          <v-form ref="tfaForm" @submit.prevent>
            <v-otp-input
              :error-messages="tfaCodeError"
              :hint="$t('2faHint')"
              :label="$t('2faLabel')"
              :rules="[rules.required, rules.maxLength(6)]"
              @finish="loginWith2FA()"
              ref="tfaCode"
              type="number"
              v-model="tfaCode"
            >
            </v-otp-input>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Forgot password dialog -->
    <v-dialog
      @keydown.esc="closeForgotPasswordDialog()"
      max-width="500px"
      persistent
      v-model="forgotPasswordDialog"
    >
      <v-card>
        <v-card-title class="primary white--text">
          {{ $t("resetPassword.title") }}
          <v-spacer></v-spacer>
          <v-btn
            @click="
              isResetPasswordEmailSent
                ? closeForgotPasswordDialogAndReturn()
                : closeForgotPasswordDialog()
            "
            class="mr-n3"
            dark
            icon
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-5 pb-0" v-if="!isResetPasswordEmailSent">
          <p>{{ $t("resetPassword.subtitle") }}</p>
          <v-form ref="forgotPasswordForm" @submit.prevent>
            <v-text-field
              :error-messages="forgotPasswordEmailError"
              :label="$t('resetPassword.label')"
              :rules="[rules.required]"
              @keydown.enter="requestPasswordReset()"
              autofocus
              disabled
              outlined
              type="email"
              v-model="email"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-text v-else>
          <h1 class="text-center my-5">
            {{ $t("resetPassword.confirmedTitle") }}
          </h1>
          <p>
            {{ $t("resetPassword.confirmedDescription") }}
          </p>
        </v-card-text>

        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!email.includes('@')"
            :loading="loading"
            @click="requestPasswordReset()"
            color="primary"
            outlined
            rounded
            v-if="!isResetPasswordEmailSent"
            >{{ $t("resetPassword.button") }}</v-btn
          >
          <v-btn
            @click="closeForgotPasswordDialogAndReturn()"
            color="primary"
            outlined
            rounded
            v-else
            >{{ $t("label.close") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import msalAuth from "@/utils/msalAuth.js";
import rules from "@/utils/rules.js";

export default {
  apollo: {
    userLookup: {
      query: gql`
        query userLookup($email: String!) {
          userLookup(email: $email) {
            id
            email
            identityProvider
          }
        }
      `,
      variables() {
        return {
          email: this.email,
        };
      },
      fetchPolicy: "network-only",
      skip: true,
      update: (data) => data.userLookup,
      result() {
        this.$apollo.queries.userLookup.skip = true;

        this.loginOrSignUp();
      },
      error(error) {
        this.error = error.graphQLErrors
          .map((error) => error.message)
          .join(", ");
      },
    },
  },

  data: function () {
    return {
      email: "",
      error: "",
      forgotPasswordDialog: false,
      forgotPasswordEmailError: "",
      identityProvider: null,
      isResetPasswordEmailSent: false,
      loading: false,
      locales: [
        {
          locale: "en",
          localeName: "English",
        },
        {
          locale: "nl",
          localeName: "Nederlands",
        },
        {
          locale: "de",
          localeName: "Deutsch",
        },
      ],
      msalUser: {},
      msalAccessToken: null,
      password: "",
      showPassword: false,
      tfaCode: "",
      tfaCodeError: "",
      tfaDialog: false,
      userLookup: {},
    };
  },
  watch: {
    tfaDialog(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.tfaCode.focus();
        });
      }
    },
  },

  created() {
    this.rules = rules;

    helper.setupMsalAuth();
  },
  computed: {},
  methods: {
    backToLookup() {
      this.$refs.loginForm.resetValidation();
      this.userLookup = {};
      this.email = "";
      this.password = "";
    },

    changeLocale(locale) {
      localStorage.setItem("locale", locale);
      this.$i18n.locale = locale;
    },

    close2FADialog() {
      this.tfaCode = "";
      this.$refs.tfaCode.reset();
      this.tfaDialog = false;
    },

    closeForgotPasswordDialog() {
      this.isResetPasswordEmailSent = false;
      this.forgotPasswordEmailError = "";
      if (this.$refs.forgotPasswordForm) {
        this.$refs.forgotPasswordForm.resetValidation();
      }
      this.forgotPasswordDialog = false;
    },

    closeForgotPasswordDialogAndReturn() {
      this.closeForgotPasswordDialog();
      this.backToLookup();
    },

    fetchMe() {
      this.$store
        .dispatch("user/fetchMe")
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },

    goToSignUp() {
      this.$router.push({
        name: "sign-up",
        params: {
          emailProp: this.email,
          identityProviderProp: this.identityProvider,
          msalAccessTokenProp: this.msalAccessToken,
        },
        query: this.$route.query,
      });
    },

    lookup() {
      if (!this.$refs.loginForm.validate()) {
        return;
      }

      this.$apollo.queries.userLookup.skip = false;
    },

    login() {
      this.error = null;

      if (!this.$refs.loginForm.validate()) {
        return;
      }

      this.loading = true;

      const payload = { email: this.email, password: this.password };

      this.$store
        .dispatch("user/login", payload)
        .then(() => {
          this.fetchMe();
          this.$router.push(this.$route.query.redirect || "/");
        })
        .catch((error) => {
          if (error.graphQLErrors) {
            for (let err of error.graphQLErrors) {
              /*2fa*/
              if (err.message === "2FA") {
                this.tfaDialog = true;
              } else {
                this.error = err.message;
              }
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loginOrSignUp() {
      if (this.userLookup) {
        if (this.userLookup?.identityProvider == "TAPTARGET") {
        } else if (this.userLookup?.identityProvider == "MICROSOFT_AZURE_AD") {
          this.loginWithAzureAD(this.email);
        }
      } else {
        this.goToSignUp();
      }
    },

    loginWith2FA() {
      if (!this.$refs.tfaForm.validate()) {
        return;
      }

      this.loading = true;

      const payload = {
        email: this.email,
        password: this.password,
        tfaCode: this.tfaCode,
      };

      this.$store
        .dispatch("user/login", payload)
        .then(() => {
          this.fetchMe();
          this.$router.push(this.$route.query.redirect || "/");
        })
        .catch((error) => {
          if (error.graphQLErrors) {
            for (let err of error.graphQLErrors) {
              /*2fa*/
              if (err.message === "INVALID_GACODE") {
                this.tfaCodeError = "Invalide 2FA-Code";
              } else {
                this.tfaCodeError = err.message;
              }
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async loginWithAzureAD(email) {
      try {
        this.loading = true;
        await msalAuth.login(email);
        this.msalUser = msalAuth.user();
        this.msalAccessToken = await msalAuth.acquireToken();

        const payload = { msalAccessToken: this.msalAccessToken };

        this.$store
          .dispatch("user/loginWithAzureAd", payload)
          .then(() => {
            this.fetchMe();
            this.$router.push(this.$route.query.redirect || "/");
            msalAuth.clearLocal();
          })
          .catch((error) => {
            if (error.graphQLErrors) {
              for (let err of error.graphQLErrors) {
                this.error = err.message;
                if (this.error === "Email does not exist") {
                  // JB: get the email from the backend to prevent a mismatch
                  // between the msal library and the email/upn from the graph me endpoint
                  this.email = err.extensions.email
                  this.identityProvider = "MICROSOFT_AZURE_AD";
                  this.goToSignUp();
                }

                msalAuth.clearLocal();
                this.msalUser = {};
                this.msalAccessToken = "";
                this.userLookup = {};
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (err) {
        this.error = err.toString();
        this.userLookup = {};
        this.loading = false;
      }
    },

    requestPasswordReset() {
      if (!this.$refs.forgotPasswordForm.validate()) {
        return;
      }

      this.loading = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation requestPasswordReset($input: RequestPasswordResetInput!) {
              requestPasswordReset(input: $input) {
                success
              }
            }
          `,
          variables: {
            input: {
              email: this.email,
            },
          },
        })
        .then(() => {
          this.isResetPasswordEmailSent = true;
        })
        .catch((error) => {
          if (error.graphQLErrors) {
            for (let err of error.graphQLErrors) {
              this.forgotPasswordEmailError = err.message;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
	"2faHint": "Authenticator code",
	"2faLabel": "2FA code",
	"2faTitle": "Please enter your 2FA code",
	"backToLogin": "Back to login",
	"continueToAccount": "Continue to TapTarget account",
	"continueWithEmail": "Continue with email",
	"continueWithMicrosoft": "Continue with Microsoft",
	"forgotPassword": "Forgot password?",
	"getStarted": "Get started",
	"newToTapTarget": "New to TapTarget?",
	"resetPassword": {
		"confirmedDescription": "If account exists, an email will be sent with further instructions",
		"confirmedTitle": "Check your inbox",
		"button": "Email me a recovery link",
		"label": "Your email",
		"subtitle": "Don't worry, happens to the best of us.",
		"title": "Recover password"
	}
  },
  "nl": {
	"2faHint": "Verificatiecode",
	"2faLabel": "2FA-code",
	"2faTitle": "Voer jouw 2FA-code in",
	"backToLogin": "Terug naar aanmelden",
	"continueToAccount": "Doorgaan naar TapTarget account",
	"continueWithEmail": "Doorgaan met email",
	"continueWithMicrosoft": "Doorgaan met Microsoft",
	"forgotPassword": "Wachtwoord vergeten?",
	"getStarted": "Aan de slag",
	"newToTapTarget": "Nieuw bij TapTarget?",
	"resetPassword": {
		"confirmedDescription": "Als het account bestaat, wordt er een e-mail verstuurd met verdere instructies",
		"confirmedTitle": "Controleer je inbox",
		"button": "Stuur me een herstelkoppeling per e-mail",
		"label": "Je e-mailadres",
		"subtitle": "Geen zorgen, gebeurt iedereen wel eens.",
		"title": "Wachtwoord herstellen"
	}
  },
  "de": {
	"2faHint": "Authenticator-Code",
	"2faLabel": "2FA-Code",
	"2faTitle": "Bitte geben Sie Ihren 2FA-Code ein",
	"backToLogin": "Zurück zur Anmeldung",
	"continueToAccount": "Weiter zum TapTarget Account",
	"continueWithEmail": "Mit E-Mail fortfahren",
	"continueWithMicrosoft": "Mit Microsoft fortfahren",
	"forgotPassword": "Passwort vergessen?",
	"getStarted": "Loslegen",
	"newToTapTarget": "Neu bei TapTarget?",
	"resetPassword": {
		"confirmedDescription": "Wenn das Konto existiert, wird eine E-Mail mit weiteren Anweisungen gesendet",
		"confirmedTitle": "Überprüfe dein Postfach",
		"button": "Sende mir einen Wiederherstellungslink per E-Mail",
		"label": "Deine E-Mail-Adresse",
		"subtitle": "Keine Sorge, passiert den Besten von uns.",
		"title": "Passwort wiederherstellen"
	}
  }
}
</i18n>

<style scoped>
.row-pointer {
  cursor: pointer;
}
</style>

<template>
	<v-row class="mt-2">
		<v-col class="py-0" cols="12">
			<v-alert tile type="error" v-if="error">{{ error }}</v-alert>
		</v-col>
		<v-col cols="12" md="2" sm="6">
			<v-text-field
				:label="$t('label.search')"
				@click:append-outer="refresh()"
				@click:clear="clearSearch()"
				@keydown.enter="refresh()"
				clearable
				dense
				hide-details
				outlined
				required
				v-model="search"
			></v-text-field>
		</v-col>
		<v-spacer />
		<v-col align="right" cols="12" md="2" sm="6">
			<v-btn
				@click="resetFilters()"
				color="primary"
				dark
				rounded
				small
				text
			>
				{{ $t("label.clearFilters") }}
			</v-btn>
		</v-col>
		<v-col cols="12" md="12">
			<v-data-table
				:footer-props="{
					'disable-pagination': $apollo.queries.groups.loading,
					'items-per-page-options': [10, 25, 50, 75, 100],
				}"
				:headers="headers"
				:items="groups.edges"
				:loading="$apollo.queries.groups.loading"
				:options.sync="dataTableOptions"
				:server-items-length="groups.totalCount"
				@click:row="(item) => goToDetail(item.node.id)"
				item-key="node.id"
			>
			</v-data-table>
		</v-col>
		<GroupAddDialog :dialog.sync="addDialog" v-on:added="onAdded" />
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import GroupAddDialog from "@/components/users/GroupAddDialog.vue";

export default {
	name: "groups-list",
	components: { GroupAddDialog },

	apollo: {
		groups: {
			query: gql`
				query groups(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$orderBy: [String]
					$searchGroup: String
				) {
					groups(
						first: $first
						last: $last
						before: $before
						after: $after
						searchGroup: $searchGroup
						orderBy: $orderBy
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								name
							}
						}
					}
				}
			`,
			fetchPolicy: "cache-and-network",
			update: (data) => data.groups,
			skip: true,
			pollInterval: 60000,
		},
	},

	data: function () {
		return {
			groups: {},
			addDialog: false,
			error: null,
			headers: [
				{
					text: this.$tc("label.name", 1),
					value: "node.name",
					sortable: true,
				},
			],
		};
	},

	computed: {
		dataTableOptions: {
			get() {
				return this.$store.getters["groupsList/getDataTableOptions"];
			},
			set(value) {
				this.$store.dispatch("groupsList/setDataTableOptions", value);
			},
		},

		me() {
			return this.$store.state.user.me;
		},

		search: {
			get() {
				return this.$store.getters["groupsList/getSearch"];
			},
			set(value) {
				this.$store.dispatch("groupsList/setSearch", value);
			},
		},
	},

	watch: {
		dataTableOptions() {
			this.refresh();
		},
	},

	created() {
		this.orderByList = helper.orderByList;
	},

	mounted() {},

	methods: {
		add() {
			this.addDialog = true;
		},

		clearSearch() {
			this.search = null;
			this.refresh();
		},

		goToDetail(nodeId) {
			this.$router.push({
				name: "group-detail",
				params: {
					groupNodeId: nodeId,
				},
			});
		},

		onAdded(item) {
			this.$apollo.queries.groups.refresh();
			this.goToDetail(item.id);
		},

		onChanged() {
			this.$apollo.queries.groups.refresh();
		},

		refresh() {
			const { sortBy, sortDesc, page, itemsPerPage } =
				this.dataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.groups.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.groups.pageInfo.endCursor;
			}

			var orderByList = this.orderByList(sortBy, sortDesc);

			this.$apollo.queries.groups.setVariables({
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: orderByList,
				searchGroup: this.search,
			});

			this.$apollo.queries.groups.skip = false;
			this.$apollo.queries.groups.refresh();

			this.page = page;
		},

		resetFilters() {
			this.clearSearch();
		},
	},
};
</script>

<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col :class="['my-0', 'py-0']" cols="12">
          <v-card flat>
            <v-card-title
              :class="['font-weight-bold', 'text-h4']"
              style="word-break: break-word"
            >
              {{ $t("title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("subtitle") }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="2" sm="6">
          <v-text-field
            :label="$t('label.search')"
            @click:append-outer="refresh()"
            @click:clear="clearSearch()"
            @keydown.enter="refresh()"
            clearable
            dense
            hide-details
            outlined
            required
            v-model="search"
          ></v-text-field>
        </v-col>

        <v-spacer />
        <v-col cols="12" md="2" align="right">
          <v-btn
            @click="resetFilters()"
            color="primary"
            dark
            rounded
            small
            text
          >
            {{ $t("label.clearFilters") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :footer-props="{
              'disable-pagination':
                $apollo.queries.allTaskResultExtensions.loading,
              'items-per-page-options': [10, 25, 50, 75, 100],
            }"
            :headers="headers"
            :items="allTaskResultExtensions.edges"
            :loading="$apollo.queries.allTaskResultExtensions.loading"
            :options.sync="dataTableOptions"
            :server-items-length="allTaskResultExtensions.totalCount"
            item-key="node.id"
          >
            <template v-slot:[`item.node.pk`]="{ item }">
              #RD{{ item.node.pk }}
            </template>
            <template v-slot:[`item.node.createdAt`]="{ item }">
              {{ item.node.createdAt | moment("YYYY-MM-DD HH:mm") }}
            </template>
            <template v-slot:[`item.node.user.firstName`]="{ item }">
              {{ item.node.user.firstName }}
              {{ item.node.user.lastName }}
            </template>
            <template v-slot:[`item.node.taskResult.status`]="{ item }">
              <StatusChip :status="item.node.taskResult?.status" />
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip v-if="item.node.taskResult?.status == 'FAILURE'" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    color="error"
                    @click="openErrorMessage(item.node.taskResult.traceback)"
                  >
                    <v-icon>announcement</v-icon>
                  </v-btn>
                </template>
                <span> Show error </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    color="info"
                    @click="openTaskResultLog(item.node.taskResult)"
                  >
                    <v-icon>list</v-icon>
                  </v-btn>
                </template>
                <span> Show logs </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>

    <ErrorDialog :dialog.sync="errorDialog" :errorMessage.sync="errorMessage" />

    <TaskResultLogDialog
      :dialog.sync="logDialog"
      :taskResult.sync="selectedTaskResult"
    />
  </v-row>
</template>

<script>
import ErrorDialog from "@/components/base/ErrorDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import StatusChip from "@/components/base/StatusChip.vue";
import TaskResultLogDialog from "@/components/base/TaskResultLogDialog.vue";

export default {
  name: "resource-deployments-list",
  components: {
    ErrorDialog,
    StatusChip,
    TaskResultLogDialog,
  },

  apollo: {
    allTaskResultExtensions: {
      query: gql`
        query allTaskResultExtensions(
          $first: Int
          $last: Int
          $before: String
          $after: String
          $orderBy: [String]
          $search: String
          $subType_In: String
        ) {
          allTaskResultExtensions(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: $orderBy
            search: $search
            subType_In: $subType_In
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                createdAt
                name
                pk
                subType
                taskResult {
                  id
                  status
                  traceback
                }
                tenant {
                  tenantName
                }
                type
                user {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allTaskResultExtensions,
      skip: true,
      pollInterval: 60000,
    },
  },

  data: function () {
    return {
      allTaskResultExtensions: {},
      dataTableOptions: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: true,
        mustSort: false,
        page: 1,
        sortBy: ["node.createdAt"],
        sortDesc: [true],
      },
      error: null,
      errorDialog: false,
      errorMessage: null,
      expanded: [],
      headers: [
        {
          text: this.$tc("label.pk", 1),
          value: "node.pk",
          sortable: false,
        },
        {
          text: this.$t("label.created"),
          value: "node.createdAt",
          sortable: true,
        },
        {
          text: this.$tc("label.name", 1),
          value: "node.name",
          sortable: true,
        },
        {
          text: this.$tc("label.type", 1),
          value: "node.type",
          sortable: true,
        },
        {
          text: this.$tc("label.subtype", 1),
          value: "node.subType",
          sortable: true,
        },
        {
          text: this.$tc("label.user", 1),
          value: "node.user.firstName",
          sortable: false,
        },
        {
          text: this.$tc("label.tenant", 1),
          value: "node.tenant.tenantName",
          sortable: false,
        },
        {
          text: this.$t("label.status"),
          value: "node.taskResult.status",
          sortable: false,
        },
        {
          text: this.$t("label.action"),
          value: "action",
          align: "right",
          sortable: false,
        },
      ],
      logDialog: false,
      search: null,
      selectedTaskResult: {},
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },

  watch: {
    dataTableOptions() {
      this.refresh();
    },
  },

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;
  },

  mounted() {},

  methods: {
    clearSearch() {
      this.search = null;
      this.refresh();
    },

    onChanged() {
      this.$apollo.queries.allTaskResultExtensions.refresh();
    },

    openErrorMessage(errorMessage) {
      this.errorMessage = errorMessage;
      this.errorDialog = true;
    },

    openTaskResultLog(item) {
      this.selectedTaskResult = item;
      this.logDialog = true;
    },

    refresh() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;

      var _first = itemsPerPage;
      var _last = null;
      var _before = null;
      var _after = null;

      if (page < this.page) {
        _first = null;
        _last = itemsPerPage;
        _before = this.allTaskResultExtensions.pageInfo.startCursor;
        _after = null;
      }

      if (page > this.page) {
        _first = itemsPerPage;
        _last = null;
        _before = null;
        _after = this.allTaskResultExtensions.pageInfo.endCursor;
      }

      var orderByList = this.orderByList(sortBy, sortDesc);

      this.$apollo.queries.allTaskResultExtensions.setVariables({
        first: _first,
        last: _last,
        before: _before,
        after: _after,
        orderBy: orderByList,
        search: this.search,
        subType_In: "Group,Resource group,Resource",
      });

      this.$apollo.queries.allTaskResultExtensions.skip = false;
      this.$apollo.queries.allTaskResultExtensions.refresh();

      this.page = page;
    },

    resetFilters() {
      this.clearSearch();
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "title": "Target deployments",
      "subtitle": "All the created Targets are deployed by a background task, here you can track the status of that deployment."
    },
    "nl": {
      "title": "Target deployments",
      "subtitle": "Alle aangemaakte Targets worden ingezet door een achtergrondtaak. Hier kun je de status van die deployment volgen."
    },
    "de": {
      "title": "Target deployments",
      "subtitle": "Alle erstellten Ziele werden von einem Hintergrundtask bereitgestellt. Hier können Sie den Status dieser Bereitstellung verfolgen."
    }
  }
  </i18n>

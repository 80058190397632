var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"rounded-xl",attrs:{"color":_vm.color,"elevation":hover ? 10 : 1,"loading":_vm.loading ? 'white' : null,"dark":"","height":"160px","outlined":""},on:{"click":function($event){return _vm.goToRoute()}}},[_c('v-card-title',{staticClass:"mb-0 pb-0"},[_c('v-icon',{staticClass:"mr-2",attrs:{"large":""}},[_vm._v(_vm._s(_vm.icon))]),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_c('v-card-text',{class:[
          'align-center',
          'd-flex',
          'justify-center',
          'text-center',
          `text-${_vm.heading}`,
        ],style:({ height: _vm.textHeight })},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<v-container class="fill-height" fluid>
		<!-- Login Dialog -->
		<v-row align="center" justify="center">
			<v-col v-if="isVerifyingToken" cols="12">
				<div class="text-center">
					<v-progress-circular
						indeterminate
						color="primary"
						class="mx-2"
					></v-progress-circular>
				</div>
			</v-col>
			<v-col v-else cols="12" sm="8" md="6" lg="4">
				<v-card v-if="!isTokenValid" class="elevation-1 pa-10">
					<h3>
						<v-icon large class="mr-5">error_outline</v-icon>
						{{ $t("invalidLink") }}
						<a href="/#/login" class="ml-10">{{
							$t("label.back")
						}}</a>
					</h3>
				</v-card>

				<v-card v-else-if="isPasswordReset" class="elevation-1 pa-10">
					<h3>
						<v-icon large class="mr-5" size="100" color="primary"
							>beach_access</v-icon
						>
						{{ $t("resetSuccessful") }}
						<a href="/#/login" class="ml-10">{{
							$t("label.login")
						}}</a>
					</h3>
				</v-card>

				<v-card v-else class="elevation-1">
					<v-toolbar color="primary">
						<v-toolbar-title class="white--text">{{
							$t("title")
						}}</v-toolbar-title>
						<v-spacer />
					</v-toolbar>
					<v-card-text class="mt-10 pb-0">
						<v-form ref="resetPasswordForm">
							<v-text-field
								id="newPassword"
								:label="`${$t('label.new')} ${$tc(
									'label.password',
									1
								)}`"
								prepend-icon="lock_open"
								:type="showNewPassword ? 'text' : 'password'"
								:rules="[
									rules.maxLength(100),
									rules.required,
									rules.strength,
								]"
								outlined
								v-model="newPassword"
								:error-messages="resetPasswordError"
								autofocus
							>
								<template v-slot:append>
									<v-icon
										color="primary"
										@click="
											showNewPassword = !showNewPassword
										"
										tabindex="-1"
										>{{
											showNewPassword
												? "visibility"
												: "visibility_off"
										}}</v-icon
									>
								</template>
							</v-text-field>

							<v-progress-linear
								:class="['mb-7']"
								:color="strengthScore(newPassword).color"
								:value="strengthScore(newPassword).value"
							></v-progress-linear>

							<v-text-field
								id="newPasswordConfirm"
								:label="`${$t('label.confirm')} ${$t(
									'label.new'
								)} ${$tc('label.password', 1)}`"
								prepend-icon="lock_open"
								:type="showNewPassword ? 'text' : 'password'"
								:rules="[
									rules.required,
									rules.matching(this.newPassword),
								]"
								outlined
								v-model="newPasswordConfirm"
								:error-messages="resetPasswordError"
								@keydown.enter="resetPassword"
							>
								<template v-slot:append>
									<v-icon
										color="primary"
										@click="
											showNewPassword = !showNewPassword
										"
										tabindex="-1"
										>{{
											showNewPassword
												? "visibility"
												: "visibility_off"
										}}</v-icon
									>
								</template></v-text-field
							>
						</v-form>
					</v-card-text>

					<v-card-actions class="pb-5">
						<v-spacer></v-spacer>
						<v-btn
							outlined
							rounded
							:disabled="
								newPassword.length == 0 ||
								newPassword !== newPasswordConfirm
							"
							:loading="isResettingPassword"
							color="primary"
							@click="resetPassword()"
							>{{ $t("label.save") }}</v-btn
						>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import rules from "@/utils/rules.js";

export default {
	data: function () {
		return {
			isVerifyingToken: true,
			isTokenValid: false,
			isPasswordReset: false,

			isResettingPassword: false,
			newPassword: "",
			newPasswordConfirm: "",
			showNewPassword: "",
			resetPasswordError: "",
		};
	},
	computed: {
		uidb64() {
			return this.$route.params.uidb64;
		},
		token() {
			return this.$route.params.token;
		},
	},
	watch: {
		// Reset the password confirmation if original changes
		newPassword(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.newPasswordConfirm = "";
			}
		},
	},
	created() {
		this.verifyResetPasswordToken();
		this.rules = rules;
		this.strengthScore = helper.strengthScore;
	},
	methods: {
		verifyResetPasswordToken() {
			this.isVerifyingToken = true;

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation verifyResetPasswordToken(
							$input: VerifyResetPasswordTokenInput!
						) {
							verifyResetPasswordToken(input: $input) {
								success
							}
						}
					`,
					// Parameters
					variables: {
						input: {
							uidb64: this.uidb64,
							token: this.token,
						},
					},
				})
				.then((response) => {
					this.isTokenValid =
						response.data.verifyResetPasswordToken.success;
					console.log(this.isTokenValid);
				})
				.catch((error) => {
					if (error.graphQLErrors) {
						for (let err of error.graphQLErrors) {
							this.requestPasswordResetError = err.message;
						}
					}
				})
				.finally(() => {
					this.isVerifyingToken = false;
				});
		},

		resetPassword() {
			if (!this.$refs.resetPasswordForm.validate()) {
				return;
			}

			this.isResettingPassword = true;

			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation resetPassword($input: ResetPasswordInput!) {
							resetPassword(input: $input) {
								success
							}
						}
					`,
					// Parameters
					variables: {
						input: {
							uidb64: this.uidb64,
							token: this.token,
							password: this.newPassword,
						},
					},
				})
				.then((response) => {
					console.log(response);
					this.isPasswordReset = response.data.resetPassword.success;
				})
				.catch((error) => {
					if (error.graphQLErrors) {
						this.requestPasswordResetError =
							error.graphQLErrors[0].message;
					}
				})
				.finally(() => {
					this.isResettingPassword = false;
				});
		},
	},
};
</script>

<i18n>
{
  "en": {
	"invalidLink": "The password reset link was invalid, possibly because it has already been used. Please request a new password reset.",
	"newPassword": "New password",
	"resetSuccessful": "Password has been reset",
	"title": "Reset password"
  },
  "nl": {
	"invalidLink": "De wachtwoordresetlink is ongeldig, mogelijk omdat deze al is gebruikt. Vraag een nieuwe wachtwoordreset aan.",
	"newPassword": "Nieuw wachtwoord",
	"resetSuccessful": "Wachtwoord is gereset",
	"title": "Reset wachtwoord"
  },
  "de": {
	"invalidLink": "Der Link zum Zurücksetzen des Passworts war ungültig, möglicherweise weil er bereits verwendet wurde. Bitte fordern Sie einen neuen Passwort-Reset an.",
	"newPassword": "Neues Passwort",
	"resetSuccessful": "Passwort wurde zurückgesetzt",
	"title": "Passwort zurücksetzen"
  }
}
</i18n>

<template>
  <v-row justify="center">
    <v-dialog max-width="600px" persistent scrollable v-model="dialog">
      <v-card>
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>
        <v-alert v-if="editedItem.isSuperuser" tile type="warning">{{
          $t("warningSuperuser")
        }}</v-alert>

        <v-card-title class="primary white--text">
          {{ formTitle }}
          <v-spacer></v-spacer>
          <v-btn
            v-if="hasPermission('change_customuser') && object"
            outlined
            rounded
            small
            class="ml-2"
            dark
            @click="onChangePassword"
            >{{ `${$t("label.change")} ${$t("label.password")}` }}</v-btn
          >
        </v-card-title>

        <v-card-text class="pt-5">
          <v-form ref="form">
            <v-row align="center">
              <v-col cols="12" md="8" v-if="me.isSuperuser || me.isPartnerUser">
                <label
                  ><h4>
                    {{ $tc("label.type", 1) }}
                  </h4></label
                >
                <v-radio-group
                  :rules="[rules.required]"
                  class="mt-1"
                  row
                  v-model="editedItem.userType"
                >
                  <v-radio
                    :label="`${$tc('label.tenant', 1)} ${$tc('label.user', 1)}`"
                    value="tenantUser"
                  ></v-radio>
                  <v-radio
                    :label="`${$tc('label.partner', 1)} ${$tc(
                      'label.user',
                      1
                    )}`"
                    value="partnerUser"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                md="4"
                v-if="me.isSuperuser && editedItem.userType === 'tenantUser'"
              >
                <v-switch
                  :label="'Superuser'"
                  class="my-0 py-0"
                  hide-details
                  v-model="editedItem.isSuperuser"
                ></v-switch>
              </v-col>
              <!-- Tenant -->
              <v-col
                cols="12"
                v-if="
                  (me.isSuperuser || me.isPartnerUser) &&
                  editedItem.userType === 'tenantUser'
                "
              >
                <v-autocomplete
                  v-model="editedItem.tenantNodeId"
                  :items="tenants.edges"
                  item-text="node.tenantName"
                  item-value="node.id"
                  :loading="$apollo.queries.tenants.loading"
                  :rules="[rules.required]"
                  :label="$tc('label.tenant', 1)"
                  class="subtitle-2"
                  outlined
                  clearable
                >
                </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                v-if="me.isSuperuser && editedItem.userType === 'partnerUser'"
              >
                <v-autocomplete
                  v-model="editedItem.partnerNodeId"
                  :items="allPartners.edges"
                  item-text="node.name"
                  item-value="node.id"
                  :loading="$apollo.queries.allPartners.loading"
                  :rules="[rules.required]"
                  :label="$tc('label.partner', 1)"
                  class="subtitle-2"
                  outlined
                  clearable
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.firstName"
                  :counter="100"
                  :rules="[rules.required, rules.maxLength(100)]"
                  :label="$t('label.firstName')"
                  outlined
                  class="subtitle-2"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.lastName"
                  :counter="100"
                  :rules="[rules.required, rules.maxLength(100)]"
                  :label="$t('label.lastName')"
                  outlined
                  class="subtitle-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.email"
                  :counter="100"
                  :rules="[rules.required, rules.maxLength(100)]"
                  :label="$t('label.email')"
                  type="email"
                  outlined
                  class="subtitle-2"
                ></v-text-field>
              </v-col>

              <!-- Identity provider -->
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.identityProvider"
                  :items="identityProviders"
                  item-text="name"
                  item-value="id"
                  :rules="[rules.required]"
                  :label="$t('label.identityProvider')"
                  class="subtitle-2"
                  outlined
                  clearable
                >
                </v-autocomplete>
              </v-col>

              <!-- groups -->
              <v-col v-if="!editedItem.isSuperuser" cols="12">
                <v-autocomplete
                  v-model="editedItem.selectedGroups"
                  :items="groups.edges"
                  item-text="node.name"
                  item-value="node.id"
                  :loading="$apollo.queries.groups.loading"
                  :rules="[rules.required]"
                  :label="$tc('label.group', 2)"
                  class="subtitle-2"
                  outlined
                  clearable
                  multiple
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" v-if="!object && editedItem.identityProvider == 'TAPTARGET'">
                <v-text-field
                  :append-icon="showpassword ? 'visibility' : 'visibility_off'"
                  :counter="50"
                  :label="$tc('label.password', 1)"
                  :rules="[rules.required, rules.strength]"
                  :type="showpassword ? 'text' : 'password'"
                  @click:append="showpassword = !showpassword"
                  class="subtitle-2"
                  outlined
                  v-model="editedItem.password"
                ></v-text-field>

                <v-progress-linear
                  :class="['mb-7']"
                  :color="strengthScore(editedItem.password).color"
                  :value="strengthScore(editedItem.password).value"
                ></v-progress-linear>
              </v-col>

              <v-col cols="6" v-if="editedItem.identityProvider == 'TAPTARGET'">
                <v-switch
                  v-model="editedItem.require2fa"
                  :label="$t('label.require2fa')"
                  class="my-0 py-0"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col
                v-if="object && editedItem.identityProvider == 'TAPTARGET'"
                cols="12"
              >
                <v-row class="align-center">
                  <v-col
                    v-if="editedItem.enabled2fa && !editedItem.clear2fa"
                    cols="6"
                    class="mb-0 pb-0"
                  >
                    <v-icon color="success">verified_user</v-icon>
                    {{ $t("2faEnabledText") }}
                  </v-col>
                  <v-col
                    v-else-if="editedItem.enabled2fa && editedItem.clear2fa"
                    cols="6"
                    class="mb-0 pb-0"
                  >
                    <v-icon color="error">highlight_off</v-icon>
                    {{ $t("2faResetText") }}
                  </v-col>
                  <v-col v-else cols="6" class="mb-0 pb-0">
                    <v-icon color="warning">highlight_off</v-icon>
                    {{ $t("2faNotSetText") }}
                  </v-col>
                  <v-col
                    v-if="editedItem.enabled2fa"
                    cols="6"
                    class="mb-0 pb-0"
                  >
                    <v-checkbox
                      v-model="editedItem.clear2fa"
                      color="error"
                      :label="$t('2faReset')"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-5 pr-5">
          <v-spacer></v-spacer>
          <v-btn :disabled="isSaving" text rounded @click="close">{{
            $t("label.cancel")
          }}</v-btn>
          <v-btn
            outlined
            rounded
            :loading="isSaving"
            color="primary"
            @click="save"
            >{{ $t("label.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import rules from "@/utils/rules.js";
import TenantEditDialog from "@/components/users/TenantEditDialog.vue";

export default {
  name: "user-edit-dialog",
  props: ["dialog", "object"],
  components: {
    TenantEditDialog,
  },

  apollo: {
    allPartners: {
      query: gql`
        query allPartners {
          allPartners(orderBy: ["name"]) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.allPartners,
      skip: true,
      pollInterval: 60000,
    },

    groups: {
      query: gql`
        query groups {
          groups(orderBy: ["name"]) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.groups,
      skip: true,
      pollInterval: 60000,
    },

    tenants: {
      query: gql`
        query tenants {
          tenants(orderBy: ["tenantName"]) {
            edges {
              node {
                id
                tenantName
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenants,
      skip: true,
      pollInterval: 60000,
    },
  },

  data() {
    return {
      allPartners: {},
      defaultItem: {
        id: null,
        clear2fa: false,
        email: null,
        enabled2fa: false,
        firstName: null,
        identityProvider: null,
        isSuperuser: false,
        lastName: null,
        partnerNodeId: null,
        password: Math.random().toString(36).slice(-10),
        require2fa: true,
        selectedGroups: [],
        tenantNodeId: null,
        userType: "tenantUser",
      },
      editedItem: {
        id: null,
        clear2fa: false,
        email: null,
        enabled2fa: false,
        firstName: null,
        identityProvider: null,
        isSuperuser: false,
        lastName: null,
        partnerNodeId: null,
        password: null,
        require2fa: false,
        tenantNodeId: null,
        selectedGroups: [],
        userType: null,
      },
      error: null,
      groups: {},
      identityProviders: [
        {
          id: "TAPTARGET",
          name: "TapTarget",
        },
        {
          id: "MICROSOFT_AZURE_AD",
          name: "Microsoft Azure AD",
        },
      ],
      isSaving: false,
      isSavingPassword: false,
      passwordDialog: false,
      showpassword: false,
      tenants: {},
    };
  },
  computed: {
    formTitle() {
      return this.object
        ? `${this.$t("label.edit")} ${this.$tc("label.user", 1)}`
        : `${this.$t("label.add")} ${this.$tc("label.user", 1)}`;
    },

    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$apollo.queries.allPartners.skip = false;
        this.$apollo.queries.tenants.skip = false;
        this.$apollo.queries.groups.skip = false;
      }

      this.resetForm();
    },
  },
  created() {
    this.hasPermission = helper.hasPermission;
    this.rules = rules;
    this.strengthScore = helper.strengthScore;
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },

    resetForm() {
      this.error = null;
      this.isSaving = false;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      if (this.dialog && this.object) {
        this.editedItem.id = this.object.id;
        this.editedItem.clear2fa = false;
        this.editedItem.email = this.object.email;
        this.editedItem.enabled2fa = this.object.enabled2fa;
        this.editedItem.firstName = this.object.firstName;
        this.editedItem.identityProvider = this.object.identityProvider;
        this.editedItem.isSuperuser = this.object.isSuperuser;
        this.editedItem.lastName = this.object.lastName;
        this.editedItem.partnerNodeId = this.object.partner?.id;
        this.editedItem.require2fa = this.object.require2fa;
        this.editedItem.selectedGroups = this.object.groups
          ? this.object.groups.edges.map((item) => {
              return item.node.id;
            })
          : [];
        this.editedItem.tenantNodeId = this.object.tenant?.id;
        this.editedItem.userType = this.object.isPartnerUser
          ? "partnerUser"
          : "tenantUser";
      } else {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      }
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        email: this.editedItem.email,
        firstName: this.editedItem.firstName,
        groups: this.editedItem.selectedGroups,
        identityProvider: this.editedItem.identityProvider,
        isPartnerUser: this.editedItem.userType == "partnerUser" ? true : false,
        isSuperuser: this.editedItem.isSuperuser,
        lastName: this.editedItem.lastName,
        partnerNodeId: this.editedItem.partnerNodeId,
        require2fa: this.editedItem.require2fa,
        tenantNodeId: this.editedItem.tenantNodeId,
      };

      if (this.object) {
        payload.id = this.editedItem.id;

        payload = {
          ...payload,
          clear2fa: this.editedItem.clear2fa,
        };

        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateUser($input: UpdateUserInput!) {
                updateUser(input: $input) {
                  user {
                    id
                  }
                }
              }
            `,
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            this.$emit("changed", response);
            this.close();

            const payload = {
              color: "success",
              message: `User successfully edited`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);
          })
          .catch((error) => {
            this.error = error.graphQLErrors[0].message;
            this.isSaving = false;
          })
          .finally(() => {});
      } else {
        payload.password = this.editedItem.password;

        this.$apollo
          .mutate({
            mutation: gql`
              mutation createUser($input: CreateUserInput!) {
                createUser(input: $input) {
                  user {
                    id
                  }
                }
              }
            `,
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            this.$emit("changed", response);
            this.close();

            const payload = {
              color: "success",
              message: `User successfully added`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);
          })
          .catch((error) => {
            console.log(error);

            this.error = error.graphQLErrors
              .map((error) => error.message)
              .join(", ");
            this.isSaving = false;
          })
          .finally(() => {});
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
	"warningSuperuser": "Beware: a superuser can see and manage all tenants and partners. It must be a tenant user.",
	"2faEnabledText": "2FA is enabled",
	"2faNotSetText": "2FA is not set yet",
	"2faReset": "Reset 2FA",
	"2faResetText": "2FA will be reset"
  },
  "nl": {
	"warningSuperuser": "Let op: een superuser kan alle tenants en partners inzien en beheren. Dit moet tevens een tenant gebruiker zijn.",
	"2faEnabledText": "2FA is ingeschakeld",
	"2faNotSetText": "2FA is nog niet ingesteld",
	"2faReset": "Reset 2FA",
	"2faResetText": "2FA wordt gereset"
  },
  "de": {
	"warningSuperuser": "Achtung: Ein Superuser kann alle Tenant und Partner sehen und verwalten. Es muss ein Tenant-Benutzer sein.",
	"2faEnabledText": "2FA ist aktiviert",
	"2faNotSetText": "2FA ist noch nicht festgelegt",
	"2faReset": "2FA zurücksetzen",
	"2faResetText": "2FA wird zurückgesetzt"
  }
}
</i18n>

<template>
	<v-container :fluid="$vuetify.breakpoint.lgAndDown">
		<DeleteDialog
			:dialog.sync="deleteDialog"
			v-on:confirmed="deletingSelectedUserReportFiltersConfirmed"
		>
		</DeleteDialog>

		<ReportFiltersShareDialog
			:userReportFilterNodeId.sync="selectedUserReportFilterNodeId"
			:dialog.sync="dialog"
			v-on:onFilterChange="onFilterChange"
		></ReportFiltersShareDialog>

		<v-row justify="center" align="center">
			<v-col cols="12">
				<v-tabs v-model="tabs">
					<v-tabs-slider color="primary"></v-tabs-slider>

					<v-tab>{{
						$tc("label.report", 1) + $tc("label.filter", 2)
					}}</v-tab>
					<v-spacer />
					<v-btn
						class="mr-2 mt-2"
						color="error"
						dark
						outlined
						rounded
						small
						:disabled="selectedFilters.length <= 0"
						@click="deleteDialog = true"
						>{{ $t("label.delete") }}</v-btn
					>
					<v-btn class="mt-1" icon @click="closeDrawer()">
						<v-icon>close</v-icon>
					</v-btn>
				</v-tabs>

				<v-divider class="mb-5" />

				<v-row align="center" class="mb-10">
					<v-col cols="12">
						<v-data-table
							:headers="headers"
							:loading="$apollo.queries.userReportFilters.loading"
							:items="userReportFilters.edges"
							:options.sync="dataTableOptions"
							:server-items-length="userReportFilters.totalCount"
							:items-per-page="defaultItemsPerPage"
							:footer-props="{
								'items-per-page-options': [10, 25, 50, 75, 100],
								'disable-pagination':
									$apollo.queries.userReportFilters.loading,
							}"
							item-key="node.id"
							v-model="selectedFilters"
							show-select
						>
							<template v-slot:[`item.node.type`]="{ item }">
								<v-chip
									small
									:color="
										item.node.type === 'SHARED'
											? 'info'
											: item.node.type === 'ORGANIZATION'
											? 'primary'
											: ''
									"
								>
									{{ item.node.type }}
								</v-chip>
							</template>
							<template v-slot:[`item.action`]="{ item }">
								<v-btn
									icon
									color="primary"
									@click="shareFilter(item.node)"
								>
									<v-icon>share</v-icon>
								</v-btn>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import ReportFiltersShareDialog from "@/components/reports/ReportFiltersShareDialog.vue";

export default {
	name: "report-filters-drawer",
	props: ["drawer"],
	components: {
		ReportFiltersShareDialog,
		DeleteDialog,
	},
	apollo: {
		userReportFilters: {
			query: gql`
				query userReportFilters(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$orderBy: [String]
					$reportNodeId: ID!
					$allUserReportFilters: Boolean!
				) {
					userReportFilters(
						first: $first
						last: $last
						before: $before
						after: $after
						orderBy: $orderBy
						report_Id: $reportNodeId
						allUserReportFilters: $allUserReportFilters
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								filterJson
								filterName
								type
							}
						}
					}
				}
			`,

			fetchPolicy: "network-only",
			update: (data) => data.userReportFilters,
			pollInterval: 60000,
			skip: true,
		},
	},
	data: function () {
		return {
			dataTableOptions: {
				groupBy: [],
				groupDesc: [],
				itemsPerPage: 25,
				multiSort: true,
				mustSort: false,
				page: 1,
				sortBy: ["node.filterName"],
				sortDesc: [false],
			},
			defaultItemsPerPage: 25,
			deleteDialog: false,
			dialog: false,
			headers: [
				{
					text: this.$tc("label.name", 1),
					value: "node.filterName",
					sortable: true,
				},
				{
					text: this.$tc("label.type", 1),
					value: "node.type",
					sortable: true,
				},
				{
					text: this.$t("label.action"),
					align: "center",
					value: "action",
					sortable: false,
				},
			],
			page: 1,
			selectedFilters: [],
			tabs: [],
			userReportFilters: {},
			selectedUserReportFilterNodeId: null,
		};
	},
	computed: {
		me() {
			return this.$store.state.user.me;
		},
	},
	watch: {
		drawer(val) {
			if (val) {
				this.selectedFilters = [];
				this.refreshCurrentPage();
			}
		},

		dataTableOptions: {
			handler() {
				this.refreshCurrentPage();
			},
			deep: true,
		},
	},
	created() {
		this.orderByList = helper.orderByList;
	},
	mounted() {},
	methods: {
		closeDrawer() {
			this.$emit("update:drawer", false);
		},

		deletingSelectedUserReportFiltersConfirmed() {
			var filterIds = [];
			this.selectedFilters.forEach(function (item, index) {
				filterIds.push(item.node.id);
			});

			const payload = {
				ids: filterIds,
			};

			this.$apollo
				.mutate({
					mutation: gql`
						mutation deleteUserReportFilters(
							$input: DeleteUserReportFiltersInput!
						) {
							deleteUserReportFilters(input: $input) {
								userReportFilters {
									id
								}
							}
						}
					`,
					variables: {
						input: payload,
					},
				})
				.then((response) => {
					this.$apollo.queries.userReportFilters.refresh();

					this.deleteDialog = false;

					const payload = {
						color: "success",
						message: `Filters successfully deleted!`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				});
			this.selectedFilters = [];
		},

		onFilterChange() {
			this.$apollo.queries.userReportFilters.refresh();
		},

		refreshCurrentPage() {
			const { sortBy, sortDesc, page, itemsPerPage } =
				this.dataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.userReportFilters.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.userReportFilters.pageInfo.endCursor;
			}

			var orderByList = this.orderByList(sortBy, sortDesc);

			this.$apollo.queries.userReportFilters.setVariables({
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: orderByList,
				reportNodeId: this.$route.params.reportNodeId,
				allUserReportFilters: false,
			});

			this.$apollo.queries.userReportFilters.skip = false;
			this.$apollo.queries.userReportFilters.refresh();

			this.page = page;
		},

		shareFilter(item) {
			this.selectedUserReportFilterNodeId = item.id;
			this.dialog = true;
		},
	},
};
</script>

import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

// rich text editor tiptap
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

import "@/sass/variables.scss";

Vue.use(Vuetify);

const vuetify = new Vuetify({
	theme: {
		themes: {
			light: {
				// primary: "#10325a", // LT donkerblauw --> TT blauw
				primary: "#0073FF", // LT donkerblauw --> TT blauw
				secondary: colors.cyan.lighten4, // LT oranje --> TT paars
				success: colors.green.lighten1, // #66bb6a
				info: colors.lightBlue.lighten1, // #29b6f6
				warning: colors.orange, // #ff9800
				error: colors.red.lighten1, // #ef5350
			},
		},
	},
	icons: {
		iconfont: "md",
	},
	breakpoint: {
		mobileBreakpoint: 1025, // This is equivalent to a value of 960
	},
	defaultAssets: {
		font: {
			family: "Poppins",
		},
	},
});

Vue.use(TiptapVuetifyPlugin, {
	vuetify,
});

export default vuetify;

const state = {
	dataTableOptions: {
		groupBy: [],
		groupDesc: [],
		itemsPerPage: 25,
		multiSort: true,
		mustSort: false,
		page: 1,
		sortBy: ["node.user.email", "node.report.reportName"],
		sortDesc: [false, false],
	},
	search: null,
	selectedPartner: null,
	selectedReportTenant: null,
	selectedUserTenant: null,
};

const mutations = {
	SET_DATA_TABLE_OPTIONS(state, payload) {
		state.dataTableOptions = payload;
	},

	SET_SEARCH(state, payload) {
		state.search = payload;
	},

	SET_SELECTED_PARTNER(state, payload) {
		state.selectedPartner = payload;
	},

	SET_SELECTED_REPORT_TENANT(state, payload) {
		state.selectedReportTenant = payload;
	},

	SET_SELECTED_USER_TENANT(state, payload) {
		state.selectedUserTenant = payload;
	},
};

const actions = {
	setDataTableOptions(context, payload) {
		context.commit("SET_DATA_TABLE_OPTIONS", payload);
	},

	setSearch(context, payload) {
		context.commit("SET_SEARCH", payload);
	},

	setSelectedPartner(context, payload) {
		context.commit("SET_SELECTED_PARTNER", payload);
	},

	setSelectedReportTenant(context, payload) {
		context.commit("SET_SELECTED_REPORT_TENANT", payload);
	},

	setSelectedUserTenant(context, payload) {
		context.commit("SET_SELECTED_USER_TENANT", payload);
	},
};

const getters = {
	getDataTableOptions(state) {
		return state.dataTableOptions;
	},

	getSearch(state) {
		return state.search;
	},

	getSelectedPartner(state) {
		return state.selectedPartner;
	},

	getSelectedReportTenant(state) {
		return state.selectedReportTenant;
	},

	getSelectedUserTenant(state) {
		return state.selectedUserTenant;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};

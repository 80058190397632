<template>
  <v-container fluid class="my-1">
    <v-expansion-panel class="mb-2">
      <v-expansion-panel-header>Azure</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12" md="3" lg="4">
            <ControlPanelCard
              :title="$tc('createSharepoint.title')"
              :text="$tc('createSharepoint.description')"
              @open="createSharepoint($event)"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <CreateSharepointDialog :dialog.sync="dialog.createSharepoint" />
  </v-container>
</template>

<script>
import CreateSharepointDialog from "@/components/integrations/controlPanel/azure/CreateSharepointDialog.vue";
import ControlPanelCard from "@/components/integrations/controlPanel/ControlPanelCard.vue";

export default {
  name: "azure-panel",
  props: [],
  components: {
    CreateSharepointDialog,
    ControlPanelCard,
  },

  apollo: {},

  data() {
    return {
      dialog: {
        createSharepoint: false,
      },
    };
  },
  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {},
  created() {},
  methods: {
    createSharepoint() {
      this.dialog.createSharepoint = true;
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "createSharepoint": {
        "title": "Create Sharepoint",
        "description": "This dialog will allow you to create a client Sharepoint and all other needed resources."
      }
    },
    "nl": {
      "createSharepoint": {
        "title": "Sharepoint aanmaken",
        "description": "Deze dialoog stelt je in staat om een klant-Sharepoint en alle andere benodigde resources aan te maken."
      }
    },
    "de": {
      "createSharepoint": {
        "title": "Sharepoint Erstellen",
        "description": "Dieser Dialog ermöglicht es Ihnen, ein Sharepoint für den Kunden und alle anderen benötigten Ressourcen zu erstellen."
      }
    }
  }
</i18n>

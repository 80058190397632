<template>
  <v-row>
    <v-col cols="12" md="2" sm="6">
      <v-text-field
        :label="$t('label.search')"
        @click:append-outer="refresh()"
        @click:clear="clearSearch()"
        @keydown.enter="refresh()"
        clearable
        dense
        hide-details
        outlined
        required
        v-model="search"
      ></v-text-field>
    </v-col>

    <v-spacer />

    <v-col cols="12" md="2" align="right">
      <v-btn @click="resetFilters()" color="primary" dark rounded small text>
        {{ $t("label.clearFilters") }}
      </v-btn>
    </v-col>

    <v-col cols="12" md="12">
      <v-data-table
        :footer-props="{
          'disable-pagination': $apollo.queries.allTaps.loading,
          'items-per-page-options': [5, 10, 25, 50, 75, 100],
        }"
        :headers="headers"
        :items="allTaps.edges"
        :loading="$apollo.queries.allTaps.loading"
        :options.sync="dataTableOptions"
        :server-items-length="allTaps.totalCount"
        item-key="node.id"
      >
        <template v-slot:[`item.node.status`]="{ item }">
          <StatusChip :status="item.node.status" />
        </template>
        <template v-slot:[`item.node.webflowItem.isDraft`]="{ item }">
          <StatusChip :status="item.node.webflowItem.isDraft ? 'DRAFT' : 'PUBLISHED'" />
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="copyLink(item.node)"
                color="primary"
                icon
                v-on="on"
              >
                <v-icon>link</v-icon>
              </v-btn>
            </template>
            <span> Copy install link </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import StatusChip from "@/components/base/StatusChip.vue";

export default {
  name: "taps-data-table",
  props: [],
  components: {
    StatusChip,
  },

  apollo: {
    allTaps: {
      query: gql`
        query allTaps(
          $first: Int
          $last: Int
          $before: String
          $after: String
          $orderBy: [String]
          $search: String
        ) {
          allTaps(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: $orderBy
            search: $search
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                connectionSet {
                  totalCount
                }
                externalId
                name
                pk
                status
                webflowItem {
                  isDraft
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allTaps,
    },
  },

  data() {
    return {
      dataTableOptions: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 5,
        multiSort: true,
        mustSort: false,
        page: 1,
        sortBy: ["node.name"],
        sortDesc: [],
      },
      headers: [
        {
          text: this.$tc("label.pk", 1),
          value: "node.pk",
          sortable: true,
        },
        {
          text: this.$tc("label.name", 1),
          value: "node.name",
          sortable: true,
        },
        {
          text: this.$tc("label.connection", 2),
          value: "node.connectionSet.totalCount",
          sortable: false,
        },
        {
          text: this.$tc("label.status"),
          value: "node.status",
          sortable: true,
        },
        {
          text: `Webflow ${this.$tc("label.status")}`,
          value: "node.webflowItem.isDraft",
          sortable: false,
        },
        {
          text: this.$t("label.action"),
          align: "center",
          value: "action",
          sortable: false,
        },
      ],
      allTaps: {},
      search: null,
    };
  },
  computed: {},
  watch: {
    dataTableOptions() {
      this.refresh();
    },
  },
  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;
  },
  methods: {
    clearSearch() {
      this.search = null;
      this.refresh();
    },

    copyLink(item) {
      const { protocol, host } = window.location;
      const url = `${protocol}//${host}/#/taps/${item.externalId}/connect`;
      helper.copyValue(url);
    },

    refresh() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;

      var _first = itemsPerPage;
      var _last = null;
      var _before = null;
      var _after = null;

      if (page < this.page) {
        _first = null;
        _last = itemsPerPage;
        _before = this.allTaps.pageInfo.startCursor;
        _after = null;
      }

      if (page > this.page) {
        _first = itemsPerPage;
        _last = null;
        _before = null;
        _after = this.allTaps.pageInfo.endCursor;
      }

      var orderByList = this.orderByList(sortBy, sortDesc);

      this.$apollo.queries.allTaps.setVariables({
        first: _first,
        last: _last,
        before: _before,
        after: _after,
        orderBy: orderByList,
        search: this.search,
      });

      this.$apollo.queries.allTaps.skip = false;
      this.$apollo.queries.allTaps.refresh();

      this.page = page;
    },

    resetFilters() {
      this.clearSearch();
    },
  },
};
</script>

<template>
  <v-container fluid class="pa-0">
    <v-container>
      <v-row justify="center" align="center" class="mt-10">
        <v-col cols="12" sm="6">
          <v-autocomplete
            :filter="customFilter"
            :items="searchReports?.edges"
            :loading="$apollo.queries.searchReports.loading"
            :search-input.sync="search"
            @change="
              goToReportDetail(
                selectedReport.node.id,
                selectedReport.node.reportType
              )
            "
            cache-items
            clearable
            hide-details
            hide-no-data
            item-text="node.reportName"
            item-value="node.id"
            prepend-inner-icon="search"
            return-object
            solo
            v-bind:placeholder="$t('label.search')"
            v-model="selectedReport"
          >
            <template slot="item" slot-scope="{ item }">
              <v-icon
                :color="item.node.isStandard ? 'warning' : 'primary'"
                class="mr-2"
                >{{
                  item.node.reportType == "POWERBIREPORT"
                    ? "dashboard"
                    : "layers"
                }}</v-icon
              >
              {{
                me.isSuperuser || me.isPartnerUser
                  ? `${item.node.tenant.tenantName} - ${item.node.reportName}`
                  : item.node.reportName
              }}
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2" v-if="me.isSuperuser">
          <v-switch
            :label="$t('label.maintenance')"
            :loading="$apollo.queries.allUnderMaintenances.loading || isSaving"
            @change="updateUnderMaintenance()"
            class="my-0 py-0"
            hide-details
            v-model="isUnderMaintenance"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row align="center" class="mt-10">
        <v-col cols="12" sm="6" md="4">
          <HomeCard
            :loading="$apollo.queries.reports.loading"
            :route="{ name: 'reports' }"
            :text="reports?.totalCount"
            :title="$tc('label.report', 2)"
            :storeAction="'reports/setTabs'"
            :storeValue="0"
            color="primary"
            heading="h2"
            icon="bar_chart"
          />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <HomeCard
            :loading="$apollo.queries.allUserTasks.loading"
            :route="{ name: 'reports' }"
            :text="allUserTasks?.totalCount"
            :title="$tc('label.export', 2)"
            :storeAction="'reports/setTabs'"
            :storeValue="1"
            color="primary"
            heading="h2"
            icon="file_download"
          />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <HomeCard
            :loading="me ? false : true"
            :route="{ name: 'settings' }"
            :text="me.firstName"
            :title="$tc('label.setting', 2)"
            color="primary"
            heading="h3"
            icon="settings"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          v-if="hasPermission('users.view_customuser') && me.passed2fa"
        >
          <HomeCard
            :loading="$apollo.queries.users.loading"
            :route="{ name: 'users' }"
            :text="users?.totalCount"
            :title="$tc('label.user', 2)"
            color="primary"
            heading="h2"
            icon="contacts"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          v-if="hasPermission('integrations.view_connection') && me.passed2fa"
        >
          <HomeCard
            :loading="$apollo.queries.allConnections.loading"
            :route="{ name: 'taps' }"
            :text="allConnections?.totalCount"
            :title="$tc('label.tap', 2)"
            :storeAction="'taps/setTabs'"
            :storeValue="0"
            color="primary"
            heading="h2"
            icon="sync_alt"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          v-if="
            hasPermission('integrations.view_run') &&
            me.passed2fa &&
            !me.isPartnerUser
          "
        >
          <HomeCard
            :loading="$apollo.queries.allAssets.loading"
            :route="{ name: 'taps' }"
            :text="allAssets?.totalCount"
            :title="$tc('label.run', 2)"
            :storeAction="'taps/setTabs'"
            :storeValue="me.isSuperuser ? 3 : 1"
            color="primary"
            heading="h2"
            icon="sync"
          />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <HomeCard
            :color="overallStatus ? overallStatus?.color : 'primary'"
            :icon="overallStatus?.icon"
            :loading="overallStatus ? false : true"
            :route="{ name: 'status' }"
            :text="
              overallStatus?.[`name${capitalizeFirstLetter($i18n.locale)}`]
            "
            :title="$tc('label.status', 2)"
            heading="h3"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          v-if="allMaintenances?.edges?.length > 0"
        >
          <HomeCard
            :loading="$apollo.queries.allMaintenances.loading"
            :route="{ name: 'status' }"
            :text="
              allMaintenances?.edges[0]?.node?.start | moment('YYYY-MM-DD')
            "
            :title="`${$t('label.next')} ${$tc('label.maintenance', 2)}`"
            color="primary"
            heading="h3"
            icon="build"
          />
        </v-col>
      </v-row>

      <v-row class="mt-10">
        <v-slide-group active-class="success" show-arrows>
          <v-slide-item
            v-for="reportFavorite in reportFavorites.edges"
            :key="reportFavorite.node.id"
          >
            <v-hover>
              <template v-slot="{ hover }">
                <v-card
                  :loading="
                    $apollo.queries.reportFavorites.loading ? 'white' : null
                  "
                  :elevation="hover ? 10 : 1"
                  color="grey lighten-1"
                  dark
                  outlined
                  height="160px"
                  link
                  class="rounded-xl ma-4"
                >
                  <v-card-title class="subtitle-1"
                    >{{ reportFavorite.node.report.reportName }}
                    <v-spacer />
                    <v-icon
                      right
                      small
                      @click="deleteReportFavorite(reportFavorite.node.id)"
                      >star</v-icon
                    >
                  </v-card-title>

                  <v-card-text
                    @click="
                      goToReportDetail(
                        reportFavorite.node.report.id,
                        reportFavorite.node.report.reportType
                      )
                    "
                    class="text-center"
                  >
                    <v-row>
                      <v-col cols="12">
                        <v-icon
                          v-if="
                            reportFavorite.node.report.reportType ===
                            'POWERBIREPORT'
                          "
                          x-large
                          >dashboard</v-icon
                        >
                        <v-icon
                          v-else-if="
                            reportFavorite.node.report.reportType ===
                            'PAGINATEDREPORT'
                          "
                          x-large
                          >layers</v-icon
                        >
                      </v-col>
                      <v-col cols="12">
                        <v-chip
                          small
                          v-if="
                            reportFavorite.node.report.lastRefreshStatus ===
                            'Completed'
                          "
                          color="success"
                          >{{
                            reportFavorite.node.report.lastRefreshEndTime
                              | moment("YYYY-MM-DD HH:mm")
                          }}</v-chip
                        >
                        <v-chip
                          v-else-if="
                            reportFavorite.node.report.lastRefreshStatus ===
                            'Failed'
                          "
                          color="error"
                          >{{
                            reportFavorite.node.report.lastRefreshEndTime
                              | moment("YYYY-MM-DD HH:mm")
                          }}</v-chip
                        >
                        <v-chip
                          v-else-if="
                            reportFavorite.node.report.lastRefreshStatus ===
                            'Disabled'
                          "
                          color="error"
                          >{{
                            reportFavorite.node.report.lastRefreshEndTime
                              | moment("YYYY-MM-DD HH:mm")
                          }}</v-chip
                        >
                        <v-chip
                          v-else-if="
                            reportFavorite.node.report.lastRefreshStatus ===
                            'Unknown'
                          "
                          color="warning"
                          >{{
                            reportFavorite.node.report.lastRefreshEndTime
                              | moment("YYYY-MM-DD HH:mm")
                          }}</v-chip
                        >
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </template>
            </v-hover>
          </v-slide-item>
        </v-slide-group>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import HomeCard from "@/components/base/HomeCard.vue";

export default {
  props: ["loggedOutAsOtherUserCounter"],
  components: {
    HomeCard,
  },

  apollo: {
    reports: {
      query: gql`
        query reports($ownReports: Boolean!) {
          reports(ownReports: $ownReports) {
            totalCount
          }
        }
      `,
      variables() {
        return {
          ownReports: false,
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.reports,
    },

    allUserTasks: {
      query: gql`
        query allUserTasks {
          allUserTasks {
            totalCount
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allUserTasks,
    },

    users: {
      query: gql`
        query users {
          users {
            totalCount
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.users,
      skip: true,
    },

    allConnections: {
      query: gql`
        query allConnections {
          allConnections {
            totalCount
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allConnections,
      skip: true,
    },

    allAssets: {
      query: gql`
        query allAssets($subType_In: String) {
          allAssets(subType_In: $subType_In) {
            totalCount
          }
        }
      `,
      variables() {
        return {
          subType_In: "ADF pipeline,LA trigger,Dataset,Job",
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allAssets,
      skip: true,
    },

    allMaintenances: {
      query: gql`
        query allMaintenances(
          $first: Int
          $orderBy: [String]
          $isCurrentOrFuture: Boolean
        ) {
          allMaintenances(
            first: $first
            orderBy: $orderBy
            isCurrentOrFuture: $isCurrentOrFuture
          ) {
            edges {
              node {
                id
                start
              }
            }
          }
        }
      `,
      variables() {
        return {
          first: 1,
          orderBy: ["start"],
          isCurrentOrFuture: true,
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allMaintenances,
    },

    searchReports: {
      query: gql`
        query searchReports(
          $search: String
          $orderBy: [String]
          $ownReports: Boolean!
        ) {
          reports(search: $search, orderBy: $orderBy, ownReports: $ownReports) {
            edges {
              node {
                id
                isStandard
                reportName
                reportType
                tenant {
                  tenantName
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.reports,
      skip: true,
    },

    reportFavorites: {
      query: gql`
        query reportFavorites {
          reportFavorites {
            edges {
              node {
                id
                isFavorite
                report {
                  id
                  reportName
                  lastRefreshEndTime
                  lastRefreshStatus
                  reportType
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.reportFavorites,
    },

    allUnderMaintenances: {
      query: gql`
        query allUnderMaintenances {
          allUnderMaintenances {
            edges {
              node {
                id
                isUnderMaintenance
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allUnderMaintenances,
      result() {
        this.isUnderMaintenance =
          this.allUnderMaintenances.edges[0].node.isUnderMaintenance;
      },
    },
  },

  data() {
    return {
      allAssets: {},
      allConnections: {},
      allMaintenances: {},
      allUnderMaintenances: {},
      allUserTasks: {},
      isSaving: false,
      reportFavorites: {},
      reports: {},
      search: null,
      searchReports: {},
      selectedReport: null,
      isUnderMaintenance: false,
      users: {},
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me || {};
    },

    overallStatus() {
      return this.$store.state.user.overallStatus;
    },

    customFilter() {
      return (item, queryText, itemText) => {
        // Custom filtering logic
        const searchText = queryText.toLowerCase();
        const reportName = item.node.reportName.toLowerCase();
        const tenantName = item.node.tenant.tenantName.toLowerCase();
        return (
          reportName.includes(searchText) || tenantName.includes(searchText)
        );
      };
    },
  },
  watch: {
    loggedOutAsOtherUserCounter(val) {
      this.refreshAll();
    },

    search(val) {
      if (this.search == null || this.search.length < 1) {
        return;
      } else {
        this.refresh();
      }
    },

    me() {
      this.setSkipQueries();
    },
  },
  created() {
    this.capitalizeFirstLetter = helper.capitalizeFirstLetter;
    this.hasPermission = helper.hasPermission;

    this.setSkipQueries();
    this.fetchOverallStatus();
  },
  methods: {
    goToReportDetail(reportNodeId, reportType) {
      if (reportType === "POWERBIREPORT") {
        this.$router.push({
          name: "report-detail",
          params: {
            reportNodeId: reportNodeId,
          },
        });
      } else if (reportType === "PAGINATEDREPORT") {
        this.$router.push({
          name: "paginated-report-detail",
          params: {
            reportNodeId: reportNodeId,
          },
        });
      }
    },

    refresh() {
      this.$apollo.queries.searchReports.setVariables({
        search: this.search,
        orderBy: ["tenant__tenantName", "reportName"],
        ownReports: false,
      });

      this.$apollo.queries.searchReports.skip = false;
      this.$apollo.queries.searchReports.refresh();
    },

    refreshAll() {
      this.$apollo.queries.reports.refresh();
      this.$apollo.queries.allUserTasks.refresh();
      this.$apollo.queries.users.refresh();
      this.$apollo.queries.allConnections.refresh();
      this.$apollo.queries.allAssets.refresh();
      this.$apollo.queries.allMaintenances.refresh();
      this.$apollo.queries.searchReports.refresh();
      this.$apollo.queries.reportFavorites.refresh();
      this.$apollo.queries.allUnderMaintenances.refresh();
    },

    setSkipQueries() {
      if (this.me.passed2fa) {
        if (this.hasPermission("users.view_customuser")) {
          this.$apollo.queries.users.skip = false;
        }

        if (this.hasPermission("integrations.view_connection")) {
          this.$apollo.queries.allConnections.skip = false;
        }

        if (this.hasPermission("integrations.view_run")) {
          this.$apollo.queries.allAssets.skip = false;
        }
      }
    },

    deleteReportFavorite(reportFavoriteId) {
      var payload = {
        id: reportFavoriteId,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteReportFavorite($input: DeleteReportFavoriteInput!) {
              deleteReportFavorite(input: $input) {
                reportFavorite {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then(() => {
          this.$apollo.queries.reportFavorites.refresh();
        })
        .catch((error) => {
          this.error = error.graphQLErrors[0].message;
        });
    },

    updateUnderMaintenance() {
      this.error = null;
      this.isSaving = true;

      var payload = {
        id: this.allUnderMaintenances.edges[0].node.id,
        isUnderMaintenance: this.isUnderMaintenance,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateUnderMaintenance(
              $input: UpdateUnderMaintenanceInput!
            ) {
              updateUnderMaintenance(input: $input) {
                clientMutationId
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Under maintenance successfully updated`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$apollo.queries.allUnderMaintenances.refresh();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    fetchOverallStatus() {
      this.$store
        .dispatch("user/fetchOverallStatus")
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<template>
	<v-row class="mt-2">
		<v-col class="py-0" cols="12">
			<v-alert tile type="error" v-if="error">{{ error }}</v-alert>
		</v-col>
		<v-col cols="12" md="2" sm="6">
			<v-text-field
				@click:append-outer="refresh()"
				@click:clear="clearSearch()"
				@keydown.enter="refresh()"
				clearable
				dense
				hide-details
				outlined
				required
				:label="$t('label.search')"
				v-model="search"
			></v-text-field>
		</v-col>
		<v-spacer />
		<v-col align="right" cols="12" md="2" sm="6">
			<v-btn
				@click="resetFilters()"
				color="primary"
				dark
				rounded
				small
				text
			>
				{{ $t("label.clearFilters") }}
			</v-btn>
		</v-col>
		<v-col cols="12" md="12">
			<v-data-table
				:footer-props="{
					'disable-pagination': $apollo.queries.themes.loading,
					'items-per-page-options': [10, 25, 50, 75, 100],
				}"
				:headers="headers"
				:items="themes.edges"
				:loading="$apollo.queries.themes.loading"
				:options.sync="dataTableOptions"
				:server-items-length="themes.totalCount"
				:show-select="me.isSuperuser ? 'show-select' : undefined"
				item-key="node.id"
				v-model="selectedItems"
			>
				<template v-slot:[`item.node.primaryColor`]="{ item }">
					<v-chip :color="item.node.primaryColor" small>{{
						item.node.primaryColor
					}}</v-chip>
				</template>
				<template v-slot:[`item.action`]="{ item }">
					<v-btn @click="edit(item.node)" color="primary" icon>
						<v-icon>edit</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-col>

		<DeleteDialog
			:dialog.sync="deleteDialog"
			v-on:confirmed="onDeleteConfirmed"
		/>
		<ThemeEditDialog
			:dialog.sync="editDialog"
			:object.sync="editedItem"
			v-on:changed="onChanged"
		/>
	</v-row>
</template>

<script>
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import ThemeEditDialog from "@/components/users/ThemeEditDialog.vue";

export default {
	name: "themes-list",
	components: {
		DeleteDialog,
		ThemeEditDialog,
	},

	apollo: {
		themes: {
			query: gql`
				query themes(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$orderBy: [String]
					$search: String
				) {
					themes(
						first: $first
						last: $last
						before: $before
						after: $after
						search: $search
						orderBy: $orderBy
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								themeName
								primaryColor
								themeLogo
								themeLogoPath
								themeLogoName
							}
						}
					}
				}
			`,
			fetchPolicy: "cache-and-network",
			update: (data) => data.themes,
			skip: true,
			pollInterval: 60000,
		},
	},

	data: function () {
		return {
			themes: {},
			deleteDialog: false,
			editDialog: false,
			editedItem: {},
			error: null,
			headers: [
				{
					text: this.$tc("label.name", 1),
					value: "node.themeName",
					sortable: true,
				},
				{
					text: this.$tc("label.color", 1),
					value: "node.primaryColor",
					sortable: true,
				},
				{
					text: this.$t("label.action"),
					align: "center",
					value: "action",
					sortable: false,
				},
			],
			selectedItems: [],
		};
	},

	computed: {
		dataTableOptions: {
			get() {
				return this.$store.getters["themesList/getDataTableOptions"];
			},
			set(value) {
				this.$store.dispatch("themesList/setDataTableOptions", value);
			},
		},

		me() {
			return this.$store.state.user.me;
		},

		search: {
			get() {
				return this.$store.getters["themesList/getSearch"];
			},
			set(value) {
				this.$store.dispatch("themesList/setSearch", value);
			},
		},
	},

	watch: {
		dataTableOptions() {
			this.refresh();
		},

		selectedItems() {
			this.$emit("updateNrOfSelectedItems", this.selectedItems.length);
		},
	},

	created() {
		this.orderByList = helper.orderByList;
	},

	mounted() {},

	methods: {
		add() {
			this.editedItem = null;
			this.editDialog = true;
		},

		clearSearch() {
			this.search = null;
			this.refresh();
		},

		deleteSelected() {
			this.deleteDialog = true;
		},

		edit(item) {
			this.editedItem = item;
			this.editDialog = true;
		},

		onChanged() {
			this.$apollo.queries.themes.refresh();
		},

		onDeleteConfirmed() {
			var ids = [];
			this.selectedItems.forEach(function (item, index) {
				ids.push(item.node.id);
			});

			const payload = {
				ids: ids,
			};

			this.$apollo
				.mutate({
					mutation: gql`
						mutation deleteThemes($input: DeleteThemesInput!) {
							deleteThemes(input: $input) {
								theme {
									id
								}
							}
						}
					`,
					variables: {
						input: payload,
					},
				})
				.then(() => {
					this.$apollo.queries.themes.refresh();

					this.deleteDialog = false;
					this.selectedItems = [];

					const payload = {
						color: "success",
						message: `Themes successfully deleted`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					this.error = error;
				});
		},

		refresh() {
			const { sortBy, sortDesc, page, itemsPerPage } =
				this.dataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.themes.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.themes.pageInfo.endCursor;
			}

			var orderByList = this.orderByList(sortBy, sortDesc);

			this.$apollo.queries.themes.setVariables({
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: orderByList,
				search: this.search,
			});

			this.$apollo.queries.themes.skip = false;
			this.$apollo.queries.themes.refresh();

			this.page = page;
		},

		resetFilters() {
			this.clearSearch();
		},
	},
};
</script>

<template>
	<v-row justify="center">
		<v-dialog max-width="500px" persistent v-model="dialog">
			<v-card>
				<v-alert tile type="error" v-if="error">{{ error }}</v-alert>
				<v-card-title class="primary white--text">{{
					formTitle
				}}</v-card-title>
				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row>
							<v-col cols="12">
								<v-text-field
									:append-icon="
										showOldPassword
											? 'visibility'
											: 'visibility_off'
									"
									:counter="50"
									:label="`${$t('label.old')} ${$tc(
										'label.password',
										1
									)}`"
									:rules="[
										rules.maxLength(50),
										rules.required,
									]"
									:type="
										showOldPassword ? 'text' : 'password'
									"
									@click:append="
										showOldPassword = !showOldPassword
									"
									outlined
									prepend-icon="lock"
									v-model="oldPassword"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									:append-icon="
										showNewPassword
											? 'visibility'
											: 'visibility_off'
									"
									:counter="50"
									:label="`${$t('label.new')} ${$tc(
										'label.password',
										1
									)}`"
									:rules="[
										rules.maxLength(50),
										rules.required,
										rules.strength,
									]"
									:type="
										showNewPassword ? 'text' : 'password'
									"
									@click:append="
										showNewPassword = !showNewPassword
									"
									outlined
									prepend-icon="lock_open"
									v-model="newPassword"
								></v-text-field>

								<v-progress-linear
									:class="['mb-7']"
									:color="strengthScore(newPassword).color"
									:value="strengthScore(newPassword).value"
								></v-progress-linear>
							</v-col>
							<v-col cols="12">
								<v-text-field
									:append-icon="
										showNewPassword
											? 'visibility'
											: 'visibility_off'
									"
									:counter="50"
									:label="`${$t('label.confirm')} ${$tc(
										'label.password',
										1
									)}`"
									:rules="[
										rules.required,
										rules.matching(this.newPassword),
									]"
									:type="
										showNewPassword ? 'text' : 'password'
									"
									@click:append="
										showNewPassword = !showNewPassword
									"
									@keydown.enter="save"
									@submit.prevent
									outlined
									prepend-icon="lock_open"
									v-model="newPasswordConfirm"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn @click="close" rounded text>{{
						$t("label.cancel")
					}}</v-btn>
					<v-btn
						:loading="isSaving"
						@click="save"
						color="primary"
						outlined
						rounded
						>{{ $t("label.save") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import rules from "@/utils/rules.js";

export default {
	name: "password-edit-dialog",
	props: ["dialog"],
	data() {
		return {
			error: null,
			isSaving: false,
			newPassword: "",
			newPasswordConfirm: "",
			oldPassword: "",
			showNewPassword: false,
			showOldPassword: false,
		};
	},
	computed: {
		formTitle() {
			return `${this.$t("label.edit")} ${this.$tc("label.password", 1)}`;
		},
	},
	watch: {
		dialog() {
			this.resetForm();
		},

		// Reset the password confirmation if original changes
		newPassword(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.newPasswordConfirm = "";
			}
		},
	},
	created() {
		this.rules = rules;
		this.strengthScore = helper.strengthScore;
	},
	methods: {
		close() {
			this.$emit("update:dialog", false);
		},

		resetForm() {
			this.error = null;
			this.isSaving = false;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			this.newPassword = "";
			this.newPasswordConfirm = "";
			this.oldPassword = "";
			this.showNewPassword = false;
			this.showOldPassword = false;
		},

		save() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.error = null;
			this.isSaving = true;

			var payload = {
				newPassword: this.newPassword,
				oldPassword: this.oldPassword,
			};

			this.$apollo
				.mutate({
					mutation: gql`
						mutation changeMyPassword(
							$input: ChangeMyPasswordInput!
						) {
							changeMyPassword(input: $input) {
								user {
									id
								}
							}
						}
					`,
					variables: {
						input: payload,
					},
				})
				.then((response) => {
					this.close();

					const payload = {
						color: "success",
						message: `Password successfully edited`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					this.error = error.graphQLErrors[0].message;
					this.isSaving = false;
				})
				.finally(() => {});
		},
	},
};
</script>

<template>
  <v-container fluid>
    <v-row class="align-center pl-3">
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
      <v-spacer />
    </v-row>

    <v-container :fluid="$vuetify.breakpoint.lgAndDown">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-tabs v-model="tabs">
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab>{{ $tc("label.status", 2) }}</v-tab>

            <v-spacer />
          </v-tabs>
          <v-divider></v-divider>

          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <StatusList ref="StatusList" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import StatusList from "@/components/status/StatusList.vue";
import helper from "@/utils/helper.js";

export default {
  name: "status",
  components: {
    StatusList,
  },

  apollo: {},

  data: function () {
    return {
      tabs: 0,
    };
  },

  computed: {
    breadcrumbs() {
      let breadcrumbs = [
        {
          text: this.$t("label.home"),
          exact: true,
          disable: false,
          to: {
            name: "home",
          },
        },
        {
          text: this.$tc("label.status", 2),
          disabled: true,
        },
      ];
      return breadcrumbs;
    },

    me() {
      return this.$store.state.user.me;
    },
  },

  watch: {},

  created() {
    this.hasPermission = helper.hasPermission;
  },

  mounted() {},

  methods: {},
};
</script>

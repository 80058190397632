<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col :class="['my-0', 'py-0']" cols="12">
          <v-card flat>
            <v-card-title
              :class="['font-weight-bold', 'text-h4']"
              style="word-break: break-word"
            >
              {{ $t("title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("subtitle") }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="2" sm="6">
          <v-text-field
            :label="$t('label.search')"
            @click:append-outer="refresh()"
            @click:clear="clearSearch()"
            @keydown.enter="refresh()"
            clearable
            dense
            hide-details
            outlined
            required
            v-model="search"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2" sm="6">
          <v-switch
            :label="$t('label.expandAll')"
            @change="expandAll($event)"
            class="mb-0 mt-2 py-0"
            hide-details
          ></v-switch>
        </v-col>

        <v-spacer />

        <v-col cols="12" md="2" align="right">
          <v-btn
            @click="resetFilters()"
            color="primary"
            dark
            rounded
            small
            text
          >
            {{ $t("label.clearFilters") }}
          </v-btn>
        </v-col>

        <v-col cols="12" md="12">
          <v-data-table
            :expanded.sync="expanded"
            :footer-props="{
              'disable-pagination':
                $apollo.queries.allTaskResultExtensions.loading,
              'items-per-page-options': [10, 25, 50, 75, 100],
            }"
            :headers="headers"
            :items="allTaskResultExtensions.edges"
            :loading="$apollo.queries.allTaskResultExtensions.loading"
            :options.sync="dataTableOptions"
            :server-items-length="allTaskResultExtensions.totalCount"
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            item-key="node.id"
            show-expand
          >
            <template v-slot:[`item.node.pk`]="{ item }">
              #REL{{ item.node.pk }}
            </template>
            <template v-slot:[`item.node.status`]="{ item }">
              <StatusChip :status="item.node.status" />
            </template>
            <template v-slot:[`item.node.createdAt`]="{ item }">
              {{ item.node.createdAt | moment("YYYY-MM-DD HH:mm:SS") }}
            </template>
            <template v-slot:[`item.node.user.firstName`]="{ item }">
              {{ item.node.user.firstName }}
              {{ item.node.user.lastName }}
            </template>
            <template v-slot:item.data-table-expand="{ item, isExpanded }">
              <td class="text-start">
                <v-btn
                  icon
                  class="v-data-table__expand-icon"
                  :class="{
                    'v-data-table__expand-icon--active': isExpanded,
                  }"
                >
                  <v-icon>expand_more</v-icon>
                </v-btn>
              </td>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="headersRelease"
                  :hide-default-footer="true"
                  :hide-default-header="true"
                  :items="item.node.taskresultextensionSet.edges"
                  :items-per-page="100"
                  item-key="item.node.id"
                >
                  <template v-slot:[`item.node.connection.pk`]="{ item }">
                    #TAP{{ item.node.connection.pk }}
                  </template>
                  <template v-slot:[`item.node.taskResult.status`]="{ item }">
                    <StatusChip :status="item.node.taskResult.status" />
                  </template>
                  <template v-slot:[`item.node.createdAt`]="{ item }">
                    {{ item.node.createdAt | moment("YYYY-MM-DD HH:mm:SS") }}
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-tooltip
                      v-if="item.node.taskResult.status == 'FAILURE'"
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          color="error"
                          @click="
                            openErrorMessage(item.node.taskResult.traceback)
                          "
                        >
                          <v-icon>announcement</v-icon>
                        </v-btn>
                      </template>
                      <span> Show error </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </td>
            </template>
            <template v-slot:[`item.action`]="{ item }"> </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>

    <ErrorDialog :dialog.sync="errorDialog" :errorMessage.sync="errorMessage" />

    <ReleaseCreateDialog
      :dialog.sync="createDialog"
      v-on:changed="refresh()"
    ></ReleaseCreateDialog>
  </v-row>
</template>

<script>
import ErrorDialog from "@/components/base/ErrorDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import ReleaseCreateDialog from "@/components/integrations/taps/releases/ReleaseCreateDialog.vue";
import StatusChip from "@/components/base/StatusChip.vue";

export default {
  name: "releases-list",
  components: {
    ErrorDialog,
    ReleaseCreateDialog,
    StatusChip,
  },

  apollo: {
    allTaskResultExtensions: {
      query: gql`
        query allTaskResultExtensions(
          $first: Int
          $last: Int
          $before: String
          $after: String
          $orderBy: [String]
          $search: String
          $subType_In: String
        ) {
          allTaskResultExtensions(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: $orderBy
            search: $search
            subType_In: $subType_In
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                createdAt
                name
                pk
                status
                tap {
                  id
                  name
                  pk
                }
                taskresultextensionSet(first: 100) {
                  edges {
                    node {
                      id
                      connection {
                        id
                        pk
                        tenant {
                          id
                          pk
                          tenantName
                        }
                      }
                      createdAt
                      name
                      pk
                      taskResult {
                        id
                        pk
                        status
                        traceback
                      }
                    }
                  }
                }
                type
                user {
                  firstName
                  lastName
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allTaskResultExtensions,
      skip: true,
      pollInterval: 60000,
    },
  },

  data: function () {
    return {
      dataTableOptions: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: true,
        mustSort: false,
        page: 1,
        sortBy: ["node.createdAt"],
        sortDesc: [true],
      },
      errorDialog: false,
      errorMessage: null,
      expanded: [],
      headers: [
        {
          text: this.$tc("label.pk", 1),
          value: "node.pk",
          sortable: true,
        },
        {
          text: this.$tc("label.tap", 1),
          value: "node.tap.name",
          sortable: true,
        },
        {
          text: this.$tc("label.type", 1),
          value: "node.type",
          sortable: true,
        },
        {
          text: this.$t("label.status"),
          value: "node.status",
          sortable: false,
        },
        {
          text: this.$t("label.created"),
          value: "node.createdAt",
          sortable: true,
        },
        {
          text: this.$tc("label.user", 1),
          value: "node.user.firstName",
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
      ],
      headersRelease: [
        {
          text: this.$tc("label.name", 1),
          value: "node.connection.pk",
          sortable: false,
        },
        {
          text: this.$tc("label.name", 1),
          value: "node.connection.tenant.tenantName",
          sortable: false,
        },
        {
          text: this.$tc("label.name", 1),
          value: "node.taskResult.status",
          sortable: false,
        },
        {
          text: this.$tc("label.name", 1),
          value: "node.createdAt",
          sortable: false,
        },
        {
          text: this.$t("label.action"),
          value: "action",
          align: "right",
          sortable: false,
        },
      ],
      allTaskResultExtensions: {},
      error: null,
      search: null,
      createDialog: false,
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },

  watch: {
    dataTableOptions() {
      this.refresh();
    },
  },

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;
  },

  mounted() {},

  methods: {
    add() {
      this.createDialog = true;
    },

    clearSearch() {
      this.search = null;
      this.refresh();
    },

    expandAll(val) {
      if (val) {
        this.expanded = this.allTaskResultExtensions.edges;
      } else {
        this.expanded = [];
      }
    },

    onChanged() {
      this.refresh();
    },

    openErrorMessage(errorMessage) {
      this.errorMessage = errorMessage;
      this.errorDialog = true;
    },

    refresh() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;

      var _first = itemsPerPage;
      var _last = null;
      var _before = null;
      var _after = null;

      if (page < this.page) {
        _first = null;
        _last = itemsPerPage;
        _before = this.allTaskResultExtensions.pageInfo.startCursor;
        _after = null;
      }

      if (page > this.page) {
        _first = itemsPerPage;
        _last = null;
        _before = null;
        _after = this.allTaskResultExtensions.pageInfo.endCursor;
      }

      var orderByList = this.orderByList(sortBy, sortDesc);

      this.$apollo.queries.allTaskResultExtensions.setVariables({
        first: _first,
        last: _last,
        before: _before,
        after: _after,
        orderBy: orderByList,
        search: this.search,
        subType_In: "Release",
      });

      this.$apollo.queries.allTaskResultExtensions.skip = false;
      this.$apollo.queries.allTaskResultExtensions.refresh();

      this.page = page;
    },

    resetFilters() {
      this.clearSearch();
    },
  },
};
</script>

<style scoped>
.v-data-table
  >>> .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>

<i18n>
{
  "en": {
    "title": "Releases",
    "subtitle": "Create and monitor Power BI and Orchest releases. Power BI gets the latest master .pbix from the Tap workspace and deploys it to the selected connections. For Orchest, the jobs are copied, so the configuration stays intact and the old jobs are deleted afterwards."
  },
  "nl": {
    "title": "Releases",
    "subtitle": "Power BI haalt de nieuwste .pbix van de master op uit de Tap-werkruimte en implementeert het naar de geselecteerde connecties. Voor Orchest worden de jobs gekopieerd, zodat de configuratie intact blijft en de oude jobs achteraf worden verwijderd."
  },
  "de": {
    "title": "Releases",
    "subtitle": "Power BI holt die neueste .pbix aus dem Master aus der Tap-Arbeitsbereich und implementiert sie in den ausgewählten Verbindungen. Für Orchest werden die Jobs kopiert, damit die Konfiguration intakt bleibt und die alten Jobs anschließend gelöscht werden."
  }
}
</i18n>

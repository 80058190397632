<template>
  <v-container class="px-0" fluid>
    <v-row class="align-center pl-3">
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
      <v-spacer />
    </v-row>

    <v-container class="px-0" :fluid="$vuetify.breakpoint.lgAndDown">
      <v-row justify="center" align="center">
        <v-col cols="4" md="1">
          <div class="mx-auto text-center">
            <v-avatar :size="'90'">
              <img :src="webflowTap?.fieldData?.logo?.url" />
            </v-avatar>
          </div>
        </v-col>

        <v-col cols="2" md="1">
          <v-progress-linear
            :class="['mb-1']"
            :color="'primary'"
            buffer-value="0"
            reverse
            stream
          ></v-progress-linear>
        </v-col>

        <v-col cols="4" md="1">
          <div class="mx-auto text-center">
            <v-avatar size="90" rounded="0">
              <img src="/static/taptarget-logo.png" />
            </v-avatar>
          </div>
        </v-col>
        <v-col cols="12">
          <v-card outlined color="transparent">
            <v-card-title
              :class="[
                'justify-center',
                'font-weight-bold',
                'text-h4',
                'text-md-h2',
                'text-center',
                'pt-0',
              ]"
              style="word-break: break-word"
            >
              {{ webflowTap?.fieldData?.name }}
            </v-card-title>

            <v-row justify="center" align="center">
              <v-col cols="12" xl="4" md="5">
                <v-card-subtitle
                  :class="['justify-center', 'text-center', 'text-body-1']"
                >
                  {{ webflowTap?.fieldData?.description }}
                </v-card-subtitle>

                <v-divider></v-divider>

                <v-card-text
                  :class="[
                    'justify-center',
                    'text-center',
                    'text-body-2',
                    'grey--text',
                  ]"
                >
                  Door het koppelen van deze Tap ga je akkoord met
                  <span :key="i" v-for="(term, i) in webflowTerms.items">
                    <span v-if="term?.fieldData?.bestanden">
                      <a
                        :class="[
                          'blue--text',
                          'row-pointer',
                          'text-decoration-underline',
                        ]"
                        :href="term?.fieldData?.bestanden?.url"
                        @click.stop
                        target="_blank"
                      >
                        {{ term?.fieldData?.name }}
                      </a>
                      <span
                        class="black--text"
                        v-if="i + 1 < webflowTerms?.items?.length"
                        >,
                      </span>
                    </span>
                  </span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card :class="['mt-4', 'rounded-xl']">
                  <v-alert type="warning" v-if="onBehalfOfTenant">
                    <v-row align="center">
                      <v-col class="grow">
                        {{ $t("warning") }}{{ onBehalfOfTenant?.tenantName }}
                      </v-col>
                      <v-col class="shrink">
                        <BaseButton
                          @click="resetOnBehalfOfTenant()"
                          color="black"
                        >
                          {{ $t("label.reset") }}
                        </BaseButton>
                      </v-col>
                    </v-row>
                  </v-alert>

                  <v-alert
                    type="info"
                    v-else-if="me.isSuperuser || me.isPartnerUser"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        {{ $t("info") }}
                      </v-col>
                    </v-row>
                  </v-alert>

                  <v-stepper flat v-model="step">
                    <v-stepper-header style="box-shadow: none">
                      <v-stepper-step :complete="step > 1" step="1">
                        Info
                      </v-stepper-step>

                      <v-divider />

                      <v-stepper-step
                        :complete="step > 2"
                        step="2"
                        v-if="tap?.userInstallComponent"
                      >
                        Install
                      </v-stepper-step>

                      <v-divider />

                      <v-stepper-step
                        :complete="step > (tap?.userInstallComponent ? 3 : 2)"
                        :step="tap?.userInstallComponent ? 3 : 2"
                      >
                        Koppel
                      </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                      <!-- Step 1: info -->
                      <v-stepper-content step="1" class="mt-0 pt-0">
                        <v-card flat>
                          <div
                            v-html="webflowTap?.fieldData?.installation"
                          ></div>

                          <v-card-actions class="mt-4">
                            <v-spacer />
                            <v-btn
                              @click="start()"
                              :block="true"
                              :class="[]"
                              :color="'success'"
                              :rounded="true"
                              :text="false"
                              v-bind="btnSize('x-large')"
                            >
                              Koppel {{ webflowTap?.fieldData?.name }}
                            </v-btn>
                            <v-spacer />
                          </v-card-actions>
                        </v-card>
                      </v-stepper-content>

                      <!-- Step 2: optional if article requires user install -->
                      <v-stepper-content
                        :class="['mt-0', 'pt-0']"
                        step="2"
                        v-if="tap?.userInstallComponent"
                      >
                        <component
                          :getAuthCode.sync="getAuthCode"
                          :is="userInstallComponent"
                          :selectedCompanies.sync="selectedCompanies"
                          :step.sync="step"
                          :token.sync="token"
                        />
                      </v-stepper-content>

                      <!-- Step 2/3: confirmation -->
                      <v-stepper-content
                        :step="tap?.userInstallComponent ? 3 : 2"
                        class="mt-0 pt-0"
                      >
                        <v-card flat>
                          <v-list class="">
                            <v-list-item three-line>
                              <v-list-item-avatar
                                size="80"
                                v-if="!this.$vuetify.breakpoint.xs"
                              >
                                <img :src="webflowTap?.fieldData?.logo?.url" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  class="font-weight-medium text-h6 text-wrap"
                                  >{{ webflowTap?.fieldData?.name }}
                                </v-list-item-title>
                                <v-list-item-title>
                                  {{ $tc("label.quantity", 1) }}:
                                  {{
                                    selectedCompanies &&
                                    selectedCompanies.length > 0
                                      ? selectedCompanies.length
                                      : 1
                                  }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  <v-chip
                                    class="mr-1 text-lowercase"
                                    color="success"
                                    label
                                    small
                                  >
                                    {{
                                      `${
                                        webflowTap?.fieldData?.["dagen-gratis"]
                                      } ${$tc("label.day", 2)} ${$tc(
                                        "label.free",
                                        2
                                      )}`
                                    }}
                                  </v-chip>
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-content
                                class="text-right align-self-start mt-8"
                              >
                                <v-list-item-title>
                                  €{{ webflowTap?.fieldData?.["prijs-taps"] }},-
                                </v-list-item-title>
                                <v-list-item-subtitle class="wrap-text">
                                  {{ webflowTap?.fieldData?.prijs }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>

                          <v-divider></v-divider>

                          <v-list class="mb-5">
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ $tc("label.total", 1) }}
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-content
                                class="text-right align-self-start"
                              >
                                <v-list-item-title>
                                  €{{
                                    selectedCompanies &&
                                    selectedCompanies.length > 0
                                      ? selectedCompanies.length *
                                        webflowTap?.fieldData?.["prijs-taps"]
                                      : webflowTap?.fieldData?.["prijs-taps"]
                                  }},-
                                </v-list-item-title>
                                <v-list-item-subtitle class="wrap-text">
                                  per maand
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>

                          <v-card-text>
                            <v-form ref="form" onSubmit="return false;">
                              <v-row>
                                <v-col :class="['my-0', 'py-0']" cols="12">
                                  <v-textarea
                                    :class="['mt-0']"
                                    :label="$t('label.remark')"
                                    outlined
                                    v-model="remark"
                                  >
                                  </v-textarea>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              @click="back()"
                              :class="['black--text', 'mr-2']"
                              :loading="isSaving"
                              rounded
                              small
                              text
                            >
                              {{ $t("label.back") }}
                            </v-btn>
                            <v-btn
                              @click="save()"
                              :color="'success'"
                              :loading="isSaving"
                              rounded
                              small
                            >
                              {{ $t("label.add") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-stepper-content>
                    </v-stepper-items>
                  </v-stepper>
                  <v-alert type="warning" v-if="onBehalfOfTenant">
                    <v-row align="center">
                      <v-col class="grow">
                        {{ $t("warning") }}{{ onBehalfOfTenant?.tenantName }}
                      </v-col>
                      <v-col class="shrink">
                        <BaseButton
                          @click="resetOnBehalfOfTenant()"
                          color="black"
                        >
                          {{ $t("label.reset") }}
                        </BaseButton>
                      </v-col>
                    </v-row>
                  </v-alert>

                  <v-alert
                    type="info"
                    v-else-if="me.isSuperuser || me.isPartnerUser"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        {{ $t("info") }}
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import BaseButton from "@/components/base/BaseButton.vue";

export default {
  name: "tap-connect",
  components: {
    BaseButton,
  },
  apollo: {
    tap: {
      query: gql`
        query allTaps($externalId: String!) {
          allTaps(externalId: $externalId) {
            edges {
              node {
                id
                name
                userInstallComponent
              }
            }
          }
        }
      `,
      variables() {
        return {
          externalId: this.$route.params.externalId,
        };
      },
      fetchPolicy: "network-only",
      update: (data) => data.allTaps.edges[0].node,
      result() {
        if (this.$route.query.code) {
          this.step = 1;
        }
      },
    },
    webflowTap: {
      query: gql`
        query webflowItem($collectionId: String!, $itemId: String!) {
          webflowItem(collectionId: $collectionId, itemId: $itemId) {
            id
            cmsLocaleId
            createdOn
            fieldData
            isArchived
            isDraft
            lastPublished
            lastUpdated
          }
        }
      `,
      variables() {
        return {
          collectionId: "658435cbaf21ee3aa891ffa2",
          itemId: this.$route.params.externalId,
        };
      },
      update: (data) => data.webflowItem,
      result() {},
    },
    webflowTerms: {
      query: gql`
        query allWebflowItems($collectionId: String!) {
          allWebflowItems(collectionId: $collectionId) {
            items {
              id
              cmsLocaleId
              createdOn
              fieldData
              isArchived
              isDraft
              lastPublished
              lastUpdated
            }
          }
        }
      `,
      variables() {
        return {
          collectionId: "659ff21c34b2a7010495626e",
        };
      },
      update: (data) => data.allWebflowItems,
      result() {},
    },
  },
  data: function () {
    return {
      error: null,
      event: "virtualPageView",
      getAuthCode: false,
      isSaving: false,
      pageTitle: "TapConnect",
      remark: null,
      selectedCompanies: [],
      step: 1,
      tap: {},
      token: {},
      webflowTap: {},
      webflowTerms: {},
    };
  },
  computed: {
    breadcrumbs() {
      let breadcrumbs = [
        {
          text: this.$t("label.home"),
          exact: true,
          disable: false,
          to: {
            name: "home",
          },
        },
        {
          text: this.$tc("label.tap", 2),
          exact: true,
          disable: false,
          to: {
            name: "taps",
          },
        },
        {
          text: this?.webflowTap?.fieldData?.name,
          disable: true,
          to: {
            name: "tap-connect",
            params: {
              externalId: this.$route.params.externalId,
            },
          },
        },
      ];
      return breadcrumbs;
    },

    quantity() {
      return this.selectedCompanies.length > 0
        ? this.selectedCompanies.length
        : 1;
    },

    ecommerce() {
      return {
        currency: "EUR",
        value: this.quantity * this.webflowTap?.fieldData?.["prijs-taps"],
        items: [
          {
            item_id: this.$route.params.externalId,
            item_name: this?.webflowTap?.fieldData?.name,
            item_variant: this.webflowTap?.fieldData?.prijs,
            price: this.webflowTap?.fieldData?.["prijs-taps"],
            quantity: this.quantity,
          },
        ],
      };
    },

    userInstallComponent() {
      if (this.tap?.userInstallComponent) {
        return () => import(`@/${this.tap?.userInstallComponent}`);
      }
      return null;
    },

    onBehalfOfTenant: {
      get() {
        return this.$store.getters["controlPanel/getOnBehalfOfTenant"];
      },
      set(value) {
        this.$store.dispatch("controlPanel/setOnBehalfOfTenant", value);
      },
    },

    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {
    webflowTap(val) {
      if (val) {
        document.title = `${this.webflowTap?.fieldData?.name} | TapTarget`;
      }
    },
  },
  created() {
    this.btnSize = helper.btnSize;
    this.updateMailchimpTags = helper.updateMailchimpTags;
  },
  mounted() {
    this.$gtm.trackEvent({
      event: this.event,
      pagePath: this.$route.fullPath,
      pageTitle: this.pageTitle,
    });

    this.updateMailchimpTags("a789eec6cf", [
      { name: "AddToCart", status: "active" },
    ]);
  },
  methods: {
    back() {
      this.step = this.step - 1;
    },

    save() {
      this.error = null;
      this.isSaving = true;

      if (!this.$refs.form.validate()) {
        return;
      }

      if (Array.isArray(this.token)) {
        var token = JSON.stringify(this.token);
      } else {
        var token = this.token.accessToken;
      }

      var payload = {
        accessToken: token,
        companies: JSON.stringify(this.selectedCompanies),
        daysFree: this.webflowTap?.fieldData?.["dagen-gratis"],
        expiresAt: this.token.expiresAt,
        expiresIn: this.token.expiresIn,
        price: this.webflowTap?.fieldData?.["prijs-taps"],
        priceUnit: this.webflowTap?.fieldData?.prijs,
        refreshToken: this.token.refreshToken,
        remark: this.remark,
        tap: this.tap.id,
        tenant: this.onBehalfOfTenant?.id
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createConnection($input: CreateConnectionInput!) {
              createConnection(input: $input) {
                connection {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Connection successfully created`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$gtm.trackEvent({
            event: "purchase",
            pagePath: this.$route.fullPath,
            pageTitle: this.pageTitle,
            ecommerce: this.ecommerce,
          });

          this.updateMailchimpTags("a789eec6cf", [
            { name: "Purchase", status: "active" },
          ]);

          this.resetOnBehalfOfTenant();

          this.$router.push({
            name: "taps",
          });
        })
        .catch((error) => {
          this.error = error.graphQLErrors[0].message;
          this.isSaving = false;

          const payload = {
            color: "error",
            message: this.error,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .finally(() => {});
    },

    start() {
      this.step = 2;

      if (!this.$router.currentRoute.query.code) {
        this.getAuthCode = true;
      }

      this.$gtm.trackEvent({
        event: "beginCheckout",
        pagePath: this.$route.fullPath,
        pageTitle: this.pageTitle,
        ecommerce: this.ecommerce,
      });
    },

    resetOnBehalfOfTenant() {
      this.onBehalfOfTenant = null;
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "warning": "You are connecting this Tap for the tenant: ",
      "info": "You are connection this Tap for your own tenant."
    },
    "nl": {
      "warning": "Je koppelt deze Tap voor de tenant: ",
      "info": "Je koppelt deze Tap voor jouw eigen tenant."
    },
    "de": {
      "warning": "Sie verbinden diesen Tap für den tenant: ",
      "info": "Sie verbinden diesen Tap für Ihren eigenen tenant."
    }
  }
  </i18n>

<template>
	<v-container :fluid="$vuetify.breakpoint.lgAndDown">
		<v-row justify="center" align="center">
			<v-col cols="12">
				<v-tabs v-model="tabs">
					<v-tabs-slider color="primary"></v-tabs-slider>

					<v-tab>{{ $tc("label.export", 1) }}</v-tab>
					<v-spacer />

					<v-btn class="mt-1" icon @click="closeDrawer()">
						<v-icon>close</v-icon>
					</v-btn>
				</v-tabs>

				<v-divider class="mb-5" />

				<v-row align="center" class="mb-10">
					<v-col cols="12">
						<v-card flat>
							<v-alert
								v-if="error"
								tile
								type="error"
								class="mb-0"
								>{{ error }}</v-alert
							>

							<v-card-text>
								<v-form
									ref="form"
									onSubmit="return false;"
									v-model="isFormValid"
								>
									<v-row class="my-0 py-0">
										<v-col class="my-0 py-0" cols="12">
											<v-select
												:items="[
													{
														name: 'PDF',
														format: 'pdf',
													},
													{
														name: 'Powerpoint',
														format: 'pptx',
													},
												]"
												:label="$tc('label.type', 1)"
												:rules="[rules.required]"
												item-key="format"
												item-text="name"
												item-value="format"
												outlined
												v-model="format"
											></v-select>
										</v-col>

										<v-col cols="12" class="mt-0 pt-0 pb-5">
											<label
												><h4>
													{{ $tc("label.export", 1) }}
												</h4></label
											>
											<v-radio-group
												:hint="$t('hint')"
												:rules="[rules.required]"
												class="mt-1"
												persistent-hint
												row
												v-model="type"
											>
												<v-radio
													:label="$t('current')"
													value="CURRENT"
												></v-radio>
												<v-radio
													:label="$t('standard')"
													value="STANDARD"
												></v-radio>
											</v-radio-group>
										</v-col>

										<v-col class="my-0 py-0" cols="12">
											<v-select
												:items="reportPages"
												:label="$tc('label.page', 2)"
												:rules="[rules.required]"
												clearable
												item-text="displayName"
												item-value="name"
												multiple
												outlined
												v-model="selectedPages"
											>
												<template
													v-slot:selection="{
														item,
														index,
													}"
												>
													<v-chip
														v-if="index === 0"
														small
													>
														<span>{{
															item.displayName
														}}</span>
													</v-chip>
													<span
														v-if="index === 1"
														class="grey--text text-caption"
													>
														(+{{
															selectedPages.length -
															1
														}})
													</span>
												</template>
											</v-select>
										</v-col>
									</v-row>
								</v-form>
							</v-card-text>

							<v-card-actions>
								<v-spacer />
								<v-btn @click="closeDrawer()" rounded text>{{
									$t("label.cancel")
								}}</v-btn>
								<v-btn
									:disabled="!isFormValid"
									:loading="isSaving"
									@click="save()"
									color="primary"
									outlined
									rounded
									>{{ $tc("label.export", 1) }}</v-btn
								>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
	name: "report-export-drawer",
	props: ["drawer", "reportPages"],
	components: {},
	apollo: {},
	data: function () {
		return {
			error: null,
			format: null,
			isFormValid: false,
			isSaving: false,
			selectedPages: [],
			tabs: [],
			type: "CURRENT",
		};
	},
	computed: {},
	watch: {
		drawer(val) {
			if (val) {
				this.error = null;
				this.format = null;
				this.isFormValid = false;
				this.isSaving = false;
				this.tabs = [];
				this.type = "CURRENT";
				this.selectedPages = [];

				if (this.$refs.form) {
					this.$refs.form.resetValidation();
				}
			}
		},
	},
	created() {
		this.rules = rules;
	},
	mounted() {},
	methods: {
		closeDrawer() {
			this.$emit("update:drawer", false);
		},

		save() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.error = null;
			this.isSaving = true;

			// if there is an bookmark get it
			var bookmarkState = null;
			if (
				localStorage.getItem(this.$route.params.reportNodeId) !==
					null &&
				this.type == "CURRENT"
			) {
				bookmarkState = JSON.parse(
					localStorage.getItem(this.$route.params.reportNodeId)
				).state;
			}

			var payload = {
				reportId: this.$route.params.reportNodeId,
				bookmarkState: bookmarkState,
				format: this.format,
				pages: this.selectedPages,
			};

			this.$apollo
				.mutate({
					mutation: gql`
						mutation createReportExport(
							$input: CreateReportExportInput!
						) {
							createReportExport(input: $input) {
								clientMutationId
							}
						}
					`,
					variables: {
						input: payload,
					},
				})
				.then((response) => {
					this.$store
						.dispatch("user/fetchAllUserTasks")
						.then(() => {})
						.catch((error) => {
							console.log(error);
						});

					this.closeDrawer();

					const payload = {
						color: "success",
						message: this.$t("success"),
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					this.error = error.graphQLErrors[0].message;
					this.isSaving = false;

					const payload = {
						color: "error",
						message: this.error,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.finally(() => {});
		},
	},
};
</script>

<i18n>
{
  "en": {
	"current": "Current",
	"hint": "By chosing standard, the default values are exported. If you choose current, the currently shown values will be used.",
	"standard": "Standard",
	"success": "Report is being exported"
  },
  "nl": {
	"current": "Huidig",
	"hint": "Door standaard te kiezen, worden de standaardwaarden geëxporteerd. Als je huidig kiest, worden de momenteel weergegeven waarden gebruikt.",
	"standard": "Standaard",
	"success": "Het rapport wordt geëxporteerd"
  },
  "de": {
	"current": "Aktuell",
	"hint": "Durch die Auswahl von Standard werden die Standardwerte exportiert. Wenn Sie aktuell wählen, werden die momentan angezeigten Werte verwendet.",
	"standard": "Standard",
	"success": "Der Bericht wird exportiert"
  }
}
</i18n>

<template>
	<v-row justify="center">
		<v-dialog max-width="500px" persistent v-model="dialog">
			<v-card>
				<v-alert tile type="error" v-if="error">{{ error }}</v-alert>
				<v-card-title class="primary white--text">{{
					formTitle
				}}</v-card-title>
				<v-card-text class="pt-5">
					<p class="text-center">
						{{ $t("description") }}
					</p>

					<p class="subtitle-2 text-center mt-5">
						{{ $t("step1") }}
					</p>

					<v-row justify="center">
						<v-col cols="8">
							<qrcode-vue
								:value="qrCodeValue"
								size="300"
								level="H"
							/>
						</v-col>
					</v-row>

					<p class="subtitle-2 text-center mt-5">
						{{ $t("step2") }}
					</p>

					<v-form ref="form">
						<v-row justify="center">
							<v-col cols="8">
								<div
									class="ma-auto position-relative"
									style="max-width: 300px"
								>
									<v-otp-input
										:disabled="isSaving"
										@finish="save"
										v-model="tfaCode"
									></v-otp-input>
									<v-overlay :value="isSaving" absolute>
										<v-progress-circular
											color="primary"
											indeterminate
										></v-progress-circular>
									</v-overlay>
								</div>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import Config from "@/utils/config.json";
import gql from "graphql-tag";
import QrcodeVue from "qrcode.vue";
import rules from "@/utils/rules.js";

export default {
	name: "tfa-enable-dialog",
	components: {
		Config,
		QrcodeVue,
	},
	props: ["dialog"],
	data() {
		return {
			error: null,
			isSaving: false,
			secretKey: null,
			tfaCode: null,
		};
	},
	created() {
		this.rules = rules;
	},
	computed: {
		formTitle() {
			return "Enable 2FA";
		},

		me() {
			return this.$store.state.user.me;
		},

		qrCodeValue() {
			if (!this.me) {
				return "";
			}

			return (
				"otpauth://totp/TapTarget%20portal?secret=" +
				this.secretKey +
				"&issuer=" +
				encodeURIComponent(this.me.email)
			);
		},
	},
	watch: {
		dialog() {
			this.resetForm();
		},
	},
	methods: {
		close() {
			this.$emit("update:dialog", false);
			this.$emit("changed");
		},

		resetForm() {
			this.error = null;
			this.isSaving = false;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			this.secretKey = null;
			this.qrCodeValue = null;
		},

		enableTfa() {
			this.error = null;
			this.isSaving = true;

			this.$apollo
				.mutate({
					mutation: gql`
						mutation enable2fa {
							enable2fa(input: {}) {
								secretKey
							}
						}
					`,
				})
				.then((response) => {
					this.secretKey = response.data.enable2fa.secretKey;
					this.isSaving = false;
				})
				.catch((error) => {
					this.error = error.graphQLErrors[0].message;
					this.isSaving = false;
				})
				.finally(() => {});
		},

		save() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.error = null;
			this.isSaving = true;

			var payload = {
				tfaCode: this.tfaCode,
			};

			this.$apollo
				.mutate({
					mutation: gql`
						mutation verify2fa($input: Verify2FAInput!) {
							verify2fa(input: $input) {
								success
							}
						}
					`,
					variables: {
						input: payload,
					},
				})
				.then((response) => {
					const success = response.data.verify2fa.success;

					if (success) {
						this.close();

						const payload = {
							color: "success",
							message: `2FA successfully verified`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					} else {
						this.error = "Invalid 2FA code";
						this.isSaving = false;
					}
				})
				.catch((error) => {
					this.error = error.graphQLErrors[0].message;
					this.isSaving = false;
				})
				.finally(() => {});
		},
	},
};
</script>

<i18n>
{
  "en": {
	"description": "Please follow these steps to complete the two-factor authentication registration",
	"step1": "Step 1. Scan this QR-Code with Google Authenticator or Microsoft Authenticator",
	"step2": "Step 2. Verify the code from your authenticator"
  },
  "nl": {
	"description": "Volg deze stappen om de registratie voor tweestapsverificatie te voltooien",
	"step1": "Stap 1. Scan deze QR-code met Google Authenticator of Microsoft Authenticator",
	"step2": "Stap 2. Verifieer de code vanuit uw authenticator"
  },
  "de": {
	"description": "Folgen Sie diesen Schritten, um die Registrierung für die Zwei-Faktor-Authentifizierung abzuschließen",
	"step1": "Schritt 1. Scannen Sie diesen QR-Code mit Google Authenticator oder Microsoft Authenticator",
	"step2": "Schritt 2. Überprüfen Sie den Code in Ihrer Authenticator-App"
  }
}
</i18n>

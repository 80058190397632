<template>
  <v-btn
    :block="block"
    :class="classes"
    :color="color"
    :disabled="disabled"
    :loading="loading"
    :outlined="outlined"
    :rounded="rounded"
    :text="text"
    @click="onClick()"
    v-bind="btnSize(size)"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
import helper from "@/utils/helper.js";

export default {
  name: "base-dialog",
  components: {},
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: [String, Array, Object],
      default: '',
    },
    color: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: "small",
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  apollo: {},

  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
    this.btnSize = helper.btnSize;
  },
  methods: {
    onClick(val) {
      this.$emit("click", val);
    },
  },
};
</script>

<template>
	<v-row class="mt-2">
		<v-col class="py-0" cols="12">
			<v-alert tile type="error" v-if="error">{{ error }}</v-alert>
		</v-col>
		<v-col cols="12" md="2" sm="6">
			<v-text-field
				:label="$t('label.search')"
				@click:append-outer="refresh()"
				@click:clear="clearSearch()"
				@keydown.enter="refresh()"
				clearable
				dense
				hide-details
				outlined
				required
				v-model="search"
			></v-text-field>
		</v-col>
		<v-col
			cols="12"
			md="2"
			sm="6"
			v-if="me.isSuperuser || me.isPartnerUser"
		>
			<v-autocomplete
				:items="tenants.edges"
				:label="$tc('label.tenant', 1)"
				:loading="$apollo.queries.tenants.loading"
				@change="refresh()"
				clearable
				dense
				hide-details
				item-text="node.tenantName"
				item-value="node.id"
				outlined
				v-model="selectedTenant"
			>
			</v-autocomplete>
		</v-col>
		<v-spacer />
		<v-col cols="12" md="2" align="right">
			<v-btn
				@click="resetFilters()"
				color="primary"
				dark
				rounded
				small
				text
			>
				{{ $t("label.clearFilters") }}
			</v-btn>
		</v-col>
		<v-col cols="12" md="12">
			<v-data-table
				:footer-props="{
					'disable-pagination':
						$apollo.queries.allReportGroups.loading,
					'items-per-page-options': [10, 25, 50, 75, 100],
				}"
				:headers="
					me.isSuperuser || me.isPartnerUser
						? headersSuperuser
						: headers
				"
				:items="allReportGroups.edges"
				:loading="$apollo.queries.allReportGroups.loading"
				:options.sync="dataTableOptions"
				:server-items-length="allReportGroups.totalCount"
				:show-select="true"
				item-key="node.id"
				v-model="selectedItems"
			>
				<template v-slot:[`item.action`]="{ item }">
					<v-btn @click="edit(item.node)" color="primary" icon>
						<v-icon>edit</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-col>

		<DeleteDialog
			:dialog.sync="deleteDialog"
			v-on:confirmed="onDeleteConfirmed"
		/>
		<ReportGroupEditDialog
			:dialog.sync="editDialog"
			:object.sync="editedItem"
			v-on:changed="onChanged"
		/>
	</v-row>
</template>

<script>
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import ReportGroupEditDialog from "@/components/reports/ReportGroupEditDialog.vue";

export default {
	name: "report-groups-list",
	components: {
		DeleteDialog,
		ReportGroupEditDialog,
	},

	apollo: {
		allReportGroups: {
			query: gql`
				query allReportGroups(
					$first: Int
					$last: Int
					$before: String
					$after: String
					$orderBy: [String]
					$search: String
					$tenant: ID
				) {
					allReportGroups(
						first: $first
						last: $last
						before: $before
						after: $after
						orderBy: $orderBy
						search: $search
						tenant: $tenant
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								name
								reportSet {
									edges {
										node {
											id
										}
									}
								}
								tenant {
									id
									tenantName
								}
							}
						}
					}
				}
			`,
			fetchPolicy: "cache-and-network",
			update: (data) => data.allReportGroups,
			skip: true,
			pollInterval: 60000,
		},

		tenants: {
			query: gql`
				query tenants {
					tenants(orderBy: ["tenantName"]) {
						edges {
							node {
								id
								tenantName
							}
						}
					}
				}
			`,
			variables() {},
			fetchPolicy: "cache-and-network",
			update: (data) => data.tenants,
			skip: true,
		},
	},

	data: function () {
		return {
			allReportGroups: {},
			deleteDialog: false,
			editDialog: false,
			editedItem: {},
			error: null,
			selectedItems: [],
			tenants: {},
		};
	},

	computed: {
		dataTableOptions: {
			get() {
				return this.$store.getters[
					"reportGroupsList/getDataTableOptions"
				];
			},
			set(value) {
				this.$store.dispatch(
					"reportGroupsList/setDataTableOptions",
					value
				);
			},
		},

		headers() {
			return [
				{
					text: this.$tc("label.name", 1),
					value: "node.name",
					sortable: true,
				},
				{
					text: this.$t("label.action"),
					align: "center",
					value: "action",
					sortable: false,
				},
			];
		},

		headersSuperuser() {
			return [
				{
					text: this.$tc("label.tenant", 1),
					value: "node.tenant.tenantName",
					sortable: true,
				},
				{
					text: this.$tc("label.name", 1),
					value: "node.name",
					sortable: true,
				},
				{
					text: this.$t("label.action"),
					align: "center",
					value: "action",
					sortable: false,
				},
			];
		},

		me() {
			return this.$store.state.user.me;
		},

		search: {
			get() {
				return this.$store.getters["reportGroupsList/getSearch"];
			},
			set(value) {
				this.$store.dispatch("reportGroupsList/setSearch", value);
			},
		},

		selectedTenant: {
			get() {
				return this.$store.getters[
					"reportGroupsList/getSelectedTenant"
				];
			},
			set(value) {
				this.$store.dispatch(
					"reportGroupsList/setSelectedTenant",
					value
				);
			},
		},
	},

	watch: {
		dataTableOptions() {
			this.refresh();
		},

		selectedItems() {
			this.$emit("updateNrOfSelectedItems", this.selectedItems.length);
		},
	},

	created() {
		this.orderByList = helper.orderByList;

		if (this.me.isSuperuser || this.me.isPartnerUser) {
			this.$apollo.queries.tenants.skip = false;
		}
	},

	mounted() {},

	methods: {
		add() {
			this.editedItem = null;
			this.editDialog = true;
		},

		clearSearch() {
			this.search = null;
			this.refresh();
		},

		deleteSelected() {
			this.deleteDialog = true;
		},

		edit(item) {
			this.editedItem = item;
			this.editDialog = true;
		},

		onChanged() {
			this.$apollo.queries.allReportGroups.refresh();
		},

		onDeleteConfirmed() {
			var ids = [];
			this.selectedItems.forEach(function (item, index) {
				ids.push(item.node.id);
			});

			const payload = {
				ids: ids,
			};

			this.$apollo
				.mutate({
					mutation: gql`
						mutation deleteReportGroups(
							$input: DeleteReportGroupsInput!
						) {
							deleteReportGroups(input: $input) {
								deletionCount
							}
						}
					`,
					variables: {
						input: payload,
					},
				})
				.then(() => {
					this.$apollo.queries.allReportGroups.refresh();

					this.deleteDialog = false;
					this.selectedItems = [];

					const payload = {
						color: "success",
						message: `Report groups successfully deleted`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					this.error = error;
				});
		},

		refresh() {
			const { sortBy, sortDesc, page, itemsPerPage } =
				this.dataTableOptions;

			var _first = itemsPerPage;
			var _last = null;
			var _before = null;
			var _after = null;

			if (page < this.page) {
				_first = null;
				_last = itemsPerPage;
				_before = this.allReportGroups.pageInfo.startCursor;
				_after = null;
			}

			if (page > this.page) {
				_first = itemsPerPage;
				_last = null;
				_before = null;
				_after = this.allReportGroups.pageInfo.endCursor;
			}

			var orderByList = this.orderByList(sortBy, sortDesc);

			this.$apollo.queries.allReportGroups.setVariables({
				first: _first,
				last: _last,
				before: _before,
				after: _after,
				orderBy: orderByList,
				search: this.search,
				tenant: this.selectedTenant,
			});

			this.$apollo.queries.allReportGroups.skip = false;
			this.$apollo.queries.allReportGroups.refresh();

			this.page = page;
		},

		resetFilters() {
			this.clearSearch();
		},
	},
};
</script>

<template>
	<v-container fluid>
		<v-row class="align-center pl-3">
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
			<v-spacer />
		</v-row>

		<v-container :fluid="$vuetify.breakpoint.lgAndDown">
			<v-row justify="center" align="center">
				<v-col cols="12">
					<v-tabs v-model="tabs">
						<v-tabs-slider color="primary"></v-tabs-slider>

						<v-tab>{{ $tc("label.user", 2) }}</v-tab>
						<v-tab v-if="me.isSuperuser">{{
							$tc("label.tenant", 2)
						}}</v-tab>
						<v-tab v-if="me.isSuperuser">{{
							$tc("label.group", 2)
						}}</v-tab>
						<v-tab v-if="me.isSuperuser">{{
							$tc("label.theme", 2)
						}}</v-tab>
						<v-tab v-if="me.isSuperuser">{{
							$tc("label.partner", 2)
						}}</v-tab>
						<v-spacer />
						<v-btn
							:disabled="
								(!tabs == 0 || nrOfSelectedUsers <= 0) &&
								(!tabs == 1 || nrOfSelectedTenants <= 0) &&
								(!tabs == 3 || nrOfSelectedThemes <= 0) &&
								(!tabs == 4 || nrOfSelectedPartners <= 0)
							"
							@click="deleteSelected()"
							class="mt-2"
							color="error"
							dark
							outlined
							rounded
							small
							>{{ $t("label.delete") }}</v-btn
						>
						<v-btn
							@click="add()"
							class="mt-2 ml-2"
							color="primary"
							dark
							outlined
							rounded
							small
							>{{ $t("label.add") }}</v-btn
						>
					</v-tabs>
					<v-divider></v-divider>

					<v-tabs-items v-model="tabs">
						<v-tab-item>
							<UsersList
								ref="UsersList"
								v-on:updateNrOfSelectedItems="
									onUpdateNrOfSelectedUsers
								"
							/>
						</v-tab-item>
						<v-tab-item v-if="me.isSuperuser">
							<TenantsList
								ref="TenantsList"
								v-on:updateNrOfSelectedItems="
									onUpdateNrOfSelectedTenants
								"
							/>
						</v-tab-item>
						<v-tab-item v-if="me.isSuperuser">
							<GroupsList ref="GroupsList" />
						</v-tab-item>
						<v-tab-item v-if="me.isSuperuser">
							<ThemesList
								ref="ThemesList"
								v-on:updateNrOfSelectedItems="
									onUpdateNrOfSelectedThemes
								"
							/>
						</v-tab-item>
						<v-tab-item v-if="me.isSuperuser">
							<PartnersList
								ref="PartnersList"
								v-on:updateNrOfSelectedItems="
									onUpdateNrOfSelectedPartners
								"
							/>
						</v-tab-item>
					</v-tabs-items>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import GroupsList from "@/components/users/GroupsList.vue";
import PartnersList from "@/components/users/PartnersList.vue";
import TenantsList from "@/components/users/TenantsList.vue";
import ThemesList from "@/components/users/ThemesList.vue";
import UsersList from "@/components/users/UsersList.vue";

export default {
	name: "users",
	components: {
		GroupsList,
		PartnersList,
		TenantsList,
		ThemesList,
		UsersList,
	},

	apollo: {},

	data: function () {
		return {
			nrOfSelectedPartners: 0,
			nrOfSelectedTenants: 0,
			nrOfSelectedThemes: 0,
			nrOfSelectedUsers: 0,
		};
	},

	computed: {
		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("label.home"),
					exact: true,
					disable: false,
					to: {
						name: "home",
					},
				},
				{
					text: this.$tc("label.user", 2),
					disabled: true,
				},
			];
			return breadcrumbs;
		},

		me() {
			return this.$store.state.user.me;
		},

		tabs: {
			get() {
				return this.$store.getters["users/getTabs"];
			},
			set(value) {
				this.$store.dispatch("users/setTabs", value);
			},
		},
	},

	watch: {},

	created() {},

	mounted() {},

	methods: {
		add() {
			switch (this.tabs) {
				case 0:
					this.$refs.UsersList.add();
					break;
				case 1:
					this.$refs.TenantsList.add();
					break;
				case 2:
					this.$refs.GroupsList.add();
					break;
				case 3:
					this.$refs.ThemesList.add();
					break;
				case 4:
					this.$refs.PartnersList.add();
					break;
				default:
					break;
			}
		},

		deleteSelected() {
			switch (this.tabs) {
				case 0:
					this.$refs.UsersList.deleteSelected();
					break;
				case 1:
					this.$refs.TenantsList.deleteSelected();
					break;
				case 3:
					this.$refs.ThemesList.deleteSelected();
					break;
				case 4:
					this.$refs.PartnersList.deleteSelected();
					break;
				default:
					break;
			}
		},

		onUpdateNrOfSelectedPartners(length) {
			this.nrOfSelectedPartners = length;
		},

		onUpdateNrOfSelectedTenants(length) {
			this.nrOfSelectedTenants = length;
		},

		onUpdateNrOfSelectedThemes(length) {
			this.nrOfSelectedThemes = length;
		},

		onUpdateNrOfSelectedUsers(length) {
			this.nrOfSelectedUsers = length;
		},
	},
};
</script>

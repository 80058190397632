<template>
  <v-card :class="['mx-2', 'my-1', 'pb-4']" flat>
    <v-alert v-if="error" tile type="error">{{ error }}</v-alert>
    <v-form ref="form" onSubmit="return false;" v-model="isFormValid">
      <v-card flat>
        <v-card-title
          :class="['font-weight-bold', 'text-h5', 'mb-0', 'pb-0']"
          style="word-break: break-word"
        >
          Target
          <v-spacer></v-spacer>
          <v-btn
            @click="resetForm()"
            class="mt-2 ml-2"
            color="black"
            dark
            outlined
            rounded
            small
            text
            v-if="editTarget"
            >{{ $t("label.cancel") }}</v-btn
          >
          <v-btn
            @click="editTarget = true"
            class="mt-2 ml-2"
            color="primary"
            dark
            outlined
            rounded
            small
            v-if="!editTarget"
            >{{ $t("label.edit") }}</v-btn
          >
          <v-btn
            @click="goToTargets()"
            class="mt-2 ml-2"
            color="primary"
            dark
            rounded
            small
            >{{ $t("label.add") }}</v-btn
          >
        </v-card-title>

        <v-card-text class="pt-5 pb-0">
          <v-row class="my-0 py-0">
            <v-col cols="12" md="4" class="my-0 py-0">
              <v-autocomplete
                :items="allTaps.edges"
                :label="$tc('label.tap', 1)"
                :loading="$apollo.queries.allTaps.loading"
                :rules="[rules.required]"
                disabled
                item-text="node.name"
                item-value="node.id"
                outlined
                v-model="selectedTap"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="4" class="my-0 py-0">
              <v-autocomplete
                :disabled="!editTarget"
                :items="allGroups.edges"
                :label="$tc('label.group', 1)"
                :loading="$apollo.queries.allGroups.loading"
                :rules="[rules.required]"
                clearable
                item-text="node.name"
                item-value="node.id"
                outlined
                return-object
                v-model="selectedGroup"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="4" class="my-0 py-0">
              <v-autocomplete
                :disabled="!editTarget"
                :items="allResourceGroups.edges"
                :label="$tc('label.resourceGroup', 1)"
                :loading="$apollo.queries.allResourceGroups.loading"
                :rules="[rules.required]"
                item-text="node.name"
                item-value="node.id"
                outlined
                return-object
                v-model="selectedResourceGroup"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="4" class="my-0 py-0">
              <v-autocomplete
                @change="sqlTestConnection()"
                :disabled="!selectedResourceGroup || !editTarget"
                :items="allDatawarehouses.edges"
                :label="$tc('label.datawarehouse', 1)"
                :loading="$apollo.queries.allDatawarehouses.loading"
                :rules="[rules.required]"
                item-text="node.name"
                item-value="node.id"
                outlined
                return-object
                v-model="selectedDatawarehouse"
              >
              </v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              md="4"
              class="my-0 py-0"
              v-if="connection?.tap?.requiresDataFactory"
            >
              <v-autocomplete
                :disabled="!selectedResourceGroup || !editTarget"
                :hint="
                  selectedResourceGroup
                    ? `Naming hint: ${selectedResourceGroup?.node?.name.toLowerCase()}-${
                        connection?.tap?.abbreviation
                      }-df`
                    : ''
                "
                :items="allDataFactories.edges"
                :label="$tc('label.dataFactory', 1)"
                :loading="$apollo.queries.allDataFactories.loading"
                :rules="[rules.required]"
                item-text="node.name"
                item-value="node.id"
                outlined
                persistent-hint
                return-object
                v-model="selectedDataFactory"
              >
              </v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              md="4"
              class="my-0 py-0"
              v-if="connection?.tap?.tokenDbServer"
            >
              <v-text-field
                :disabled="!editTarget"
                :label="$tc('label.token', 1)"
                hint="Token id for seperate token database. If empty a new token will be created in the database. This is used for shared tokens cross tenant."
                outlined
                persistent-hint
                type="number"
                v-model="tokenId"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card flat>
        <v-card-title
          :class="['font-weight-bold', 'text-h5', 'mb-0', 'pb-0']"
          style="word-break: break-word"
        >
          Install parameters
          <v-spacer></v-spacer>
          <v-btn
            @click="saveConnection()"
            :disabled="!isFormValid || !editTarget"
            :loading="isSaving"
            class="ml-2"
            color="primary"
            outlined
            rounded
            small
            >{{ $t("label.save") }}</v-btn
          >
          <v-btn
            @click="saveConnection(true)"
            :disabled="!isFormValid || !editTarget"
            :loading="isSaving"
            class="ml-2"
            color="primary"
            rounded
            small
            >{{ $t("label.save") }} and deploy</v-btn
          >
        </v-card-title>

        <v-card-text class="pt-5 pb-0">
          <v-row class="my-0 py-0">
            <v-col
              :key="paramName"
              cols="12"
              class="my-1 py-0"
              v-for="(param, paramName) in installParameters"
            >
              <v-text-field
                :append-icon="param.default ? 'rotate_right' : ''"
                :disabled="!editTarget"
                :hint="param.hint"
                :label="`${paramName}${param.isRequired ? '*' : ''}`"
                :rules="param.isRequired ? [rules.required] : []"
                @click:append="param.value = param.default"
                outlined
                persistent-hint
                v-model="param.value"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
  name: "connection-detail-view-superuser",
  components: {},
  props: ["connection", "dialog"],

  apollo: {
    allGroups: {
      query: gql`
        query allGroups($first: Int, $tenant: ID!, $type_In: String!) {
          allResources(
            first: $first
            tenant: $tenant
            type_In: $type_In
            orderBy: ["name"]
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allResources,
      skip() {
        if (this.connection?.tenant?.id && this.dialog) {
          return false;
        } else {
          return true;
        }
      },
      variables() {
        return {
          first: 100,
          tenant: this.connection?.tenant?.id,
          type_In: "Power BI",
        };
      },
    },

    allResourceGroups: {
      query: gql`
        query allResourceGroups($first: Int, $tenant: ID!) {
          allResourceGroups(first: $first, tenant: $tenant) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allResourceGroups,
      skip() {
        if (this.connection?.tenant?.id && this.dialog) {
          return false;
        } else {
          return true;
        }
      },
      variables() {
        return {
          first: 100,
          tenant: this.connection?.tenant?.id,
        };
      },
    },

    allDatawarehouses: {
      query: gql`
        query allDatawarehouses(
          $first: Int
          $resourceGroup: ID!
          $subType_In: String
          $type_In: String!
        ) {
          allResources(
            first: $first
            resourceGroup: $resourceGroup
            subType_In: $subType_In
            type_In: $type_In
            orderBy: ["name"]
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                name
                password
                user
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allResources,
      skip() {
        return !this.selectedResourceGroup?.node?.id;
      },
      variables() {
        return {
          first: 100,
          resourceGroup: this.selectedResourceGroup?.node?.id,
          subType_In: "Microsoft.Sql/servers/databases",
          type_In: "Azure",
        };
      },
    },

    allDataFactories: {
      query: gql`
        query allDataFactories(
          $first: Int
          $resourceGroup: ID!
          $subType_In: String
          $type_In: String!
        ) {
          allResources(
            first: $first
            resourceGroup: $resourceGroup
            subType_In: $subType_In
            type_In: $type_In
            orderBy: ["name"]
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allResources,
      skip() {
        return !this.selectedResourceGroup?.node?.id;
      },
      variables() {
        return {
          first: 100,
          resourceGroup: this.selectedResourceGroup?.node?.id,
          subType_In: "Microsoft.DataFactory/factories",
          type_In: "Azure",
        };
      },
    },

    allTaps: {
      query: gql`
        query allTaps($first: Int) {
          allTaps(first: $first, orderBy: ["name"]) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                externalId
                name
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allTaps,
      variables() {
        return {
          first: 100,
        };
      },
    },
  },

  data() {
    return {
      allDataFactories: {},
      allDatawarehouses: {},
      allGroups: {},
      allResourceGroups: {},
      allTaps: {},
      editTarget: false,
      error: null,
      installParameters: {},
      isFormValid: false,
      isSaving: false,
      selectedDataFactory: null,
      selectedDatawarehouse: null,
      selectedGroup: null,
      selectedResourceGroup: null,
      selectedTap: null,
      tokenId: null,
    };
  },
  computed: {
    formTitle() {
      return `Connection detail`;
    },
  },
  watch: {
    connection: {
      deep: true,
      handler(val) {
        if (this.dialog) {
          this.resetForm();
        }
      },
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    deployConnection() {
      this.error = null;
      this.isSaving = true;

      var payload = {
        id: this.connection.id,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createConnectionDeployment(
              $input: CreateConnectionDeploymentInput!
            ) {
              createConnectionDeployment(input: $input) {
                connection {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Connection deployment successfully started`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          setTimeout(() => {
            this.$emit("deployed", response);
          }, 5000);

          this.isSaving = false;
        })
        .catch((error) => {
          this.error = error;
          this.isSaving = false;
        });
    },

    goToTargets() {
      this.$router.push({
        name: "targets",
        query: { tenant: this.connection?.tenant?.id },
      });
    },

    resetForm() {
      this.error = null;
      this.isSaving = false;
      this.editTarget = false;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      this.selectedDataFactory = { node: this.connection?.dataFactory };
      this.selectedDatawarehouse = {
        node: this.connection?.datawarehouse,
      };
      this.selectedGroup = { node: this.connection?.group };
      this.selectedResourceGroup = {
        node: this.connection?.resourceGroup,
      };
      this.selectedTap = this.connection?.tap?.id;
      this.tokenId = this.connection?.tokenId;

      this.setInstallParameters();
    },

    saveConnection(deploy = false) {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      const installParametersOutput = {};
      for (const key in this.installParameters) {
        installParametersOutput[key] = this.installParameters[key].value;
      }

      var payload = {
        id: this.connection.id,
        dataFactory: this.selectedDataFactory?.node?.id,
        datawarehouse: this.selectedDatawarehouse?.node?.id,
        group: this.selectedGroup?.node?.id,
        installParameters: JSON.stringify(installParametersOutput),
        resourceGroup: this.selectedResourceGroup?.node?.id,
        tokenId: this.tokenId,
      };

      console.log(payload);

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateConnection($input: UpdateConnectionInput!) {
              updateConnection(input: $input) {
                connection {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Connection successfully edited`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.editTarget = false;
          this.isSaving = false;

          if (deploy) {
            this.deployConnection();
          }
        })
        .catch((error) => {
          this.error = error;
          this.isSaving = false;
        });
    },

    setInstallParameters() {
      var installParameters = JSON.parse(
        this.connection?.tap?.installParameters
      );
      var actualValues = JSON.parse(this.connection?.installParameters);

      const combinedValues = {};

      installParameters.forEach((param) => {
        const paramName = param.name;

        // Check if the actual value exists, otherwise use the default value
        const value = actualValues.hasOwnProperty(paramName)
          ? actualValues[paramName]
          : null;

        // Include the hint along with the value
        combinedValues[paramName] = {
          value: value,
          hint: param.hint,
          default: param.default,
          isRequired: param.is_required,
        };
      });

      this.installParameters = combinedValues;
    },

    sqlTestConnection() {
      this.error = null;

      const connection = this.selectedDatawarehouse.node.name.split("/");

      var payload = {
        server: connection[0] + ".database.windows.net",
        database: connection[1],
        user: this.selectedDatawarehouse.node.user,
        password: this.selectedDatawarehouse.node.password,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation sqlTestConnection($input: SqlTestConnectionInput!) {
              sqlTestConnection(input: $input) {
                clientMutationId
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Test connection successfully`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .catch((error) => {
          const payload = {
            color: "error",
            message: `Test connection failed: ${error}`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>

<style>
.editor {
  width: 100%;
  height: 500px;
}
</style>

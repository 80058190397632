<template>
	<v-row justify="center">
		<v-dialog max-width="600px" persistent scrollable v-model="dialog">
			<v-card>
				<v-alert tile type="error" v-if="error">{{ error }}</v-alert>

				<v-card-title :class="['primary', 'white--text']">
					{{ formTitle }}
				</v-card-title>

				<v-card-text :class="['pt-5']">
					<v-form
						onSubmit="return false;"
						ref="form"
						v-model="isFormValid"
					>
						<v-row :class="['my-0', 'py-0']">
							<v-col :class="['my-0', 'py-0']" cols="12">
								<v-autocomplete
									:disabled="
										object?.tenant?.id ? true : false
									"
									:items="tenants.edges"
									:label="$tc('label.tenant', 1)"
									:loading="$apollo.queries.tenants.loading"
									:rules="[rules.required]"
									clearable
									item-text="node.tenantName"
									item-value="node.id"
									outlined
									v-if="me.isSuperuser || me.isPartnerUser"
									v-model="editedItem.tenantId"
								>
								</v-autocomplete>
							</v-col>
							<v-col :class="['my-0', 'py-0']" cols="12">
								<v-text-field
									:label="$tc('label.name', 1)"
									:rules="[rules.required]"
									outlined
									v-model="editedItem.name"
								></v-text-field>
							</v-col>
							<v-col :class="['my-0', 'py-0']" cols="12">
								<v-autocomplete
									:filter="filterReports()"
									:items="reports.edges"
									:label="$tc('label.report', 2)"
									:loading="$apollo.queries.reports.loading"
									:rules="[rules.required]"
									:search-input.sync="searchReports"
									cache-items
									chips
									class="subtitle-2"
									clearable
									item-text="node.reportName"
									item-value="node.id"
									multiple
									outlined
									small-chips
									v-model="selectedReports"
								>
									<template slot="item" slot-scope="{ item }"
										>{{ item.node.tenant.tenantName }} -
										{{ item.node.reportName }}</template
									>
								</v-autocomplete>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions :class="['pb-5', 'pr-5']">
					<v-spacer></v-spacer>
					<v-btn :disabled="isSaving" @click="close()" rounded text>{{
						$t("label.cancel")
					}}</v-btn>
					<v-btn
						:disabled="!isFormValid"
						:loading="isSaving"
						@click="save()"
						color="primary"
						outlined
						rounded
						>{{ $t("label.save") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
	name: "report-group-edit-dialog",
	props: ["dialog", "object"],

	apollo: {
		reports: {
			query: gql`
				query reports(
					$search: String
					$orderBy: [String]
					$ownReports: Boolean!
				) {
					reports(
						search: $search
						orderBy: $orderBy
						ownReports: $ownReports
					) {
						edges {
							node {
								id
								reportName
								tenant {
									id
									tenantName
								}
							}
						}
					}
				}
			`,
			variables() {
				return {
					ownReports: true,
					orderBy: ["tenant__tenantName", "reportName"],
				};
			},
			fetchPolicy: "cache-and-network",
			update: (data) => data.reports,
			skip: true,
			pollInterval: 60000,
		},

		tenants: {
			query: gql`
				query tenants {
					tenants(orderBy: ["tenantName"]) {
						edges {
							node {
								id
								tenantName
							}
						}
					}
				}
			`,
			variables() {},
			fetchPolicy: "cache-and-network",
			update: (data) => data.tenants,
			skip: true,
			pollInterval: 60000,
		},
	},

	data() {
		return {
			configs: [],
			editedItem: {
				id: null,
				name: null,
				tenantId: null,
			},
			error: null,
			isFormValid: false,
			isSaving: false,
			reports: {},
			search: null,
			searchReports: null,
			selectedReports: [],
			tenants: {},
		};
	},
	computed: {
		formTitle() {
			return `${this.$t("label.edit")} ${this.$tc("label.group", 1)}`;
		},

		me() {
			return this.$store.state.user.me;
		},
	},
	watch: {
		dialog(val) {
			if (val) {
				this.$apollo.queries.reports.skip = false;
				this.$apollo.queries.tenants.skip = false;
			}

			this.resetForm();
		},

		searchReports(val) {
			val && val !== this.select && this.refreshReports(val);
		},
	},
	created() {
		this.rules = rules;
	},
	methods: {
		close() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		filterReports(item, queryText, itemText) {
			const textOne = item?.node.tenant.tenantName.toLowerCase();
			const textTwo = item?.node.reportName.toLowerCase();
			if (queryText) {
				const searchText = queryText.toLowerCase();

				return (
					textOne.indexOf(searchText) > -1 ||
					textTwo.indexOf(searchText) > -1
				);
			}
		},

		refreshReports(searchVal) {
			this.$apollo.queries.reports.setVariables({
				search: searchVal,
				orderBy: ["tenant__tenantName", "reportName"],
				ownReports: true,
			});

			// set skip false to execute the query
			this.$apollo.queries.reports.skip = false;
			this.$apollo.queries.reports.refresh();
		},

		resetForm() {
			this.error = null;
			this.isSaving = false;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			if (this.dialog && this.object) {
				this.editedItem.id = this.object.id;
				this.editedItem.name = this.object.name;
				this.editedItem.tenantId = this.object.tenant.id;

				this.selectedReports = this.object.reportSet
					? this.object.reportSet.edges.map((item) => {
							return item.node.id;
					  })
					: [];
			} else {
				for (var key in this.editedItem) {
					this.editedItem[key] = null;
				}

				this.selectedReports = [];
			}
		},

		save() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.error = null;
			this.isSaving = true;

			var payload = {
				name: this.editedItem.name,
				reportIds: this.selectedReports,
			};

			if (this.object) {
				payload.id = this.editedItem.id;

				this.$apollo
					.mutate({
						mutation: gql`
							mutation updateReportGroup(
								$input: UpdateReportGroupInput!
							) {
								updateReportGroup(input: $input) {
									reportGroup {
										id
									}
								}
							}
						`,
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit("changed", response);
						this.close();

						const payload = {
							color: "success",
							message: `Report group successfully edited`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors
							.map((error) => error.message)
							.join(", ");
						this.isSaving = false;
					})
					.finally(() => {});
			} else {
				payload.tenantId = this.editedItem.tenantId;

				this.$apollo
					.mutate({
						mutation: gql`
							mutation createReportGroup(
								$input: CreateReportGroupInput!
							) {
								createReportGroup(input: $input) {
									reportGroup {
										id
										name
									}
								}
							}
						`,
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit("changed", response);
						this.close();

						const payload = {
							color: "success",
							message: `Report group successfully added`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors
							.map((error) => error.message)
							.join(", ");
						this.isSaving = false;
					})
					.finally(() => {});
			}
		},
	},
};
</script>

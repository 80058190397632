<template>
  <BaseDialog :error="error" :dialog="dialog" @close="close($event)">
    <template v-slot:title> {{ $tc("title") }} </template>
    <template v-slot:text>
      <div class="mb-5">
        {{ $tc("description") }}
      </div>

      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :footer-props="{
              'disable-pagination':
                $apollo.queries.allOrchestPipelineRuns.loading,
              'items-per-page-options': [10, 25, 50, 75, 100],
            }"
            :headers="headers"
            :items="allOrchestPipelineRuns.pipelineRuns"
            :loading="$apollo.queries.allOrchestPipelineRuns.loading"
            :options.sync="dataTableOptions"
            item-key="uuid"
          >
            <template v-slot:[`item.status`]="{ item }">
              <StatusChip :status="item.status" />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actionsRight>
      <BaseButton
        :loading="isSaving"
        :text="true"
        @click="close()"
        color="black"
      >
        {{ $t("label.cancel") }}
      </BaseButton>
      <BaseButton :disabled="!formValid" :loading="isSaving" @click="save()">
        {{ $t("label.confirm") }}
      </BaseButton>
    </template>
  </BaseDialog>
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import StatusChip from "@/components/base/StatusChip.vue";

export default {
  name: "cancel-runs-dialog",
  components: {
    BaseButton,
    BaseDialog,
    StatusChip,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  apollo: {
    allOrchestPipelineRuns: {
      query: gql`
        query allOrchestPipelineRuns {
          allOrchestPipelineRuns(statusses: ["STARTED", "PENDING"]) {
            pipelineRuns {
              jobName
              jobUuid
              pipelineUuid
              projectName
              projectUuid
              status
              uuid
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allOrchestPipelineRuns,
    },
  },

  data() {
    return {
      error: null,
      formValid: true,
      isSaving: false,
      dataTableOptions: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 50,
        multiSort: true,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
      headers: [
        {
          text: `Project ${this.$tc("label.name")}`,
          value: "projectName",
          sortable: false,
        },
        {
          text: `Job ${this.$tc("label.name")}`,
          value: "jobName",
          sortable: false,
        },
        {
          text: this.$tc("label.status"),
          value: "status",
          sortable: false,
        },
      ],
      allOrchestPipelineRuns: {},
      search: null,
    };
  },

  computed: {},

  watch: {
    dataTableOptions() {
      this.refresh();
    },

    dialog(val) {
      // reset the form to it's original state and reset the validation when dialog closes
      if (val) {
        this.refresh();
      }
    },
  },

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;
  },

  mounted() {},

  methods: {
    clearSearch() {
      this.search = null;
      this.refresh();
    },

    close() {
      this.$emit("update:dialog", false);
    },

    refresh() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;

      var _first = itemsPerPage;
      var _last = null;
      var _before = null;
      var _after = null;

      if (page < this.page) {
        _first = null;
        _last = itemsPerPage;
        _before = this.allOrchestPipelineRuns.pageInfo.startCursor;
        _after = null;
      }

      if (page > this.page) {
        _first = itemsPerPage;
        _last = null;
        _before = null;
        _after = this.allOrchestPipelineRuns.pageInfo.endCursor;
      }

      var orderByList = this.orderByList(sortBy, sortDesc);

      this.$apollo.queries.allOrchestPipelineRuns.setVariables({
        first: _first,
        last: _last,
        before: _before,
        after: _after,
      });

      this.$apollo.queries.allOrchestPipelineRuns.skip = false;
      this.$apollo.queries.allOrchestPipelineRuns.refresh();

      this.page = page;
    },

    resetFilters() {
      this.clearSearch();
    },

    save() {
      if (!this.formValid) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {};

      this.$apollo
        .mutate({
          mutation: gql`
            mutation orchestCancelPipelineRuns($input: OrchestCancelPipelineRunsInput!) {
              orchestCancelPipelineRuns(input: $input) {
                clientMutationId
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Successfully cancelled runs`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$emit("changed", response);

          this.close();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "title": "Cancel running pipelines",
      "description": "In this table all the started and pending Orchest pipeline runs are displayed. At the bottom you can click the confirm button to cancel all these pipeline runs."
    },
    "nl": {
      "title": "Annuleer actieve pipelines",
      "description": "In deze tabel worden alle gestarte en wachtende Orchest pipeline runs weergegeven. Onderaan kun je op de bevestig knop klikken om alle deze pipeline runs te annuleren."
    },
    "de": {
      "title": "Aktieve Pipelines abbrechen",
      "description": "In dieser Tabelle werden alle gestarteten und ausstehenden Orchest-Pipeline-Läufe angezeigt. Unten kannst du auf die Schaltfläche 'Bestätigen' klicken, um alle diese Pipeline-Läufe abzubrechen."
    }
  }
</i18n>

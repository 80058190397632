<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="1300px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          {{ formTitle }}
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" onSubmit="return false;" v-model="isFormValid">
            <v-row class="my-0 py-0">
              <v-col cols="10" class="my-0 py-0">
                <v-chip-group v-model="selectedTemplates" column multiple>
                  <v-chip disabled filter outlined value="resourceGroup">
                    {{ $tc("label.resourceGroup", 1) }}
                  </v-chip>
                  <v-chip filter outlined value="database"> Database </v-chip>
                  <v-chip
                    :disabled="selectedTemplates.includes('database')"
                    filter
                    outlined
                    value="keyVault"
                  >
                    Key vault
                  </v-chip>
                  <v-chip filter outlined value="dataFactory">
                    Data factory
                  </v-chip>
                  <v-chip filter outlined value="storageAccount">
                    Storage account
                  </v-chip>
                  <v-chip
                    filter
                    outlined
                    value="logicApp"
                    :disabled="selectedTemplates.includes('logicAppExcel')"
                  >
                    Logic app
                  </v-chip>
                  <v-chip
                    filter
                    outlined
                    value="logicAppExcel"
                    :disabled="selectedTemplates.includes('logicApp')"
                  >
                    Logic app excel
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="2" class="my-0 py-0">
                <v-switch
                  :label="'Show templates'"
                  class="mb-0 mt-2 py-0"
                  hide-details
                  v-model="showTemplates"
                ></v-switch>
              </v-col>

              <v-col cols="12" class="my-0 py-0 mt-2" v-if="showTemplates">
                <MonacoEditor
                  :options="{
                    readOnly: true,
                  }"
                  class="editor"
                  language="json"
                  theme="vs-dark"
                  v-model="templateCode"
                />
              </v-col>

              <v-col cols="12" class="my-0 py-0 mt-10">
                <v-row>
                  <v-col
                    :key="key"
                    class="my-0 py-0"
                    cols="12"
                    sm="6"
                    md="4"
                    v-for="(value, key) in parameters"
                  >
                    <v-text-field
                      :append-icon="
                        generateDefaultKeys.includes(key) ? 'rotate_right' : ''
                      "
                      :disabled="disabled(key)"
                      :label="key"
                      :rules="[rules.required]"
                      @click:append="generateDefault(key)"
                      outlined
                      v-model="parameters[key].value"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="my-0 py-0 mt-2" v-if="showTemplates">
                <MonacoEditor
                  :options="{
                    readOnly: true,
                  }"
                  class="editor"
                  language="json"
                  theme="vs-dark"
                  v-model="parametersCode"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="'primary'"
            :disabled="!isFormValid"
            :loading="isSaving"
            @click="save()"
            rounded
            small
          >
            {{ $t("label.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import MonacoEditor from "vue-monaco";
import rules from "@/utils/rules.js";
import {
  database,
  dataFactory,
  keyVault,
  logicApp,
  logicAppExcel,
  resourceGroup,
  storageAccount,
} from "@/components/integrations/targets/arm/templates.js";

export default {
  name: "resource-group-add-dialog",
  components: {
    MonacoEditor,
  },
  props: ["dialog", "tenant", "resourceGroup"],

  apollo: {},

  data() {
    return {
      combinedTemplate: null,
      defaultParameters: {
        action_group:
          "/subscriptions/aa86c9a9-d98a-474c-9634-5eb69681a197/resourceGroups/ActionGroups/providers/microsoft.insights/actiongroups/AG_Support",
        blob_connection_name: "azureblob",
        data_factory_connection_name: "azuredatafactory",
        sharepoint_connection_name: "sharepointonline",
        resource_group_location: "westeurope",
        resource_tags: {
          CreatedBy: "Marketplace",
          Product: "Power BI",
        },
        sql_server_location: "germanywestcentral",
        sql_user: "sysadmin",
        sql_password: this.generatePassword(),
      },
      generateDefaultKeys: [
        "resource_group",
        "key_vault",
        "sql_server",
        "sql_database",
        "data_factory",
        "storage_account",
        "logic_app",
      ],
      error: null,
      isFormValid: false,
      isSaving: false,
      parameters: {},
      parametersCode: "{}",
      selectedTemplates: ["resourceGroup"],
      showTemplates: false,
      templateCode: "{}",
    };
  },
  computed: {
    formTitle() {
      if (this.resourceGroup) {
        var title = this.$tc("label.resource", 1);
      } else {
        var title = this.$tc("label.resourceGroup", 1);
      }

      return `${this.$t("label.add")} ${title}`;
    },
  },
  watch: {
    selectedTemplates(val) {
      if (val.includes("database") && val.includes("keyVault")) {
        // if there is a database, automatically there is a keyVault,
        // this is added to prevent duplicates
        this.selectedTemplates = val.filter((e) => e !== "keyVault");
      }

      // Ensure only one of 'logicAppExcel' or 'logicApp' is selected
      if (val.includes("logicAppExcel") && val.includes("logicApp")) {
        // Remove 'logicApp' if 'logicAppExcel' is present, otherwise remove 'logicAppExcel'
        val = val.filter(
          (e) =>
            e !== (val.includes("logicAppExcel") ? "logicApp" : "logicAppExcel")
        );
      }

      this.generateCombinedTemplate();
    },

    parameters: {
      deep: true,
      handler(newParameters) {
        // Convert resource_tags value to a JavaScript object
        if (newParameters.resource_tags && newParameters.resource_tags.value) {
          try {
            newParameters.resource_tags.value = JSON.parse(
              newParameters.resource_tags.value
            );
          } catch (error) {
            console.error("Error parsing resource_tags value:", error);
          }
        }

        // Update parametersCode
        this.parametersCode = JSON.stringify(newParameters, null, 2);
      },
    },

    dialog(val) {
      this.resetForm();
      this.generateCombinedTemplate();
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    disabled(key) {
      var disabledKeys = [
        "resource_group",
        "resource_group_location",
        "resource_tags",
      ];
      if (this.resourceGroup && disabledKeys.includes(key)) {
        return true;
      } else false;
    },

    generateCombinedTemplate() {
      this.combinedTemplate = this.combineTemplates(this.selectedTemplates);

      this.templateCode = JSON.stringify(this.combinedTemplate, null, 2);

      this.parameters = this.extractParameters(this.combinedTemplate);
    },

    combineTemplates(selectedTemplates) {
      // empty base combination
      let combinedTemplate = {
        $schema:
          "https://schema.management.azure.com/schemas/2018-05-01/subscriptionDeploymentTemplate.json#",
        contentVersion: "1.0.0.0",
        outputs: {},
        parameters: {},
        resources: [],
        variables: {},
      };

      // for each selected template get the template and merge it
      selectedTemplates.forEach((templateName) => {
        const template = this.getTemplateByName(templateName);
        combinedTemplate = this.mergeTemplates(combinedTemplate, template);
      });

      // Ensure that the resourceGroup template is always included at the end
      combinedTemplate = this.mergeTemplates(
        combinedTemplate,
        this.getTemplateByName("resourceGroup")
      );

      return combinedTemplate;
    },
    mergeTemplates(template1, template2) {
      // Merge two templates into a new template
      // You may need to implement your logic for merging resources, parameters, etc.
      // Ensure unique parameters based on the key and unique resources based on the name

      return {
        $schema: template1.$schema,
        contentVersion: template1.contentVersion,
        outputs: { ...template1.outputs, ...template2.outputs },
        parameters: {
          ...template1.parameters,
          ...template2.parameters,
        },
        resources: this.mergeResources(
          template1.resources,
          template2.resources
        ),
        variables: { ...template1.variables, ...template2.variables },
      };
    },
    mergeResources(resources1, resources2) {
      // Merge two arrays of resources ensuring uniqueness based on the name

      const mergedResources = [];
      const resourceNames = new Set();

      // Add resources from the first array
      resources1.forEach((resource) => {
        if (!resourceNames.has(resource.name)) {
          mergedResources.push(resource);
          resourceNames.add(resource.name);
        }
      });

      // Add resources from the second array, avoiding duplicates
      resources2.forEach((resource) => {
        if (!resourceNames.has(resource.name)) {
          mergedResources.push(resource);
          resourceNames.add(resource.name);
        }
      });

      return mergedResources;
    },
    getTemplateByName(templateName) {
      // Implement logic to retrieve a template by its name

      switch (templateName) {
        case "database":
          return database;
        case "dataFactory":
          return dataFactory;
        case "keyVault":
          return keyVault;
        case "resourceGroup":
          return resourceGroup;
        case "storageAccount":
          return storageAccount;
        case "logicApp":
          return logicApp;
        case "logicAppExcel":
          return logicAppExcel;
        // Add more cases for other templates as needed
        default:
          return {};
      }
    },
    extractParameters(combinedTemplate) {
      // Extract parameter values from the combined template
      const parameters = {};

      Object.keys(combinedTemplate.parameters).forEach((paramName) => {
        // Check if a default value is defined for the parameter
        if (this.defaultParameters.hasOwnProperty(paramName)) {
          parameters[paramName] = {
            value: this.defaultParameters[paramName],
          };
        } else {
          // If no default value, use an empty string as a placeholder
          parameters[paramName] = {
            value: "",
          };
        }
      });

      if (this.resourceGroup) {
        parameters["resource_group"] = {
          value: this.resourceGroup.name,
        };
        parameters["resource_group_location"] = {
          value: this.resourceGroup.location,
        };
        parameters["resource_tags"] = {
          value: JSON.parse(this.resourceGroup.tags),
        };
      }

      return parameters;
    },

    generatePassword() {
      var chars =
        "0123456789abcdefghijklmnopqrstuvwxyz!#^*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var passwordLength = 12;
      var password = "";
      for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
      }

      // added specific strong characters, otherwise, password not safe
      // enough for Azure
      var strongChars = "!#^*()";
      var strongPasswordLength = 1;
      var strongPassword = "";
      for (var i = 0; i <= strongPasswordLength; i++) {
        var randomNumber = Math.floor(Math.random() * strongChars.length);
        strongPassword += strongChars.substring(randomNumber, randomNumber + 1);
      }

      return password + strongPassword;
    },

    generateDefault(key) {
      console.log(key);
      var resourceGroup = this.parameters.resource_group.value;

      switch (key) {
        case "resource_group":
          this.setResourceGroupDefault(key);
          return;
        case "key_vault":
          this.setKeyVaultDefault(key, resourceGroup);
          return;
        case "sql_server":
          this.setSqlServerDefault(key, resourceGroup);
          return;
        case "sql_database":
          this.setSqlDatabaseDefault(key, resourceGroup);
          return;
        case "data_factory":
          this.setDataFactoryDefault(key, resourceGroup);
          return;
        case "storage_account":
          this.setStorageAccountDefault(key, resourceGroup);
          return;
        case "logic_app":
          this.setLogicAppDefault(key, resourceGroup);
          return;
        default:
          return {};
      }
    },

    setResourceGroupDefault(key) {
      // Max 90 characters
      // Upper and lowercase
      // alphanumeric, underscore, parentheses, hyphen, period (except at end)
      var regex = this.tenant.node.tenantName.replace(/[^0-9A-Za-z]/g, "");

      this.parameters[key].value = regex.slice(0, 90);
    },

    setKeyVaultDefault(key, resourceGroup) {
      // Between 3 and 24 characters
      // Upper and lowercase
      // Only contain alphanumeric characters and dashes and cannot start with a number
      var regex = resourceGroup.replace(/[^0-9A-Za-z]/g, "");

      this.parameters[key].value = "kv" + regex.slice(0, 22);
    },

    setSqlServerDefault(key, resourceGroup) {
      // Max 63 characters
      // Lowercase
      // letters, numbers and hyphens
      var regex = resourceGroup.replace(/[^0-9A-Za-z]/g, "").toLowerCase();

      this.parameters[key].value = regex.slice(0, 63);
    },

    setSqlDatabaseDefault(key, resourceGroup) {
      // Max 128 characters
      // Upper and lowercase
      // Your database name can't end with '.' or ' ', can't contain '<,>,*,%,&,:,\,/,?'

      var regex = resourceGroup.replace(/[^0-9A-Za-z]/g, "").toLowerCase();

      this.parameters[key].value = regex.slice(0, 124) + "_dwh";
    },

    setDataFactoryDefault(key, resourceGroup) {
      // Between 3 and 63 characters
      // Upper and lowercase
      // letter or a number, and can contain only letters, numbers, and the dash (-) characte
      var regex = resourceGroup.replace(/[^0-9A-Za-z]/g, "").toLowerCase();

      this.parameters[key].value = regex.slice(0, 63) + "-df";
    },

    setStorageAccountDefault(key, resourceGroup) {
      // Between 3 and 24 characters
      // Lowercase
      // Letters and numbers
      var regex = resourceGroup.replace(/[^0-9A-Za-z]/g, "").toLowerCase();

      this.parameters[key].value = regex.slice(0, 17) + "storage";
    },

    setLogicAppDefault(key, resourceGroup) {
      // Between 1 and 43 characters
      // Lowercase
      // alphanumeric, underscore, parentheses, hyphen, period (except at end)
      var regex = resourceGroup.replace(/[^0-9A-Za-z]/g, "").toLowerCase();

      this.parameters[key].value = regex.slice(0, 28) + "_excel_to_dwh";
    },

    resetForm() {
      this.error = null;
      this.isSaving = false;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      this.defaultParameters.sql_password = this.generatePassword();
      this.selectedTemplates = ["resourceGroup"];
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      if (this.resourceGroup) {
        var subType = "Resource";
        var tenant = this.resourceGroup.tenant.id;
      } else {
        var subType = "Resource group";
        var tenant = this.tenant.node.id;
      }

      var payload = {
        name: this.parameters.resource_group.value,
        parameters: this.parametersCode,
        subType: subType,
        template: this.templateCode,
        tenant: tenant,
        type: "Azure",
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createTaskResultExtension(
              $input: CreateTaskResultExtensionInput!
            ) {
              createTaskResultExtension(input: $input) {
                taskResultExtension {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Deployment successfully started`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$emit("changed");
          this.close();
        })
        .catch((error) => {
          this.error = error;
          this.isSaving = false;
        });
    },
  },
};
</script>

<style>
.editor {
  width: 100%;
  height: 500px;
}
</style>

<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="600px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          {{ formTitle }}
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form onSubmit="return false;" ref="form" v-model="isFormValid">
            <v-row class="my-0 py-0">
              <v-col :class="['my-0', 'py-0']" cols="12">
                <v-text-field
                  :counter="63"
                  :label="'Server'"
                  :rules="[rules.required, rules.maxLength(63)]"
                  disabled
                  outlined
                  v-model="editedItem.server"
                ></v-text-field>
              </v-col>

              <v-col :class="['my-0', 'py-0']" cols="12">
                <v-text-field
                  :counter="128"
                  :label="'Database'"
                  :rules="[rules.required, rules.maxLength(128)]"
                  disabled
                  outlined
                  v-model="editedItem.database"
                ></v-text-field>
              </v-col>

              <v-col :class="['my-0', 'py-0']" cols="12">
                <v-text-field
                  :counter="100"
                  :label="$tc('label.user', 1)"
                  :rules="[rules.required, rules.maxLength(100)]"
                  outlined
                  v-model="editedItem.user"
                ></v-text-field>
              </v-col>

              <v-col :class="['my-0', 'py-0']" cols="12">
                <v-text-field
                  :counter="100"
                  :label="$tc('label.password', 1)"
                  :rules="[rules.required, rules.maxLength(100)]"
                  :type="showPassword ? 'text' : 'password'"
                  outlined
                  v-model="editedItem.password"
                >
                  <template v-slot:append>
                    <v-icon
                      @click="showPassword = !showPassword"
                      color="primary"
                      tabindex="-1"
                      >{{
                        showPassword ? "visibility" : "visibility_off"
                      }}</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            outlined
            :color="'info'"
            :disabled="!isFormValid"
            :loading="isSaving"
            @click="test()"
            rounded
            small
          >
            {{ $tc("label.test", 1) }}
          </v-btn>
          <v-btn
            :color="'primary'"
            :disabled="!isFormValid"
            :loading="isSaving"
            @click="save()"
            rounded
            small
          >
            {{ $t("label.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
  name: "resource-edit-dialog",
  props: ["dialog", "object"],

  apollo: {},

  data() {
    return {
      error: null,
      isFormValid: false,
      isSaving: false,

      editedItem: {
        id: null,
        server: null,
        database: null,
        user: null,
        password: null,
      },

      showPassword: false,
    };
  },
  computed: {
    formTitle() {
      return `${this.$t("label.edit")} ${this.$tc("label.resource", 1)}`;
    },

    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
      }

      this.resetForm();
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    resetForm() {
      this.error = null;
      this.isSaving = false;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      if (this.dialog && this.object) {
        this.editedItem.id = this.object.id;
        this.editedItem.user = this.object.user;
        this.editedItem.password = this.object.password;

        const connection = this.object.name.split("/");
        this.editedItem.server = connection[0] + ".database.windows.net";
        this.editedItem.database = connection[1];
      }
    },

    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    test() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        server: this.editedItem.server,
        database: this.editedItem.database,
        user: this.editedItem.user,
        password: this.editedItem.password,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation sqlTestConnection($input: SqlTestConnectionInput!) {
              sqlTestConnection(input: $input) {
                clientMutationId
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Test connection successfully`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .catch((error) => {
          this.error = error.graphQLErrors[0].message;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        id: this.editedItem.id,
        user: this.editedItem.user,
        password: this.editedItem.password,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateResource($input: UpdateResourceInput!) {
              updateResource(input: $input) {
                resource {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          this.$emit("changed", response);
          this.close();

          const payload = {
            color: "success",
            message: `Resource successfully edited`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .catch((error) => {
          this.error = error.graphQLErrors[0].message;
          this.isSaving = false;

          const payload = {
            color: "error",
            message: this.error,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .finally(() => {});
    },
  },
};
</script>

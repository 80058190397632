<template>
	<v-card class="ma-2 rounded-xl">
		<v-card-title>
			{{ resourceGroup.name }}
			<v-spacer></v-spacer>
			<v-switch
				:label="$tc('label.showAll', 1)"
				class="mb-0 mt-2 py-0"
				hide-details
				v-model="showAll"
			></v-switch>
			<v-spacer></v-spacer>
			<v-btn
				@click="addResourceGroup()"
				color="primary"
				dark
				outlined
				rounded
				small
				>{{ $t("label.add") }}</v-btn
			>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12">
					<v-data-table
						:footer-props="{
							'disable-pagination':
								$apollo.queries.allResources.loading,
							'items-per-page-options': [10, 25, 50, 75, 100],
						}"
						:headers="[
							{
								text: this.$tc('label.name', 1),
								value: 'node.name',
								sortable: false,
							},
							{
								text: this.$tc('label.type', 1),
								value: 'node.subType',
								sortable: false,
							},
							{
								text: this.$t('label.action'),
								align: 'center',
								value: 'action',
								sortable: false,
							},
						]"
						:items="allResources.edges"
						:items-per-page="100"
						:loading="$apollo.queries.allResources.loading"
						:server-items-length="allResources.totalCount"
						item-key="node.id"
					>
						<template v-slot:[`item.action`]="{ item }">
							<v-btn
								@click="edit(item.node)"
								color="primary"
								icon
								v-if="
									item.node.subType ==
									'Microsoft.Sql/servers/databases'
								"
							>
								<v-icon>edit</v-icon>
							</v-btn>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card-text>

		<ResourceEditDialog
			:dialog.sync="editDialog"
			:object.sync="editedItem"
			v-on:changed="onChanged"
		/>

		<ResourceGroupAddDialog
			:dialog.sync="resourceGroupAddDialog"
			:resourceGroup="resourceGroup"
			v-on:changed="onChanged"
		/>
	</v-card>
</template>

<script>
import gql from "graphql-tag";
import ResourceEditDialog from "@/components/integrations/targets/ResourceEditDialog.vue";
import ResourceGroupAddDialog from "@/components/integrations/targets/ResourceGroupAddDialog.vue";

export default {
	name: "resource-group-card",
	props: ["resourceGroup"],
	components: {
		ResourceEditDialog,
		ResourceGroupAddDialog,
	},

	apollo: {
		allResources: {
			query: gql`
				query allResources(
					$first: Int
					$resourceGroup: ID!
					$subType_In: String
					$type_In: String!
				) {
					allResources(
						first: $first
						resourceGroup: $resourceGroup
						subType_In: $subType_In
						type_In: $type_In
						orderBy: ["name"]
					) {
						edgeCount
						totalCount
						pageInfo {
							startCursor
							endCursor
							hasPreviousPage
							hasNextPage
						}
						edges {
							node {
								id
								name
								password
								subType
								user
							}
						}
					}
				}
			`,
			fetchPolicy: "cache-and-network",
			update: (data) => data.allResources,
			variables() {
				return {
					first: 100,
					resourceGroup: this.resourceGroup.id,
					subType_In: "Microsoft.Sql/servers/databases",
					type_In: "Azure",
				};
			},
		},
	},

	data() {
		return {
			allResources: {},
			editDialog: false,
			editedItem: {},
			resourceGroupAddDialog: false,
			showAll: false,
		};
	},
	computed: {},
	watch: {
		showAll() {
			this.refresh();
		},
	},
	created() {},
	methods: {
		addResourceGroup() {
			this.resourceGroupAddDialog = true;
		},

		edit(item) {
			this.editedItem = item;
			this.editDialog = true;
		},

		onChanged() {
			this.refresh();
		},

		refresh() {
			var variables = {
				first: 100,
				resourceGroup: this.resourceGroup.id,
				type_In: "Azure",
			};

			if (!this.showAll) {
				variables.subType_In = "Microsoft.Sql/servers/databases";
			}

			this.$apollo.queries.allResources.setVariables(variables);

			this.$apollo.queries.allResources.refresh();
		},
	},
};
</script>

<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="1300px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          {{ formTitle }}
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteSelected()"
            icon
            color="error"
            v-if="this.object"
          >
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" onSubmit="return false;" v-model="isFormValid">
            <v-row class="my-0 py-0">
              <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                <v-text-field
                  :label="`${$tc('label.name', 1)} NL`"
                  :rules="[rules.required]"
                  outlined
                  v-model="editedItem.nameNl"
                ></v-text-field>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                <v-text-field
                  :label="`${$tc('label.name', 1)} EN`"
                  :rules="[rules.required]"
                  outlined
                  v-model="editedItem.nameEn"
                ></v-text-field>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                <v-textarea
                  :label="`${$tc('label.description', 1)} NL`"
                  :rules="[rules.required]"
                  outlined
                  v-model="editedItem.descriptionNl"
                ></v-textarea>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                <v-textarea
                  :label="`${$tc('label.description', 1)} EN`"
                  :rules="[rules.required]"
                  outlined
                  v-model="editedItem.descriptionEn"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="'primary'"
            :disabled="!isFormValid"
            :loading="isSaving"
            @click="save()"
            rounded
            small
          >
            {{ $t("label.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DeleteDialog
      :dialog.sync="deleteDialog"
      v-on:confirmed="onDeleteConfirmed"
    />
  </v-row>
</template>

<script>
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import rules from "@/utils/rules.js";

export default {
  name: "incident-update-edit-dialog",
  components: {
    DeleteDialog,
  },
  props: ["dialog", "object", "incident"],

  apollo: {},

  data() {
    return {
      deleteDialog: false,
      editedItem: {},
      error: null,
      isFormValid: false,
      isSaving: false,
    };
  },
  computed: {
    formTitle() {
      return `${this.$t("label.add")} ${this.$tc("label.incident", 1)} Update`;
    },

    me() {
      return this.$store.state.user.me || {};
    },
  },
  watch: {
    dialog(val) {
      this.resetForm();
    },
  },
  created() {
    this.rules = rules;
    this.capitalizeFirstLetter = helper.capitalizeFirstLetter;
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    deleteSelected() {
      this.deleteDialog = true;
    },

    resetForm() {
      this.error = null;
      this.isSaving = false;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      if (this.dialog && this.object) {
        this.editedItem.id = this.object.id;
        this.editedItem.descriptionEn = this.object.descriptionEn;
        this.editedItem.descriptionNl = this.object.descriptionNl;
        this.editedItem.nameEn = this.object.nameEn;
        this.editedItem.nameNl = this.object.nameNl;
      } else {
        this.editedItem = {};
      }

      this.editedItem.incident = this.incident.id;
    },

    onDeleteConfirmed() {
      var ids = [this.object.id];

      const payload = {
        ids: ids,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteIncidentUpdates(
              $input: DeleteIncidentUpdatesInput!
            ) {
              deleteIncidentUpdates(input: $input) {
                deletionCount
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          this.deleteDialog = false;

          const payload = {
            color: "success",
            message: `Incident updates successfully deleted`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$emit("changed", response);
          this.close();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        descriptionEn: this.editedItem.descriptionEn,
        descriptionNl: this.editedItem.descriptionNl,
        incident: this.editedItem.incident,
        nameEn: this.editedItem.nameEn,
        nameNl: this.editedItem.nameNl,
      };

      if (this.object) {
        payload.id = this.editedItem.id;

        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateIncidentUpdate(
                $input: UpdateIncidentUpdateInput!
              ) {
                updateIncidentUpdate(input: $input) {
                  clientMutationId
                }
              }
            `,
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            const payload = {
              color: "success",
              message: `Incident update successfully updated`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);

            this.$emit("changed", response);
            this.close();
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.isSaving = false;
          });
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createIncidentUpdate($input: CreateIncidentUpdateInput!) {
                createIncidentUpdate(input: $input) {
                  clientMutationId
                }
              }
            `,
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            const payload = {
              color: "success",
              message: `Incident update successfully created`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);

            this.$emit("changed", response);
            this.close();
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .my-stepper .v-stepper__step {
    flex-grow: 1;
  }
}
</style>

<i18n>
  {}
</i18n>

<template>
  <v-badge
    :class="['mb-5', 'pb-1']"
    :content="connection?.remainingTrialPeriod"
    :value="
      connection?.remainingTrialPeriod && connection?.remainingTrialPeriod >= 0
        ? true
        : false
    "
    color="success"
    overlap
    v-if="!$apollo.queries.webflowItem.loading"
  >
    <v-card
      @click="openTap(webflowItem?.fieldData?.slug)"
      class="my-0 pa-1 rounded-xl row-pointer"
    >

      <v-card-title
        :class="['my-0', 'py-0', 'pt-1']"
        v-if="me.isSuperuser && connection"
      >
        <v-tooltip
          :key="index"
          bottom
          v-for="(item, index) in [
            {
              name: 'Tap id',
              value: `#TAP${connection?.pk}`,
            },
            {
              name: `Tenant - ${connection?.tenant?.pk}`,
              value: `${connection?.tenant?.tenantName}`,
              // value: `SP_BIP_tenant_${connection?.tenant?.pk}_${connection?.tenant?.tenantName}`,
            },
            {
              name: 'Created at',
              value: connection?.createdAt,
            },
          ]"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="my-1 mx-1"
              label
              small
              v-bind="attrs"
              v-on="on"
              v-if="item.name === 'Created at'"
            >
              {{ item.value | moment("YYYY-MM-DD HH:mm") }}
            </v-chip>
            <v-chip class="my-1 mx-1" label small v-bind="attrs" v-on="on" v-else>
              {{ item.value }}
            </v-chip>
          </template>
          <span>{{ item.name }}</span>
        </v-tooltip>
      </v-card-title>

      <v-card-title :class="['my-0']">
        <v-avatar size="80">
          <img :src="webflowItem?.fieldData?.logo?.url" />
        </v-avatar>

        <v-spacer />

        <v-chip
          :class="['mb-10']"
          outlined
          color="success"
          rounded
          v-if="!connection"
          >{{ $t("label.connect") }}</v-chip
        >
        <v-chip
          :color="
            connection?.status == 'In progress'
              ? 'info'
              : connection?.status == 'Connected'
              ? 'success'
              : connection?.status == 'Failed'
              ? 'error'
              : connection?.status == 'Cancelled'
              ? 'warning'
              : connection?.status == 'Deleting'
              ? 'error'
              : ''
          "
          :class="['ml-10']"
          rounded
          v-else
        >
          <v-progress-circular
            :size="14"
            :width="1"
            class="ml-n1 mr-1"
            color="white"
            indeterminate
            v-if="connection?.status == 'In progress'"
          ></v-progress-circular>
          {{ connection?.status }}
        </v-chip>
      </v-card-title>

      <v-card-title
        :class="['my-0', 'py-0', 'font-weight-bold', 'text-h5']"
        style="word-break: break-word"
      >
        {{ webflowItem?.fieldData?.name }}
        <v-spacer />
      </v-card-title>
      <v-list-item three-line :class="['mt-n5', 'my-0', 'py-0']">
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ webflowItem?.fieldData?.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <ConnectionDetailDialog
      :dialog.sync="dialog"
      :connection="connection"
      v-on:changed="onChanged"
    />
  </v-badge>

  <v-skeleton-loader
    type="list-item-avatar, article"
    v-else
  ></v-skeleton-loader>
</template>

<script>
import config from "@/utils/config.json";
import gql from "graphql-tag";
import ConnectionDetailDialog from "@/components/integrations/taps/connection/ConnectionDetailDialog.vue";

export default {
  name: "tap-card",
  props: ["tap", "connection"],
  components: {
    config,
    ConnectionDetailDialog,
  },

  apollo: {
    webflowItem: {
      query: gql`
        query webflowItem($collectionId: String!, $itemId: String!) {
          webflowItem(collectionId: $collectionId, itemId: $itemId) {
            id
            cmsLocaleId
            createdOn
            fieldData
            isArchived
            isDraft
            lastPublished
            lastUpdated
          }
        }
      `,
      variables() {
        return {
          collectionId: "658435cbaf21ee3aa891ffa2",
          itemId: this.tap.externalId,
        };
      },
      update: (data) => data.webflowItem,
      result() {},
    },
  },

  data() {
    return {
      dialog: false,
      webflowItem: {
        id: null,
        fieldData: {
          logo: {},
          name: null,
          slug: null,
        },
      },
    };
  },
  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {},
  created() {},
  methods: {
    onChanged() {},

    openTap() {
      if (!this.connection) {
        var url = `${config.WEBSITE_BASE_URL}taps/${this.webflowItem?.fieldData?.slug}`;
        window.open(url, "_blank");
      } else {
        this.dialog = true;
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
	"noReviews": "No reviews"
  },
  "nl": {
	"noReviews": "Geen recensies"
  },
  "de": {
	"noReviews": "Keine Bewertungen"
  }
}
</i18n>

<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col :class="['my-0', 'py-0']" cols="12">
          <v-card flat>
            <v-card-title
              :class="['font-weight-bold', 'text-h4']"
              style="word-break: break-word"
            >
              {{ $t("title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("subtitle") }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mb-0 pb-0" justify="center" align="center">
        <v-col :class="['my-0', 'py-0']" cols="12" xl="6" md="7">
          <v-alert
            :icon="overallStatus?.icon"
            :color="overallStatus?.color"
            class="elevation-2"
            dark
            rounded="xl"
          >
            {{ overallStatus?.[`name${capitalizeFirstLetter($i18n.locale)}`] }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row class="mt-2 mb-0 pt-0 pb-1" justify="center" align="center">
        <v-col :class="['my-0', 'py-0']" cols="12" xl="6" md="7">
          <v-card
            :loading="$apollo.queries.allSystems.loading || isSaving"
            class="rounded-xl pb-1"
          >
            <v-card-title>
              {{ $tc("label.system", 2) }}
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-subtitle> {{ $t("systemSubtitle") }} </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col
                  :class="['my-1', 'py-0']"
                  :key="system.node.id"
                  cols="12"
                  v-for="system in allSystems.edges"
                >
                  <v-card class="rounded-xl">
                    <v-card-title>
                      {{ system.node.name }}
                      <v-spacer></v-spacer>
                      <v-menu offset-y rounded="xl">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            :color="system.node?.state?.color"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon left>
                              {{ system.node?.state?.icon }}
                            </v-icon>
                            {{
                              system.node?.state?.[
                                `name${capitalizeFirstLetter($i18n.locale)}`
                              ]
                            }}
                          </v-chip>
                        </template>
                        <v-list v-if="me.isSuperuser">
                          <v-list-item
                            :key="state.node.id"
                            @click="
                              updateSystemState(system.node.id, state.node.id)
                            "
                            v-for="state in allStates.edges"
                          >
                            <v-list-item-title>
                              <v-chip :color="state.node.color">
                                <v-icon left>
                                  {{ state.node.icon }}
                                </v-icon>
                                {{
                                  state.node?.[
                                    `name${capitalizeFirstLetter($i18n.locale)}`
                                  ]
                                }}
                              </v-chip>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mt-5 mb-0 pt-0 pb-1" justify="center" align="center">
        <v-col :class="['my-0', 'py-0']" cols="12" xl="6" md="7">
          <v-card
            :loading="$apollo.queries.allSystems.loading || isSaving"
            class="rounded-xl pb-1"
          >
            <v-card-title>
              {{ $t("label.maintenance") }}
              <v-spacer></v-spacer>
              <v-btn
                @click="addMaintenance()"
                class="ml-2"
                color="primary"
                dark
                outlined
                rounded
                small
                v-if="me.isSuperuser"
                >{{ $t("label.add") }}</v-btn
              >
            </v-card-title>
            <v-card-subtitle>
              {{ $t("maintenanceSubtitle") }}
            </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col
                  :class="['my-1', 'py-0']"
                  :key="maintenance?.node?.id"
                  cols="12"
                  v-for="maintenance in allMaintenances?.edges"
                >
                  <v-card
                    class="rounded-xl"
                    @click="
                      me.isSuperuser ? editMaintenance(maintenance?.node) : null
                    "
                  >
                    <v-card-title>
                      {{
                        maintenance?.node?.[
                          `name${capitalizeFirstLetter($i18n.locale)}`
                        ]
                      }}
                      <v-spacer />
                    </v-card-title>

                    <v-card-subtitle class="pb-0">
                      {{
                        maintenance?.node?.start | moment("YYYY-MM-DD HH:mm")
                      }}
                      -
                      {{ maintenance?.node?.end | moment("YYYY-MM-DD HH:mm") }}
                    </v-card-subtitle>

                    <v-card-subtitle class="pt-1">
                      <v-chip
                        :key="system?.node?.id"
                        class="my-1 mx-1"
                        small
                        v-for="system in maintenance?.node?.systems?.edges"
                      >
                        {{ system?.node?.name }}
                      </v-chip>
                    </v-card-subtitle>

                    <v-card-text>
                      {{
                        maintenance?.node?.[
                          `description${capitalizeFirstLetter($i18n.locale)}`
                        ]
                      }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mt-5 mb-0 pt-0 pb-1" justify="center" align="center">
        <v-col :class="['my-0', 'py-0']" cols="12" xl="6" md="7">
          <v-card
            :loading="$apollo.queries.allSystems.loading || isSaving"
            class="rounded-xl pb-1"
          >
            <v-card-title>
              {{ $tc("label.incident", 2) }}
              <v-spacer />
              <v-btn
                @click="addIncident()"
                class="ml-2"
                color="primary"
                dark
                outlined
                rounded
                small
                v-if="me.isSuperuser"
                >{{ $t("label.add") }}</v-btn
              >
            </v-card-title>
            <v-card-subtitle>
              {{ $t("incidentsSubtitle") }}
            </v-card-subtitle>

            <v-card-text>
              <v-timeline dense class="mt-0 pt-0 ml-n9">
                <v-timeline-item
                  v-for="incident in allIncidents?.edges"
                  :key="incident?.node?.id"
                  left
                  small
                >
                  <v-row>
                    <v-col cols="12">
                      <v-card
                        class="rounded-xl"
                        @click="
                          me.isSuperuser ? editIncident(incident?.node) : null
                        "
                      >
                        <v-card-title>
                          {{
                            incident?.node?.[
                              `name${capitalizeFirstLetter($i18n.locale)}`
                            ]
                          }}
                          <v-spacer />
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                color="success"
                                v-if="incident?.node?.resolvedAt"
                                v-on="on"
                              >
                                <v-icon left> check_circle </v-icon>
                                {{
                                  incident?.node?.resolvedAt
                                    | moment("YYYY-MM-DD HH:mm")
                                }}
                              </v-chip>
                            </template>
                            <span> {{ $t("label.resolved") }} </span>
                          </v-tooltip>
                        </v-card-title>

                        <v-card-subtitle class="pb-0">
                          {{
                            incident?.node?.createdAt
                              | moment("YYYY-MM-DD HH:mm")
                          }}
                          -
                          <v-chip small :color="incident.node?.state?.color">
                            <v-icon left small>
                              {{ incident.node?.state?.icon }}
                            </v-icon>
                            {{
                              incident.node?.state?.[
                                `name${capitalizeFirstLetter($i18n.locale)}`
                              ]
                            }}
                          </v-chip>
                        </v-card-subtitle>

                        <v-card-subtitle class="pt-1">
                          <v-chip
                            :key="system?.node?.id"
                            class="my-1 mx-1"
                            small
                            v-for="system in incident?.node?.systems?.edges"
                          >
                            {{ system?.node?.name }}
                          </v-chip>
                        </v-card-subtitle>

                        <v-card-text>
                          <v-row>
                            <v-col cols="12">
                              {{
                                incident?.node?.[
                                  `description${capitalizeFirstLetter(
                                    $i18n.locale
                                  )}`
                                ]
                              }}
                            </v-col>
                            <v-col cols="12" v-if="me.isSuperuser">
                              <v-btn
                                :color="'primary'"
                                @click.stop="addIncidentUpdate(incident?.node)"
                                rounded
                                small
                                block
                              >
                                {{ $t("label.add") }}
                              </v-btn>
                            </v-col>
                            <v-col
                              :class="['my-1', 'py-0']"
                              :key="update?.node?.id"
                              cols="12"
                              v-for="update in incident?.node?.incidentupdateSet
                                ?.edges"
                            >
                              <v-card
                                class="rounded-xl"
                                @click.stop="
                                  me.isSuperuser
                                    ? editIncidentUpdate(
                                        update?.node,
                                        incident?.node
                                      )
                                    : null
                                "
                              >
                                <v-card-title>
                                  {{
                                    update?.node?.[
                                      `name${capitalizeFirstLetter(
                                        $i18n.locale
                                      )}`
                                    ]
                                  }}
                                  <v-spacer />
                                </v-card-title>

                                <v-card-subtitle class="pb-0">
                                  {{
                                    update?.node?.createdAt
                                      | moment("YYYY-MM-DD HH:mm")
                                  }}
                                </v-card-subtitle>

                                <v-card-text>
                                  {{
                                    update?.node?.[
                                      `description${capitalizeFirstLetter(
                                        $i18n.locale
                                      )}`
                                    ]
                                  }}
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <IncidentEditDialog
      :dialog.sync="incidentDialog"
      :object.sync="editedIncident"
      v-on:changed="refresh()"
    ></IncidentEditDialog>

    <IncidentUpdateEditDialog
      :dialog.sync="incidentUpdateDialog"
      :incident.sync="editedIncident"
      :object.sync="editedIncidentUpdate"
      v-on:changed="refresh()"
    ></IncidentUpdateEditDialog>

    <MaintenanceEditDialog
      :dialog.sync="maintenanceDialog"
      :object.sync="editedMaintenance"
      v-on:changed="refresh()"
    ></MaintenanceEditDialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import IncidentEditDialog from "@/components/status/IncidentEditDialog.vue";
import IncidentUpdateEditDialog from "@/components/status/IncidentUpdateEditDialog.vue";
import MaintenanceEditDialog from "@/components/status/MaintenanceEditDialog.vue";

export default {
  name: "status-list",
  components: {
    IncidentEditDialog,
    IncidentUpdateEditDialog,
    MaintenanceEditDialog,
  },

  apollo: {
    allIncidents: {
      query: gql`
        query allIncidents($orderBy: [String]) {
          allIncidents(orderBy: $orderBy) {
            edges {
              node {
                id
                createdAt
                descriptionEn
                descriptionNl
                nameEn
                nameNl
                pk
                resolvedAt
                state {
                  id
                  color
                  icon
                  nameEn
                  nameNl
                }
                systems {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                incidentupdateSet {
                  edges {
                    node {
                      id
                      createdAt
                      descriptionEn
                      descriptionNl
                      nameEn
                      nameNl
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          orderBy: ["-createdAt"],
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allIncidents,
    },

    allMaintenances: {
      query: gql`
        query allMaintenances($orderBy: [String], $isCurrentOrFuture: Boolean) {
          allMaintenances(
            orderBy: $orderBy
            isCurrentOrFuture: $isCurrentOrFuture
          ) {
            edges {
              node {
                id
                descriptionEn
                descriptionNl
                end
                nameEn
                nameNl
                pk
                start
                systems {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          orderBy: ["start"],
          isCurrentOrFuture: true,
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allMaintenances,
    },

    allStates: {
      query: gql`
        query allStates($orderBy: [String]) {
          allStates(orderBy: $orderBy) {
            edges {
              node {
                id
                color
                icon
                nameEn
                nameNl
              }
            }
          }
        }
      `,
      variables() {
        return {
          orderBy: ["index"],
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allStates,
    },

    allSystems: {
      query: gql`
        query allSystems($orderBy: [String]) {
          allSystems(orderBy: $orderBy) {
            edges {
              node {
                id
                name
                pk
                state {
                  id
                  nameEn
                  nameNl
                  index
                  icon
                  color
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          orderBy: ["name"],
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allSystems,
    },
  },

  data: function () {
    return {
      allIncidents: {},
      allMaintenances: {},
      allStates: {},
      allSystems: {},
      editedIncident: {},
      editedIncidentUpdate: {},
      editedMaintenance: {},
      error: null,
      incidentDialog: false,
      incidentUpdateDialog: false,
      isSaving: false,
      maintenanceDialog: false,
      overallState: {},
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },

    overallStatus() {
      return this.$store.state.user.overallStatus;
    },
  },

  watch: {},

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;
    this.capitalizeFirstLetter = helper.capitalizeFirstLetter;
  },

  mounted() {},

  methods: {
    addMaintenance() {
      this.editedMaintenance = null;
      this.maintenanceDialog = true;
    },

    addIncident() {
      this.editedIncident = null;
      this.incidentDialog = true;
    },

    addIncidentUpdate(incident) {
      this.editedIncident = incident;
      this.editedIncidentUpdate = null;
      this.incidentUpdateDialog = true;
    },

    editMaintenance(item) {
      this.editedMaintenance = item;
      this.maintenanceDialog = true;
    },

    editIncident(item) {
      this.editedIncident = item;
      this.incidentDialog = true;
    },

    editIncidentUpdate(item, incident) {
      this.editedIncident = incident;
      this.editedIncidentUpdate = item;
      this.incidentUpdateDialog = true;
    },

    refresh() {
      this.$apollo.queries.allIncidents.refresh();
      this.$apollo.queries.allMaintenances.refresh();
      this.$apollo.queries.allSystems.refresh();
      this.fetchOverallStatus();
    },

    fetchOverallStatus() {
      this.$store
        .dispatch("user/fetchOverallStatus")
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },

    updateSystemState(systemId, stateId) {
      this.error = null;
      this.isSaving = true;

      var payload = {
        id: systemId,
        state: stateId,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateSystem($input: UpdateSystemInput!) {
              updateSystem(input: $input) {
                clientMutationId
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `System successfully updated`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.refresh();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>

<style scoped></style>

<i18n>
{
  "en": {
    "title": "System status and incidents",
    "subtitle": "Stay informed about system status, scheduled maintenance, and past incidents with updates.",
    "systemSubtitle": "Status per system",
    "maintenanceSubtitle": "When is maintenance scheduled? Only active or future maintenance will be shown.",
    "incidentsSubtitle": "All incidents and updates"
  },
  "nl": {
    "title": "Systeemstatus en incidenten",
    "subtitle": "Blijf op de hoogte van systeemstatus, gepland onderhoud en eerdere incidenten met updates.",
    "systemSubtitle": "Status per systeem",
    "maintenanceSubtitle": "Wanneer staat er onderhoud gepland? Alleen actief of toekomstig onderhoud wordt getoond.",
    "incidentsSubtitle": "Alle incidenten en updates"
  },
  "de": {
    "title": "Systemstatus und Vorfälle",
    "subtitle": "Bleiben Sie über die Systemverfügbarkeit, geplante Wartungsarbeiten und vergangene Vorfälle mit Updates informiert.",
    "systemSubtitle": "Status pro System",
    "maintenanceSubtitle": "Wann ist Wartung geplant? Es werden nur aktive oder zukünftige Wartungszeiten angezeigt.",
    "incidentsSubtitle": "Alle Vorfälle und Updates"
  }
}
</i18n>

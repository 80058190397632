<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>

    <v-col cols="12">
      <v-row v-if="selectedTenant">
        <v-col :class="['my-0', 'py-0']" cols="12" md="8">
          <v-card flat>
            <v-card-title
              :class="['font-weight-bold', 'text-h4']"
              style="word-break: break-word"
            >
              {{ $t("title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("subtitle") }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="me.isSuperuser || me.isPartnerUser">
        <v-col cols="12" md="2" sm="6">
          <v-autocomplete
            :items="tenants.edges"
            :label="$tc('label.tenant', 1)"
            :loading="$apollo.queries.tenants.loading"
            @change="refresh()"
            dense
            hide-details
            item-text="node.tenantName"
            item-value="node.id"
            outlined
            return-object
            v-model="selectedTenant"
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="3" v-if="!selectedTenant && (me.isSuperuser || me.isPartnerUser)">
          <v-alert
            border="left"
            class="mr-1"
            color="info"
            colored-border
            dense
            elevation="2"
            icon="info"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t("message.selectTenant") }}
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-if="selectedTenant">
        <v-col :class="['my-0', 'py-0']" cols="12">
          <v-card flat>
            <v-card-title
              :class="['my-1', 'py-1']"
              style="word-break: break-word"
            >
              {{ $t("label.connected") }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  :key="connection?.node?.id"
                  cols="12"
                  md="4"
                  v-for="connection in allConnections.edges"
                >
                  <TapCard
                    :tap="connection?.node?.tap"
                    :connection="connection?.node"
                    v-if="connection?.node?.status !== 'Cancelled'"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col :class="['my-0', 'py-0']" cols="12" v-if="!me.isSuperuser && !me.isPartnerUser">
          <v-card flat>
            <v-card-title
              :class="['my-1', 'py-1']"
              style="word-break: break-word"
            >
              {{ $t("label.other") }} {{ $tc("label.tap", 2) }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <!-- filter out installed taps -->
                <v-col
                  :key="tap?.node?.id"
                  cols="12"
                  md="4"
                  v-for="tap in allTaps?.edges.filter(
                    (i) => !installedTaps.includes(i?.node?.id)
                  )"
                >
                  <TapCard :tap="tap?.node" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TapCard from "@/components/integrations/taps/TapCard.vue";

export default {
  name: "connections-list",
  components: {
    TapCard,
  },

  apollo: {
    allConnections: {
      query: gql`
        query allConnections($first: Int, $tenant: ID!) {
          allConnections(
            first: $first
            tenant: $tenant
            orderBy: ["tap__name"]
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                approvedAt
                cancelledAt
                completedAt
                createdAt
                pk
                remainingTrialPeriod
                status
                tap {
                  id
                  externalId
                }
                tenant {
                  id
                  pk
                  tenantName
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allConnections,
      skip: true,
      result() {
        var installedTaps = [];
        this.allConnections.edges.forEach((item) => {
          if (item.node.status !== "Cancelled") {
            installedTaps.push(item.node.tap.id);
          }
        });

        this.installedTaps = installedTaps;
      },
    },

    allTaps: {
      query: gql`
        query allTaps($first: Int) {
          allTaps(first: $first, orderBy: ["name"]) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                externalId
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allTaps,
      skip: true,
    },

    tenants: {
      query: gql`
        query tenants {
          tenants(orderBy: ["tenantName"]) {
            edges {
              node {
                id
                tenantName
                azureServicePrincipalId
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenants,
    },
  },

  data: function () {
    return {
      allConnections: {
        edges: [],
      },
      allTaps: {
        edges: [],
      },
      error: null,
      installedTaps: [],
      tenants: {},
      selectedTenant: null,
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },

  watch: {
    selectedTenant() {
      this.refresh();
    },
  },

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;

    if (!this.me.isSuperuser && !this.me.isPartnerUser) {
      this.selectedTenant = {
        node: this.me.tenant,
      };
    }
  },

  mounted() {},

  methods: {
    onChanged() {
      this.$apollo.queries.allConnections.refresh();
    },

    refresh() {
      this.$apollo.queries.allConnections.setVariables({
        first: 100,
        tenant: this.selectedTenant.node.id,
      });

      this.$apollo.queries.allConnections.skip = false;
      this.$apollo.queries.allConnections.refresh();

      this.$apollo.queries.allTaps.setVariables({
        first: 100,
      });

      this.$apollo.queries.allTaps.skip = false;
      this.$apollo.queries.allTaps.refresh();
    },

    resetFilters() {
      this.refresh();
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Connected Taps",
    "subtitle": "Manage and monitor all your linked Taps in one place. View status, adjust settings and connect new Taps."
  },
  "nl": {
    "title": "Gekoppelde Taps",
    "subtitle": "Beheer en monitor al jouw gekoppelde Taps op één plek. Bekijk de status, pas instellingen aan en koppel nieuwe Taps."
  },
  "de": {
    "title": "Verbundene Taps",
    "subtitle": "Verwalten und überwachen Sie alle Ihre verknüpften Taps an einem Ort. Status anzeigen, Einstellungen anpassen und neue Taps verbinden."
  }
}
</i18n>

<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>

    <v-col cols="12">
      <v-row v-if="selectedTenant">
        <v-col :class="['my-0', 'py-0']" cols="12">
          <v-card flat>
            <v-card-title
              :class="['font-weight-bold', 'text-h4']"
              style="word-break: break-word"
            >
              {{ $t("title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("subtitle") }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="2" sm="6">
          <v-autocomplete
            :items="tenants.edges"
            :label="$tc('label.tenant', 1)"
            :loading="$apollo.queries.tenants.loading"
            @change="refresh()"
            dense
            hide-details
            item-text="node.tenantName"
            item-value="node.id"
            outlined
            return-object
            v-model="selectedTenant"
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="3" v-if="!selectedTenant && me.isSuperuser">
          <v-alert
            border="left"
            class="mr-1"
            color="info"
            colored-border
            dense
            elevation="2"
            icon="info"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t("message.selectTenant") }}
              </v-col>
            </v-row>
          </v-alert>
        </v-col>

        <v-col
          cols="12"
          md="4"
          v-if="
            selectedTenant && !selectedTenant?.node?.azureServicePrincipalId
          "
        >
          <v-alert
            border="left"
            color="error"
            colored-border
            dense
            elevation="2"
            icon="error"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ this.$t("noServicePrincipal") }}
              </v-col>
            </v-row>
          </v-alert>
        </v-col>

        <v-spacer />
        <v-col cols="12" md="6" align="right">
          <v-btn
            @click="resetFilters()"
            color="primary"
            dark
            rounded
            small
            text
            v-if="selectedTenant"
          >
            {{ $t("label.clearFilters") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="selectedTenant">
        <v-col cols="12" md="6">
          <v-card :loading="isSyncing" class="ma-2 rounded-xl">
            <v-card-title>
              Azure
              <v-spacer></v-spacer>
              <v-btn
                :class="['mr-2']"
                :loading="isSyncing"
                @click="syncResourcesAndAssets()"
                color="info"
                outlined
                rounded
                small
                >{{ $t("label.sync") }}</v-btn
              >
              <v-btn
                :disabled="!selectedTenant?.node?.azureServicePrincipalId"
                @click="addResourceGroup()"
                color="primary"
                outlined
                rounded
                small
                >{{ $t("label.add") }}</v-btn
              >
            </v-card-title>
            <v-card-subtitle>
              {{
                `${this.$tc("label.resourceGroup", 2)} & ${this.$tc(
                  "label.resource",
                  2
                )}`
              }}
            </v-card-subtitle>
            <v-card-text>
              <v-row v-if="allResourceGroups.edges.length > 0">
                <v-col
                  :key="resourceGroup.node.id"
                  cols="12"
                  v-for="resourceGroup in allResourceGroups.edges"
                >
                  <ResourceGroupCard :resourceGroup="resourceGroup.node" />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <v-alert
                    border="left"
                    color="warning"
                    colored-border
                    dense
                    elevation="2"
                    icon="warning"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        {{ $t("noResourceGroups") }}
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card :loading="isSyncing" class="ma-2 rounded-xl">
            <v-card-title>
              Power BI
              <v-spacer />
              <v-btn
                :class="['mr-2']"
                :loading="isSyncing"
                @click="syncResourcesAndAssets()"
                color="info"
                outlined
                rounded
                small
                >{{ $t("label.sync") }}</v-btn
              >
              <v-btn
                :disabled="!selectedTenant?.node?.azureServicePrincipalId"
                @click="addGroup()"
                color="primary"
                outlined
                rounded
                small
                >{{ $t("label.add") }}</v-btn
              >
            </v-card-title>
            <v-card-subtitle>
              {{ this.$tc("label.group", 2) }}
            </v-card-subtitle>
            <v-card-text>
              <v-row v-if="allResources.edges.length > 0">
                <v-col cols="12">
                  <v-data-table
                    :footer-props="{
                      'disable-pagination':
                        $apollo.queries.allResources.loading,
                      'items-per-page-options': [10, 25, 50, 75, 100],
                    }"
                    :headers="[
                      {
                        text: this.$tc('label.name', 1),
                        value: 'node.name',
                        sortable: false,
                      },
                      {
                        text: this.$tc('label.type', 1),
                        value: 'node.subType',
                        sortable: false,
                      },
                    ]"
                    :items="allResources.edges"
                    :items-per-page="100"
                    :loading="$apollo.queries.allResources.loading"
                    :server-items-length="allResources.totalCount"
                    item-key="node.id"
                  >
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <v-alert
                    border="left"
                    color="warning"
                    colored-border
                    dense
                    elevation="2"
                    icon="warning"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        {{ $t("noGroups") }}
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <ResourceGroupAddDialog
      :dialog.sync="resourceGroupAddDialog"
      :tenant="selectedTenant"
      v-on:changed="onChanged"
    />

    <GroupAddDialog
      :dialog.sync="groupAddDialog"
      :tenant="selectedTenant"
      v-on:changed="onChanged"
    />
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import ResourceGroupAddDialog from "@/components/integrations/targets/ResourceGroupAddDialog.vue";
import ResourceGroupCard from "@/components/integrations/targets/ResourceGroupCard.vue";
import GroupAddDialog from "@/components/integrations/targets/GroupAddDialog.vue";

export default {
  name: "targets-list",
  components: {
    ResourceGroupAddDialog,
    ResourceGroupCard,
    GroupAddDialog,
  },

  apollo: {
    allResourceGroups: {
      query: gql`
        query allResourceGroups($first: Int, $tenant: ID!) {
          allResourceGroups(first: $first, tenant: $tenant, orderBy: ["name"]) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                location
                name
                tags
                tenant {
                  id
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allResourceGroups,
      skip: true,
    },

    allResources: {
      query: gql`
        query allResources($first: Int, $tenant: ID!, $type_In: String!) {
          allResources(
            first: $first
            tenant: $tenant
            type_In: $type_In
            orderBy: ["name"]
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                name
                subType
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allResources,
      skip: true,
    },

    tenants: {
      query: gql`
        query tenants {
          tenants(orderBy: ["tenantName"]) {
            edges {
              node {
                id
                tenantName
                azureServicePrincipalId
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenants,
    },

    tenant: {
      query: gql`
        query tenant($tenant: ID!) {
          tenant(id: $tenant) {
            id
            tenantName
            azureServicePrincipalId
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenant,
      skip() {
        return !this.$route.query.tenant;
      },
      variables() {
        return {
          tenant: this.$route.query.tenant,
        };
      },
    },
  },

  data: function () {
    return {
      allResourceGroups: {
        edges: [],
      },
      allResources: {
        edges: [],
      },
      error: null,
      isSyncing: false,
      resourceGroupAddDialog: false,
      selectedTenant: null,
      tenant: {},
      tenants: {},
      groupAddDialog: false,
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },

  watch: {
    tenant() {
      var node = {
        node: this.tenant,
      };
      this.selectedTenant = node;
    },

    selectedTenant() {
      this.refresh();
    },
  },

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;
  },

  mounted() {},

  methods: {
    addResourceGroup() {
      this.resourceGroupAddDialog = true;
    },

    addGroup() {
      this.groupAddDialog = true;
    },

    onChanged() {
      this.refresh();
    },

    refresh() {
      this.$apollo.queries.allResourceGroups.setVariables({
        first: 100,
        tenant: this.selectedTenant.node.id,
      });

      this.$apollo.queries.allResourceGroups.skip = false;
      this.$apollo.queries.allResourceGroups.refresh();

      this.$apollo.queries.allResources.setVariables({
        first: 100,
        tenant: this.selectedTenant.node.id,
        type_In: "Power BI",
      });

      this.$apollo.queries.allResources.skip = false;
      this.$apollo.queries.allResources.refresh();
    },

    resetFilters() {
      this.refresh();
    },

    syncResourcesAndAssets() {
      this.isSyncing = true;

      var payload = {
        tenant: this.selectedTenant.node.id,
        syncOrchest: false,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation syncResourcesAndAssets(
              $input: SyncResourcesAndAssetsInput!
            ) {
              syncResourcesAndAssets(input: $input) {
                clientMutationId
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          this.isSyncing = false;
          this.refresh();
        })
        .catch((error) => {
          this.isSyncing = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Azure and Power BI",
    "subtitle": "The Targets represent the environment where the Tap needs to be deployed to. In this case the Azure environment for the resources and het Power BI group for the reports. Here you can deploy resource groups, resources and groups. Also the datawarehouse connection is configured here on the resource. The Target can be re-used for multiple Taps. By clicking on either sync button all the resource groups, resources and Power BI groups will be synced for this tenant.",
    "noGroups": "No Power BI groups. Add, sync or give permissions.",
    "noResourceGroups": "No Azure resource groups. Add, sync or give permissions.",
    "noServicePrincipal": "Tenant does not have a service principal"
  },
  "nl": {
    "title": "Azure en Power BI",
    "subtitle": "De Targets vertegenwoordigen de omgeving waar de Tap moet worden ingezet. In dit geval de Azure omgeving voor de resources en de Power BI groep voor de rapporten. Hier kun je resourcegroepen, resources en groepen deployen. Ook wordt hier de verbinding met het datawarehouse geconfigureerd op de resource. Het Target kan worden hergebruikt voor meerdere Taps. Door te klikken op een van de synchronisatieknoppen worden alle resourcegroepen, resources en Power BI-groepen gesynchroniseerd voor deze tenant.",
    "noGroups": "Geen Power BI groepen. Toevoegen, synchroniseren of rechten verlenen.",
    "noResourceGroups": "Geen Azure resource groepen. Toevoegen, synchroniseren of rechten verlenen.",
    "noServicePrincipal": "Tenant heeft geen service principal"
  },
  "de": {
    "title": "Azure und Power BI",
    "subtitle": "Die Ziele repräsentieren die Umgebung, in der der Tap bereitgestellt werden muss. In diesem Fall die Azure-Umgebung für die Ressourcen und die Power BI-Gruppe für die Berichte. Hier können Sie Ressourcengruppen, Ressourcen und Gruppen bereitstellen. Auch die Verbindung zum Data Warehouse wird hier auf der Ressource konfiguriert. Das Ziel kann für mehrere Taps wiederverwendet werden. Durch Klicken auf eine der Sync-Schaltflächen werden alle Ressourcengruppen, Ressourcen und Power BI-Gruppen für diesen Mandanten synchronisiert.",
    "noGroups": "Keine Power BI Gruppen. Hinzufügen, synchronisieren oder Berechtigungen erteilen.",
    "noResourceGroups": "Keine Azure Resource Gruppen. Hinzufügen, synchronisieren oder Berechtigungen erteilen.",
    "noServicePrincipal": "Tenant hat keinen service principal"
  }
}
</i18n>

<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col :class="['my-0', 'py-0']" cols="12" md="8">
          <v-card flat>
            <v-card-title
              :class="['font-weight-bold', 'text-h4']"
              style="word-break: break-word"
            >
              {{ $t("title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("subtitle") }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col :class="['my-0', 'py-0']" cols="12">
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col
                  :key="addOn?.node?.id"
                  cols="12"
                  md="4"
                  v-for="addOn in allAddOns.edges"
                >
                  <AddOnCard :addOn="addOn?.node" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import AddOnCard from "@/components/integrations/addOns/AddOnCard.vue";

export default {
  name: "add-ons-list",
  components: {
    AddOnCard,
  },

  apollo: {
    allAddOns: {
      query: gql`
        query allAddOns($first: Int) {
          allAddOns(first: $first, orderBy: ["name"]) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                externalId
                formData
                formFields
                name
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allAddOns,
    },
  },

  data: function () {
    return {
      allAddOns: {
        edges: [],
      },
      error: null,
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },

  watch: {},

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;
  },

  mounted() {},

  methods: {},
};
</script>

<i18n>
{
  "en": {
    "title": "Add-ons",
    "subtitle": "View and request new add-ons."
  },
  "nl": {
    "title": "Add-ons",
    "subtitle": "Bekijk en vraag nieuwe add-ons aan."
  },
  "de": {
    "title": "Add-ons",
    "subtitle": "Neue Add-ons ansehen und anfordern."
  }
}
</i18n>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.lgAndDown}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',[_vm._v(_vm._s(_vm.$tc("label.export", 1)))]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-1",attrs:{"icon":""},on:{"click":function($event){return _vm.closeDrawer()}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-divider',{staticClass:"mb-5"}),_c('v-row',{staticClass:"mb-10",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[(_vm.error)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"onSubmit":"return false;"},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{staticClass:"my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":[
												{
													name: 'PDF',
													format: 'pdf',
												},
												{
													name: 'Powerpoint',
													format: 'pptx',
												},
											],"label":_vm.$tc('label.type', 1),"rules":[_vm.rules.required],"item-key":"format","item-text":"name","item-value":"format","outlined":""},model:{value:(_vm.format),callback:function ($$v) {_vm.format=$$v},expression:"format"}})],1),_c('v-col',{staticClass:"mt-0 pt-0 pb-5",attrs:{"cols":"12"}},[_c('label',[_c('h4',[_vm._v(" "+_vm._s(_vm.$tc("label.export", 1))+" ")])]),_c('v-radio-group',{staticClass:"mt-1",attrs:{"hint":_vm.$t('hint'),"rules":[_vm.rules.required],"persistent-hint":"","row":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-radio',{attrs:{"label":_vm.$t('current'),"value":"CURRENT"}}),_c('v-radio',{attrs:{"label":_vm.$t('standard'),"value":"STANDARD"}})],1)],1),_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.reportPages,"label":_vm.$tc('label.page', 2),"rules":[_vm.rules.required],"clearable":"","item-text":"displayName","item-value":"name","multiple":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function({
													item,
													index,
												}){return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.displayName))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedPages.length - 1)+") ")]):_vm._e()]}}]),model:{value:(_vm.selectedPages),callback:function ($$v) {_vm.selectedPages=$$v},expression:"selectedPages"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","text":""},on:{"click":function($event){return _vm.closeDrawer()}}},[_vm._v(_vm._s(_vm.$t("label.cancel")))]),_c('v-btn',{attrs:{"disabled":!_vm.isFormValid,"loading":_vm.isSaving,"color":"primary","outlined":"","rounded":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$tc("label.export", 1)))])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<v-row justify="center">
		<v-dialog v-model="show" persistent max-width="600px">
			<v-card>
				<v-card-title class="title">
					<v-icon color="error" class="mr-2">warning</v-icon>
					<slot name="title">{{ $t("title") }}</slot>
				</v-card-title>
				<v-card-text>
					<slot name="content">{{ $t("content") }}</slot>
					<v-checkbox v-if="checkbox" v-model="tickbox" color="red">
						<template v-slot:label>
							<div>
								<slot name="checkboxLabel"></slot>
							</div>
						</template>
					</v-checkbox>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn text rounded @click="cancel">{{
						$t("label.cancel")
					}}</v-btn>
					<v-btn
						color="error"
						outlined
						rounded
						:loading="isDeleting"
						@click="confirmed"
						>{{ $t("label.delete") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog></v-row
	>
</template>

<script>
export default {
	name: "delete-dialog",
	props: ["dialog", "item", "checkbox"],
	data: function () {
		return {
			show: false,
			isDeleting: false,
			tickbox: false,
		};
	},
	watch: {
		dialog(val) {
			this.show = val;
			this.isDeleting = false;
		},
	},
	methods: {
		cancel() {
			this.show = false;
			this.$emit("update:dialog", false);
		},
		confirmed() {
			this.isDeleting = true;
			// this.show = false;
			this.$emit("confirmed", this.item, this.tickbox);
			// this.$emit("update:dialog", false);
		},
	},
};
</script>

<i18n>
{
  "en": {
	"title": "Delete item(s)",
	"content": "Are you sure you want to delete the selected item(s)?"
  },
  "nl": {
	"title": "Verwijder item(s)",
	"content": "Weet je zeker dat je de geselecteerde item(s) wilt verwijderen?"
  },
  "de": {
	"title": "Element(e) löschen",
	"content": "Möchten Sie das ausgewählte Element/die ausgewählten Elemente wirklich löschen?"
  }
}
</i18n>

<template>
  <v-chip
    :color="
      status == 'STARTED'
        ? 'info'
        : status == 'SUCCESS'
        ? 'success'
        : status == 'PUBLISHED'
        ? 'success'
        : status == 'FAILURE'
        ? 'error'
        : ''
    "
    small
    v-if="status"
  >
    <v-progress-circular
      :size="14"
      :width="1"
      class="ml-n1 mr-1"
      color="white"
      indeterminate
      v-if="status == 'STARTED'"
    ></v-progress-circular>
    <v-icon left small v-else>
      {{
        status == "SUCCESS"
          ? "check_circle"
          : status == "PUBLISHED"
          ? "check_circle"
          : status == "FAILURE"
          ? "cancel"
          : ""
      }}
    </v-icon>
    {{ status }}
  </v-chip>
</template>

<script>
export default {
  name: "status-chip",
  props: ["status"],
  components: {},

  apollo: {},

  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  methods: {},
};
</script>

<i18n>
{}
</i18n>

<template>
	<v-row justify="center">
		<v-dialog max-width="500px" persistent v-model="dialog">
			<v-card>
				<v-alert tile type="error" v-if="error">{{ error }}</v-alert>
				<v-card-title class="primary white--text">{{
					formTitle
				}}</v-card-title>
				<v-card-text class="pt-5">
					<v-form ref="form" onSubmit="return false;">
						<v-row>
							<v-col cols="12">
								<v-text-field
									:counter="255"
									:label="$tc('label.name', 1)"
									:rules="[
										rules.maxLength(255),
										rules.required,
									]"
									@keydown.enter="save"
									@submit.prevent
									class="subtitle-2"
									outlined
									v-model="editedItem.name"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn @click="close" rounded text>{{
						$t("label.cancel")
					}}</v-btn>
					<v-btn
						:loading="isSaving"
						@click="save"
						color="primary"
						outlined
						rounded
						>{{ $t("label.save") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
	name: "group-add-dialog",
	props: ["dialog", "object"],
	data() {
		return {
			editedItem: {},
			error: null,
			isSaving: false,
		};
	},
	computed: {
		formTitle() {
			return `${this.$t("label.add")} ${this.$tc("label.group", 1)}`;
		},
	},
	watch: {
		dialog() {
			this.resetForm();
		},
	},
	created() {
		this.rules = rules;
	},
	methods: {
		close() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		resetForm() {
			this.error = null;
			this.isSaving = false;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			if (this.dialog && this.object) {
				this.editedItem = this.object;
			} else {
				this.editedItem = {};
			}
		},

		save() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.error = null;
			this.isSaving = true;

			var payload = {
				name: this.editedItem.name,
			};

			this.$apollo
				.mutate({
					mutation: gql`
						mutation createGroup($input: CreateGroupInput!) {
							createGroup(input: $input) {
								group {
									id
								}
							}
						}
					`,
					variables: {
						input: payload,
					},
				})
				.then((response) => {
					this.$emit("added", response.data.createGroup.group);
					this.close();

					const payload = {
						color: "success",
						message: `Group successfully added`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					console.log(error);

					this.error = error.graphQLErrors
						.map((error) => error.message)
						.join(", ");
					this.isSaving = false;
				})
				.finally(() => {});
		},
	},
};
</script>

<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="1000px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          {{ $t("label.manual") }} {{ $tc("label.deployment", 1) }}
        </v-card-title>

        <v-card-text @click.right.prevent @copy.prevent @paste.prevent>
          {{ $t("title") }}
          <v-form ref="form" onSubmit="return false;" v-model="isFormValid">
            <v-row :class="['mt-5', 'py-0']">
              <v-col :class="['my-0', 'py-0']" cols="6">
                <v-select
                  :label="$tc('label.step', 1)"
                  :items="deploymentSteps"
                  item-value="task_name"
                  outlined
                  v-model="selectedDeploymentStep"
                >
                  <template slot="selection" slot-scope="{ item }">{{
                    item.display_name[$i18n.locale]
                  }}</template>
                  <template slot="item" slot-scope="{ item }">{{
                    item.display_name[$i18n.locale]
                  }}</template>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="save()"
            :class="['black--text', 'mr-2']"
            :disabled="!isFormValid"
            :loading="isSaving"
            rounded
            small
            text
          >
            {{ $t("label.confirm") }}
          </v-btn>
          <v-btn
            @click="close()"
            :color="'success'"
            :loading="isSaving"
            rounded
            small
          >
            {{ $t("label.back") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
  name: "tap-manual-dialog",
  components: {},
  props: ["connection", "dialog"],

  apollo: {},

  data() {
    return {
      deploymentSteps: [],
      error: null,
      isFormValid: false,
      isSaving: false,
      selectedDeploymentStep: null,
      tapNameConfirm: null,
    };
  },
  computed: {
    me() {
      return this.$store.state.user.me || {};
    },
  },
  watch: {
    dialog(val) {
      this.resetForm();
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    resetForm() {
      this.deploymentSteps = JSON.parse(this.connection?.tap?.deploymentSteps);
      this.error = null;
      this.isSaving = false;
      this.selectedDeploymentStep = null;
      this.tapNameConfirm = null;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        id: this.connection.id,
        deploymentStep: this.selectedDeploymentStep,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createConnectionDeploymentManual(
              $input: CreateConnectionDeploymentManualInput!
            ) {
              createConnectionDeploymentManual(input: $input) {
                connection {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Connection deployment successfully started`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          setTimeout(() => {
            this.$emit("deployed", response);
          }, 5000);

          this.close();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Select the step from which you want to start a new deployment. All other steps will be skipped. If you do not choose a step, the connection will still be successfully completed."
  },
  "nl": {
    "title": "Selecteer de stap vanaf waar je een nieuwe deployment wilt starten. Alle andere stappen worden overgeslagen. Kies je geen stap, dan wordt de connectie wel succesvol afgerond."
  },
  "de": {
    "title": "Wählen Sie den Schritt aus, ab dem Sie eine neue Bereitstellung starten möchten. Alle anderen Schritte werden übersprungen. Wenn Sie keinen Schritt auswählen, wird die Verbindung dennoch erfolgreich abgeschlossen."
  }
}
</i18n>

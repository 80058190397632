<template>
  <v-container fluid class="my-1">
    <v-expansion-panel class="mb-2">
      <v-expansion-panel-header>Orchest</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12" md="3" lg="4">
            <ControlPanelCard
              :title="$tc('cancelRuns.title')"
              :text="$tc('cancelRuns.description')"
              @open="cancelRuns($event)"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>


    <CancelRunsDialog :dialog.sync="dialog.cancelRuns" />
  </v-container>
</template>

<script>
import CancelRunsDialog from "@/components/integrations/controlPanel/orchest/CancelRunsDialog.vue";
import ControlPanelCard from "@/components/integrations/controlPanel/ControlPanelCard.vue";

export default {
  name: "orchest-panel",
  props: [],
  components: {
    CancelRunsDialog,
    ControlPanelCard,
  },

  apollo: {},

  data() {
    return {
      dialog: {
        cancelRuns: false,
      },
    };
  },
  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {},
  created() {},
  methods: {
    cancelRuns() {
      this.dialog.cancelRuns = true;
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "cancelRuns": {
        "title": "Cancel running pipelines",
        "description": "This dialog will allow you to cancel all running Orchest pipelines runs at once."
      }
    },
    "nl": {
      "cancelRuns": {
        "title": "Annuleer actieve pipelines",
        "description": "In dit menu kun je in één keer alle actieve pipeline runs annuleren."
      }
    },
    "de": {
      "cancelRuns": {
        "title": "Aktieve Pipelines abbrechen",
        "description": "Dieser Dialog ermöglicht es Ihnen, alle laufenden Orchest-Pipelines auf einmal zu stoppen."
      }
    }
  }
</i18n>

<template>
  <v-container fluid class="my-1">
    <v-expansion-panel class="mb-2">
      <v-expansion-panel-header>AFAS</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="12" md="3" lg="4">
            <ControlPanelCard
              :title="$tc('createProjects.title')"
              :text="$tc('createProjects.description')"
              @open="createProjects($event)"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>


    <CreateProjectsDialog :dialog.sync="dialog.createProjects" />
  </v-container>
</template>

<script>
import CreateProjectsDialog from "@/components/integrations/controlPanel/afas/CreateProjectsDialog.vue";
import ControlPanelCard from "@/components/integrations/controlPanel/ControlPanelCard.vue";

export default {
  name: "afas-panel",
  props: [],
  components: {
    CreateProjectsDialog,
    ControlPanelCard,
  },

  apollo: {},

  data() {
    return {
      dialog: {
        createProjects: false,
      },
    };
  },
  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {},
  created() {},
  methods: {
    createProjects() {
      this.dialog.createProjects = true;
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "createProjects": {
        "title": "Create projects",
        "description": "Create an AFAS project for all issues in the JIRA sprints and sync the new ID back to JIRA."
      }
    },
    "nl": {
      "createProjects": {
        "title": "Projecten aanmaken",
        "description": "Maak een AFAS project aan voor alle issues in de JIRA sprints en synchroniseer de nieuwe ID terug naar JIRA."
      }
    },
    "de": {
      "createProjects": {
        "title": "Projekte erstellen",
        "description": "Erstellen Sie ein AFAS-Projekt für alle Issues in den JIRA-Sprints und synchronisieren Sie die neue ID zurück nach JIRA."
      }
    }
  }
</i18n>

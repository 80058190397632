import Vue from "vue";
import VueApollo from "vue-apollo";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import {
	ApolloClient,
	createHttpLink,
	from,
	fromPromise,
	InMemoryCache,
} from "@apollo/client/core";
import { relayStylePagination } from "@apollo/client/utilities";
import {
	REFRESH_TOKEN_MUTATION,
	REVOKE_TOKEN_MUTATION,
} from "./graphql/user.js";
import router from "./router";
import { createUploadLink } from "apollo-upload-client";

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
export const ACCESS_TOKEN_KEY = "access-token";
export const REFRESH_TOKEN_KEY = "refresh-token";

// Note that the relayStylePagination function generates a field policy with a read function
// that simply returns all available data, ignoring args, which makes relayStylePagination easier
// to use with fetchMore. This is a non-paginated read function.
// There's nothing stopping you from adapting this read function to use args to return individual
// pages, as long as you remember to update the variables of your original query after calling fetchMore.

const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				notifications: relayStylePagination(),
				knowledgeBases: relayStylePagination(),
			},
		},
		TenantNodeConnection: {
			merge: true,
		},
	},
});

const httpLink = createUploadLink({
	uri:
		process.env.NODE_ENV === "production"
			? "/graphql/"
			: "http://localhost:8000/graphql/",
	// credentials: 'include',
});

const authLink = setContext(async (operation, { headers }) => {
	const token = localStorage.getItem(ACCESS_TOKEN_KEY);

	if (!token) {
		return {
			headers: {
				...headers,
			},
		};
	} else {
		return {
			headers: {
				...headers,
				authorization: `JWT ${token}`,
			},
		};
	}
});

//////////////////////////////////////
let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
	pendingRequests.map((callback) => callback());
	pendingRequests = [];
};

const errorLink = onError(
	({ graphQLErrors, networkError, operation, forward }) => {
		if (graphQLErrors) {
			for (let err of graphQLErrors) {
				switch (err.message) {
					case "Signature has expired": {
						let forward$;

						// const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

						if (!isRefreshing) {
							// if (!refreshToken){
							//     pendingRequests = [];
							//     onLogout(apolloClient);
							//     return false;
							// }

							isRefreshing = true;
							forward$ = fromPromise(
								apolloClient
									.mutate({
										mutation: REFRESH_TOKEN_MUTATION,
										variables: {
											refreshToken:
												localStorage.getItem(
													REFRESH_TOKEN_KEY
												) || "",
										},
									})
									.then((response) => {
										const { token, refreshToken } =
											response.data.refreshToken;

										if (
											typeof localStorage !== "undefined"
										) {
											if (token) {
												localStorage.setItem(
													ACCESS_TOKEN_KEY,
													token
												);
											}
											if (refreshToken) {
												localStorage.setItem(
													REFRESH_TOKEN_KEY,
													refreshToken
												);
											}
										}
										if (
											process.env.NODE_ENV !==
											"production"
										) {
											console.log("Token Refreshed!");
										}
										return true;
									})
									.then(() => {
										resolvePendingRequests();
										return true;
									})
									.catch(() => {
										pendingRequests = [];
										return false;
									})
									.finally(() => {
										isRefreshing = false;
									})
							);
						} else {
							forward$ = fromPromise(
								new Promise((resolve) => {
									pendingRequests.push(() => resolve());
								})
							);
						}
						return forward$.flatMap(() => forward(operation));
					}
					case "Refresh token is expired": {
						if (process.env.NODE_ENV !== "production") {
							console.log("Refresh token is expired, logout!");
						}

						pendingRequests = [];
						onLogout(apolloClient);

						// push to login page
						if (router.currentRoute.name !== "login") {
							router.push("/login");
							router.go(0);
						}
						return;
					}
					// case "You do not have permission to perform this action": {
					//     if (process.env.NODE_ENV !== "production") {
					//         console.log("Refresh token is expired, logout!");
					//     }

					//     pendingRequests = [];
					//     onLogout(apolloClient);

					//     // push to login page
					//     if (router.currentRoute.name !== "login") {
					//         router.push("/login");
					//     }
					//     return;
					// }
					default:
						if (process.env.NODE_ENV !== "production") {
							console.log(
								`[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
							);
						}
				}
			}
		}

		if (networkError) {
			console.log(`[Network error]: ${networkError}`);
		}
	}
);

//////////////////////////////////////

export const apolloClient = new ApolloClient({
	link: from([errorLink, authLink, httpLink]),
	cache,
});

// Call this in the Vue app file
export function createProvider() {
	// Create vue apollo provider
	const apolloProvider = new VueApollo({
		clients: {
			apolloClient,
		},
		defaultClient: apolloClient,
		defaultOptions: {
			$query: {
				// fetchPolicy: 'cache-and-network',
			},
		},
		errorHandler(error) {
			// eslint-disable-next-line no-console
			if (process.env.NODE_ENV !== "production") {
				console.log(
					"%cError",
					"background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
					error.message
				);
			}
		},
	});

	return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(apolloClient, accessToken, refreshToken) {
	if (typeof localStorage !== "undefined") {
		if (accessToken) {
			localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
		}
		if (refreshToken) {
			localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
		}
	}

	try {
		await apolloClient.resetStore();
	} catch (e) {
		// eslint-disable-next-line no-console
		if (process.env.NODE_ENV !== "production") {
			console.log(
				"%cError on cache reset (login)",
				"color: orange;",
				e.message
			);
		}
	}
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
	if (typeof localStorage !== "undefined") {
		//TODO: revoke token
		if (localStorage.getItem(REFRESH_TOKEN_KEY)) {
			apolloClient
				.mutate({
					mutation: REVOKE_TOKEN_MUTATION,
					variables: {
						refreshToken:
							localStorage.getItem(REFRESH_TOKEN_KEY) || "",
					},
				})
				.then((response) => {
					const { revoked } = response.data.revokeToken;

					if (process.env.NODE_ENV !== "production") {
						console.log("RefreshToken is revoked! ", revoked);
					}

					return true;
				})
				.catch(() => {
					pendingRequests = [];
					return false;
				})
				.finally(() => {
					// clear localStorage
					localStorage.removeItem(ACCESS_TOKEN_KEY);
					localStorage.removeItem(REFRESH_TOKEN_KEY);
				});
		}
	}

	try {
		await apolloClient.resetStore();
	} catch (e) {
		// eslint-disable-next-line no-console
		if (process.env.NODE_ENV !== "production") {
			console.log(
				"%cError on cache reset (logout)",
				"color: orange;",
				e.message
			);
		}
	}
}

import app from "@/store/modules/app";
import controlPanel from "@/store/modules/integrations/controlPanel/controlPanel";
import groupDetail from "@/store/modules/users/groupDetail";
import groupsList from "@/store/modules/users/groupsList";
import partnersList from "@/store/modules/users/partnersList";
import reportGroupsList from "@/store/modules/reports/reportGroupsList";
import reports from "@/store/modules/reports/reports";
import reportsList from "@/store/modules/reports/reportsList";
import settings from "@/store/modules/users/settings";
import snackbar from "@/store/modules/snackbar";
import taps from "@/store/modules/integrations/taps/taps";
import targets from "@/store/modules/integrations/targets/targets";
import tenantsList from "@/store/modules/users/tenantsList";
import themesList from "@/store/modules/users/themesList";
import user from "@/store/modules/user";
import usermappingsList from "@/store/modules/reports/usermappingsList";
import users from "@/store/modules/users/users";
import usersList from "@/store/modules/users/usersList";

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		app,
		controlPanel,
		groupDetail,
		groupsList,
		partnersList,
		reportGroupsList,
		reports,
		reportsList,
		settings,
		snackbar,
		taps,
		targets,
		tenantsList,
		themesList,
		user,
		usermappingsList,
		users,
		usersList,
	},
});

<template>
  <BaseDialog
    :dialog="dialog"
    :error="error"
    :persistent="isLoggedInAsOtherUser ? false : true"
    @close="close($event)"
  >
    <template v-slot:title> {{ $t("title") }} </template>

    <template v-slot:text v-if="error">
      <p v-if="error == '2FA already enabled'">
        {{ $t("alreadyEnabled") }}
      </p>

      <p>
        support@taptarget.io <br />
        +31 174 705 635
      </p>
    </template>

    <template v-slot:text v-else-if="!secretKey">
      {{ $t("preEnableDescription") }}
      <BaseButton
        :block="true"
        :loading="isSaving"
        @click="enableTfa()"
        class="mt-5"
        size="large"
      >
        {{ $t("label.start") }}
      </BaseButton>
    </template>

    <template v-slot:text v-else>
      <p>
        {{ $t("description") }}
      </p>

      <p class="subtitle-2 mt-5">
        {{ $t("step1") }}
      </p>

      <v-row justify="center">
        <v-col cols="12" class="d-flex justify-center">
          <qrcode-vue :value="qrCodeValue" size="300" level="H" />
        </v-col>
      </v-row>

      <p class="subtitle-2 mt-5">
        {{ $t("step2") }}
      </p>

      <v-form ref="form">
        <v-row justify="center">
          <v-col cols="8">
            <div class="ma-auto position-relative" style="max-width: 300px">
              <v-otp-input
                :disabled="isSaving"
                @finish="save"
                ref="tfaCode"
                v-model="tfaCode"
              ></v-otp-input>
              <v-overlay :value="isSaving" absolute>
                <v-progress-circular
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-overlay>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/base/BaseDialog.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import Config from "@/utils/config.json";
import gql from "graphql-tag";
import QrcodeVue from "qrcode.vue";

export default {
  name: "tfa-persistent-dialog",
  components: {
    BaseButton,
    BaseDialog,
    Config,
    QrcodeVue,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      secretKey: null,
      tfaCode: null,
      error: null,
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },

    qrCodeValue() {
      if (!this.me) {
        return "";
      }

      return (
        "otpauth://totp/TapTarget%20portal?secret=" +
        this.secretKey +
        "&issuer=" +
        encodeURIComponent(this.me.email)
      );
    },

    isLoggedInAsOtherUser() {
      return this.$store.state.user.loggedInAsOtherUser;
    },
  },

  watch: {
    secretKey(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.tfaCode.focus();
        });
      }
    },
  },

  created() {},

  mounted() {},

  methods: {
    enableTfa() {
      this.error = null;
      this.isSaving = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation enable2fa {
              enable2fa(input: {}) {
                secretKey
              }
            }
          `,
        })
        .then((response) => {
          this.secretKey = response.data.enable2fa.secretKey;
          this.isSaving = false;
        })
        .catch((error) => {
          this.error = error.graphQLErrors[0].message;
          this.isSaving = false;
        })
        .finally(() => {});
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        tfaCode: this.tfaCode,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation verify2fa($input: Verify2FAInput!) {
              verify2fa(input: $input) {
                success
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const success = response.data.verify2fa.success;

          if (success) {
            const payload = {
              color: "success",
              message: `2FA successfully verified`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);

            this.$router.go();
          } else {
            this.error = "Invalid 2FA code";
            this.isSaving = false;
          }
        })
        .catch((error) => {
          this.error = error.graphQLErrors[0].message;
          this.isSaving = false;
        })
        .finally(() => {});
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Enable two-factor authentication",
    "preEnableDescription": "For enhanced security, your account requires two-factor authentication (2FA). This adds an extra layer of protection to ensure that only you can access your account. To start the 2FA verification process, please click the button below. Do not refresh or close the page before completing the process.",
    "description": "Please follow these steps to complete the two-factor authentication registration. Do not refresh or close the page before validating the QR-Code.",
    "step1": "Step 1. Scan this QR-Code with Google Authenticator or Microsoft Authenticator",
    "step2": "Step 2. Verify the code from your authenticator",
    "alreadyEnabled": "The 2FA is enabled but was not verified. Contact your administrator or TapTarget support to reset your 2FA."
  },
  "nl": {
    "title": "Schakel twee-factor-authenticatie in",
    "preEnableDescription": "Voor extra veiligheid vereist jouw account twee-factor-authenticatie (2FA). Dit voegt een extra beschermingslaag toe om ervoor te zorgen dat alleen jij toegang heeft tot jouw account. Om het 2FA-verificatieproces te starten, klikt u op de onderstaande knop. Ververs of sluit de pagina niet voordat het proces hebt afgerond.",
    "description": "Volg deze stappen om de registratie voor tweestapsverificatie te voltooien. Ververs of sluit de pagina niet voordat je de QR-code hebt gevalideerd.",
    "step1": "Stap 1. Scan deze QR-code met Google Authenticator of Microsoft Authenticator",
    "step2": "Stap 2. Verifieer de code vanuit jouw authenticator",
    "alreadyEnabled": "De 2FA is ingeschakeld, maar niet geverifieerd. Neem contact op met je beheerder of TapTarget support om jouw 2FA opnieuw in te stellen."
  },
  "de": {
    "title": "Zwei-Faktor-Authentifizierung aktivieren",
    "preEnableDescription": "Für erhöhte Sicherheit erfordert Ihr Konto eine Zwei-Faktor-Authentifizierung (2FA). Dies fügt eine zusätzliche Schutzschicht hinzu, um sicherzustellen, dass nur Sie auf Ihr Konto zugreifen können. Um den 2FA-Verifizierungsprozess zu starten, klicken Sie bitte auf die Schaltfläche unten.  Bitte die Seite nicht aktualisieren oder schließen, bevor der QR-Code überprüft wurde.",
    "description": "Folgen Sie diesen Schritten, um die Registrierung für die Zwei-Faktor-Authentifizierung abzuschließen. Bitte die Seite nicht aktualisieren oder schließen, bevor der QR-Code überprüft wurde.",
    "step1": "Schritt 1. Scannen Sie diesen QR-Code mit Google Authenticator oder Microsoft Authenticator",
    "step2": "Schritt 2. Überprüfen Sie den Code in Ihrer Authenticator-App",
    "alreadyEnabled": "Die 2FA ist aktiviert, wurde jedoch nicht verifiziert. Kontaktieren Sie Ihren Administrator oder den TapTarget-Support, um Ihre 2FA zurückzusetzen."
  }
}
</i18n>

<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="6" lg="5" xl="4">
        <v-card class="elevation-1 rounded-xl">
          <v-toolbar color="primary"
            ><v-btn icon large>
              <v-avatar size="32px" item>
                <v-img src="/static/taptarget-logo-white.png"> </v-img>
              </v-avatar>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title class="white--text font-weight-bold">
              TapTarget
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu
              offset-y
              origin="center center"
              :nudge-bottom="10"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <v-btn dark icon v-on="on">
                  <v-icon> translate </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :key="item.locale"
                  @click="changeLocale(item.locale)"
                  v-for="item in locales"
                >
                  <v-list-item-title>{{ item.localeName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>

          <v-card-title
            :class="['font-weight-medium', 'text-h5', 'mx-5 mt-5']"
            style="word-break: break-word"
          >
            {{ $t("title") }}
          </v-card-title>

          <v-card-subtitle :class="['mx-5']">
            {{ $t("subtitle") }}
          </v-card-subtitle>

          <v-card-text>
            <v-form
              ref="form"
              onSubmit="return false;"
              v-if="identityProvider"
              v-model="isFormValid"
            >
              <v-row class="mx-2">
                <v-col :class="['mb-0', 'pb-0']" cols="12">
                  <v-text-field
                    :class="['mt-3']"
                    :label="$t('label.email')"
                    :rules="[rules.required, rules.email]"
                    autofocus
                    outlined
                    ref="email"
                    type="email"
                    v-model="email"
                  ></v-text-field>
                </v-col>

                <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                  <v-text-field
                    :label="$t('label.firstName')"
                    :rules="[rules.required]"
                    outlined
                    v-model="firstName"
                  ></v-text-field>
                </v-col>

                <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                  <v-text-field
                    :label="$t('label.lastName')"
                    :rules="[rules.required]"
                    outlined
                    v-model="lastName"
                  ></v-text-field>
                </v-col>

                <v-col :class="['my-0', 'py-0']" cols="12">
                  <v-text-field
                    :label="`${$tc('label.company', 1)} ${$tc(
                      'label.name',
                      1
                    )}`"
                    :rules="[rules.required]"
                    outlined
                    v-model="companyName"
                  ></v-text-field>
                </v-col>

                <v-col
                  :class="['my-0', 'py-0']"
                  cols="12"
                  v-if="identityProvider === 'TAPTARGET'"
                >
                  <v-text-field
                    :label="$tc('label.password', 1)"
                    :rules="[
                      rules.maxLength(100),
                      rules.required,
                      rules.strength,
                    ]"
                    :type="showPassword ? 'text' : 'password'"
                    @keydown.enter="null"
                    outlined
                    v-model="password"
                  >
                    <template v-slot:append>
                      <v-icon
                        @click="showPassword = !showPassword"
                        color="primary"
                        tabindex="-1"
                        >{{
                          showPassword ? "visibility" : "visibility_off"
                        }}</v-icon
                      >
                    </template>
                  </v-text-field>

                  <v-progress-linear
                    :class="['mb-7']"
                    :color="strengthScore(password).color"
                    :value="strengthScore(password).value"
                  ></v-progress-linear>
                </v-col>

                <v-col
                  :class="['my-0', 'py-0']"
                  cols="12"
                  v-if="identityProvider === 'TAPTARGET'"
                >
                  <v-text-field
                    :label="`${$t('label.confirm')} ${$t('label.new')} ${$tc(
                      'label.password',
                      1
                    )}`"
                    :rules="[rules.required, rules.matching(this.password)]"
                    :type="showPassword ? 'text' : 'password'"
                    @keydown.enter="null"
                    outlined
                    v-model="passwordConfirm"
                  >
                    <template v-slot:append>
                      <v-icon
                        @click="showPassword = !showPassword"
                        color="primary"
                        tabindex="-1"
                        >{{
                          showPassword ? "visibility" : "visibility_off"
                        }}</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>

                <v-col :class="['my-0', 'py-0']" cols="12">
                  <v-autocomplete
                    :hint="$t('partnerHint')"
                    :items="allPartners.edges"
                    :label="$tc('label.partner', 1)"
                    :loading="$apollo.queries.allPartners.loading"
                    cache-items
                    chips
                    class="mb-2 mt-1 subtitle-2"
                    clearable
                    item-text="node.name"
                    item-value="node.id"
                    multiple
                    outlined
                    persistent-hint
                    small-chips
                    v-model="selectedPartners"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col :class="['my-0', 'py-0']" cols="12">
                  {{ $t("termsCaption") }}
                </v-col>

                <v-col :class="['my-0', 'py-0']" cols="12">
                  <v-checkbox
                    @change="selectAllCheckboxes()"
                    v-model="selectAll"
                  >
                    <template v-slot:label>
                      <v-row>
                        <v-col v-if="!selectAll" cols="12">
                          {{ $t("label.selectAll") }}
                        </v-col>
                        <v-col v-else cols="12">
                          {{ $t("label.deselectAll") }}
                        </v-col>
                      </v-row>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col
                  :class="['my-0', 'py-0']"
                  :key="index"
                  cols="12"
                  v-for="(term, index) in acceptedTerms"
                >
                  <v-checkbox :class="['ma-0', 'pa-0']" v-model="term.accept">
                    <template v-slot:label>
                      <v-row>
                        <v-col cols="12" md="9">
                          <!-- {{ term.translations[0].name }} -->
                          {{ term?.fieldData?.name }}
                        </v-col>
                        <v-col cols="12" md="3">
                          <a
                            :class="[
                              'blue--text',
                              'row-pointer',
                              'text-decoration-underline',
                            ]"
                            :href="term?.fieldData?.bestanden?.url"
                            @click.stop
                            target="_blank"
                          >
                            {{ $t("label.download") }}
                          </a>
                        </v-col>
                      </v-row>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col cols="12">
                  {{ $t("downloadAdobePrefix") }}
                  <a
                    :class="['blue--text', 'text-decoration-underline']"
                    @click.stop
                    href="https://www.adobe.com/nl/acrobat/pdf-reader.html"
                    target="_blank"
                  >
                    {{ $t("downloadAdobeMiddle") }}</a
                  >
                  {{ $t("downloadAdobeSuffix") }}
                </v-col>

                <v-col :class="['mt-0', 'pt-0']" cols="12">
                  <v-btn
                    :disabled="!isFormValid || !isAllTermsSelected"
                    :loading="loading"
                    @click="signUp()"
                    block
                    color="primary"
                    large
                    rounded
                  >
                    {{ $t("createAccount") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-row class="mx-2" v-else>
              <v-col cols="12">
                <v-btn
                  @click="setIdentityProvider('TAPTARGET')"
                  block
                  color="primary"
                  large
                  rounded
                  outlined
                >
                  <v-icon class="pr-2" color="black" left> mail </v-icon>
                  {{ $t("continueWithEmail") }}
                </v-btn>
              </v-col>
              <v-col :class="['mt-0', 'pt-0']" cols="12">
                <v-btn
                  @click="signUpWithAzureAD('')"
                  block
                  color="primary"
                  large
                  rounded
                  outlined
                >
                  <v-btn :class="['pa-2', 'mr-1']" icon>
                    <v-img
                      src="https://ltpbiportal.blob.core.windows.net/media/images/microsoft-logo.svg"
                      width="20"
                    ></v-img>
                  </v-btn>
                  {{ $t("continueWithMicrosoft") }}
                </v-btn>
              </v-col>
            </v-row>

            <div class="mt-5 mx-5">
              {{ $t("alreadyHaveAccount") }}
              <span
                @click="goToLogin()"
                class="blue--text row-pointer text-decoration-underline"
                >{{ $t("label.login") }}</span
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import msalAuth from "@/utils/msalAuth.js";
import rules from "@/utils/rules.js";

export default {
  name: "sign-up",
  props: {
    emailProp: {
      type: String,
      default: "",
    },
    identityProviderProp: {
      type: String,
      default: null,
    },
    msalAccessTokenProp: {
      type: String,
      default: null,
    },
  },
  apollo: {
    allPartners: {
      query: gql`
        query allPartners {
          allPartners(orderBy: ["name"]) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.allPartners,
      skip: false,
    },

    webflowTerms: {
      query: gql`
        query allWebflowItems($collectionId: String!) {
          allWebflowItems(collectionId: $collectionId) {
            items {
              id
              cmsLocaleId
              createdOn
              fieldData
              isArchived
              isDraft
              lastPublished
              lastUpdated
            }
          }
        }
      `,
      variables() {
        return {
          collectionId: "659ff21c34b2a7010495626e",
        };
      },
      update: (data) => data.allWebflowItems,
      result() {
        this.acceptedTerms = this.webflowTerms.items
          .filter((v) => v.fieldData.hasOwnProperty("bestanden"))
          .map((v) => ({
            ...v,
            accept: false,
          }));
      },
    },
  },

  data: function () {
    return {
      allPartners: {},
      companyName: "",
      email: this.emailProp,
      error: "",
      firstName: "",
      identityProvider: this.identityProviderProp,
      isFormValid: false,
      lastName: "",
      loading: false,
      locales: [
        {
          locale: "en",
          localeName: "English",
        },
        {
          locale: "nl",
          localeName: "Nederlands",
        },
        {
          locale: "de",
          localeName: "Deutsch",
        },
      ],
      msalAccessToken: this.msalAccessTokenProp,
      password: "",
      passwordConfirm: "",
      selectAll: false,
      selectedPartners: [],
      showPassword: false,
      acceptedTerms: [],
      webflowTerms: {},
    };
  },
  watch: {
    // Reset the password confirmation if original changes
    password(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.passwordConfirm = "";
      }
    },
  },

  created() {
    helper.setupMsalAuth();
    this.rules = rules;
    this.strengthScore = helper.strengthScore;
  },
  computed: {
    // check if all terms are accepted
    isAllTermsSelected() {
      return this.acceptedTerms.every((i) => i.accept);
    },
  },
  methods: {
    changeLocale(locale) {
      localStorage.setItem("locale", locale);
      this.$i18n.locale = locale;
    },

    fetchMe() {
      this.$store
        .dispatch("user/fetchMe")
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },

    goToLogin() {
      this.$router.push({
        name: "login",
        query: this.$route.query,
      });
    },

    login() {
      this.error = null;

      this.loading = true;

      const payload = { email: this.email, password: this.password };

      this.$store
        .dispatch("user/login", payload)
        .then(() => {
          this.fetchMe();
          this.$router.push(this.$route.query.redirect || "/taps");
        })
        .catch((error) => {
          if (error.graphQLErrors) {
            for (let err of error.graphQLErrors) {
              /*2fa*/
              if (err.message === "2FA") {
                this.tfaDialog = true;
              } else {
                this.error = err.message;
              }
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    loginWithAzureAD() {
      this.error = null;

      this.loading = true;

      const payload = { msalAccessToken: this.msalAccessToken };

      this.$store
        .dispatch("user/loginWithAzureAd", payload)
        .then(() => {
          this.fetchMe();
          this.$router.push(this.$route.query.redirect || "/taps");
          msalAuth.clearLocal();
        })
        .catch((error) => {
          if (error.graphQLErrors) {
            for (let err of error.graphQLErrors) {
              /*2fa*/
              if (err.message === "2FA") {
                this.tfaDialog = true;
              } else {
                this.error = err.message;
              }
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async signUpWithAzureAD(email) {
      this.loading = true;

      await msalAuth.login(email);
      const msalUser = msalAuth.user();

      this.msalAccessToken = await msalAuth.acquireToken();
      this.email = msalUser.username;
      this.identityProvider = "MICROSOFT_AZURE_AD";

      this.loading = false;
    },

    selectAllCheckboxes() {
      if (this.selectAll) {
        this.acceptedTerms.map((i) => (i.accept = true));
      } else {
        this.acceptedTerms.map((i) => (i.accept = false));
      }
    },

    setIdentityProvider(identityProvider) {
      this.identityProvider = identityProvider;
    },

    signUp() {
      this.error = null;
      this.loading = true;

      if (!this.$refs.form.validate()) {
        return;
      }

      var payload = {
        companyName: this.companyName,
        email: this.email,
        firstName: this.firstName,
        identityProvider: this.identityProvider,
        lastName: this.lastName,
        partners: this.selectedPartners,
        password: this.password,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation signUp($input: SignUpInput!) {
              signUp(input: $input) {
                user {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `User successfully created`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          switch (this.identityProvider) {
            case "TAPTARGET":
              this.login();
              break;
            case "MICROSOFT_AZURE_AD":
              this.loginWithAzureAD();
              break;
            default:
              this.error = "Identity provider not found";
              break;
          }
        })
        .catch((error) => {
          this.error = error.graphQLErrors[0].message;

          const payload = {
            color: "error",
            message: this.error,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
	"alreadyHaveAccount": "Already have a TapTarget account?",
	"continueWithEmail": "Continue with email",
	"continueWithMicrosoft": "Continue with Microsoft",
	"createAccount": "Create TapTarget account",
	"downloadAdobeMiddle": "here.",
	"downloadAdobePrefix": "To open the documents, you need a recent version of Adobe Acrobat Reader. Download the latest version for free ",
	"downloadAdobeSuffix": " ",
	"partnerHint": "You can automatically share your dashboards with our partners by selecting them here. If you don't want to do this, leave this field empty.",
	"subtitle": "One more step before you start your free trial",
	"termsCaption": "By checking the box, you agree that the document referenced next to the checkbox applies to the agreement between you and TapTarget B.V. The documents can be accessed and saved directly by clicking the 'Download' button next to the document.",
	"title": "Create a TapTarget account"
  },
  "nl": {
	"alreadyHaveAccount": "Heb je al een TapTarget account?",
	"continueWithEmail": "Doorgaan met email",
	"continueWithMicrosoft": "Doorgaan met Microsoft",
	"createAccount": "TapTarget account maken",
	"downloadAdobeMiddle": "hier ",
	"downloadAdobePrefix": "Voor het openen van de documenten heeft u een recente versie van Adobe Acrobat Reader nodig. Download ",
	"downloadAdobeSuffix": "gratis de laatste versie.",
	"partnerHint": "Je kunt jouw dashboards automatisch delen met onze partners door deze hier te selecteren. Wil je dit niet, laat dit veld dan leeg.",
	"subtitle": "Nog één stap voordat je gratis proefperiode start",
	"termsCaption": "Je gaat er door het zetten van een vinkje in het hokje mee akkoord dat het document vermeld achter het hokje van toepassing is op de overeenkomst tussen jou en TapTarget B.V. De documenten zijn direct te raadplegen en op te slaan door op de knop 'Download', vermeld achter het document, te klikken.",
	"title": "Een TapTarget account maken"
  },
  "de": {
	"alreadyHaveAccount": "Hast du bereits ein TapTarget Account?",
	"continueWithEmail": "Mit E-Mail fortfahren",
	"continueWithMicrosoft": "Mit Microsoft fortfahren",
	"createAccount": "Ein TapTarget Account erstellen",
	"downloadAdobeMiddle": "hier ",
	"downloadAdobePrefix": "Um die Dokumente zu öffnen, benötigen Sie eine aktuelle Version von Adobe Acrobat Reader. Laden Sie ",
	"downloadAdobeSuffix": "die neueste Version kostenlos herunter.",
	"partnerHint": "Du kannst deine Dashboards automatisch mit unseren Partnern teilen, indem du sie hier auswählst. Wenn du dies nicht möchtest, lasse dieses Feld leer.",
	"subtitle": "Noch ein Schritt, bevor du deine kostenlose Testphase beginnst",
	"termsCaption": "Durch das Ankreuzen des Kästchens erklärst du dich damit einverstanden, dass das Dokument, das neben dem Kästchen aufgeführt ist, für die Vereinbarung zwischen dir und TapTarget B.V. gilt. Die Dokumente können direkt über den 'Download'-Button neben dem Dokument abgerufen und gespeichert werden.",
	"title": "Ein TapTarget Account erstellen"
  }
}
</i18n>

<style scoped>
.row-pointer {
  cursor: pointer;
}
</style>

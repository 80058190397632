<template>
  <v-container fluid>
    <v-row class="align-center pl-3">
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
      <v-spacer />
    </v-row>

    <v-container :fluid="$vuetify.breakpoint.lgAndDown">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-tabs v-model="tabs">
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab v-if="me.isSuperuser">{{
              $tc("label.inProgress", 2)
            }}</v-tab>
            <v-tab v-if="me.isSuperuser">{{ $tc("label.release", 2) }}</v-tab>
            <v-tab v-if="hasPermission('integrations.view_connection')">{{
              $tc("label.tap", 2)
            }}</v-tab>
            <v-tab v-if="hasPermission('integrations.view_run') && !me.isPartnerUser">{{
              $tc("label.run", 2)
            }}</v-tab>
            <v-spacer />

            <v-btn
              @click="add()"
              class="mt-2 ml-2"
              color="primary"
              dark
              outlined
              rounded
              small
              v-if="me.isSuperuser && tabs == 1"
              >{{ $t("label.add") }}</v-btn
            >
          </v-tabs>
          <v-divider></v-divider>

          <v-tabs-items v-model="tabs">
            <v-tab-item v-if="me.isSuperuser">
              <ConnectionsInProgressList ref="ConnectionsInProgressList" />
            </v-tab-item>
            <v-tab-item v-if="me.isSuperuser">
              <ReleasesList ref="ReleasesList" />
            </v-tab-item>
            <v-tab-item v-if="hasPermission('integrations.view_connection')">
              <ConnectionsList ref="ConnectionsList" />
            </v-tab-item>
            <v-tab-item v-if="hasPermission('integrations.view_run') && !me.isPartnerUser">
              <RunsList ref="RunsList" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import ConnectionsInProgressList from "@/components/integrations/taps/ConnectionsInProgressList.vue";
import ConnectionsList from "@/components/integrations/taps/ConnectionsList.vue";
import helper from "@/utils/helper.js";
import ReleasesList from "@/components/integrations/taps/ReleasesList.vue";
import RunsList from "@/components/integrations/taps/RunsList.vue";

export default {
  name: "taps",
  components: {
    ReleasesList,
    ConnectionsInProgressList,
    ConnectionsList,
    RunsList,
  },

  apollo: {},

  data: function () {
    return {};
  },

  computed: {
    breadcrumbs() {
      let breadcrumbs = [
        {
          text: this.$t("label.home"),
          exact: true,
          disable: false,
          to: {
            name: "home",
          },
        },
        {
          text: this.$tc("label.tap", 2),
          disabled: true,
        },
      ];
      return breadcrumbs;
    },

    me() {
      return this.$store.state.user.me;
    },

    tabs: {
      get() {
        return this.$store.getters["taps/getTabs"];
      },
      set(value) {
        this.$store.dispatch("taps/setTabs", value);
      },
    },
  },

  watch: {},

  created() {
    this.hasPermission = helper.hasPermission;
  },

  mounted() {},

  methods: {
    add() {
      switch (this.tabs) {
        case 1:
          this.$refs.ReleasesList.add();
          break;
        default:
          break;
      }
    },
  },
};
</script>

<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="1300px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          {{ formTitle }}
          <v-spacer></v-spacer>
          <v-btn icon @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-row class="my-0 py-0">
          <v-col :class="['my-0', 'py-0']" cols="12">
            <v-stepper flat v-model="step" vertical>
              <v-stepper-step :complete="step > 1" step="1">
                {{ $t("label.select") }} {{ $tc("label.tap", 1) }}
                <small v-if="step > 1">{{ selectedTap?.node?.name }}</small>
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-card flat>
                  <v-card-text>
                    <v-row class="my-0 py-0">
                      <v-col cols="12" md="4" class="my-0 py-0">
                        <v-autocomplete
                          :items="allTaps.edges"
                          :label="$tc('label.tap', 1)"
                          :loading="$apollo.queries.allTaps.loading"
                          :rules="[rules.required]"
                          item-text="node.name"
                          item-value="node.id"
                          outlined
                          return-object
                          v-model="selectedTap"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :color="'primary'"
                      :disabled="!selectedTap"
                      @click="step = 2"
                      rounded
                      small
                    >
                      {{ $t("label.next") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-step :complete="step > 2" step="2">
                {{ $t("label.select") }} {{ $tc("label.type", 1) }}
                <small v-if="step > 2"
                  >{{ selectedType }}</small
                >
              </v-stepper-step>

              <v-stepper-content step="2">
                <v-card flat>
                  <v-card-text>
                    <v-row class="my-0 py-0">
                      <v-col cols="12" md="4" class="my-0 py-0">
                        <v-autocomplete
                          :items="types"
                          :label="$tc('label.type', 1)"
                          :rules="[rules.required]"
                          outlined
                          v-model="selectedType"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :class="['black--text', 'mr-2']"
                      @click="step = 1"
                      rounded
                      small
                      text
                    >
                      {{ $t("label.back") }}
                    </v-btn>
                    <v-btn
                      :color="'primary'"
                      :disabled="!selectedType"
                      @click="step = 3"
                      rounded
                      small
                    >
                      {{ $t("label.next") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-step :complete="step > 3" step="3">
                {{ $t("label.select") }} {{ $tc("label.connection", 2) }}
                <small v-if="step == 3"
                  >{{ $t("connectionStepSmall") }}</small
                >
              </v-stepper-step>

              <v-stepper-content step="3">
                <v-card flat>
                  <v-card-text>
                    <v-row class="my-0 py-0">
                      <v-col cols="12" class="my-0 py-0">
                        <v-autocomplete
                          :items="allConnections.edges"
                          :label="$tc('label.connection', 1)"
                          :loading="$apollo.queries.allConnections.loading"
                          :rules="[rules.required]"
                          item-value="node.id"
                          multiple
                          outlined
                          v-model="selectedConnections"
                        >
                          <template slot="selection" slot-scope="{ item }">
                            <v-chip small>
                              {{
                                `${item.node.tenant.tenantName} - #TAP${item.node.pk}`
                              }}
                            </v-chip>
                          </template>
                          <template slot="item" slot-scope="{ item }">
                            {{
                              `${item.node.tenant.tenantName} - #TAP${item.node.pk}`
                            }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :class="['black--text', 'mr-2']"
                      :loading="isSaving"
                      @click="step = 2"
                      rounded
                      small
                      text
                    >
                      {{ $t("label.back") }}
                    </v-btn>
                    <v-btn
                      :color="'primary'"
                      :disabled="selectedConnections.length == 0"
                      :loading="isSaving"
                      @click="save()"
                      rounded
                      small
                    >
                      {{ $t("label.save") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
  name: "release-create-dialog",
  components: {},
  props: ["dialog"],

  apollo: {
    allTaps: {
      query: gql`
        query allTaps {
          allTaps(orderBy: ["name"]) {
            edges {
              node {
                id
                name
                orchestPipeline {
                  id
                }
                pbiMaster {
                  id
                }
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.allTaps,
    },

    allConnections: {
      query: gql`
        query allConnections(
          $first: Int
          $orderBy: [String]
          $tap: ID!
          $completedAt_Isnull: Boolean!
        ) {
          allConnections(
            first: $first
            orderBy: $orderBy
            tap: $tap
            completedAt_Isnull: $completedAt_Isnull
          ) {
            edges {
              node {
                id
                pk
                tenant {
                  id
                  tenantName
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allConnections,
      skip: true,
      result() {},
    },
  },

  data() {
    return {
      allConnections: {},
      allTaps: {},
      error: null,
      isSaving: false,
      selectedConnections: [],
      selectedTap: null,
      selectedType: null,
      step: 1,
      types: [],
    };
  },
  computed: {
    formTitle() {
      return `${this.$t("label.add")} ${this.$tc("label.release", 1)}`;
    },

    me() {
      return this.$store.state.user.me || {};
    },
  },
  watch: {
    dialog(val) {
      this.resetForm();
    },

    selectedTap: {
      deep: true,
      handler(val) {
        if (val) {
          this.step = 2;
          this.selectedConnections = [];
          this.selectedType = null;
          this.refresh();
          this.setTypes();
        }
      },
    },

    selectedType: {
      deep: true,
      handler(val) {
        if (val) {
          this.step = 3;
        }
      },
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    refresh() {
      this.$apollo.queries.allConnections.setVariables({
        first: 100,
        orderBy: ["tenant__tenantName", "pk"],
        tap: this.selectedTap.node.id,
        completedAt_Isnull: false,
      });

      this.$apollo.queries.allConnections.skip = false;
      this.$apollo.queries.allConnections.refresh();
    },

    resetForm() {
      this.error = null;
      this.isSaving = false;

      this.selectedConnections = [];
      this.selectedTap = null;
      this.selectedType = null;
      this.step = 1;
      this.types = [];

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    save() {
      this.error = null;
      this.isSaving = true;

      var payload = {
        connections: this.selectedConnections,
        type: this.selectedType,
        tap: this.selectedTap.node.id
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createRelease($input: CreateReleaseInput!) {
              createRelease(input: $input) {
                clientMutationId
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Release successfully created`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$emit("changed", response);
          this.close();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    setTypes() {
      var tap = this.selectedTap.node;
      var types = [];

      if (tap.orchestPipeline) {
        types.push("Orchest");
      }

      if (tap.pbiMaster) {
        types.push("Power BI");
      }

      this.types = types;
    },
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .my-stepper .v-stepper__step {
    flex-grow: 1;
  }
}
</style>

<i18n>
{
  "en": {
    "connectionStepSmall": "Only completed connections are displayed"
  },
  "nl": {
    "connectionStepSmall": "Alleen afgeronde koppelingen worden weergegeven"
  },
  "de": {
    "connectionStepSmall": "Only completed connections are displayed"
  }
}
</i18n>

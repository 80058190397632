const state = {
	disableAppBar: false,
	appBarTitle: null,
};

const mutations = {
	setDisableAppBar(state, disableAppBar) {
		state.disableAppBar = disableAppBar;
	},
	setAppBarTitle(state, appBarTitle) {
		state.appBarTitle = appBarTitle;
	},
};

const actions = {
	mutateDisableAppBar(context, disableAppBar) {
		context.commit("setDisableAppBar", disableAppBar);
	},
	mutateAppBarTitle(context, appBarTitle) {
		context.commit("setAppBarTitle", appBarTitle);
	},
};

const getters = {};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};

<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="600px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          {{ formTitle }}
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form onSubmit="return false;" ref="form" v-model="isFormValid">
            <v-row class="my-0 py-0">
              <v-col :class="['my-0', 'py-0']" cols="12">
                <v-autocomplete
                  :items="allResourceGroups.edges"
                  :label="$tc('label.resourceGroup', 1)"
                  :loading="$apollo.queries.allResourceGroups.loading"
                  :rules="[rules.required]"
                  clearable
                  item-text="node.name"
                  item-value="node.id"
                  outlined
                  return-object
                  v-model="selectedResourceGroup"
                >
                </v-autocomplete>
              </v-col>

              <v-col :class="['my-0', 'py-0']" cols="12">
                <v-text-field
                  :append-icon="selectedResourceGroup ? 'rotate_right' : ''"
                  :counter="58"
                  :label="$tc('label.name', 1)"
                  :rules="[rules.required, rules.maxLength(58)]"
                  @click:append="setDefaultName()"
                  outlined
                  v-model="name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="'primary'"
            :disabled="!isFormValid"
            :loading="isSaving"
            @click="save()"
            rounded
            small
          >
            {{ $t("label.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
  name: "group-add-dialog",
  props: ["dialog", "tenant"],

  apollo: {
    allResourceGroups: {
      query: gql`
        query allResourceGroups($first: Int, $tenant: ID!) {
          allResourceGroups(first: $first, tenant: $tenant) {
            edges {
              node {
                id
                location
                name
                tags
                tenant {
                  id
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allResourceGroups,
      skip: true,
      variables() {
        return {
          first: 100,
          tenant: this.tenant.node.id,
        };
      },
    },
  },

  data() {
    return {
      allResourceGroups: {},
      error: null,
      isFormValid: false,
      isSaving: false,
      name: null,
      selectedResourceGroup: null,
    };
  },
  computed: {
    formTitle() {
      return `${this.$t("label.add")} ${this.$tc("label.group", 1)}`;
    },

    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.$apollo.queries.allResourceGroups.skip = false;
      }

      this.resetForm();
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    resetForm() {
      this.error = null;
      this.isSaving = false;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      this.selectedResourceGroup = null;
      this.name = null;

      this.$apollo.queries.allResourceGroups.refresh();
    },

    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var parameters = {
        resource_group: { value: this.selectedResourceGroup.node.name },
      };

      var payload = {
        name: this.name,
        parameters: JSON.stringify(parameters),
        subType: "Group",
        tenant: this.tenant.node.id,
        type: "Power BI",
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createTaskResultExtension(
              $input: CreateTaskResultExtensionInput!
            ) {
              createTaskResultExtension(input: $input) {
                taskResultExtension {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Deployment successfully started`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$emit("changed");
          this.close();
        })
        .catch((error) => {
          this.error = error;
          this.isSaving = false;
        });
    },

    setDefaultName() {
      this.name = this.selectedResourceGroup.node.name;
    },
  },
};
</script>

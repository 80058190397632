<template>
	<v-container v-if="me.isSuperuser" fluid>
		<v-row class="align-center pl-3">
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
			<v-spacer />
		</v-row>

		<v-container :fluid="$vuetify.breakpoint.lgAndDown">
			<v-row justify="center" align="center">
				<v-col cols="12">
					<v-tabs v-model="tabs">
						<v-tabs-slider color="primary"></v-tabs-slider>

						<v-tab :disabled="editMode">{{ group.name }}</v-tab>
						<v-spacer />
						<v-btn
							@click="deleteSelected()"
							class="mt-2 ml-2"
							color="error"
							dark
							outlined
							rounded
							small
							v-if="!editMode"
							>{{ $t("label.delete") }}</v-btn
						>
						<v-btn
							@click="edit()"
							class="mt-2 ml-2"
							color="primary"
							dark
							outlined
							rounded
							small
							v-if="!editMode"
							>{{ $t("label.edit") }}</v-btn
						>
						<v-btn
							@click="cancel()"
							class="mt-2 ml-2"
							rounded
							small
							text
							v-if="editMode"
							>{{ $t("label.cancel") }}</v-btn
						>
						<v-btn
							@click="save()"
							class="mt-2 ml-2"
							color="primary"
							dark
							outlined
							rounded
							small
							v-if="editMode"
							>{{ $t("label.save") }}</v-btn
						>
					</v-tabs>
					<v-divider> </v-divider>
					<v-progress-linear
						height="3"
						indeterminate
						v-if="this.$apollo.queries.group.loading"
					></v-progress-linear>

					<v-tabs-items v-model="tabs">
						<v-tab-item>
							<GroupPermissionsTab
								:editMode.sync="editMode"
								:object.sync="group"
								ref="GroupPermissionsTab"
								v-on:changed="onChanged"
							/>
						</v-tab-item>
					</v-tabs-items>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>
<script>
import gql from "graphql-tag";
import GroupPermissionsTab from "@/components/users/GroupPermissionsTab.vue";

export default {
	name: "group-detail",
	components: { GroupPermissionsTab },
	apollo: {
		group: {
			query: gql`
				query group($id: ID!) {
					group(id: $id) {
						id
						name
						permissions {
							edges {
								node {
									id
									codename
								}
							}
						}
						pk
						userSet {
							edges {
								node {
									id
									email
									enabled2fa
									firstName
									lastName
									require2fa
									tenant {
										id
										tenantName
									}
								}
							}
						}
					}
				}
			`,
			variables() {
				return {
					id: this.$route.params.groupNodeId,
				};
			},
			fetchPolicy: "cache-and-network",
			update: (data) => data.group,
			pollInterval: 60000,
		},
	},
	data: function () {
		return {
			editMode: false,
			group: {},
		};
	},
	computed: {
		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("label.home"),
					exact: true,
					disable: false,
					to: {
						name: "home",
					},
				},
				{
					text: this.$tc("label.user", 2),
					exact: true,
					disable: false,
					to: {
						name: "users",
					},
				},
				{
					text: "#GRP" + this.group.pk,
					disabled: true,
				},
			];
			return breadcrumbs;
		},

		me() {
			return this.$store.state.user.me;
		},

		tabs: {
			get() {
				return this.$store.getters["groupDetail/getTabs"];
			},
			set(value) {
				this.$store.dispatch("groupDetail/setTabs", value);
			},
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		edit() {
			this.editMode = true;
		},

		cancel() {
			this.editMode = false;

			switch (this.tabs) {
				case 0:
					this.$refs.GroupPermissionsTab.resetForm();
			}
		},

		deleteSelected() {
			switch (this.tabs) {
				case 0:
					this.$refs.GroupPermissionsTab.deleteSelected();
					break;
				default:
					break;
			}
		},

		save() {
			switch (this.tabs) {
				case 0:
					this.$refs.GroupPermissionsTab.save();
			}
		},

		onChanged() {
			switch (this.tabs) {
				case 0:
					this.$apollo.queries.group.refresh();
			}
			this.editMode = false;
		},
	},
};
</script>

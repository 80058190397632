<template>
  <v-container fluid>
    <v-row class="align-center pl-3">
      <v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
      <v-spacer />
    </v-row>

    <v-container :fluid="$vuetify.breakpoint.lgAndDown">
      <v-row justify="center" align="center">
        <v-col cols="12">
          <v-tabs v-model="tabs">
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab>{{ $tc("label.report", 2) }}</v-tab>
            <v-tab>
              <v-badge
                :color="
                  allUserTasks?.tasksRunning?.totalCount > 0
                    ? 'info'
                    : allUserTasks?.tasksSuccess?.totalCount > 0
                    ? 'success'
                    : ''
                "
                :value="
                  allUserTasks?.tasksRunning?.totalCount > 0 ||
                  allUserTasks?.tasksSuccess?.totalCount > 0
                    ? true
                    : false
                "
                >{{ $tc("label.export", 2) }}</v-badge
              >
            </v-tab>
            <v-tab v-if="hasPermission('dashboards.view_usermapping')">{{
              $tc("label.usermapping", 2)
            }}</v-tab>
            <v-tab v-if="hasPermission('dashboards.view_usermapping')"
              >{{ $tc("label.report", 1) }}{{ $tc("label.group", 2) }}</v-tab
            >
            <v-tab v-if="me.isSuperuser">{{ $tc("label.error", 2) }}</v-tab>
            <v-spacer />
            <v-btn
              :disabled="
                (!tabs == 0 || nrOfSelectedReports <= 0 || !me.isSuperuser) &&
                (!tabs == 1 || nrOfSelectedUsermappings <= 0) &&
                (!tabs == 2 || nrOfSelectedReportGroups <= 0)
              "
              @click="deleteSelected()"
              class="mt-2"
              color="error"
              dark
              outlined
              rounded
              small
              >{{ $t("label.delete") }}</v-btn
            >
            <v-btn
              @click="add()"
              class="mt-2 ml-2"
              color="primary"
              dark
              outlined
              rounded
              small
              v-if="
                (me.isSuperuser && tabs != 1 && tabs != 4) ||
                tabs == 2 ||
                tabs == 3
              "
              >{{ $t("label.add") }}</v-btn
            >
          </v-tabs>
          <v-divider></v-divider>

          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <ReportsList
                ref="ReportsList"
                v-on:updateNrOfSelectedItems="onUpdateNrOfSelectedReports"
              />
            </v-tab-item>
            <v-tab-item> <ExportsList ref="ExportsList" /> </v-tab-item>
            <v-tab-item v-if="hasPermission('dashboards.view_usermapping')">
              <UsermappingsList
                ref="UsermappingsList"
                v-on:updateNrOfSelectedItems="onUpdateNrOfSelectedUsermappings"
              />
            </v-tab-item>
            <v-tab-item>
              <ReportGroupsList
                ref="ReportGroupsList"
                v-on:updateNrOfSelectedItems="onUpdateNrOfSelectedReportGroups"
              />
            </v-tab-item>
            <v-tab-item v-if="me.isSuperuser">
              <ErrorsList ref="ErrorsList" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import ErrorsList from "@/components/reports/ErrorsList.vue";
import ExportsList from "@/components/reports/ExportsList.vue";
import helper from "@/utils/helper.js";
import ReportGroupsList from "@/components/reports/ReportGroupsList.vue";
import ReportsList from "@/components/reports/ReportsList.vue";
import UsermappingsList from "@/components/reports/UsermappingsList.vue";

export default {
  name: "reports",
  components: {
    ExportsList,
    ReportGroupsList,
    ReportsList,
    UsermappingsList,
    ErrorsList,
  },

  apollo: {},

  data: function () {
    return {
      nrOfSelectedReportGroups: 0,
      nrOfSelectedReports: 0,
      nrOfSelectedUsermappings: 0,
    };
  },

  computed: {
    breadcrumbs() {
      let breadcrumbs = [
        {
          text: this.$t("label.home"),
          exact: true,
          disable: false,
          to: {
            name: "home",
          },
        },
        {
          text: this.$tc("label.report", 2),
          disabled: true,
        },
      ];
      return breadcrumbs;
    },

    me() {
      return this.$store.state.user.me;
    },

    tabs: {
      get() {
        return this.$store.getters["reports/getTabs"];
      },
      set(value) {
        this.$store.dispatch("reports/setTabs", value);
      },
    },

    allUserTasks() {
      return this.$store.state.user.allUserTasks;
    },
  },

  watch: {},

  created() {
    this.hasPermission = helper.hasPermission;
  },

  mounted() {},

  methods: {
    add() {
      switch (this.tabs) {
        case 0:
          this.$refs.ReportsList.add();
          break;
        case 2:
          this.$refs.UsermappingsList.add();
          break;
        case 3:
          this.$refs.ReportGroupsList.add();
          break;
        default:
          break;
      }
    },

    deleteSelected() {
      switch (this.tabs) {
        case 0:
          this.$refs.ReportsList.deleteSelected();
          break;
        case 2:
          this.$refs.UsermappingsList.deleteSelected();
          break;
        case 3:
          this.$refs.ReportGroupsList.deleteSelected();
          break;
        default:
          break;
      }
    },

    onUpdateNrOfSelectedReportGroups(length) {
      this.nrOfSelectedReportGroups = length;
    },

    onUpdateNrOfSelectedReports(length) {
      this.nrOfSelectedReports = length;
    },

    onUpdateNrOfSelectedUsermappings(length) {
      this.nrOfSelectedUsermappings = length;
    },
  },
};
</script>

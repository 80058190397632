<template>
  <v-row justify="center">
    <v-dialog
      :fullscreen="fullscreen"
      :max-width="`${maxWidth}px`"
      :persistent="persistent"
      @click:outside="close()"
      @keydown.esc="close()"
      v-model="dialog"
    >
      <v-card class="px-5 pt-5 pb-5">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          class="font-weight-bold text-h4 mb-3"
          style="word-break: break-word"
        >
          <slot name="title">Base dialog</slot>
          <v-spacer />
          <v-btn icon @click="close()" v-if="!persistent">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <slot name="text">Base text</slot>
        </v-card-text>

        <v-card-actions>
          <slot name="actionsLeft"></slot>
          <v-spacer />
          <slot name="actionsRight"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "base-dialog",
  components: {},
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    error: {
      default: null,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    maxWidth: {
      default: 900,
      type: Number,
    },
    persistent: {
      default: false,
      type: Boolean,
    },
  },

  apollo: {},

  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    close(val) {
      if (!this.persistent) {
        this.$emit("close", val);
      }
    },
  },
};
</script>

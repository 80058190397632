<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title class="title">
          <v-icon color="error" class="mr-2">warning</v-icon>
          <slot name="title">{{ $tc("label.error", 1) }}</slot>
        </v-card-title>
        <v-card-text>
          {{ errorMessage }}
        </v-card-text>

        <v-card-actions class="pb-5 pr-5">
          <v-spacer></v-spacer>
          <v-btn text rounded @click="cancel">{{ $t("label.cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog></v-row
  >
</template>

<script>
export default {
  name: "error-dialog",
  props: ["dialog", "errorMessage"],
  data: function () {
    return {};
  },
  watch: {},
  methods: {
    cancel() {
      this.$emit("update:dialog", false);
    },
  },
};
</script>

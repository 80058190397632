<template>
	<v-row justify="center">
		<ThemeEditDialog
			:dialog.sync="themeDialog"
			v-on:item-created="themeAdded"
		></ThemeEditDialog>

		<v-dialog max-width="500px" persistent scrollable v-model="dialog">
			<v-card>
				<v-alert v-if="error" tile type="error">{{ error }}</v-alert>

				<v-card-title class="primary white--text">
					{{ formTitle }}
				</v-card-title>

				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row class="my-0 py-0">
							<v-col :class="['my-0', 'py-0']" cols="12" md="8">
								<v-text-field
									:counter="200"
									:label="$tc('label.name', 1)"
									:rules="[
										rules.required,
										rules.maxLength(200),
									]"
									outlined
									v-model="editedItem.tenantName"
								></v-text-field>
							</v-col>
							<v-col :class="['my-0', 'py-0']" cols="12" md="4">
								<v-text-field
									:label="`Max ${$tc('label.user', 2)}`"
									outlined
									type="number"
									v-model="editedItem.maxUsers"
								></v-text-field>
							</v-col>
							<v-col :class="['my-0', 'py-0']" cols="12" md="8">
								<v-text-field
									:counter="50"
									:label="`${$t('label.external')} ${$tc(
										'label.id',
										1
									)}`"
									:rules="[rules.maxLength(50)]"
									outlined
									v-model="editedItem.externalId"
								></v-text-field>
							</v-col>
							<v-col :class="['my-0', 'py-0']" cols="12" md="4">
								<v-text-field
									:label="'Refresh tokens'"
									outlined
									type="number"
									v-model="editedItem.monthlyRefreshTokens"
								></v-text-field>
							</v-col>
							<v-col :class="['my-0', 'py-0']" cols="12">
								<v-textarea
									:counter="8000"
									:label="'Allowed hosts'"
									:rules="[
										rules.maxLength(8000),
										rules.required,
									]"
									hint='["127.0.0.1", "192.168.2.3"]'
									outlined
									persistent-hint
									v-model="editedItem.allowedHosts"
								></v-textarea>
							</v-col>
							<v-col :class="['my-0', 'py-0']" cols="12">
								<v-autocomplete
									:items="groups.edges"
									:label="$tc('label.group', 2)"
									:loading="$apollo.queries.groups.loading"
									:rules="[rules.required]"
									clearable
									item-text="node.name"
									item-value="node.id"
									multiple
									outlined
									v-model="editedItem.selectedGroups"
								>
								</v-autocomplete>
							</v-col>
							<v-col :class="['my-0', 'py-0']" cols="12">
								<v-autocomplete
									:items="themes.edges"
									:label="$tc('label.theme', 1)"
									:loading="$apollo.queries.themes.loading"
									:rules="[rules.required]"
									clearable
									item-text="node.themeName"
									item-value="node.id"
									outlined
									v-model="editedItem.themeNodeId"
								>
									<template v-slot:append-outer>
										<v-btn small tabindex="-1" text>
											<v-icon
												@click="addTheme"
												color="grey"
												tabindex="-1"
												>add</v-icon
											>
										</v-btn>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col
								:class="['my-0', 'py-0']"
								cols="12"
								v-if="object && !editedItem.azureClientId"
							>
								<v-btn
									:loading="isSaving"
									@click="generateAzureServicePrincipal()"
									block
									color="primary"
									rounded
									>Generate Azure service principal
								</v-btn>
							</v-col>
							<v-col
								:class="['my-0', 'py-0']"
								cols="12"
								v-if="editedItem.azureAppObjectId"
							>
								<v-text-field
									:counter="36"
									:label="'Azure app object id'"
									:rules="[rules.maxLength(36)]"
									outlined
									v-model="editedItem.azureAppObjectId"
								></v-text-field>
							</v-col>
							<v-col
								:class="['my-0', 'py-0']"
								cols="12"
								v-if="editedItem.azureClientId"
							>
								<v-text-field
									:counter="36"
									:label="'Azure client id'"
									:rules="[rules.maxLength(36)]"
									outlined
									v-model="editedItem.azureClientId"
								></v-text-field>
							</v-col>
							<v-col
								:class="['my-0', 'py-0']"
								cols="12"
								v-if="editedItem.azureClientSecret"
							>
								<v-text-field
									:counter="255"
									:label="'Azure client secret'"
									:rules="[rules.maxLength(255)]"
									:type="showPassword ? 'text' : 'password'"
									outlined
									v-model="editedItem.azureClientSecret"
								>
									<template v-slot:append>
										<v-icon
											@click="
												showPassword = !showPassword
											"
											color="primary"
											tabindex="-1"
											>{{
												showPassword
													? "visibility"
													: "visibility_off"
											}}</v-icon
										>
									</template>
								</v-text-field>
							</v-col>
							<v-col
								:class="['my-0', 'py-0']"
								cols="12"
								v-if="editedItem.azureServicePrincipalId"
							>
								<v-text-field
									:counter="36"
									:label="'Azure service principal id'"
									:rules="[rules.maxLength(36)]"
									outlined
									v-model="editedItem.azureServicePrincipalId"
								></v-text-field>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isSaving"
						@click="closeTenantDialog"
						rounded
						text
						>{{ $t("label.cancel") }}</v-btn
					>
					<v-btn
						:loading="isSaving"
						@click="saveTenant"
						color="primary"
						outlined
						rounded
						>{{ $t("label.save") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";
import ThemeEditDialog from "@/components/users/ThemeEditDialog.vue";

export default {
	name: "tenant-edit-dialog",
	props: ["dialog", "object"],
	components: {
		ThemeEditDialog,
	},

	apollo: {
		groups: {
			query: gql`
				query groups {
					groups(orderBy: ["name"]) {
						edges {
							node {
								id
								name
							}
						}
					}
				}
			`,
			variables() {},
			fetchPolicy: "cache-and-network",
			update: (data) => data.groups,
			skip: true,
			pollInterval: 60000,
		},

		themes: {
			query: gql`
				query themes {
					themes(orderBy: ["themeName"]) {
						edges {
							node {
								id
								themeName
							}
						}
					}
				}
			`,
			variables() {},
			fetchPolicy: "cache-and-network",
			update: (data) => data.themes,
			skip: true,
			pollInterval: 60000,
		},
	},

	data() {
		return {
			error: null,
			isSaving: false,
			themeDialog: false,

			groups: {},
			themes: {},

			editedItem: {
				id: null,
				allowedHosts: null,
				azureAppObjectId: null,
				azureClientId: null,
				azureClientSecret: null,
				azureServicePrincipalId: null,
				externalId: null,
				maxUsers: null,
				monthlyRefreshTokens: null,
				selectedGroups: [],
				tenantName: null,
				themeNodeId: "",
			},
			defaultItem: {
				id: null,
				allowedHosts: '["*"]',
				azureAppObjectId: null,
				azureClientId: null,
				azureClientSecret: null,
				azureServicePrincipalId: null,
				externalId: null,
				maxUsers: null,
				monthlyRefreshTokens: null,
				selectedGroups: [],
				tenantName: null,
				themeNodeId: "VGhlbWVOb2RlOjE=",
			},

			showPassword: false,
		};
	},
	computed: {
		formTitle() {
			return this.object
				? `${this.$t("label.edit")} ${this.$tc("label.tenant", 1)}`
				: `${this.$t("label.add")} ${this.$tc("label.tenant", 1)}`;
		},
	},
	watch: {
		// reset form when dialog open or close
		dialog(val) {
			// if open dialog, initialize selections
			if (val) {
				this.$apollo.queries.groups.skip = false;
				this.$apollo.queries.themes.skip = false;
			}

			this.resetForm();
		},
	},
	created() {
		this.rules = rules;
	},
	methods: {
		generateAzureServicePrincipal() {
			this.error = null;
			this.isSaving = true;

			var payload = {};

			if (this.object) {
				payload.id = this.editedItem.id;

				this.$apollo
					.mutate({
						mutation: gql`
							mutation createTenantServicePrincipal(
								$input: CreateTenantServicePrincipalInput!
							) {
								createTenantServicePrincipal(input: $input) {
									tenant {
										id
									}
								}
							}
						`,
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit("changed", response);
						this.closeTenantDialog();

						const payload = {
							color: "success",
							message: `Service principal successfully generated`,
						};

						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
						this.isSaving = false;

						const payload = {
							color: "error",
							message: this.error,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.finally(() => {});
			}
		},

		resetForm() {
			// reset form state
			this.error = null;
			this.isSaving = false;
			this.panel = 0;
			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			// when editing, set form with object
			if (this.dialog && this.object) {
				this.editedItem.id = this.object.id;
				this.editedItem.allowedHosts = this.object.allowedHosts;
				this.editedItem.azureAppObjectId = this.object.azureAppObjectId;
				this.editedItem.azureClientId = this.object.azureClientId;
				this.editedItem.azureClientSecret =
					this.object.azureClientSecret;
				this.editedItem.azureServicePrincipalId =
					this.object.azureServicePrincipalId;
				this.editedItem.externalId = this.object.externalId;
				this.editedItem.maxUsers = this.object.maxUsers;
				this.editedItem.monthlyRefreshTokens =
					this.object.monthlyRefreshTokens;
				this.editedItem.selectedGroups = this.object.tenantGroupSet
					? this.object.tenantGroupSet.edges.map((item) => {
							return item.node.group.id;
					  })
					: [];
				this.editedItem.tenantName = this.object.tenantName;
				this.editedItem.themeNodeId = this.object.theme.id;
			}
			// when new or closing, set form with default
			else {
				this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
			}
		},

		closeTenantDialog() {
			this.$emit("update:dialog", false);
			this.resetForm();
		},

		saveTenant() {
			if (!this.$refs.form.validate()) {
				return;
			}

			// set form state
			this.error = null;
			this.isSaving = true;

			// prepare api call payload
			var payload = {
				allowedHosts: this.editedItem.allowedHosts,
				externalId: this.editedItem.externalId,
				groups: this.editedItem.selectedGroups,
				maxUsers: this.editedItem.maxUsers,
				monthlyRefreshTokens: this.editedItem.monthlyRefreshTokens,
				tenantName: this.editedItem.tenantName,
				themeNodeId: this.editedItem.themeNodeId,
			};

			// if editing
			if (this.object) {
				// prepare graphql mutation payload
				payload.id = this.editedItem.id;
				payload.azureAppObjectId = this.editedItem.azureAppObjectId;
				payload.azureClientId = this.editedItem.azureClientId;
				payload.azureClientSecret = this.editedItem.azureClientSecret;
				payload.azureServicePrincipalId =
					this.editedItem.azureServicePrincipalId;

				// update
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation updateTenant($input: UpdateTenantInput!) {
								updateTenant(input: $input) {
									tenant {
										id
										tenantName
										externalId
										maxUsers
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						// update parent object
						this.$emit("changed", response);
						this.closeTenantDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Tenant successfully edited`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						this.error = error.graphQLErrors[0].message;
						this.isSaving = false;

						// show snackbar
						const payload = {
							color: "error",
							message: this.error,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.finally(() => {});
			}
			// new
			else {
				this.$apollo
					.mutate({
						// Query
						mutation: gql`
							mutation createTenant($input: CreateTenantInput!) {
								createTenant(input: $input) {
									tenant {
										id
									}
								}
							}
						`,
						// Parameters
						variables: {
							input: payload,
						},
					})
					.then((response) => {
						this.$emit("changed", response);
						this.closeTenantDialog();

						// show snackbar
						const payload = {
							color: "success",
							message: `Tenant successfully added`,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.catch((error) => {
						console.log(error);

						this.error = error.graphQLErrors
							.map((error) => error.message)
							.join(", ");
						this.isSaving = false;

						// show snackbar
						const payload = {
							color: "error",
							message: this.error,
						};
						this.$store.dispatch("snackbar/showMessage", payload);
					})
					.finally(() => {});
			}
		},

		addTheme() {
			this.themeDialog = true;
		},
		themeAdded(item) {
			console.log(item);
			this.$apollo.queries.themes.refresh();
			this.editedItem.themeNodeId = item.id;
		},
	},
};
</script>

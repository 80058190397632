<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="1300px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          {{ formTitle }}
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteSelected()"
            icon
            color="error"
            v-if="this.object"
          >
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" onSubmit="return false;" v-model="isFormValid">
            <v-row class="my-0 py-0">
              <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                <v-text-field
                  :label="`${$tc('label.name', 1)} NL`"
                  :rules="[rules.required]"
                  outlined
                  v-model="editedItem.nameNl"
                ></v-text-field>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                <v-text-field
                  :label="`${$tc('label.name', 1)} EN`"
                  :rules="[rules.required]"
                  outlined
                  v-model="editedItem.nameEn"
                ></v-text-field>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                <v-textarea
                  :label="`${$tc('label.description', 1)} NL`"
                  :rules="[rules.required]"
                  outlined
                  v-model="editedItem.descriptionNl"
                ></v-textarea>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                <v-textarea
                  :label="`${$tc('label.description', 1)} EN`"
                  :rules="[rules.required]"
                  outlined
                  v-model="editedItem.descriptionEn"
                ></v-textarea>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" md="4">
                <v-menu
                  ref="dateRangeMenu"
                  v-model="dateRangeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dateRangeMenu"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRange"
                      :label="$tc('label.dateRange', 1)"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateRange"
                    scrollable
                    first-day-of-week="1"
                    locale-first-day-of-year="4"
                    show-week
                    range
                    :min="minDate"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="dateRangeMenu = false"
                      :class="['black--text']"
                      rounded
                      small
                      text
                    >
                      {{ $t("label.cancel") }}
                    </v-btn>
                    <v-btn
                      :color="'primary'"
                      @click="$refs.dateRangeMenu.save(dateRange)"
                      rounded
                      small
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" md="4">
                <v-menu
                  ref="timeStartMenu"
                  v-model="timeStartMenu"
                  :close-on-content-click="false"
                  :return-value.sync="timeStart"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="timeStart"
                      :label="$tc('label.startTime', 1)"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeStartMenu"
                    v-model="timeStart"
                    full-width
                    @click:minute="$refs.timeStartMenu.save(timeStart)"
                    format="24hr"
                    scrollable
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" md="4">
                <v-menu
                  ref="timeEndMenu"
                  v-model="timeEndMenu"
                  :close-on-content-click="false"
                  :return-value.sync="timeEnd"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="timeEnd"
                      :label="$tc('label.endTime', 1)"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeEndMenu"
                    v-model="timeEnd"
                    full-width
                    @click:minute="$refs.timeEndMenu.save(timeEnd)"
                    format="24hr"
                    scrollable
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12">
                <v-autocomplete
                  :items="allSystems.edges"
                  :label="$tc('label.system', 2)"
                  :loading="$apollo.queries.allSystems.loading"
                  :rules="[rules.required]"
                  chips
                  item-text="node.name"
                  item-value="node.id"
                  multiple
                  outlined
                  small-chips
                  v-model="selectedSystems"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="'primary'"
            :disabled="!isFormValid"
            :loading="isSaving"
            @click="save()"
            rounded
            small
          >
            {{ $t("label.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DeleteDialog
      :dialog.sync="deleteDialog"
      v-on:confirmed="onDeleteConfirmed"
    />
  </v-row>
</template>

<script>
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import gql from "graphql-tag";
import moment from "moment";
import rules from "@/utils/rules.js";

export default {
  name: "maintenance-edit-dialog",
  components: {
    DeleteDialog
  },
  props: ["dialog", "object"],

  apollo: {
    allSystems: {
      query: gql`
        query allSystems($orderBy: [String]) {
          allSystems(orderBy: $orderBy) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      variables() {
        return {
          orderBy: ["name"],
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allSystems,
    },
  },

  data() {
    return {
      allSystems: {},
      dateRange: [],
      dateRangeMenu: false,
      deleteDialog: false,
      editedItem: {},
      error: null,
      isFormValid: false,
      isSaving: false,
      minDate: null,
      selectedSystems: [],
      timeEnd: null,
      timeEndMenu: false,
      timeStart: null,
      timeStartMenu: false,
    };
  },
  computed: {
    formTitle() {
      return `${this.$t("label.add")} ${this.$tc("label.maintenance", 1)}`;
    },

    me() {
      return this.$store.state.user.me || {};
    },
  },
  watch: {
    dialog(val) {
      this.resetForm();
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    deleteSelected() {
      this.deleteDialog = true;
    },

    resetForm() {
      this.error = null;
      this.isSaving = false;

      this.dateRange = [];
      this.minDate = new Date().toISOString().slice(0, 10);
      this.selectedSystems = [];
      this.timeEnd = null;
      this.timeStart = null;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      if (this.dialog && this.object) {
        this.editedItem.id = this.object.id;
        this.editedItem.descriptionEn = this.object.descriptionEn;
        this.editedItem.descriptionNl = this.object.descriptionNl;
        this.editedItem.nameEn = this.object.nameEn;
        this.editedItem.nameNl = this.object.nameNl;

        this.selectedSystems = this.object.systems
          ? this.object.systems.edges.map((item) => {
              return item.node.id;
            })
          : [];

        // set dates and times
        this.minDate = null;
        var dateRangeStart = moment(this.object.start).format("yyyy-MM-DD");
        var dateRangeEnd = moment(this.object.end).format("yyyy-MM-DD");
        this.dateRange.push(dateRangeStart, dateRangeEnd);
        this.timeStart = moment(this.object.start).format("hh:mm");
        this.timeEnd = moment(this.object.end).format("hh:mm");
      } else {
        this.editedItem = {};
      }
    },

    onDeleteConfirmed() {
      var ids = [this.object.id];

      const payload = {
        ids: ids,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteMaintenances($input: DeleteMaintenancesInput!) {
              deleteMaintenances(input: $input) {
                deletionCount
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          this.deleteDialog = false;
          
          const payload = {
            color: "success",
            message: `Maintenances successfully deleted`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$emit("changed", response);
          this.close();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var orderedDateRange = this.dateRange.sort(function (a, b) {
        return Date.parse(a) > Date.parse(b);
      });
      var startDate = orderedDateRange[0];
      var endDate = orderedDateRange[1];
      var startTime = "00:00";
      if (this.timeStart !== null) {
        var startTime = this.timeStart;
      }
      var endTime = "23:59";
      if (this.timeEnd !== null) {
        var endTime = this.timeEnd;
      }

      var startDateTime = new Date(startDate + " " + startTime);
      var endDateTime = new Date(endDate + " " + endTime);

      var payload = {
        descriptionEn: this.editedItem.descriptionEn,
        descriptionNl: this.editedItem.descriptionNl,
        end: endDateTime,
        nameEn: this.editedItem.nameEn,
        nameNl: this.editedItem.nameNl,
        start: startDateTime,
        systems: this.selectedSystems,
      };

      if (this.object) {
        payload.id = this.editedItem.id;

        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateMaintenance($input: UpdateMaintenanceInput!) {
                updateMaintenance(input: $input) {
                  clientMutationId
                }
              }
            `,
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            const payload = {
              color: "success",
              message: `Maintenance successfully updated`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);

            this.$emit("changed", response);
            this.close();
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.isSaving = false;
          });
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createMaintenance($input: CreateMaintenanceInput!) {
                createMaintenance(input: $input) {
                  clientMutationId
                }
              }
            `,
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            const payload = {
              color: "success",
              message: `Maintenance successfully created`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);

            this.$emit("changed", response);
            this.close();
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .my-stepper .v-stepper__step {
    flex-grow: 1;
  }
}
</style>

<i18n>
  {}
</i18n>

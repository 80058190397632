<template>
  <BaseDialog
    :dialog="dialog"
    :error="error"
    :maxWidth="1800"
    fullscreen
    @close="close($event)"
  >
    <template v-slot:title> {{ $tc("title") }} </template>
    <template v-slot:text>
      <div class="mb-5">
        {{ $tc("description") }}
      </div>

      <v-row>
        <v-col cols="12" md="6" ref="jql">
          <v-text-field
            @click:append-outer="refresh()"
            @keydown.enter="refresh()"
            dense
            hide-details
            label="JQL"
            outlined
            required
            v-model="jql"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2" sm="6">
          <v-text-field
            @click:append-outer="refresh()"
            @keydown.enter="refresh()"
            dense
            hide-details
            label="Uren per storypoint"
            outlined
            required
            type="number"
            v-model="hoursPerStoryPoint"
          ></v-text-field>
        </v-col>

        <v-spacer />

        <!-- Error Messages Section -->
        <v-col cols="12" v-if="errorDetails.length > 0">
          <v-alert type="error" class="mb-4" id="alert">
            <v-list class="rounded-xl">
              <v-list-item-group>
                <v-list-item
                  v-for="(error, index) in errorDetails"
                  :key="index"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      Issue: {{ error.jiraIssueKey }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Step: {{ error.step }} - {{ error.message }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-alert>
        </v-col>

        <v-col cols="12" md="12">
          <v-data-table
            :footer-props="{
              'disable-pagination': $apollo.queries.allJiraIssues.loading,
              'items-per-page-options': [10, 25, 50, 75, 100],
            }"
            :headers="headers"
            :items="items"
            :loading="$apollo.queries.allJiraIssues.loading"
            :options.sync="dataTableOptions"
            item-key="id"
            v-model="selectedItems"
            show-select
          >
            <template v-slot:[`item.key`]="{ item }">
              <a :href="item.url" target="_blank">
                {{ item.key }}
              </a>
            </template>
            <template v-slot:[`item.summary`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ truncateText(item.summary) }}</span>
                </template>
                <span>
                  {{ item.summary }}
                </span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.projectType`]="{ item }">
              <v-select
                :items="['BI', 'ST']"
                :rules="[rules.required]"
                dense
                hide-details
                label="Project type"
                solo
                v-model="item.projectType"
              ></v-select>
            </template>
            <template v-slot:[`item.afasSystemId`]="{ item }">
              <v-autocomplete
                :items="allAfasSystems.edges"
                :loading="$apollo.queries.allAfasSystems.loading"
                dense
                hide-details
                item-text="name"
                item-value="id"
                label="Systeem"
                solo
                v-model="item.afasSystemId"
              >
              </v-autocomplete>
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <v-text-field
                :rules="[rules.required]"
                dense
                hide-details
                label="Omschrijving"
                solo
                v-model="item.description"
              >
              </v-text-field>
            </template>
            <template v-slot:[`item.charge`]="{ item }">
              <v-switch
                class="my-0"
                hide-details
                v-model="item.charge"
              ></v-switch>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actionsRight>
      <BaseButton
        :loading="isSaving"
        :text="true"
        @click="close()"
        color="black"
      >
        {{ $t("label.cancel") }}
      </BaseButton>
      <BaseButton :disabled="!formValid" :loading="isSaving" @click="save()">
        {{ $t("label.confirm") }}
      </BaseButton>
    </template>
  </BaseDialog>
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import rules from "@/utils/rules.js";

const initialData = () => ({
  error: null,
  errorDetails: [],
  hoursPerStoryPoint: 4,
  jql: "project in (ST1, ST2) AND type = Story AND sprint in openSprints ()",
  items: [],
  selectedItems: [],
  formValid: false,
});

export default {
  name: "create-projects-dialog",
  components: {
    BaseButton,
    BaseDialog,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  apollo: {
    allAfasSystems: {
      query: gql`
        query allAfasSystems {
          allAfasSystems {
            edges {
              id
              name
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allAfasSystems,
    },

    allJiraIssues: {
      query: gql`
        query allJiraIssues($jql: String) {
          allJiraIssues(jql: $jql) {
            edges {
              id
              afasDebtorId
              afasProjectId
              assigneeId
              hoursProposal
              issueType
              key
              parent
              storyPoints
              summary
              url
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allJiraIssues,
      skip: true,
      result() {
        this.setItems();
      },
    },
  },

  data() {
    return {
      error: null,
      errorDetails: [],
      hoursPerStoryPoint: null,
      jql: null,
      formValid: false,
      isSaving: false,
      dataTableOptions: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 50,
        multiSort: true,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
      headers: [
        { text: "", value: "select", sortable: false },
        {
          text: this.$tc("label.id"),
          value: "key",
          sortable: false,
        },
        {
          text: "Story points",
          value: "storyPoints",
          sortable: false,
        },
        {
          text: "Parent",
          value: "parent",
          sortable: false,
        },
        {
          text: "AFAS debiteur ID",
          value: "afasDebtorId",
          sortable: false,
        },
        {
          text: "AFAS project ID",
          value: "afasProjectId",
          sortable: false,
        },
        {
          text: this.$tc("label.name"),
          value: "summary",
          sortable: false,
        },
        {
          text: "AFAS project type",
          value: "projectType",
          sortable: false,
        },
        {
          text: "Uren",
          value: "hours",
          sortable: false,
        },
        {
          text: "Uren offerte",
          value: "hoursProposal",
          sortable: false,
        },
        {
          text: "Systeem",
          value: "afasSystemId",
          sortable: false,
        },
        {
          text: "Omschrijving",
          value: "description",
          sortable: false,
        },
        {
          text: "Doorbelasten",
          value: "charge",
          sortable: false,
        },
      ],
      allJiraIssues: {},
      allAfasSystems: {},
      search: null,
      items: [],
      selectedItems: [],
    };
  },

  computed: {},

  watch: {
    dataTableOptions() {
      this.refresh();
    },

    dialog(val) {
      // reset the form to it's original state and reset the validation when dialog closes
      if (val) {
        this.resetData();
        this.refresh();
      } else {
        this.resetData();
      }
    },

    selectedItems: {
      handler(val) {
        this.validateAllSelectedItems();
      },
      deep: true,
    },
  },

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;
    this.rules = rules;
  },

  mounted() {},

  methods: {
    clearSearch() {
      this.search = null;
      this.refresh();
    },

    close() {
      this.$emit("update:dialog", false);
    },

    resetData() {
      // reset the data to it's initial state
      const data = initialData();
      Object.keys(data).forEach((k) => (this[k] = data[k]));
    },

    refresh() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;

      var _first = itemsPerPage;
      var _last = null;
      var _before = null;
      var _after = null;

      if (page < this.page) {
        _first = null;
        _last = itemsPerPage;
        _before = this.allJiraIssues.pageInfo.startCursor;
        _after = null;
      }

      if (page > this.page) {
        _first = itemsPerPage;
        _last = null;
        _before = null;
        _after = this.allJiraIssues.pageInfo.endCursor;
      }

      var orderByList = this.orderByList(sortBy, sortDesc);

      this.$apollo.queries.allJiraIssues.setVariables({
        first: _first,
        last: _last,
        before: _before,
        after: _after,
        jql: this.jql,
      });

      this.$apollo.queries.allJiraIssues.skip = false;
      this.$apollo.queries.allJiraIssues.refresh();

      this.page = page;
    },

    resetFilters() {
      this.clearSearch();
    },

    setItems() {
      const processedData = this.allJiraIssues.edges.map((edge) => {
        // Extract the issue name or summary
        const issueName = edge.summary || "";

        // Determine the project type based on the issue name
        let projectType = null;
        if (issueName.startsWith("BI ")) {
          projectType = "BI";
        } else if (issueName.startsWith("ST ")) {
          projectType = "ST";
        }

        return {
          ...edge,
          afasSystemId: null,
          charge: edge.hoursProposal == 0 ? false : true,
          description: null,
          hours: edge.storyPoints * this.hoursPerStoryPoint,
          isValid: false,
          projectType,
        };
      });

      this.items = processedData;
      this.selectedItems = processedData.filter((item) => !item.afasProjectId);
    },

    save() {
      if (!this.formValid) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        projects: this.selectedItems.map((item) => ({
          afasDebtorId: item.afasDebtorId,
          afasProjectType: item.projectType,
          afasSystemId: item.afasSystemId,
          charge: item.charge,
          description: item.description,
          hours: item.hours,
          hoursProposal: item.hoursProposal,
          jiraIssueKey: item.key,
        })),
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation afasCreateProjects($input: AfasCreateProjectsInput!) {
              afasCreateProjects(input: $input) {
                results {
                  jiraIssueKey
                  success
                  message
                  step
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const results = response.data.afasCreateProjects.results;
          const errors = results.filter((result) => !result.success);
          if (errors.length > 0) {
            this.errorDetails = errors.map((error) => ({
              jiraIssueKey: error.jiraIssueKey,
              step: error.step,
              message: error.message,
            }));
          } else {
            const successMessage = {
              color: "success",
              message: `Successfully created ${results.length} projects`,
            };
            this.$store.dispatch("snackbar/showMessage", successMessage);
          }

          if (errors.length > 0) {
            this.$refs.jql.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            this.refresh();
          } else {
            this.$emit("changed", response);
            this.close();
          }
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    truncateText(text, wordLimit = 8) {
      const words = text.split(" ");
      if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(" ") + "...";
      }

      return text;
    },

    validateItem(item) {
      // Ensure that all required fields are filled
      const isValid =
        item.afasDebtorId &&
        item.projectType &&
        item.description &&
        item.hours !== undefined;
      item.isValid = isValid;
      return isValid;
    },

    validateAllSelectedItems() {
      // Check if all selected items are valid
      this.formValid = this.selectedItems.every((item) =>
        this.validateItem(item)
      );
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "title": "Create projects",
      "description": "All JIRA issues will be retreived from the active sprints. Only issues without AFAS project ID will be shown and a check is done whether de AFAS debtor ID on the epic is filled. If not, it has to be filled on the epic before proceeding. After that a forecast will be created in AFAS, a precalculation is added and a project is created. The ID of the newly created project will be added to the issue in JIRA."
    },
    "nl": {
      "title": "Projecten aanmaken",
      "description": "Alle JIRA issues worden opgehaald uit de actieve sprints. Alleen issues zonder AFAS project ID worden weergegeven en er wordt gecontroleerd of de AFAS debiteur ID op de epic is ingevuld. Als dit niet het geval is, moet deze op de epic worden ingevuld voordat je verder gaat. Daarna wordt een forecast aangemaakt in AFAS, wordt een voorcalculatie toegevoegd en wordt een project aangemaakt. De ID van het nieuw aangemaakte project wordt toegevoegd aan de issue in JIRA."
    },
    "de": {
      "title": "Projekte erstellen",
      "description": "Alle JIRA-Issues werden aus den aktiven Sprints abgerufen. Nur Issues ohne AFAS-Projekt-ID werden angezeigt, und es wird überprüft, ob die AFAS-Debitor-ID im Epic ausgefüllt ist. Falls nicht, muss sie im Epic ausgefüllt werden, bevor fortgefahren wird. Danach wird eine Prognose in AFAS erstellt, eine Vorkalkulation hinzugefügt und ein Projekt angelegt. Die ID des neu erstellten Projekts wird dem Issue in JIRA hinzugefügt."
    }
  }
</i18n>

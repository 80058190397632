var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.$apollo.queries.webflowItem.loading)?_c('v-badge',{class:['mb-5', 'pb-1'],attrs:{"content":_vm.connection?.remainingTrialPeriod,"value":_vm.connection?.remainingTrialPeriod && _vm.connection?.remainingTrialPeriod >= 0
      ? true
      : false,"color":"success","overlap":""}},[_c('v-card',{staticClass:"my-0 pa-1 rounded-xl row-pointer",on:{"click":function($event){return _vm.openTap(_vm.webflowItem?.fieldData?.slug)}}},[(_vm.me.isSuperuser && _vm.connection)?_c('v-card-title',{class:['my-0', 'py-0', 'pt-1']},_vm._l(([
          {
            name: 'Tap id',
            value: `#TAP${_vm.connection?.pk}`,
          },
          {
            name: `Tenant - ${_vm.connection?.tenant?.pk}`,
            value: `${_vm.connection?.tenant?.tenantName}`,
            // value: `SP_BIP_tenant_${connection?.tenant?.pk}_${connection?.tenant?.tenantName}`,
          },
          {
            name: 'Created at',
            value: _vm.connection?.createdAt,
          },
        ]),function(item,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(item.name === 'Created at')?_c('v-chip',_vm._g(_vm._b({staticClass:"my-1 mx-1",attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("moment")(item.value,"YYYY-MM-DD HH:mm"))+" ")]):_c('v-chip',_vm._g(_vm._b({staticClass:"my-1 mx-1",attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.value)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])}),1):_vm._e(),_c('v-card-title',{class:['my-0']},[_c('v-avatar',{attrs:{"size":"80"}},[_c('img',{attrs:{"src":_vm.webflowItem?.fieldData?.logo?.url}})]),_c('v-spacer'),(!_vm.connection)?_c('v-chip',{class:['mb-10'],attrs:{"outlined":"","color":"success","rounded":""}},[_vm._v(_vm._s(_vm.$t("label.connect")))]):_c('v-chip',{class:['ml-10'],attrs:{"color":_vm.connection?.status == 'In progress'
            ? 'info'
            : _vm.connection?.status == 'Connected'
            ? 'success'
            : _vm.connection?.status == 'Failed'
            ? 'error'
            : _vm.connection?.status == 'Cancelled'
            ? 'warning'
            : _vm.connection?.status == 'Deleting'
            ? 'error'
            : '',"rounded":""}},[(_vm.connection?.status == 'In progress')?_c('v-progress-circular',{staticClass:"ml-n1 mr-1",attrs:{"size":14,"width":1,"color":"white","indeterminate":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.connection?.status)+" ")],1)],1),_c('v-card-title',{class:['my-0', 'py-0', 'font-weight-bold', 'text-h5'],staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.webflowItem?.fieldData?.name)+" "),_c('v-spacer')],1),_c('v-list-item',{class:['mt-n5', 'my-0', 'py-0'],attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.webflowItem?.fieldData?.description)+" ")])],1)],1)],1),_c('ConnectionDetailDialog',{attrs:{"dialog":_vm.dialog,"connection":_vm.connection},on:{"update:dialog":function($event){_vm.dialog=$event},"changed":_vm.onChanged}})],1):_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar, article"}})
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>

    <v-col cols="12">
      <v-row v-if="selectedTenant">
        <v-col :class="['my-0', 'py-0']" cols="12" md="8">
          <v-card flat>
            <v-card-title
              :class="['font-weight-bold', 'text-h4']"
              style="word-break: break-word"
            >
              {{ $t("title") }}
            </v-card-title>
            <v-card-text>
              {{ $t("subtitle") }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col :class="['my-0', 'py-0']" cols="12" md="4">
          <v-alert
            :class="['mr-1', 'mt-5']"
            :color="
              refreshTokensLeft.extraUsed
                ? 'error'
                : refreshTokensLeft.tokensLeft == 0
                ? 'warning'
                : 'success'
            "
            border="left"
            elevation="2"
            colored-border
            icon="bolt"
            dense
          >
            <v-row align="center">
              <v-col class="grow">
                <strong>{{ refreshTokensLeft.tokensLeft }}</strong>
                {{ $t("refreshTokensLeft") }}
                <span v-if="refreshTokensLeft.extraUsed">
                  , <strong>{{ refreshTokensLeft.extraUsed }}</strong>
                  {{ $t("extraUsed") }}
                </span>
              </v-col>
              <v-col class="shrink">
                <v-btn
                  @click="infoDialog = true"
                  color="primary"
                  rounded
                  x-small
                >
                  {{ $t("label.more") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-if="me.isSuperuser">
        <v-col cols="12" md="2" sm="6">
          <v-autocomplete
            :items="tenants.edges"
            :label="$tc('label.tenant', 1)"
            :loading="$apollo.queries.tenants.loading"
            @change="refresh()"
            dense
            hide-details
            item-text="node.tenantName"
            item-value="node.id"
            outlined
            return-object
            v-model="selectedTenant"
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="3" v-if="!selectedTenant && me.isSuperuser">
          <v-alert
            border="left"
            class="mr-1"
            color="info"
            colored-border
            dense
            elevation="2"
            icon="info"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ $t("message.selectTenant") }}
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-if="selectedTenant">
        <v-col cols="12" md="2" sm="6">
          <v-text-field
            :label="$t('label.search')"
            @click:append-outer="refresh()"
            @click:clear="clearSearch()"
            @keydown.enter="refresh()"
            clearable
            dense
            hide-details
            outlined
            required
            v-model="search"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2" sm="6">
          <v-switch
            :label="$t('label.expandAll')"
            @change="expandAll($event)"
            class="mb-0 mt-2 py-0"
            hide-details
          ></v-switch>
        </v-col>

        <v-spacer />

        <v-col cols="12" md="2" align="right">
          <v-btn
            :class="['mr-2']"
            :loading="isSyncing"
            @click="sync()"
            color="primary"
            rounded
            small
            >{{ $t("label.sync") }}</v-btn
          >
          <v-btn
            @click="resetFilters()"
            color="primary"
            dark
            rounded
            small
            text
          >
            {{ $t("label.clearFilters") }}
          </v-btn>
        </v-col>

        <v-col cols="12" md="12">
          <v-data-table
            :expanded.sync="expanded"
            :footer-props="{
              'disable-pagination': $apollo.queries.allAssets.loading,
              'items-per-page-options': [10, 25, 50, 75, 100],
            }"
            :headers="headers"
            :items="allAssets.edges"
            :loading="$apollo.queries.allAssets.loading"
            :options.sync="dataTableOptions"
            :server-items-length="allAssets.totalCount"
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            item-key="node.id"
            show-expand
          >
            <template v-slot:[`item.node.avgDurationSec`]="{ item }">
              {{
                item.node.avgDurationSec
                  ? new Date(item.node.avgDurationSec * 1000)
                      .toISOString()
                      .substring(11, 19)
                  : null
              }}
            </template>
            <template v-slot:[`item.node.lastCompleted`]="{ item }">
              {{ item.node.lastCompleted | moment("YYYY-MM-DD HH:mm:SS") }}
            </template>
            <template v-slot:[`item.node.runStatus`]="{ item }">
              <v-chip :color="'info'" small v-if="item.node.runStatus">
                <v-progress-circular
                  :size="14"
                  :width="1"
                  class="ml-n1 mr-1"
                  color="white"
                  indeterminate
                ></v-progress-circular>
                {{ item.node.runStatus }}
              </v-chip>
            </template>

            <template v-slot:group.header="item">
              <td :colspan="headers.length">
                <v-btn icon @click="item.toggle">
                  <v-icon>{{ item.isOpen ? "close" : "add" }}</v-icon>
                </v-btn>
                {{ item.group }}
              </td>
            </template>
            <template v-slot:item.data-table-expand="{ item, isExpanded }">
              <td class="text-start">
                <v-btn
                  icon
                  class="v-data-table__expand-icon"
                  :class="{
                    'v-data-table__expand-icon--active': isExpanded,
                  }"
                >
                  <v-icon>expand_more</v-icon>
                </v-btn>
              </td>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="headersRun"
                  :hide-default-footer="true"
                  :hide-default-header="true"
                  :items="item.node.runSet.edges"
                  :items-per-page="100"
                  item-key="item.node.id"
                >
                  <template v-slot:[`item.node.start`]="{ item }">
                    {{ item.node.start | moment("YYYY-MM-DD HH:mm:SS") }}
                  </template>

                  <template v-slot:[`item.node.end`]="{ item }">
                    {{ item.node.end | moment("YYYY-MM-DD HH:mm:SS") }}
                  </template>
                  <template v-slot:[`item.node.duration`]="{ item }">
                    {{ convertToDuration(item.node.start, item.node.end) }}
                  </template>
                  <template v-slot:[`item.node.status`]="{ item }">
                    <v-chip
                      :color="
                        item.node.status == 'IN_PROGRESS'
                          ? 'info'
                          : item.node.status == 'COMPLETED'
                          ? 'success'
                          : item.node.status == 'FAILED'
                          ? 'error'
                          : ''
                      "
                      small
                    >
                      <v-progress-circular
                        :size="14"
                        :width="1"
                        class="ml-n1 mr-1"
                        color="white"
                        indeterminate
                        v-if="item.node.status == 'IN_PROGRESS'"
                      ></v-progress-circular>
                      <v-icon left small v-else>
                        {{
                          item.node.status == "COMPLETED"
                            ? "check_circle"
                            : item.node.status == "FAILED"
                            ? "cancel"
                            : ""
                        }}
                      </v-icon>
                      {{ item.node.status }}
                    </v-chip>
                  </template>
                </v-data-table>
              </td>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                :loading="$apollo.queries.allAssets.loading || isSyncing"
                :disabled="isInProgress"
                @click.stop="item"
                @click="createRun(item.node)"
                color="primary"
                icon
              >
                <v-icon>sync</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>

    <TokenInfoDialog :dialog.sync="infoDialog" />

    <RunCreateDialog
      :dialog.sync="runCreateDialog"
      :object.sync="runCreateItem"
      :refreshTokensLeft.sync="refreshTokensLeft"
      v-on:changed="onChanged"
    />
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TokenInfoDialog from "@/components/integrations/taps/runs/TokenInfoDialog.vue";
import RunCreateDialog from "@/components/integrations/taps/runs/RunCreateDialog.vue";

export default {
  name: "taps-list",
  components: {
    TokenInfoDialog,
    RunCreateDialog,
  },

  apollo: {
    allAssets: {
      query: gql`
        query allAssets(
          $first: Int
          $last: Int
          $before: String
          $after: String
          $orderBy: [String]
          $search: String
          $tenant: ID!
          $subType_In: String
        ) {
          allAssets(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: $orderBy
            search: $search
            tenant: $tenant
            subType_In: $subType_In
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                avgDurationSec
                name
                lastCompleted
                resource {
                  name
                }
                subType
                type
                runSet {
                  edges {
                    node {
                      start
                      end
                      status
                    }
                  }
                }
                runStatus
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allAssets,
      skip: true,
      pollInterval: 60000,
    },

    tenants: {
      query: gql`
        query tenants {
          tenants(orderBy: ["tenantName"]) {
            edges {
              node {
                id
                tenantName
                azureServicePrincipalId
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenants,
    },

    allRefreshTokenMutations: {
      query: gql`
        query allRefreshTokenMutations($first: Int, $tenant: ID!) {
          allRefreshTokenMutations(first: $first, tenant: $tenant) {
            edges {
              node {
                balance
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allRefreshTokenMutations,
      skip: true,
    },
  },

  data: function () {
    return {
      allRefreshTokenMutations: {},
      dataTableOptions: {
        groupBy: ["node.type"],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: true,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: [true],
      },
      selectedTenant: null,
      tenants: {},
      expanded: [],
      headers: [
        {
          text: this.$tc("label.resource", 1),
          value: "node.resource.name",
          sortable: false,
        },
        {
          text: this.$tc("label.asset", 1),
          value: "node.name",
          sortable: false,
        },
        {
          text: this.$t("lastCompleted"),
          value: "node.lastCompleted",
          sortable: false,
        },
        {
          text: this.$t("avgDuration"),
          value: "node.avgDurationSec",
          sortable: false,
        },
        {
          text: this.$t("label.status"),
          value: "node.runStatus",
          sortable: false,
        },
        {
          text: this.$t("label.action"),
          align: "center",
          value: "action",
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
      ],
      headersRun: [
        {
          text: this.$tc("label.pk", 1),
          value: "node.pk",
          sortable: false,
        },
        {
          text: this.$tc("label.name", 1),
          value: "node.start",
          sortable: false,
        },
        {
          text: this.$tc("label.name", 1),
          value: "node.end",
          sortable: false,
        },
        {
          text: this.$tc("label.name", 1),
          value: "node.duration",
          sortable: false,
        },
        {
          text: this.$tc("label.name", 1),
          value: "node.status",
          sortable: false,
        },
      ],
      allAssets: {},
      error: null,
      search: null,
      isSyncing: false,
      infoDialog: false,
      runCreateItem: {},
      runCreateDialog: false,
      polling: null,
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },

    isInProgress() {
      if (this.allAssets?.edges?.length > 0) {
        var status = [];
        this.allAssets.edges.forEach(function (item, index) {
          var itemStatus = item.node.runStatus;
          if (itemStatus) {
            status.push(itemStatus);
          }
        });

        if (status.includes("IN_PROGRESS")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    refreshTokensLeft() {
      if (this.allRefreshTokenMutations?.edges?.length > 0) {
        var balance = this.allRefreshTokenMutations.edges[0].node.balance;

        if (balance < 0) {
          var tokensLeft = 0;
          var extraUsed = balance * -1;
        } else {
          var tokensLeft = balance;
          var extraUsed = null;
        }
        return {
          tokensLeft: tokensLeft,
          extraUsed: extraUsed,
        };
      } else {
        return {};
      }
    },
  },

  watch: {
    selectedTenant() {
      this.syncRuns();
      this.refresh();
    },

    dataTableOptions() {
      this.refresh();
    },
  },

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;

    if (!this.me.isSuperuser) {
      this.selectedTenant = {
        node: this.me.tenant,
      };
    }

    this.pollSync();
  },

  mounted() {},

  methods: {
    createRun(item) {
      this.runCreateItem = item;
      this.runCreateDialog = true;
    },

    clearSearch() {
      this.search = null;
      this.refresh();
    },

    pollSync() {
      this.polling = setInterval(() => {
        this.syncRuns();
      }, 60000 * 5);
    },

    convertToDuration(startDateTime, endDateTime) {
      if (endDateTime) {
        const diff = Math.abs(new Date(startDateTime) - new Date(endDateTime));

        const result = new Date(diff).toISOString().slice(11, 19);

        return result;
      } else {
        return null;
      }
    },

    expandAll(val) {
      if (val) {
        this.expanded = this.allAssets.edges;
      } else {
        this.expanded = [];
      }
    },

    onChanged() {
      this.syncRuns();
    },

    refresh() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;

      var _first = itemsPerPage;
      var _last = null;
      var _before = null;
      var _after = null;

      if (page < this.page) {
        _first = null;
        _last = itemsPerPage;
        _before = this.allAssets.pageInfo.startCursor;
        _after = null;
      }

      if (page > this.page) {
        _first = itemsPerPage;
        _last = null;
        _before = null;
        _after = this.allAssets.pageInfo.endCursor;
      }

      var orderByList = ["type", "resource__name", "name"];

      this.$apollo.queries.allAssets.setVariables({
        first: _first,
        last: _last,
        before: _before,
        after: _after,
        orderBy: orderByList,
        search: this.search,
        tenant: this.selectedTenant.node.id,
        subType_In: "ADF pipeline,LA trigger,Dataset,Job",
      });

      this.$apollo.queries.allAssets.skip = false;
      this.$apollo.queries.allAssets.refresh();

      this.page = page;

      this.$apollo.queries.allRefreshTokenMutations.setVariables({
        first: 1,
        tenant: this.selectedTenant.node.id,
      });

      this.$apollo.queries.allRefreshTokenMutations.skip = false;
      this.$apollo.queries.allRefreshTokenMutations.refresh();
    },

    resetFilters() {
      this.clearSearch();
    },

    sync() {
      this.isSyncing = true;

      var payload = {
        tenant: this.selectedTenant.node.id,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation syncAll($tenant: ID!) {
              syncResourcesAndAssets(input: { tenant: $tenant }) {
                clientMutationId
              }
              syncRuns(input: { tenant: $tenant }) {
                clientMutationId
              }
            }
          `,
          variables: payload,
        })
        .then((response) => {
          this.refresh();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSyncing = false;
        });
    },

    syncRuns() {
      this.isSyncing = true;

      var payload = {
        tenant: this.selectedTenant.node.id,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation syncRuns($tenant: ID!) {
              syncRuns(input: { tenant: $tenant }) {
                clientMutationId
              }
            }
          `,
          variables: payload,
        })
        .then((response) => {
          this.refresh();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSyncing = false;
        });
    },

    beforeDestroy() {
      clearInterval(this.polling);
    },
  },
};
</script>

<style scoped>
.v-data-table
  >>> .v-data-table__wrapper
  tbody
  tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>

<i18n>
{
	"en": {
    "title": "Runs and refreshes",
    "subtitle": "Manage and monitor all your jobs, runs and refreshes from one place. Trigger a refresh manualy or check it's progress.",
		"avgDuration": "Average duration",
		"extraUsed": "extra used",
		"lastCompleted": "Last completed",
		"refreshTokensLeft": "refresh token(s) left"
	},
	"nl": {
    "title": "Runs en vernieuwingen",
    "subtitle": "Beheer en monitor al jouw jobs, runs en vernieuwingen vanaf één plek. Start handmatig een vernieuwing of controleer de voortgang ervan.",
		"avgDuration": "Gemiddelde duur",
		"extraUsed": "extra verbruikt",
		"lastCompleted": "Laatst voltooid",
		"refreshTokensLeft": "refresh token(s) over"
	},
	"de": {
    "title": "Runs and refreshes",
    "subtitle": "Verwalten und überwachen Sie alle Ihre Jobs, Läufe und Aktualisierungen an einem Ort. Starten Sie eine Aktualisierung manuell oder überprüfen Sie deren Fortschritt.",
		"avgDuration": "Durchschnittliche Dauer",
		"extraUsed": "zusätzlich verwendet",
		"lastCompleted": "Zuletzt abgeschlossen",
		"refreshTokensLeft": "Verbleibende refresh token(s)"
	}
}
</i18n>

<template>
  <v-row justify="center">
    <v-dialog max-width="600px" persistent scrollable v-model="dialog">
      <v-card>
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title class="primary white--text">
          {{ formTitle }}
        </v-card-title>

        <v-card-text class="pt-5">
          <v-form onSubmit="return false;" ref="form" v-model="isFormValid">
            <v-row class="my-0 py-0">
              <v-col
                :class="['my-0', 'py-0']"
                cols="12"
                md="8"
                v-if="me.isSuperuser"
              >
                <v-autocomplete
									@change="onTenantChange()"
                  :items="tenants.edges"
                  :label="$tc('label.tenant', 1)"
                  :loading="$apollo.queries.tenants.loading"
                  :rules="[rules.required]"
                  clearable
                  item-text="node.tenantName"
                  item-value="node.id"
                  outlined
                  v-model="editedItem.tenantNodeId"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                :class="['my-0', 'py-0']"
                cols="12"
                md="4"
                v-if="
                  me.isSuperuser && editedItem.reportType === 'POWERBIREPORT'
                "
              >
                <v-switch
                  :class="['mt-3']"
                  :label="$t('label.editable')"
                  v-model="editedItem.isEditable"
                ></v-switch>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12">
                <v-text-field
                  :counter="100"
                  :label="`${$tc('label.report', 1)} ${$tc('label.name', 1)}`"
                  :rules="[rules.required, rules.maxLength(100)]"
                  outlined
                  v-model="editedItem.reportName"
                ></v-text-field>
              </v-col>
              <v-col
                :class="['my-0', 'py-0']"
                cols="12"
                md="8"
                v-if="me.isSuperuser"
              >
                <v-autocomplete
                  :items="reportTypeChoices"
                  :label="`${$tc('label.report', 1)} ${$tc('label.type', 1)}`"
                  :rules="[]"
                  clearable
                  item-text="text"
                  item-value="value"
                  outlined
                  v-model="editedItem.reportType"
                >
                </v-autocomplete>
              </v-col>

              <v-col
                :class="['my-0', 'py-0']"
                cols="12"
                md="4"
                v-if="me.isSuperuser"
              >
                <v-switch
                  :class="['mt-3']"
                  :label="$t('label.standard')"
                  v-model="editedItem.isStandard"
                ></v-switch>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" v-if="me.isSuperuser">
                <v-text-field
                  :counter="36"
                  :label="`${$tc('label.group', 1)} ${$tc('label.id', 1)}`"
                  :rules="[
                    rules.required,
                    rules.minLength(36),
                    rules.maxLength(36),
                  ]"
                  outlined
                  v-model="editedItem.groupId"
                ></v-text-field>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" v-if="me.isSuperuser">
                <v-text-field
                  :counter="36"
                  :label="`${$tc('label.report', 1)} ${$tc('label.id', 1)}`"
                  :rules="[
                    rules.required,
                    rules.minLength(36),
                    rules.maxLength(36),
                  ]"
                  outlined
                  v-model="editedItem.reportId"
                ></v-text-field>
              </v-col>
              <v-col
                :class="['my-0', 'py-0']"
                cols="12"
                v-if="
                  me.isSuperuser && editedItem.reportType === 'POWERBIREPORT'
                "
              >
                <v-text-field
                  :counter="36"
                  :label="`${$tc('label.dataset', 1)} ${$tc('label.id', 1)}`"
                  :rules="[
                    rules.required,
                    rules.minLength(36),
                    rules.maxLength(36),
                  ]"
                  outlined
                  v-model="editedItem.datasetId"
                ></v-text-field>
              </v-col>
              <v-col
                :class="['my-0', 'py-0']"
                cols="12"
                md="8"
                v-if="
                  me.isSuperuser && editedItem.reportType === 'POWERBIREPORT'
                "
              >
                <v-text-field
                  :counter="36"
                  :label="`${$tc('label.binded', 1)} ${$tc(
                    'label.group',
                    1
                  )} ${$tc('label.id', 1)}`"
                  :rules="[rules.maxLength(36)]"
                  class="subtitle-2"
                  outlined
                  v-model="editedItem.bindedGroupId"
                ></v-text-field>
              </v-col>
              <v-col
                :class="['my-0', 'py-0']"
                cols="12"
                md="4"
                v-if="
                  me.isSuperuser && editedItem.reportType === 'POWERBIREPORT'
                "
              >
                <v-switch
                  :class="['mt-3']"
                  :label="$t('label.binded')"
                  v-model="editedItem.isBinded"
                ></v-switch>
              </v-col>
              <v-col
                :class="['my-0', 'py-0']"
                cols="12"
                md="8"
                v-if="
                  me.isSuperuser && editedItem.reportType === 'POWERBIREPORT'
                "
              >
                <v-text-field
                  :counter="36"
                  :label="`${$tc('label.binded', 1)} ${$tc(
                    'label.dataset',
                    1
                  )} ${$tc('label.id', 1)}`"
                  :rules="[rules.maxLength(36)]"
                  class="subtitle-2"
                  outlined
                  v-model="editedItem.bindedDatasetId"
                ></v-text-field>
              </v-col>
              <v-col
                :class="['my-0', 'py-0']"
                cols="12"
                md="4"
                v-if="me.isSuperuser"
              >
                <v-switch
                  :class="['mt-3']"
                  label="Direct Query"
                  v-model="editedItem.isDirectQuery"
                ></v-switch>
              </v-col>
              <v-col
                :class="['my-0', 'py-0']"
                cols="12"
                md="6"
                v-if="
                  me.isSuperuser && editedItem.reportType === 'POWERBIREPORT'
                "
              >
                <v-text-field
                  :counter="30"
                  :label="$t('label.pbiDateTable')"
                  :rules="[rules.maxLength(30)]"
                  outlined
                  v-model="editedItem.pbiDateTable"
                ></v-text-field>
              </v-col>
              <v-col
                :class="['my-0', 'py-0']"
                cols="12"
                md="6"
                v-if="
                  me.isSuperuser && editedItem.reportType === 'POWERBIREPORT'
                "
              >
                <v-text-field
                  :counter="30"
                  :label="$t('label.pbiDateColumn')"
                  :rules="[rules.maxLength(30)]"
                  outlined
                  v-model="editedItem.pbiDateColumn"
                ></v-text-field>
              </v-col>
							<v-col
                :class="['my-0', 'py-0']"
                cols="12"
                v-if="me.isSuperuser"
              >
                <v-autocomplete
									:item-text="(item) => `${item.node.pk} - ${item.node.tap.name}`"
                  :items="allConnections.edges"
                  :label="$tc('label.tap', 1)"
                  :loading="$apollo.queries.allConnections.loading"
                  clearable
                  item-value="node.id"
                  outlined
                  v-model="editedItem.connection"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions :class="['pb-5', 'pr-5']">
          <v-spacer></v-spacer>
          <v-btn :disabled="isSaving" @click="close()" rounded text>{{
            $t("label.cancel")
          }}</v-btn>
          <v-btn
            :disabled="!isFormValid"
            :loading="isSaving"
            @click="save()"
            color="primary"
            outlined
            rounded
            >{{ $t("label.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
  name: "report-edit-dialog",
  props: ["dialog", "object"],

  apollo: {
    tenants: {
      query: gql`
        query tenants {
          tenants(orderBy: ["tenantName"]) {
            edges {
              node {
                id
                tenantName
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenants,
      skip: true,
      pollInterval: 60000,
    },
    allConnections: {
      query: gql`
        query allConnections($tenant: ID!) {
          allConnections(tenant: $tenant) {
            edges {
              node {
                id
                pk
                tap {
                  name
                }
              }
            }
          }
        }
      `,
      variables() {
				return {
					tenant: this.editedItem.tenantNodeId
				}
			},
      fetchPolicy: "cache-and-network",
      update: (data) => data.allConnections,
      skip() {
				return !this.editedItem.tenantNodeId
			},
    },
  },

  data() {
    return {
      error: null,
      isFormValid: false,
      isSaving: false,

      allConnections: {},
      tenants: {},

      editedItem: {
        id: null,
        bindedDatasetId: null,
        bindedGroupId: null,
				connection: null,
        datasetId: null,
        groupId: null,
        isBinded: false,
        isDirectQuery: false,
        isEditable: false,
        isStandard: false,
        pbiDateColumn: null,
        pbiDateTable: null,
        reportId: null,
        reportName: null,
        reportType: null,
        tenantNodeId: null,
      },
      defaultItem: {
        id: null,
        bindedDatasetId: null,
        bindedGroupId: null,
				connection: null,
        datasetId: null,
        groupId: null,
        isBinded: false,
        isDirectQuery: false,
        isEditable: false,
        isStandard: false,
        pbiDateColumn: null,
        pbiDateTable: null,
        reportId: null,
        reportName: null,
        reportType: null,
        tenantNodeId: null,
      },
      reportTypeChoices: [
        { value: "POWERBIREPORT", text: "Power BI Report" },
        { value: "PAGINATEDREPORT", text: "Paginated Report" },
      ],
    };
  },
  computed: {
    formTitle() {
      return `${this.$t("label.edit")} ${this.$tc("label.report", 1)}`;
    },

    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {
    // reset form when dialog open or close
    dialog(val) {
      // if open dialog, initialize selections
      if (val) {
        this.$apollo.queries.tenants.skip = false;
      }

      this.resetForm();
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    resetForm() {
      // reset form state
      this.error = null;
      this.isSaving = false;
      this.panel = 0;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      // when editing, set form with object
      if (this.dialog && this.object) {
        this.editedItem.id = this.object.id;
        this.editedItem.bindedDatasetId = this.object.bindedDatasetId;
        this.editedItem.bindedGroupId = this.object.bindedGroupId;
				this.editedItem.connection = this.object?.connection?.id
        this.editedItem.datasetId = this.object.datasetId;
        this.editedItem.groupId = this.object.groupId;
        this.editedItem.isBinded = this.object.isBinded;
        this.editedItem.isDirectQuery = this.object.isDirectQuery;
        this.editedItem.isEditable = this.object.isEditable;
        this.editedItem.isStandard = this.object.isStandard;
        this.editedItem.pbiDateColumn = this.object.pbiDateColumn;
        this.editedItem.pbiDateTable = this.object.pbiDateTable;
        this.editedItem.reportId = this.object.reportId;
        this.editedItem.reportName = this.object.reportName;
        this.editedItem.reportType = this.object.reportType;
        this.editedItem.tenantNodeId = this.object.tenant.id;
      }
      // when new or closing, set form with default
      else {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      }
    },

    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

		onTenantChange() {
      this.editedItem.connection = null; // Reset connection selection when tenant changes
      this.$apollo.queries.allConnections.refresh(); // Refresh the connections query
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      // set form state
      this.error = null;
      this.isSaving = true;

      // prepare api call payload
      var payload = {
        bindedDatasetId: this.editedItem.bindedDatasetId,
        bindedGroupId: this.editedItem.bindedGroupId,
        connection: this.editedItem.connection,
        datasetId: this.editedItem.datasetId,
        groupId: this.editedItem.groupId,
        isBinded: this.editedItem.isBinded,
        isDirectQuery: this.editedItem.isDirectQuery,
        isEditable: this.editedItem.isEditable,
        isStandard: this.editedItem.isStandard,
        pbiDateColumn: this.editedItem.pbiDateColumn,
        pbiDateTable: this.editedItem.pbiDateTable,
        reportId: this.editedItem.reportId,
        reportName: this.editedItem.reportName,
        reportType: this.editedItem.reportType,
        tenantNodeId: this.editedItem.tenantNodeId,
      };

      // if editing
      if (this.object) {
        // prepare graphql mutation payload
        payload.id = this.editedItem.id;

        // update
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation updateReport($input: UpdateReportInput!) {
                updateReport(input: $input) {
                  report {
                    id
                    reportName
                    groupId
                    reportId
                    datasetId
                    pbiDateTable
                    pbiDateColumn
                    isEditable
                    isDirectQuery
                    isActive
                    tenant {
                      id
                      tenantName
                    }
                  }
                }
              }
            `,
            // Parameters
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            // update parent object
            this.$emit("changed", response);
            this.close();

            // show snackbar
            const payload = {
              color: "success",
              message: `Report successfully edited`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);
          })
          .catch((error) => {
            this.error = error.graphQLErrors[0].message;
            this.isSaving = false;

            // show snackbar
            const payload = {
              color: "error",
              message: this.error,
            };
            this.$store.dispatch("snackbar/showMessage", payload);
          })
          .finally(() => {});
      }
    },
  },
};
</script>

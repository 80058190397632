import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { createProvider } from "./vue-apollo";
import Print from "vue-print-nb";
import i18n from "./i18n";
import VueGtm from "@gtm-support/vue2-gtm";

Vue.use(Print);

Vue.config.productionTip = false;

const moment = require("moment");
require("moment/locale/nl");
Vue.use(require("vue-moment"), {
	moment,
});

Vue.use(VueGtm, {
	id: [
		"GTM-5ZV4VQ6",
	],
	defer: false,
	enabled: true,
	debug: false,
	loadScript: true,
	trackOnNextTick: false,
});

new Vue({
	router,
	store,
	vuetify,
	apolloProvider: createProvider(),
	i18n,
	render: (h) => h(App),
}).$mount("#app");

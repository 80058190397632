import { ACCESS_TOKEN_KEY } from "@/vue-apollo";
import i18n from "@/i18n.js";
import store from "../store";
import Vue from "vue";
import VueRouter from "vue-router";

import AddOns from "@/views/AddOns.vue";
import ControlPanel from "@/views/ControlPanel.vue";
import GroupDetail from "@/views/GroupDetail.vue";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import PaginatedReportDetail from "@/views/PaginatedReportDetail.vue";
import ReportDetail from "@/views/ReportDetail.vue";
import Reports from "@/views/Reports.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import Settings from "@/views/Settings.vue";
import SignUp from "@/views/SignUp.vue";
import Status from "@/views/Status.vue";
import TapConnect from "@/views/TapConnect.vue";
import Taps from "@/views/Taps.vue";
import Targets from "@/views/Targets.vue";
import Users from "@/views/Users.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/group/:groupNodeId",
    name: "group-detail",
    component: GroupDetail,
    meta: {
      requiresAuth: true,
      title: i18n.tc("label.group", 1),
    },
  },
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true,
      title: i18n.tc("label.home", 1),
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: i18n.t("label.login"),
    },
  },
  {
    path: "/paginated-report/:reportNodeId",
    name: "paginated-report-detail",
    component: PaginatedReportDetail,
    meta: {
      requiresAuth: true,
    },
    // title via report detail
  },
  {
    path: "/report/:reportNodeId",
    name: "report-detail",
    component: ReportDetail,
    meta: {
      requiresAuth: true,
      // title via report detail
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
    meta: {
      requiresAuth: true,
      title: i18n.tc("label.report", 2),
    },
  },
  {
    path: "/resetpassword/:uidb64/:token",
    name: "resetpassword",
    component: ResetPassword,
  },
  {
    path: "/signup",
    name: "sign-up",
    component: SignUp,
    props: true,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      requiresAuth: true,
      title: i18n.tc("label.setting", 2),
    },
  },
  {
    path: "/status",
    name: "status",
    component: Status,
    meta: {
      requiresAuth: true,
      title: i18n.tc("label.status", 2),
    },
  },
  {
    path: "/add-ons",
    name: "add-ons",
    component: AddOns,
    meta: {
      requiresAuth: true,
      title: i18n.tc("label.addOn", 2),
    },
  },
  {
    path: "/taps/:externalId/connect",
    name: "tap-connect",
    component: TapConnect,
    meta: {
      requiresAuth: true,
    },
    // title via TapConnect.vue
  },
  {
    path: "/taps",
    name: "taps",
    component: Taps,
    meta: {
      requiresAuth: true,
      title: i18n.tc("label.tap", 2),
    },
  },
  {
    path: "/targets",
    name: "targets",
    component: Targets,
    meta: {
      requiresAuth: true,
      title: i18n.tc("label.target", 2),
    },
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: {
      requiresAuth: true,
      title: i18n.tc("label.user", 2),
    },
  },
  {
    path: "/control-panel",
    name: "control-panel",
    component: ControlPanel,
    meta: {
      requiresAuth: true,
      title: i18n.tc("label.controlPanel", 1),
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("app/mutateAppBarTitle", null);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      next();
    } else {
      next({
        name: "login",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }

  if (to.meta.title) {
    var title = to.meta.title;
  } else {
    var title = "TapTarget portal";
  }

  document.title = `${title} | TapTarget`;
});

export default router;

<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="1200px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          {{ formTitle }}
          <v-spacer></v-spacer>
          <v-btn icon @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-row class="my-0 py-0">
          <v-col :class="['my-0', 'py-0']" cols="12">
            <v-stepper flat v-model="step" vertical>
              <v-stepper-step :complete="step > 1" step="1">
                Selecteer tenant
                <small v-if="step > 1">{{
                  selectedTenant?.node?.tenantName
                }}</small>
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-card flat>
                  <v-card-text>
                    <v-row class="my-0 py-0">
                      <v-col cols="12" md="4" class="my-0 py-0">
                        <v-autocomplete
                          :items="tenants.edges"
                          :label="$tc('label.tenant', 1)"
                          :loading="$apollo.queries.tenants.loading"
                          :rules="[rules.required]"
                          item-text="node.tenantName"
                          item-value="node.id"
                          outlined
                          return-object
                          v-model="selectedTenant"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :color="'primary'"
                      :disabled="!selectedTenant"
                      @click="step = 2"
                      rounded
                      small
                    >
                      {{ $t("label.next") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <v-stepper-step :complete="step > 2" step="2">
                Selecteer dataset
                <small v-if="step > 2">
                  {{ selectedDataset }}
                </small>
              </v-stepper-step>

              <v-stepper-content step="2">
                <v-card flat>
                  <v-card-text>
                    <v-row
                      class="fill-height"
                      align-content="center"
                      justify="center"
                      v-if="isSyncing"
                    >
                      <v-col class="text-center" cols="12">
                        Power BI wordt gesynct
                      </v-col>
                      <v-col cols="6">
                        <v-progress-linear
                          indeterminate
                          rounded
                          height="6"
                        ></v-progress-linear>
                      </v-col>
                    </v-row>

                    <v-row class="my-0 py-0" v-else>
                      <v-col cols="12" class="my-0 py-0">
                        <v-autocomplete
                          :items="datasets.edges"
                          :label="$tc('label.dataset', 1)"
                          :loading="$apollo.queries.datasets.loading"
                          :rules="[rules.required]"
                          item-text="node.name"
                          item-value="node.id"
                          outlined
                          return-object
                          v-model="selectedDataset"
                        >
                          <template slot="selection" slot-scope="{ item }">
                            <v-chip small>
                              {{ item.node.resource.name }} -
                              {{ item.node.name }}
                            </v-chip>
                          </template>
                          <template slot="item" slot-scope="{ item }">
                            {{ item.node.resource.name }} - {{ item.node.name }}
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      :class="['black--text', 'mr-2']"
                      @click="step = 1"
                      rounded
                      small
                      text
                    >
                      {{ $t("label.back") }}
                    </v-btn>
                    <v-btn
                      :color="'primary'"
                      :disabled="!selectedDataset"
                      @click="save()"
                      rounded
                      small
                    >
                      {{ $t("label.bind") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
  name: "report-bind-dialog",
  components: {},
  props: ["dialog"],

  apollo: {
    tenants: {
      query: gql`
        query tenants {
          tenants(orderBy: ["tenantName"]) {
            edges {
              node {
                id
                tenantName
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenants,
    },

    datasets: {
      query: gql`
        query datasets(
          $first: Int
          $tenant: ID!
          $type_In: String!
          $subType_In: String!
        ) {
          allAssets(
            first: $first
            tenant: $tenant
            type_In: $type_In
            subType_In: $subType_In
            orderBy: ["name"]
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                name
                resource {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.allAssets,
      skip: true,
      result() {},
    },
  },

  data() {
    return {
      datasets: {},
      error: null,
      isSaving: false,
      isSyncing: false,
      selectedDataset: null,
      selectedTenant: null,
      step: 1,
      tenants: {},
    };
  },
  computed: {
    formTitle() {
      return `${this.$t("label.bind")} ${this.$tc("label.dataset", 1)}`;
    },

    me() {
      return this.$store.state.user.me || {};
    },
  },
  watch: {
    dialog(val) {
      this.resetForm();
    },

    selectedTenant: {
      deep: true,
      handler(val) {
        if (val) {
          this.step = 2;
          this.syncResourcesAndAssets();
        }
      },
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    refresh() {
      this.$apollo.queries.datasets.setVariables({
        first: 100,
        tenant: this.selectedTenant?.node?.id,
        type_In: "Power BI",
        subType_In: "Dataset",
      });

      this.$apollo.queries.datasets.skip = false;
      this.$apollo.queries.datasets.refresh();
    },

    resetForm() {
      this.error = null;
      this.isSaving = false;

      this.isStandard = false;
      this.selectedDataset = null;
      this.selectedTenant = null;
      this.step = 1;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    save() {
      this.$emit("binded", this.selectedDataset, this.selectedTenant);
      this.close();
    },

    syncResourcesAndAssets() {
      this.isSyncing = true;

      var payload = {
        tenant: this.selectedTenant.node.id,
        syncAzure: false,
        syncOrchest: false,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation syncResourcesAndAssets(
              $input: SyncResourcesAndAssetsInput!
            ) {
              syncResourcesAndAssets(input: $input) {
                clientMutationId
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          this.refresh();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSyncing = false;
        });
    },
  },
};
</script>

<style scoped></style>

<i18n>
  {}
</i18n>

<template>
  <v-card
    :color="color"
    :loading="loading ? 'white' : null"
    @click="open()"
    class="rounded-xl"
    height="130px"
  >
    <v-card-title class="mb-0 pb-0">
      {{ title }}
    </v-card-title>

    <v-card-text
      :class="[
        'd-flex',
        `text-${heading}`,
      ]"
      :style="{ height: textHeight }"
    >
      {{ text }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: [
    "color",
    "heading",
    "icon",
    "loading",
    "route",
    "text",
    "title",
    "storeAction",
    "storeValue",
  ],
  apollo: {},

  data() {
    return {};
  },

  computed: {
    textHeight() {
      return this.heading === "h3" ? "calc(100% - 57px)" : "calc(100% - 54px)";
    },
  },
  watch: {},
  created() {},
  methods: {
    open() {
      this.$emit("open");
    },
  },
};
</script>

<template>
	<v-container fluid>
		<v-form ref="form">
			<v-row class="mt-2">
				<v-col class="py-0" cols="12">
					<v-alert tile type="error" v-if="error">{{
						error
					}}</v-alert>
				</v-col>
				<v-col cols="12" md="6">
					<v-row>
						<v-col class="py-0" cols="12" md="12">
							<label
								><h4>
									{{ $t("label.email") }}
								</h4></label
							>
							<v-text-field
								:disabled="!editMode"
								:hint="$t('emailHint')"
								:rules="[rules.required, rules.maxLength(100)]"
								class="mt-1"
								outlined
								type="email"
								v-model="editedItem.email"
							></v-text-field>
						</v-col>

						<v-col class="py-0" cols="12" md="6">
							<label
								><h4>
									{{ $t("label.firstName") }}
								</h4></label
							>
							<v-text-field
								:disabled="!editMode"
								:rules="[rules.required, rules.maxLength(100)]"
								class="mt-1"
								outlined
								v-model="editedItem.firstName"
							></v-text-field>
						</v-col>

						<v-col class="py-0" cols="12" md="6">
							<label
								><h4>{{ $t("label.lastName") }}</h4></label
							>
							<v-text-field
								:disabled="!editMode"
								:rules="[rules.required, rules.maxLength(100)]"
								class="mt-1"
								outlined
								v-model="editedItem.lastName"
							></v-text-field>
						</v-col>

						<v-col
							class="py-0"
							cols="8"
							md="4"
							v-if="me.identityProvider == 'TAPTARGET'"
						>
							<label
								><h4>{{ $tc("label.password", 1) }}</h4></label
							>
							<v-text-field
								class="mt-1"
								disabled
								outlined
								type="password"
								value="XXXXXXXXXX"
							></v-text-field>
						</v-col>

						<v-col
							class="py-0"
							cols="4"
							md="2"
							v-if="me.identityProvider == 'TAPTARGET'"
						>
							<v-btn
								@click="passwordDialog = true"
								class="mt-10"
								color="primary"
								dark
								outlined
								rounded
								small
								>{{ $t("label.edit") }}</v-btn
							>
						</v-col>

						<v-col
							class="py-0"
							cols="8"
							md="4"
							v-if="me.identityProvider == 'TAPTARGET'"
						>
							<label
								><h4>
									{{ $t("label.2fa") }}
								</h4></label
							>
							<v-text-field
								:value="
									me.enabled2fa && me.verified2fa
										? $t('2faEnabled')
										: me.enabled2fa && !me.verified2fa
										? $t('2faNotVerified')
										: $t('2faNotEnabled')
								"
								:hint="
									me.enabled2fa && !me.verified2fa
										? $t('2faHint')
										: ''
								"
								persistent-hint
								class="mt-1"
								disabled
								outlined
							>
								<v-icon
									:color="
										me.enabled2fa && me.verified2fa
											? 'success'
											: me.enabled2fa && !me.verified2fa
											? 'warning'
											: 'error'
									"
									slot="prepend-inner"
								>
									{{
										me.enabled2fa && me.verified2fa
											? "verified_user"
											: me.enabled2fa && !me.verified2fa
											? "shield"
											: "security"
									}}
								</v-icon>
							</v-text-field>
						</v-col>

						<v-col
							cols="4"
							md="2"
							class="py-0"
							v-if="me.identityProvider == 'TAPTARGET'"
						>
							<v-btn
								@click="disableTfa()"
								class="mt-10"
								color="error"
								outlined
								rounded
								small
								v-if="me.enabled2fa && me.verified2fa"
								>{{ $t("label.disable") }}</v-btn
							>
							<v-btn
								:disabled="me.enabled2fa && !me.verified2fa"
								@click="enableTfa()"
								class="mt-10"
								color="primary"
								outlined
								rounded
								small
								v-else
								>{{ $t("label.enable") }}</v-btn
							>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-form>

		<PasswordEditDialog :dialog.sync="passwordDialog" />
		<TfaDisableDialog
			:dialog.sync="tfaDisableDialog"
			v-on:changed="onChanged"
		/>
		<TfaEnableDialog
			:dialog.sync="tfaEnableDialog"
			ref="TfaEnableDialog"
			v-on:changed="onChanged"
		/>
	</v-container>
</template>
<script>
import gql from "graphql-tag";
import PasswordEditDialog from "@/components/users/PasswordEditDialog.vue";
import rules from "@/utils/rules.js";
import TfaDisableDialog from "@/components/users/TfaDisableDialog.vue";
import TfaEnableDialog from "@/components/users/TfaEnableDialog.vue";

export default {
	name: "profile-tab",
	props: ["editMode"],
	components: {
		PasswordEditDialog,
		TfaDisableDialog,
		TfaEnableDialog,
	},

	apollo: {},
	data: function () {
		return {
			editedItem: {
				email: null,
				firstName: null,
				lastName: null,
			},
			error: null,
			isSaving: false,
			tfaDisableDialog: false,
			tfaEnableDialog: false,
			passwordDialog: false,
		};
	},
	computed: {
		me() {
			return this.$store.state.user.me;
		},
	},
	watch: {},
	created() {
		this.rules = rules;

		this.resetForm();
	},
	mounted() {},
	methods: {
		disableTfa() {
			this.tfaDisableDialog = true;
		},

		enableTfa() {
			this.tfaEnableDialog = true;
			this.$refs.TfaEnableDialog.enableTfa();
		},

		onChanged() {
			this.$emit("changed");
		},

		resetForm() {
			this.error = null;
			this.isSaving = false;

			if (this.$refs.form) {
				this.$refs.form.resetValidation();
			}

			this.editedItem.email = this.me.email;
			this.editedItem.firstName = this.me.firstName;
			this.editedItem.lastName = this.me.lastName;
		},

		save() {
			if (!this.$refs.form.validate()) {
				return;
			}

			this.error = null;
			this.isSaving = true;

			var payload = {
				email: this.editedItem.email,
				firstName: this.editedItem.firstName,
				lastName: this.editedItem.lastName,
			};

			this.$apollo
				.mutate({
					mutation: gql`
						mutation updateMyProfile(
							$input: UpdateMyProfileInput!
						) {
							updateMyProfile(input: $input) {
								user {
									id
								}
							}
						}
					`,
					variables: {
						input: payload,
					},
				})
				.then((response) => {
					this.$emit("changed", response);

					const payload = {
						color: "success",
						message: `Profile successfully edited`,
					};
					this.$store.dispatch("snackbar/showMessage", payload);
				})
				.catch((error) => {
					this.error = error.graphQLErrors[0].message;
					this.isSaving = false;
				})
				.finally(() => {});
		},
	},
};
</script>

<i18n>
{
  "en": {
	"2faEnabled": "2FA enabled",
	"2faHint": "Please contact your administrator",
	"2faNotEnabled": "Please enable 2fa",
	"2faNotVerified": "2FA enabled, not verified",
	"emailHint": "Changing email requires to login again"
  },
  "nl": {
	"2faEnabled": "2FA ingeschakeld",
	"2faHint": "Neem contact op met uw beheerder",
	"2faNotEnabled": "Schakel 2FA in",
	"2faNotVerified": "2FA ingeschakeld, niet geverifieerd",
	"emailHint": "Het wijzigen van het e-mailadres vereist opnieuw inloggen"
  },
  "de": {
	"2faEnabled": "2FA aktiviert",
	"2faHint": "Bitte kontaktieren Sie Ihren Administrator",
	"2faNotEnabled": "Bitte 2FA aktivieren",
	"2faNotVerified": "2FA aktiviert, nicht verifiziert",
	"emailHint": "Die Änderung der E-Mail erfordert erneutes Anmelden"
  }
}
</i18n>

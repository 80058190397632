<template>
	<v-layout>
		<v-container
			class="ma-0 pa-0 align-start"
			fill-height
			fluid
			v-if="$vuetify.breakpoint.mdAndUp"
		>
			<v-container
				:style="containerStyle"
				class="ma-0 pa-0 align-start"
				fill-height
				fluid
			>
				<v-toolbar class="ma-0 pa-0" dense>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								@click="$router.push({ name: 'reports' })"
								class="mr-2"
								color="primary"
								rounded
								small
								text
								v-bind="attrs"
								v-on="on"
							>
								<v-icon outline> arrow_back </v-icon>
							</v-btn>
						</template>
						<span>{{ $t("label.back") }}</span>
					</v-tooltip>
				</v-toolbar>

				<div id="reportContainer" :style="iFrameStyle" />
			</v-container>
		</v-container>

		<v-container class="ma-0 pa-0 align-start" fill-height fluid v-else>
			<v-row class="ma-0 pa-0">
				<v-bottom-navigation v-if="!disableAppBar">
					<v-btn
						class="navigation-btn"
						small
						@click="$router.push({ name: 'reports' })"
					>
						<span>{{ $t("label.back") }}</span>

						<v-icon>arrow_back</v-icon>
					</v-btn>

					<v-btn
						class="navigation-btn"
						small
						@click="fullscreenMobile()"
					>
						<span>{{ $t("label.fullscreen") }}</span>

						<v-icon>fullscreen</v-icon>
					</v-btn>
				</v-bottom-navigation>
				<v-col class="ma-0 pa-0" cols="12" v-else>
					<v-system-bar color="primary" dark lights-out window>
						<span>{{ report.reportName }}</span>
						<v-spacer></v-spacer>
						<v-icon @click="fullscreenMobileExit()"
							>fullscreen_exit</v-icon
						>
					</v-system-bar></v-col
				>
				<v-col class="ma-0 pa-0" cols="12">
					<v-container class="ma-0 pa-0" fluid>
						<div :style="iFrameStyle" id="reportContainer" />
					</v-container>
				</v-col>
			</v-row>
		</v-container>
	</v-layout>
</template>

<script>
import * as pbi from "powerbi-client";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";

export default {
	name: "paginated-report-detail-page",
	components: {},

	apollo: {
		pbiEmbedToken: {
			query: gql`
				query pbiEmbedToken($reportNodeId: ID!) {
					pbiEmbedToken(reportNodeId: $reportNodeId) {
						expiration
						odataContext
						token
						tokenId
					}
				}
			`,
			variables() {
				return {
					reportNodeId: this.$route.params.reportNodeId,
				};
			},
			fetchPolicy: "network-only",
			skip: true,
			update: (data) => data.pbiEmbedToken,
			result({ data }) {
				var expiration = Date.parse(data.pbiEmbedToken.expiration);

				var safetyInterval = 5 * 60 * 1000;
				var currentTime = Date.now();

				// time until token refresh in milliseconds
				var timeout = expiration - currentTime - safetyInterval;
				// var timeout = (expiration - currentTime - safetyInterval) / 100;

				console.log(
					"Report Embed Token will be updated in " +
						timeout / 60000 +
						" minutes."
				);

				// set the refresh of the next token
				this.$apollo.queries.pbiEmbedToken.stopPolling();
				this.$apollo.queries.pbiEmbedToken.startPolling(timeout); //ms

				if (this.nrOfTokens == 0) {
					this.loadReport();
				} else {
					this.updateToken();
				}

				this.nrOfTokens++;
			},
			// Error handling
			error(error) {
				console.error("We've got an error!", error);
				this.error = error.graphQLErrors
					.map((error) => error.message)
					.join(", ");
			},
		},

		report: {
			query: gql`
				query report($reportNodeId: ID!) {
					report(id: $reportNodeId) {
						id
						reportName
						groupId
						reportId
						datasetId
						isBinded
						bindedGroupId
						bindedDatasetId
						pbiDateColumn
						pbiDateTable
						isEditable
						reportType
						isStandard
						tenant {
							tenantName
						}
					}
				}
			`,
			variables() {
				return {
					reportNodeId: this.$route.params.reportNodeId,
				};
			},
			fetchPolicy: "network-only",
			update: (data) => data.report,
			pollInterval: 60000, // ms
			result() {
				this.setAppBarTitle();

				this.$apollo.queries.pbiEmbedToken.skip = false;
			},
		},
	},

	data() {
		return {
			report: {},
			pbiEmbedToken: {},
			nrOfTokens: 0,
			error: null,
		};
	},

	computed: {
		iFrameStyle() {
			var breakpointName = this.$vuetify.breakpoint.name;

			if (breakpointName == "xs" && !this.disableAppBar) {
				return "width: 100%; height: 86vh; border-style: none;";
			} else if (breakpointName == "xs" && this.disableAppBar) {
				return "width: 100%; height: 95vh; border-style: none;";
			} else if (breakpointName == "sm" && !this.disableAppBar) {
				return "width: 100%; height: 71vh; border-style: none;";
			} else if (breakpointName == "sm" && this.disableAppBar) {
				return "width: 100%; height: 90vh; border-style: none;";
			} else if (breakpointName == "xl") {
				return "width: 100%; height: 88vh; border-style: none;";
			} else {
				return "width: 100%; height: 86vh; border-style: none;";
			}
		},

		pageDrawerWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case "md":
					return "15%";
				case "lg":
					return "15%";
				case "xl":
					return "13%";
				default:
					return "15%";
			}
		},

		reportFiltersDrawerWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case "xs":
					return "100%";
				case "sm":
					return "100%";
				case "md":
					return "100%";
				case "lg":
					return "35%";
				case "xl":
					return "25%";
				default:
					return "100%";
			}
		},

		containerStyle() {
			if (!this.pageDrawer) {
				return "width: 100%;";
			} else {
				switch (this.$vuetify.breakpoint.name) {
					case "md":
						return "width: 85%;";
					case "lg":
						return "width: 85%;";
					case "xl":
						return "width: 87%;";
					default:
						return "width: 85%;";
				}
			}
		},

		disableAppBar() {
			return this.$store.state.app.disableAppBar;
		},
	},
	watch: {},
	created() {
		this.hasPermission = helper.hasPermission;

		this.logPageView();
	},
	mounted() {},
	methods: {
		getReportContainer() {
			// Get a reference to the embedded report HTML element
			var embedContainer = document.getElementById("reportContainer");

			// Get a reference to the embedded report.
			var report = powerbi.get(embedContainer);

			return report;
		},

		async updateToken() {
			let report = this.getReportContainer();

			// Set the new access token
			await report.setAccessToken(this.pbiEmbedToken.token);
			console.log("updated token");
		},

		loadReport() {
			const self = this;

			var reportId = this.report.reportId;

			var models = pbi.models;

			let embedUrl =
				"https://app.powerbi.com/rdlEmbed?reportId=" +
				reportId +
				"&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUVVUk9QRS1OT1JUSC1CLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZSwiY2VydGlmaWVkVGVsZW1ldHJ5RW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d";

			// Read embed type from radio
			let tokenType = models.TokenType.Embed;

			// Se view permissions.
			let permissions = models.Permissions.View;

			// Create the embed configuration object for the paginated report
			// For more information see https://go.microsoft.com/fwlink/?linkid=2153700
			let config = {
				type: "report",
				tokenType: tokenType,
				accessToken: this.pbiEmbedToken.token,
				embedUrl: embedUrl,
				id: reportId,
				permissions: permissions,
			};

			// Get a reference to the paginated embedded report HTML element
			let embedContainer = document.getElementById("reportContainer");

			// Embed the paginated report and display it within the div container.
			let paginatedReport = powerbi.embed(embedContainer, config);

			// After embedded delete the border of the iframe
			var iframe = document.getElementsByTagName("iframe")[0];
			iframe.setAttribute(
				"style",
				"width: 100%; height: 100%; border-style: none;"
			);
		},

		fullscreenMobile() {
			this.$store.dispatch("app/mutateDisableAppBar", true);
		},

		fullscreenMobileExit() {
			this.$store.dispatch("app/mutateDisableAppBar", false);
		},

		setAppBarTitle() {
			var appBarTitle = null;
			if (this.report.isStandard) {
				appBarTitle =
					this.report.tenant.tenantName +
					" - " +
					this.report.reportName;
			} else {
				appBarTitle = this.report.reportName;
			}

			this.$store.dispatch("app/mutateAppBarTitle", appBarTitle);

			document.title = `${appBarTitle} | TapTarget`;
		},

		logPageView() {
			// prepare api call payload
			var payload = {
				logType: "pageview",
				refType: "report",
				refNodeId: this.$route.params.reportNodeId,
			};

			// new
			this.$apollo
				.mutate({
					// Query
					mutation: gql`
						mutation createLog($input: CreateLogInput!) {
							createLog(input: $input) {
								log {
									id
								}
							}
						}
					`,
					// Parameters
					variables: {
						input: payload,
					},
				})
				.then((response) => {})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {});
		},
	},
};
</script>

<style>
.v-btn.navigation-btn {
	min-width: 20px !important;
}
</style>

<template>
	<div>
		<v-snackbar
			v-for="(snackbar, index) in snackbars.filter((s) => s.showing)"
			:key="snackbar.message + Math.random()"
			v-model="snackbar.showing"
			:color="snackbar.color"
			:timeout="snackbar.timeout"
			:style="`bottom: ${index * 60 + 8}px`"
		>
			{{ snackbar.message }}
			<template v-slot:action="{ attrs }">
				<v-btn
					v-if="!snackbar.isPermanent"
					dark
					text
					v-bind="attrs"
					@click="snackbar.showing = false"
				>
					{{ $t("label.close") }}
				</v-btn>
			</template>

			<v-progress-linear
				v-if="snackbar.isPermanent"
				absolute
				bottom
				color="primary"
				indeterminate
				rounded
			/>
		</v-snackbar>
	</div>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			icon: "",
			message: "",
			color: "",
		};
	},
	computed: {
		snackbars: function () {
			return this.$store.state.snackbar.snackbars;
		},
	},
	watch: {
		snackbars(val) {
			if (val) {
				this.message = val.message;
				this.color = val.color;
				this.show = true;
			}
		},
	},
};
</script>

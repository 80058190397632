<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="1300px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          Tap #{{ connectionDetail?.pk }}
          <v-chip
            :color="
              connectionDetail?.status == 'In progress'
                ? 'info'
                : connectionDetail?.status == 'Connected'
                ? 'success'
                : connectionDetail?.status == 'Failed'
                ? 'error'
                : connectionDetail?.status == 'Cancelled'
                ? 'warning'
                : connectionDetail?.status == 'Deleting'
                ? 'error'
                : ''
            "
            :class="['ml-10']"
            rounded
          >
            <v-progress-circular
              :size="14"
              :width="1"
              class="ml-n1 mr-1"
              color="white"
              indeterminate
              v-if="connectionDetail?.status == 'In progress'"
            ></v-progress-circular>
            {{ connectionDetail?.status }}
          </v-chip>

          <v-spacer></v-spacer>
          <v-menu
            bottom
            left
            v-if="
              !connectionDetail?.cancelledAt &&
              connectionDetail?.status != 'In progress'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item>
                <v-list-item-title @click="openCancel()">{{
                  $t("label.cancel")
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            @click="refresh()"
            :loading="$apollo.queries.connectionDetail.loading"
            icon
          >
            <v-icon>refresh</v-icon>
          </v-btn>
          <v-btn icon @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle>
          <v-row :class="['my-0', 'py-0']">
            <v-col :class="['my-0', 'py-0', 'black--text']" cols="12">
              {{ connectionDetail?.tap?.name }}
            </v-col>
            <v-col :class="['my-0', 'py-0']" cols="12">
              <v-row :class="['my-0', 'py-0']">
                <v-col :class="['my-0', 'py-0']" cols="12" md="2">
                  {{ $tc("label.date", 1) }}:
                </v-col>
                <v-col :class="['my-0', 'py-0']" cols="12" md="10">
                  {{ connectionDetail?.createdAt | moment("YYYY-MM-DD HH:mm") }}
                </v-col>
              </v-row>
            </v-col>

            <v-col
              :class="['my-0', 'py-0']"
              cols="12"
              v-if="companies.length > 0"
            >
              <v-row :class="['my-0', 'py-0']">
                <v-col :class="['my-0', 'py-0']" cols="12" md="2">
                  {{ $tc("label.company", 2) }}:
                </v-col>
                <v-col :class="['my-n2', 'py-0']" cols="12" md="10">
                  <v-chip-group disabled show-arrows>
                    <v-chip x-small v-for="company in companies" :key="company">
                      {{ company }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-col>
            <v-col :class="['my-0', 'py-0']" cols="12" v-if="me.isSuperuser">
              <v-row :class="['my-0', 'py-0']">
                <v-col :class="['my-0', 'py-0']" cols="12" md="2">
                  {{ $tc("label.name", 1) }}:
                </v-col>
                <v-col :class="['my-0', 'py-0']" cols="12" md="10">
                  {{
                    `${connectionDetail?.createdByUser?.firstName} ${connectionDetail?.createdByUser?.lastName}`
                  }}
                </v-col>
              </v-row>
            </v-col>
            <v-col :class="['my-0', 'py-0']" cols="12" v-if="me.isSuperuser">
              <v-row :class="['my-0', 'py-0']">
                <v-col :class="['my-0', 'py-0']" cols="12" md="2">
                  {{ $tc("label.email", 1) }}:
                </v-col>
                <v-col :class="['my-0', 'py-0']" cols="12" md="10">
                  {{ connectionDetail?.createdByUser?.email }}
                </v-col>
              </v-row>
            </v-col>
            <v-col
              :class="['my-0', 'py-0']"
              cols="12"
              v-if="connectionDetail?.remark"
            >
              <v-row :class="['my-0', 'py-0']">
                <v-col :class="['my-0', 'py-0']" cols="12" md="2">
                  {{ $tc("label.remark", 1) }}:
                </v-col>
                <v-col :class="['my-0', 'py-0']" cols="12" md="10">
                  {{ connectionDetail?.remark }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-subtitle>

        <v-row class="my-0 py-0">
          <v-col :class="['my-0', 'py-0']" cols="12">
            <v-stepper
              :value="step"
              alt-labels
              class="my-stepper mx-n5 px-0"
              flat
            >
              <v-stepper-header>
                <v-stepper-step complete step="-1">
                  {{ $tc("label.confirmed", 1) }}
                  <small>{{
                    connectionDetail?.createdAt | moment("YYYY-MM-DD")
                  }}</small>
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="connectionDetail?.approvedAt ? true : false"
                  step="0"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        {{
                          connectionDetail?.tap?.installType == "Approval"
                            ? $t("approval")
                            : $t("install")
                        }}
                        <v-icon small v-if="!connectionDetail?.approvedAt"
                          >info</v-icon
                        >
                      </span>
                    </template>
                    <span>
                      {{
                        connectionDetail?.tap?.installType == "Approval"
                          ? $t("approvalTooltip")
                          : $t("installTooltip")
                      }}
                    </span>
                  </v-tooltip>
                  <small v-if="connectionDetail?.approvedAt">{{
                    connectionDetail?.approvedAt | moment("YYYY-MM-DD")
                  }}</small>
                  <small class="mt-1" v-else>
                    <v-progress-linear
                      indeterminate
                      rounded
                    ></v-progress-linear>
                  </small>
                </v-stepper-step>

                <v-divider></v-divider>

                <template v-for="(item, index) in deploymentSteps">
                  <v-stepper-step
                    @click="
                      openErrorMessage(
                        deploymentStepStatus(item.task_name)?.traceback
                      )
                    "
                    :complete="
                      deploymentStepStatus(item.task_name)?.status === 'SUCCESS' || connectionDetail?.completedAt
                    "
                    :key="`${index}-step`"
                    :rules="[
                      () =>
                        deploymentStepStatus(item.task_name)?.status ===
                        'FAILURE'
                          ? false
                          : true,
                    ]"
                    :step="index + 1"
                  >
                    {{ item.display_name[$i18n.locale] }}
                    <small
                      v-if="
                        deploymentStepStatus(item.task_name)?.status ===
                          'SUCCESS' ||
                        deploymentStepStatus(item.task_name)?.status ===
                          'FAILURE'
                      "
                      >{{
                        deploymentStepStatus(item.task_name)?.date_done
                          | moment("YYYY-MM-DD")
                      }}</small
                    >
                    <small
                      class="mt-1"
                      v-if="
                        deploymentStepStatus(item.task_name)?.status ===
                        'STARTED'
                      "
                    >
                      <v-progress-linear
                        indeterminate
                        rounded
                      ></v-progress-linear>
                    </small>
                  </v-stepper-step>

                  <v-divider
                    v-if="index !== deploymentSteps"
                    :key="index"
                  ></v-divider>
                </template>

                <v-stepper-step
                  :complete="connectionDetail?.completedAt ? true : false"
                  :step="completedStep"
                >
                  {{ $t("label.done") }}
                  <small v-if="connectionDetail?.completedAt">{{
                    connectionDetail?.completedAt | moment("YYYY-MM-DD")
                  }}</small>
                  <small
                    v-if="
                      me.isSuperuser &&
                      connectionDetail?.status != 'In progress'
                    "
                  >
                    <v-btn
                      @click="openManual()"
                      class="mt-1"
                      color="primary"
                      rounded
                      x-small
                      >{{ $t("label.manual") }}</v-btn
                    >
                  </small>
                </v-stepper-step>

                <v-divider v-if="me.isSuperuser"></v-divider>

                <v-stepper-step
                  :complete="connectionDetail?.cancelledAt ? true : false"
                  :step="cancelledStep"
                  v-if="me.isSuperuser"
                >
                  {{ $t("label.cancelled") }}
                  <small v-if="connectionDetail?.cancelledAt">{{
                    connectionDetail?.cancelledAt | moment("YYYY-MM-DD")
                  }}</small>
                  <small
                    v-if="
                      !connectionDetail.cancelledAt &&
                      connectionDetail?.status != 'In progress'
                    "
                  >
                    <v-btn
                      @click="openCancel()"
                      class="mt-1"
                      color="warning"
                      rounded
                      x-small
                      >{{ $t("label.cancel") }}</v-btn
                    >
                  </small>
                </v-stepper-step>

                <v-divider v-if="me.isSuperuser"></v-divider>

                <v-stepper-step
                  :complete="false"
                  :step="deletedStep"
                  v-if="me.isSuperuser"
                >
                  {{ $t("label.delete") }}
                  <small v-if="connectionDetail?.deletedAt">{{
                    connectionDetail?.deletedAt | moment("YYYY-MM-DD")
                  }}</small>
                  <small
                    v-if="
                      !connectionDetail.deletedAt &&
                      connectionDetail?.status != 'In progress'
                    "
                  >
                    <v-btn
                      @click="openDelete()"
                      :disabled="!connectionDetail.cancelledAt"
                      class="mt-1"
                      color="error"
                      rounded
                      x-small
                      >{{ $t("label.delete") }}</v-btn
                    >
                  </small>
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-col>

          <!-- delete stepper -->
          <v-col
            :class="['my-0', 'py-0']"
            cols="12"
            v-if="connectionDetail?.preDeletedAt && me.isSuperuser"
          >
            <v-stepper
              :value="deleteStep"
              alt-labels
              class="my-stepper mx-n5 px-0"
              flat
            >
              <v-stepper-header>
                <v-stepper-step color="warning" complete step="-1">
                  Pre {{ $t("label.delete") }}
                  <small>{{
                    connectionDetail?.preDeletedAt | moment("YYYY-MM-DD")
                  }}</small>
                </v-stepper-step>

                <v-divider></v-divider>

                <template v-for="(item, index) in deleteSteps">
                  <v-stepper-step
                    @click="
                      openErrorMessage(
                        deleteStepStatus(item.task_name)?.traceback
                      )
                    "
                    :complete="
                      deleteStepStatus(item.task_name)?.status === 'SUCCESS'
                    "
                    :key="`${index}-step`"
                    :rules="[
                      () =>
                        deleteStepStatus(item.task_name)?.status === 'FAILURE'
                          ? false
                          : true,
                    ]"
                    :step="index + 1"
                    color="warning"
                  >
                    {{ item.display_name[$i18n.locale] }}
                    <small
                      v-if="
                        deleteStepStatus(item.task_name)?.status ===
                          'SUCCESS' ||
                        deleteStepStatus(item.task_name)?.status === 'FAILURE'
                      "
                      >{{
                        deleteStepStatus(item.task_name)?.date_done
                          | moment("YYYY-MM-DD")
                      }}</small
                    >
                    <small
                      class="mt-1"
                      v-if="
                        deleteStepStatus(item.task_name)?.status === 'STARTED'
                      "
                    >
                      <v-progress-linear
                        indeterminate
                        rounded
                      ></v-progress-linear>
                    </small>
                  </v-stepper-step>

                  <v-divider
                    v-if="index !== deleteSteps.length"
                    :key="index"
                  ></v-divider>
                </template>

                <v-stepper-step
                  :complete="false"
                  :step="deleteSteps.length + 1"
                >
                  {{ $t("label.done") }}
                  <small v-if="me.isSuperuser">
                    <v-btn
                      @click="openManualDelete()"
                      class="mt-1"
                      color="error"
                      rounded
                      x-small
                      >{{ $t("label.manual") }}</v-btn
                    >
                  </small>
                </v-stepper-step>
              </v-stepper-header>
            </v-stepper>
          </v-col>
        </v-row>

        <ConnectionDetailViewSuperuser
          :connection="connectionDetail"
          :dialog="dialog"
          ref="ConnectionDetailViewSuperuser"
          v-if="me.isSuperuser"
          v-on:deployed="refresh()"
        />

        <ErrorDialog
          :errorMessage.sync="errorMessage"
          :dialog.sync="errorDialog"
        ></ErrorDialog>

        <ManualDialog
          :connection="connectionDetail"
          :dialog.sync="manualDialog"
          v-on:deployed="refresh()"
        ></ManualDialog>

        <CancelDialog
          :connection="connectionDetail"
          :dialog.sync="cancelDialog"
          v-on:cancelled="refresh()"
        ></CancelDialog>

        <DeleteDialog
          :connection="connectionDetail"
          :dialog.sync="deleteDialog"
        ></DeleteDialog>

        <ManualDeleteDialog
          :connection="connectionDetail"
          :dialog.sync="manualDeleteDialog"
          v-on:deleted="refresh()"
        ></ManualDeleteDialog>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import CancelDialog from "@/components/integrations/taps/connection/CancelDialog.vue";
import ConnectionDetailViewSuperuser from "@/components/integrations/taps/connection/ConnectionDetailViewSuperuser.vue";
import DeleteDialog from "@/components/integrations/taps/connection/DeleteDialog.vue";
import ErrorDialog from "@/components/base/ErrorDialog.vue";
import gql from "graphql-tag";
import ManualDeleteDialog from "@/components/integrations/taps/connection/ManualDeleteDialog.vue";
import ManualDialog from "@/components/integrations/taps/connection/ManualDialog.vue";
import rules from "@/utils/rules.js";

export default {
  name: "connection-detail-dialog",
  components: {
    CancelDialog,
    ConnectionDetailViewSuperuser,
    DeleteDialog,
    ErrorDialog,
    ManualDeleteDialog,
    ManualDialog,
  },
  props: ["connection", "dialog"],

  apollo: {
    connectionDetail: {
      query: gql`
        query connectionDetail($connection: ID!) {
          connection(id: $connection) {
            id
            approvedAt
            cancelledAt
            companies
            completedAt
            createdAt
            createdByUser {
              email
              firstName
              lastName
            }
            dataFactory {
              id
              name
            }
            datawarehouse {
              id
              name
              password
              user
            }
            deletedAt
            deleteSteps
            deleteTaskResult {
              id
              allChildren
              status
            }
            group {
              id
              name
            }
            installParameters
            pk
            preDeletedAt
            remark
            resourceGroup {
              id
              name
            }
            status
            tap {
              id
              abbreviation
              deploymentSteps
              externalId
              installParameters
              installType
              name
              requiresDataFactory
              tokenDbServer
            }
            taskResult {
              id
              allChildren
              status
            }
            tenant {
              id
              tenantName
            }
            tokenId
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.connection,
      skip() {
        if (this.connection.id && this.dialog) {
          return false;
        } else {
          return true;
        }
      },
      variables() {
        return {
          connection: this.connection.id,
        };
      },
      result() {
        this.deleteSteps = JSON.parse(this.connectionDetail?.deleteSteps);
        this.deleteTaskResults =
          this.connectionDetail?.deleteTaskResult?.allChildren;
        this.deploymentTaskResults =
          this.connectionDetail?.taskResult?.allChildren;
        this.deploymentSteps = JSON.parse(
          this.connectionDetail?.tap?.deploymentSteps
        );
        this.tenant = this.connectionDetail?.tenant;

        this.cancelledStep = this.deploymentSteps.length + 2;
        this.completedStep = this.deploymentSteps.length + 1;
        this.deletedStep = this.deploymentSteps.length + 3;
        this.companies = JSON.parse(this.connectionDetail?.companies);

        this.setActiveDeleteStep();
        this.setActiveDeploymentStep();
      },
      pollInterval: 10000,
    },
  },

  data() {
    return {
      cancelDialog: false,
      cancelledStep: 0,
      companies: [],
      completedStep: 0,
      connectionDetail: {},
      deleteDialog: false,
      deletedStep: 0,
      deleteStep: 0,
      deleteSteps: [],
      deleteTaskResults: [],
      deploymentSteps: [],
      deploymentTaskResults: [],
      error: null,
      errorDialog: false,
      errorMessage: null,
      isSaving: false,
      manualDeleteDialog: false,
      manualDialog: false,
      step: 0,
      tenant: {},
    };
  },
  computed: {
    formTitle() {
      return `Connection detail`;
    },

    me() {
      return this.$store.state.user.me || {};
    },
  },
  watch: {
    dialog(val) {
      this.resetForm();
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    deleteStepStatus(taskName) {
      const task = this.deleteTaskResults?.find(
        (i) => i.task_name === taskName
      );

      return task;
    },

    deploymentStepStatus(taskName) {
      const task = this.deploymentTaskResults?.find(
        (i) => i.task_name === taskName
      );

      return task;
    },

    openErrorMessage(errorMessage) {
      if (!errorMessage || !this.me.isSuperuser) {
      } else {
        this.errorMessage = errorMessage;
        this.errorDialog = true;
      }
    },

    openCancel() {
      this.cancelDialog = true;
    },

    openDelete() {
      this.deleteDialog = true;
    },

    openManual() {
      this.manualDialog = true;
    },

    openManualDelete() {
      this.manualDeleteDialog = true;
    },

    refresh() {
      this.$apollo.queries.connectionDetail.refresh();
    },

    resetForm() {
      this.error = null;
      this.isSaving = false;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      this.connectionDetail = {};
    },

    setActiveDeleteStep() {
      var activeStep = 0;

      if (this.deleteTaskResults?.length > 0) {
        activeStep = this.deleteTaskResults?.length - 2;
      }

      this.deleteStep = activeStep;
    },

    setActiveDeploymentStep() {
      var activeStep = 0;

      if (this.connectionDetail?.cancelledAt) {
        activeStep = this.deploymentSteps?.length + 2;
      } else if (this.connectionDetail?.completedAt) {
        activeStep = this.deploymentSteps?.length + 1;
      } else if (this.connectionDetail?.taskResult) {
        // ignore the chain and base task
        activeStep = this.deploymentTaskResults?.length - 2;
      }

      this.step = activeStep;
    },
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .my-stepper .v-stepper__step {
    flex-grow: 1;
  }
}
</style>

<i18n>
{
  "en": {
    "approval": "Approval",
    "approvalTooltip": "Waiting for TapTarget to approve the connection.",
    "install": "Install",
    "installTooltip": "You will be contacted by TapTarget to schedule the installation."
  },
  "nl": {
    "approval": "Goedkeuring",
    "approvalTooltip": "Wachten op goedkeuring van TapTarget.",
    "install": "Installatie",
    "installTooltip": "TapTarget neemt contact met je op om de installatie te plannen."
  },
  "de": {
    "approval": "Genehmigung",
    "approvalTooltip": "Warten auf die Genehmigung von TapTarget für die Verbindung.",
    "install": "Installieren",
    "installTooltip": "Sie werden von TapTarget kontaktiert, um die Installation zu planen."
  }
}
</i18n>

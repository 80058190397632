<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="900px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          Refresh tokens
          <v-spacer />
          <v-btn icon @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          {{ $t("info") }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "token-info-dialog",
  components: {},
  props: ["dialog"],

  apollo: {},

  data() {
    return {};
  },
  computed: {
    me() {},
  },
  watch: {
    dialog(val) {},
  },
  created() {},
  methods: {
    close() {
      this.$emit("update:dialog", false);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "info": "Every month, your tenant receives a set of free refresh tokens, allowing you to manually update your data. If you exceed these tokens and wish to trigger additional jobs, each run beyond the allocated tokens will incur a charge of 2 euro. This ensures flexibility in managing your data while maintaining cost transparency."
  },
  "nl": {
    "info": "Elke maand ontvangt jouw tenant een aantal gratis vernieuwingstokens, waarmee je handmatig jouw gegevens kunt bijwerken. Als je meer tokens wilt gebruiken en extra jobs wilt starten, wordt elke extra uitvoering boven het toegewezen aantal tokens in rekening gebracht tegen een tarief van 2 euro. Dit biedt flexibiliteit bij het beheren van uw gegevens, met behoud van kostentransparantie."
  },
  "de": {
    "info": "Jeden Monat erhält Ihr Tenant-Konto eine Anzahl kostenloser Aktualisierungstoken, die es Ihnen ermöglichen, Ihre Daten manuell zu aktualisieren. Wenn Sie diese Token überschreiten und zusätzliche Jobs auslösen möchten, fallen für jede darüber hinausgehende Ausführung 2 Euro Gebühren an. Dies gewährleistet Flexibilität bei der Verwaltung Ihrer Daten und gleichzeitig Kostentransparenz."
  }
}
</i18n>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.lgAndDown}},[_c('DeleteDialog',{attrs:{"dialog":_vm.deleteDialog},on:{"update:dialog":function($event){_vm.deleteDialog=$event},"confirmed":_vm.deletingSelectedUserReportFiltersConfirmed}}),_c('ReportFiltersShareDialog',{attrs:{"userReportFilterNodeId":_vm.selectedUserReportFilterNodeId,"dialog":_vm.dialog},on:{"update:userReportFilterNodeId":function($event){_vm.selectedUserReportFilterNodeId=$event},"update:user-report-filter-node-id":function($event){_vm.selectedUserReportFilterNodeId=$event},"update:dialog":function($event){_vm.dialog=$event},"onFilterChange":_vm.onFilterChange}}),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',[_vm._v(_vm._s(_vm.$tc("label.report", 1) + _vm.$tc("label.filter", 2)))]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 mt-2",attrs:{"color":"error","dark":"","outlined":"","rounded":"","small":"","disabled":_vm.selectedFilters.length <= 0},on:{"click":function($event){_vm.deleteDialog = true}}},[_vm._v(_vm._s(_vm.$t("label.delete")))]),_c('v-btn',{staticClass:"mt-1",attrs:{"icon":""},on:{"click":function($event){return _vm.closeDrawer()}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-divider',{staticClass:"mb-5"}),_c('v-row',{staticClass:"mb-10",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.$apollo.queries.userReportFilters.loading,"items":_vm.userReportFilters.edges,"options":_vm.dataTableOptions,"server-items-length":_vm.userReportFilters.totalCount,"items-per-page":_vm.defaultItemsPerPage,"footer-props":{
							'items-per-page-options': [10, 25, 50, 75, 100],
							'disable-pagination':
								_vm.$apollo.queries.userReportFilters.loading,
						},"item-key":"node.id","show-select":""},on:{"update:options":function($event){_vm.dataTableOptions=$event}},scopedSlots:_vm._u([{key:`item.node.type`,fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","color":item.node.type === 'SHARED'
										? 'info'
										: item.node.type === 'ORGANIZATION'
										? 'primary'
										: ''}},[_vm._v(" "+_vm._s(item.node.type)+" ")])]}},{key:`item.action`,fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.shareFilter(item.node)}}},[_c('v-icon',[_vm._v("share")])],1)]}}],null,true),model:{value:(_vm.selectedFilters),callback:function ($$v) {_vm.selectedFilters=$$v},expression:"selectedFilters"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
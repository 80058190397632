<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="1000px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          <v-icon color="error" class="mr-2" large>warning</v-icon>
          {{ $t("label.manual") }} {{ $t("label.delete") }}
          {{ $tc("label.tap", 1) }}
        </v-card-title>

        <v-card-text @click.right.prevent @copy.prevent @paste.prevent>
          {{ $t("label.title") }}
          <v-form ref="form" onSubmit="return false;" v-model="isFormValid">
            <v-row :class="['mt-5', 'py-0']">
              <v-col :class="['my-0', 'py-0']" cols="6">
                <v-select
                  :items="deleteSteps"
                  :label="$tc('label.step', 1)"
                  :rules="[rules.required]"
                  item-value="task_name"
                  outlined
                  v-model="selectedDeleteStep"
                >
                  <template slot="selection" slot-scope="{ item }">{{
                    item.display_name[$i18n.locale]
                  }}</template>
                  <template slot="item" slot-scope="{ item }">{{
                    item.display_name[$i18n.locale]
                  }}</template>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="save()"
            :class="['black--text', 'mr-2']"
            :disabled="!isFormValid"
            :loading="isSaving"
            rounded
            small
            text
          >
            {{ $t("label.confirm") }}
          </v-btn>
          <v-btn
            @click="close()"
            :color="'success'"
            :loading="isSaving"
            rounded
            small
          >
            {{ $t("label.back") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import rules from "@/utils/rules.js";

export default {
  name: "tap-manual-delete-dialog",
  components: {},
  props: ["connection", "dialog"],

  apollo: {},

  data() {
    return {
      deleteSteps: [],
      error: null,
      isFormValid: false,
      isSaving: false,
      selectedDeleteStep: null,
      tapNameConfirm: null,
    };
  },
  computed: {
    me() {
      return this.$store.state.user.me || {};
    },
  },
  watch: {
    dialog(val) {
      this.resetForm();
    },
  },
  created() {
    this.rules = rules;
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    resetForm() {
      this.deleteSteps = JSON.parse(this.connection?.deleteSteps);
      this.error = null;
      this.isSaving = false;
      this.selectedDeleteStep = null;
      this.tapNameConfirm = null;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        id: this.connection.id,
        deleteStep: this.selectedDeleteStep,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteConnectionManual(
              $input: DeleteConnectionManualInput!
            ) {
              deleteConnectionManual(input: $input) {
                connection {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          const payload = {
            color: "success",
            message: `Connection delete successfully started`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          setTimeout(() => {
            this.$emit("deleted", response);
          }, 5000);

          this.close();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Select the step from which you want to start a new deletion. All other steps will be skipped."
  },
  "nl": {
    "title": "Selecteer de stap vanaf waar je een nieuwe delete wilt starten. Alle andere stappen worden overgeslagen."
  },
  "de": {
    "title": "Wählen Sie den Schritt aus, ab dem Sie eine neue Löschung starten möchten. Alle anderen Schritte werden übersprungen."
  }
}
</i18n>

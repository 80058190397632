<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>
    <v-col class="py-0" cols="12" md="6" v-if="editMode">
      <v-text-field
        :label="'Module'"
        class="mt-1"
        outlined
        v-model="editedItem.name"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="12">
      <v-card-text :key="index" v-for="(group, index) in permissionsList">
        <v-card-title>
          <v-icon class="mr-3 primary--text">lock_open</v-icon>
          {{ group.header }}
        </v-card-title>

        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left" width="40%">Module</th>
              <th>{{ $t("label.view") }}</th>
              <th class="text-left">{{ $t("label.add") }}</th>
              <th class="text-left">{{ $t("label.edit") }}</th>
              <th class="text-left">{{ $t("label.delete") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="index" v-for="(item, index) in group.items">
              <td>{{ item.name }}</td>
              <td>
                <v-checkbox
                  :disabled="!editMode"
                  class="ma-0"
                  hide-details
                  v-model="permissions['view_' + item.model]"
                ></v-checkbox>
              </td>
              <td>
                <v-checkbox
                  :disabled="!editMode"
                  class="ma-0"
                  hide-details
                  v-model="permissions['add_' + item.model]"
                ></v-checkbox>
              </td>
              <td>
                <v-checkbox
                  :disabled="!editMode"
                  class="ma-0"
                  hide-details
                  v-model="permissions['change_' + item.model]"
                ></v-checkbox>
              </td>
              <td>
                <v-checkbox
                  :disabled="!editMode"
                  class="ma-0"
                  hide-details
                  v-model="permissions['delete_' + item.model]"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-col>

    <DeleteDialog
      :dialog.sync="deleteDialog"
      v-on:confirmed="onDeleteConfirmed"
    />
  </v-row>
</template>

<script>
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import gql from "graphql-tag";

export default {
  name: "group-permissions-tab",
  props: ["object", "editMode"],
  components: {
    DeleteDialog,
  },

  apollo: {},

  data: function () {
    return {
      deleteDialog: false,
      error: null,
      editedItem: {
        name: null,
      },
      permissions: {},
      permissionsList: [
        {
          header: "Reports",
          items: [
            { model: "embedded_report", name: "Embedded Report" },
            { model: "usermapping", name: "User Mapping" },
          ],
        },
        {
          header: "Users",
          items: [{ model: "customuser", name: "Users" }],
        },
        {
          header: "Taps",
          items: [
            { model: "connection", name: "Connection" },
            { model: "run", name: "Run" },
          ],
        },
      ],
    };
  },

  computed: {},

  watch: {
    object() {
      this.resetForm();
    },
  },

  created() {},

  mounted() {},

  methods: {
    deleteSelected() {
      this.deleteDialog = true;
    },

    onDeleteConfirmed() {
      const payload = {
        id: this.$route.params.groupNodeId,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteGroup($input: DeleteGroupInput!) {
              deleteGroup(input: $input) {
                group {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then(() => {
          this.deleteDialog = false;

          const payload = {
            color: "success",
            message: `Group successfully deleted`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$router.push({ name: "users" });
        })
        .catch((error) => {
          this.error = error;
        });
    },

    resetForm() {
      this.error = null;
      this.isSaving = false;

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      this.editedItem.name = this.object.name;

      var permissions = {};
      this.object.permissions.edges.forEach(function (item, index) {
        permissions[item.node.codename] = true;
      });

      this.permissions = permissions;
    },

    save() {
      this.error = null;
      this.isSaving = true;

      var payload = {
        name: this.editedItem.name,
      };

      (payload.id = this.$route.params.groupNodeId),
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateGroup($input: UpdateGroupInput!) {
                updateGroup(input: $input) {
                  group {
                    id
                    name
                  }
                }
              }
            `,
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            const payload = {
              color: "success",
              message: `Group successfully edited`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);

            this.savePermissions();
          })
          .catch((error) => {
            this.error = error.graphQLErrors[0].message;
            this.isSaving = false;
          })
          .finally(() => {});
    },

    savePermissions() {
      this.error = null;
      this.isSaving = true;

      const selectedPermissions = Object.keys(this.permissions).filter(
        (key) => this.permissions[key]
      );

      const payload = {
        id: this.$route.params.groupNodeId,
        permissions: selectedPermissions,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($input: UpdateGroupPermissionsInput!) {
              updateGroupPermissions(input: $input) {
                group {
                  id
                  name
                  permissions {
                    edges {
                      node {
                        id
                        codename
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then(() => {
          this.$emit("update:editMode", false);

          const payload = {
            color: "success",
            message: `Permissions successfully edited`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .catch((error) => {
          this.error = error;
          this.isSaving = false;
        });
    },
  },
};
</script>

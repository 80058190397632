var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.status)?_c('v-chip',{attrs:{"color":_vm.status == 'STARTED'
      ? 'info'
      : _vm.status == 'SUCCESS'
      ? 'success'
      : _vm.status == 'PUBLISHED'
      ? 'success'
      : _vm.status == 'FAILURE'
      ? 'error'
      : '',"small":""}},[(_vm.status == 'STARTED')?_c('v-progress-circular',{staticClass:"ml-n1 mr-1",attrs:{"size":14,"width":1,"color":"white","indeterminate":""}}):_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(_vm.status == "SUCCESS" ? "check_circle" : _vm.status == "PUBLISHED" ? "check_circle" : _vm.status == "FAILURE" ? "cancel" : "")+" ")]),_vm._v(" "+_vm._s(_vm.status)+" ")],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
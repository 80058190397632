var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-2 rounded-xl"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.resourceGroup.name)+" "),_c('v-spacer'),_c('v-switch',{staticClass:"mb-0 mt-2 py-0",attrs:{"label":_vm.$tc('label.showAll', 1),"hide-details":""},model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":"","outlined":"","rounded":"","small":""},on:{"click":function($event){return _vm.addResourceGroup()}}},[_vm._v(_vm._s(_vm.$t("label.add")))])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"footer-props":{
						'disable-pagination':
							_vm.$apollo.queries.allResources.loading,
						'items-per-page-options': [10, 25, 50, 75, 100],
					},"headers":[
						{
							text: this.$tc('label.name', 1),
							value: 'node.name',
							sortable: false,
						},
						{
							text: this.$tc('label.type', 1),
							value: 'node.subType',
							sortable: false,
						},
						{
							text: this.$t('label.action'),
							align: 'center',
							value: 'action',
							sortable: false,
						},
					],"items":_vm.allResources.edges,"items-per-page":100,"loading":_vm.$apollo.queries.allResources.loading,"server-items-length":_vm.allResources.totalCount,"item-key":"node.id"},scopedSlots:_vm._u([{key:`item.action`,fn:function({ item }){return [(
								item.node.subType ==
								'Microsoft.Sql/servers/databases'
							)?_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.edit(item.node)}}},[_c('v-icon',[_vm._v("edit")])],1):_vm._e()]}}],null,true)})],1)],1)],1),_c('ResourceEditDialog',{attrs:{"dialog":_vm.editDialog,"object":_vm.editedItem},on:{"update:dialog":function($event){_vm.editDialog=$event},"update:object":function($event){_vm.editedItem=$event},"changed":_vm.onChanged}}),_c('ResourceGroupAddDialog',{attrs:{"dialog":_vm.resourceGroupAddDialog,"resourceGroup":_vm.resourceGroup},on:{"update:dialog":function($event){_vm.resourceGroupAddDialog=$event},"changed":_vm.onChanged}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
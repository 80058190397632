<template>
  <v-hover>
    <template v-slot="{ hover }">
      <v-card
        :color="color"
        :elevation="hover ? 10 : 1"
        :loading="loading ? 'white' : null"
        @click="goToRoute()"
        class="rounded-xl"
        dark
        height="160px"
        outlined
      >
        <v-card-title class="mb-0 pb-0">
          <v-icon class="mr-2" large>{{ icon }}</v-icon>
          {{ title }}
        </v-card-title>

        <v-card-text
          :class="[
            'align-center',
            'd-flex',
            'justify-center',
            'text-center',
            `text-${heading}`,
          ]"
          :style="{ height: textHeight }"
        >
          {{ text }}
        </v-card-text>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  props: [
    "color",
    "heading",
    "icon",
    "loading",
    "route",
    "text",
    "title",
    "storeAction",
    "storeValue",
  ],
  apollo: {},

  data() {
    return {};
  },

  computed: {
    textHeight() {
      return this.heading === "h3" ? "calc(100% - 57px)" : "calc(100% - 54px)";
    },
  },
  watch: {},
  created() {},
  methods: {
    goToRoute() {
      if (this.storeAction) {
        this.$store.dispatch(this.storeAction, this.storeValue);
      }
      this.$router.push(this.route);
    },
  },
};
</script>

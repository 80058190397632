<template>
	<v-container fluid>
		<v-row class="align-center pl-3">
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
			<v-spacer />
		</v-row>

		<v-container :fluid="$vuetify.breakpoint.lgAndDown">
			<v-row justify="center" align="center">
				<v-col cols="12">
					<v-tabs v-model="tabs">
						<v-tabs-slider color="primary"></v-tabs-slider>

						<v-tab>{{ $tc("label.target", 2) }}</v-tab>
						<v-tab>{{ $tc("label.deployment", 2) }}</v-tab>
						<v-spacer />
					</v-tabs>
					<v-divider></v-divider>

					<v-tabs-items v-model="tabs">
						<v-tab-item>
							<TargetsList ref="TargetsList" />
						</v-tab-item>
						<v-tab-item>
							<ResourceDeploymentsList
								ref="ResourceDeploymentsList"
							/>
						</v-tab-item>
					</v-tabs-items>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import helper from "@/utils/helper.js";
import ResourceDeploymentsList from "@/components/integrations/targets/ResourceDeploymentsList.vue";
import TargetsList from "@/components/integrations/targets/TargetsList.vue";

export default {
	name: "targets",
	components: {
		ResourceDeploymentsList,
		TargetsList,
	},

	apollo: {},

	data: function () {
		return {};
	},

	computed: {
		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("label.home"),
					exact: true,
					disable: false,
					to: {
						name: "home",
					},
				},
				{
					text: this.$tc("label.target", 2),
					disabled: true,
				},
			];
			return breadcrumbs;
		},

		me() {
			return this.$store.state.user.me;
		},

		tabs: {
			get() {
				return this.$store.getters["targets/getTabs"];
			},
			set(value) {
				this.$store.dispatch("targets/setTabs", value);
			},
		},
	},

	watch: {},

	created() {
		this.hasPermission = helper.hasPermission;
	},

	mounted() {},

	methods: {},
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"600px","persistent":"","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")]),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',{ref:"form",attrs:{"onSubmit":"return false;"},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{staticClass:"my-0 py-0"},[(_vm.me.isSuperuser)?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12","md":"8"}},[_c('v-autocomplete',{attrs:{"items":_vm.tenants.edges,"label":_vm.$tc('label.tenant', 1),"loading":_vm.$apollo.queries.tenants.loading,"rules":[_vm.rules.required],"clearable":"","item-text":"node.tenantName","item-value":"node.id","outlined":""},on:{"change":function($event){return _vm.onTenantChange()}},model:{value:(_vm.editedItem.tenantNodeId),callback:function ($$v) {_vm.$set(_vm.editedItem, "tenantNodeId", $$v)},expression:"editedItem.tenantNodeId"}})],1):_vm._e(),(
                  _vm.me.isSuperuser && _vm.editedItem.reportType === 'POWERBIREPORT'
                )?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12","md":"4"}},[_c('v-switch',{class:['mt-3'],attrs:{"label":_vm.$t('label.editable')},model:{value:(_vm.editedItem.isEditable),callback:function ($$v) {_vm.$set(_vm.editedItem, "isEditable", $$v)},expression:"editedItem.isEditable"}})],1):_vm._e(),_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"counter":100,"label":`${_vm.$tc('label.report', 1)} ${_vm.$tc('label.name', 1)}`,"rules":[_vm.rules.required, _vm.rules.maxLength(100)],"outlined":""},model:{value:(_vm.editedItem.reportName),callback:function ($$v) {_vm.$set(_vm.editedItem, "reportName", $$v)},expression:"editedItem.reportName"}})],1),(_vm.me.isSuperuser)?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12","md":"8"}},[_c('v-autocomplete',{attrs:{"items":_vm.reportTypeChoices,"label":`${_vm.$tc('label.report', 1)} ${_vm.$tc('label.type', 1)}`,"rules":[],"clearable":"","item-text":"text","item-value":"value","outlined":""},model:{value:(_vm.editedItem.reportType),callback:function ($$v) {_vm.$set(_vm.editedItem, "reportType", $$v)},expression:"editedItem.reportType"}})],1):_vm._e(),(_vm.me.isSuperuser)?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12","md":"4"}},[_c('v-switch',{class:['mt-3'],attrs:{"label":_vm.$t('label.standard')},model:{value:(_vm.editedItem.isStandard),callback:function ($$v) {_vm.$set(_vm.editedItem, "isStandard", $$v)},expression:"editedItem.isStandard"}})],1):_vm._e(),(_vm.me.isSuperuser)?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"counter":36,"label":`${_vm.$tc('label.group', 1)} ${_vm.$tc('label.id', 1)}`,"rules":[
                    _vm.rules.required,
                    _vm.rules.minLength(36),
                    _vm.rules.maxLength(36),
                  ],"outlined":""},model:{value:(_vm.editedItem.groupId),callback:function ($$v) {_vm.$set(_vm.editedItem, "groupId", $$v)},expression:"editedItem.groupId"}})],1):_vm._e(),(_vm.me.isSuperuser)?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"counter":36,"label":`${_vm.$tc('label.report', 1)} ${_vm.$tc('label.id', 1)}`,"rules":[
                    _vm.rules.required,
                    _vm.rules.minLength(36),
                    _vm.rules.maxLength(36),
                  ],"outlined":""},model:{value:(_vm.editedItem.reportId),callback:function ($$v) {_vm.$set(_vm.editedItem, "reportId", $$v)},expression:"editedItem.reportId"}})],1):_vm._e(),(
                  _vm.me.isSuperuser && _vm.editedItem.reportType === 'POWERBIREPORT'
                )?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"counter":36,"label":`${_vm.$tc('label.dataset', 1)} ${_vm.$tc('label.id', 1)}`,"rules":[
                    _vm.rules.required,
                    _vm.rules.minLength(36),
                    _vm.rules.maxLength(36),
                  ],"outlined":""},model:{value:(_vm.editedItem.datasetId),callback:function ($$v) {_vm.$set(_vm.editedItem, "datasetId", $$v)},expression:"editedItem.datasetId"}})],1):_vm._e(),(
                  _vm.me.isSuperuser && _vm.editedItem.reportType === 'POWERBIREPORT'
                )?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":36,"label":`${_vm.$tc('label.binded', 1)} ${_vm.$tc(
                    'label.group',
                    1
                  )} ${_vm.$tc('label.id', 1)}`,"rules":[_vm.rules.maxLength(36)],"outlined":""},model:{value:(_vm.editedItem.bindedGroupId),callback:function ($$v) {_vm.$set(_vm.editedItem, "bindedGroupId", $$v)},expression:"editedItem.bindedGroupId"}})],1):_vm._e(),(
                  _vm.me.isSuperuser && _vm.editedItem.reportType === 'POWERBIREPORT'
                )?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12","md":"4"}},[_c('v-switch',{class:['mt-3'],attrs:{"label":_vm.$t('label.binded')},model:{value:(_vm.editedItem.isBinded),callback:function ($$v) {_vm.$set(_vm.editedItem, "isBinded", $$v)},expression:"editedItem.isBinded"}})],1):_vm._e(),(
                  _vm.me.isSuperuser && _vm.editedItem.reportType === 'POWERBIREPORT'
                )?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{staticClass:"subtitle-2",attrs:{"counter":36,"label":`${_vm.$tc('label.binded', 1)} ${_vm.$tc(
                    'label.dataset',
                    1
                  )} ${_vm.$tc('label.id', 1)}`,"rules":[_vm.rules.maxLength(36)],"outlined":""},model:{value:(_vm.editedItem.bindedDatasetId),callback:function ($$v) {_vm.$set(_vm.editedItem, "bindedDatasetId", $$v)},expression:"editedItem.bindedDatasetId"}})],1):_vm._e(),(_vm.me.isSuperuser)?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12","md":"4"}},[_c('v-switch',{class:['mt-3'],attrs:{"label":"Direct Query"},model:{value:(_vm.editedItem.isDirectQuery),callback:function ($$v) {_vm.$set(_vm.editedItem, "isDirectQuery", $$v)},expression:"editedItem.isDirectQuery"}})],1):_vm._e(),(
                  _vm.me.isSuperuser && _vm.editedItem.reportType === 'POWERBIREPORT'
                )?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"counter":30,"label":_vm.$t('label.pbiDateTable'),"rules":[_vm.rules.maxLength(30)],"outlined":""},model:{value:(_vm.editedItem.pbiDateTable),callback:function ($$v) {_vm.$set(_vm.editedItem, "pbiDateTable", $$v)},expression:"editedItem.pbiDateTable"}})],1):_vm._e(),(
                  _vm.me.isSuperuser && _vm.editedItem.reportType === 'POWERBIREPORT'
                )?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"counter":30,"label":_vm.$t('label.pbiDateColumn'),"rules":[_vm.rules.maxLength(30)],"outlined":""},model:{value:(_vm.editedItem.pbiDateColumn),callback:function ($$v) {_vm.$set(_vm.editedItem, "pbiDateColumn", $$v)},expression:"editedItem.pbiDateColumn"}})],1):_vm._e(),(_vm.me.isSuperuser)?_c('v-col',{class:['my-0', 'py-0'],attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"item-text":(item) => `${item.node.pk} - ${item.node.tap.name}`,"items":_vm.allConnections.edges,"label":_vm.$tc('label.tap', 1),"loading":_vm.$apollo.queries.allConnections.loading,"clearable":"","item-value":"node.id","outlined":""},model:{value:(_vm.editedItem.connection),callback:function ($$v) {_vm.$set(_vm.editedItem, "connection", $$v)},expression:"editedItem.connection"}})],1):_vm._e()],1)],1)],1),_c('v-card-actions',{class:['pb-5', 'pr-5']},[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isSaving,"rounded":"","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t("label.cancel")))]),_c('v-btn',{attrs:{"disabled":!_vm.isFormValid,"loading":_vm.isSaving,"color":"primary","outlined":"","rounded":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t("label.save")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
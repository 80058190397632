<template>
  <v-row justify="center">
    <v-dialog
      @click:outside="close()"
      @keydown.esc="close()"
      max-width="1300px"
      v-model="dialog"
    >
      <v-card :class="['px-5', 'pt-5', 'pb-5']">
        <v-alert v-if="error" tile type="error">{{ error }}</v-alert>

        <v-card-title
          :class="['font-weight-bold', 'text-h4', 'mb-3']"
          style="word-break: break-word"
        >
          {{ formTitle }}
          <v-spacer></v-spacer>
          <v-btn
            @click="deleteSelected()"
            icon
            color="error"
            v-if="this.object"
          >
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" onSubmit="return false;" v-model="isFormValid">
            <v-row class="my-0 py-0">
              <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                <v-text-field
                  :label="`${$tc('label.name', 1)} NL`"
                  :rules="[rules.required]"
                  outlined
                  v-model="editedItem.nameNl"
                ></v-text-field>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                <v-text-field
                  :label="`${$tc('label.name', 1)} EN`"
                  :rules="[rules.required]"
                  outlined
                  v-model="editedItem.nameEn"
                ></v-text-field>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                <v-textarea
                  :label="`${$tc('label.description', 1)} NL`"
                  :rules="[rules.required]"
                  outlined
                  v-model="editedItem.descriptionNl"
                ></v-textarea>
              </v-col>
              <v-col :class="['my-0', 'py-0']" cols="12" md="6">
                <v-textarea
                  :label="`${$tc('label.description', 1)} EN`"
                  :rules="[rules.required]"
                  outlined
                  v-model="editedItem.descriptionEn"
                ></v-textarea>
              </v-col>

              <v-col :class="['my-0', 'py-0']" cols="12" md="4">
                <v-autocomplete
                  :items="allStates.edges"
                  :label="$tc('label.status', 1)"
                  :loading="$apollo.queries.allStates.loading"
                  :rules="[rules.required]"
                  chips
                  item-text="node.nameEn"
                  item-value="node.id"
                  outlined
                  small-chips
                  v-model="selectedState"
                >
                  <template slot="selection" slot-scope="{ item }">
                    <v-chip :color="item?.node?.color" small>
                      <v-icon left small>
                        {{ item?.node?.icon }}
                      </v-icon>
                      {{
                        item?.node?.[
                          `name${capitalizeFirstLetter($i18n.locale)}`
                        ]
                      }}
                    </v-chip>
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    <v-chip :color="item?.node?.color">
                      <v-icon left>
                        {{ item?.node?.icon }}
                      </v-icon>
                      {{
                        item?.node?.[
                          `name${capitalizeFirstLetter($i18n.locale)}`
                        ]
                      }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col :class="['my-0', 'py-0']" cols="12" md="4">
                <v-autocomplete
                  :items="allSystems.edges"
                  :label="$tc('label.system', 2)"
                  :loading="$apollo.queries.allSystems.loading"
                  :rules="[rules.required]"
                  chips
                  item-text="node.name"
                  item-value="node.id"
                  multiple
                  outlined
                  small-chips
                  v-model="selectedSystems"
                >
                </v-autocomplete>
              </v-col>

              <v-col :class="['my-0', 'py-0']" cols="12" md="4">
                <v-text-field
                  :label="$tc('label.resolved', 1)"
                  @click:append="generateCurrentTimestamp()"
                  append-icon="rotate_right"
                  hint="Example in UTC: 2024-03-26T10:30:56+00:00, use button on the right to generate current timestamp."
                  outlined
                  persistent-hint
                  v-model="resolvedAt"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            :color="'primary'"
            :disabled="!isFormValid"
            :loading="isSaving"
            @click="save()"
            rounded
            small
          >
            {{ $t("label.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DeleteDialog
      :dialog.sync="deleteDialog"
      v-on:confirmed="onDeleteConfirmed"
    />
  </v-row>
</template>

<script>
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import rules from "@/utils/rules.js";

export default {
  name: "incident-edit-dialog",
  components: {
    DeleteDialog,
  },
  props: ["dialog", "object"],

  apollo: {
    allSystems: {
      query: gql`
        query allSystems($orderBy: [String]) {
          allSystems(orderBy: $orderBy) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
      variables() {
        return {
          orderBy: ["name"],
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allSystems,
    },

    allStates: {
      query: gql`
        query allStates($orderBy: [String]) {
          allStates(orderBy: $orderBy) {
            edges {
              node {
                id
                color
                icon
                nameEn
                nameNl
              }
            }
          }
        }
      `,
      variables() {
        return {
          orderBy: ["index"],
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allStates,
    },
  },

  data() {
    return {
      allStates: {},
      allSystems: {},
      deleteDialog: false,
      editedItem: {},
      error: null,
      isFormValid: false,
      isSaving: false,
      resolvedAt: null,
      selectedState: null,
      selectedSystems: [],
    };
  },
  computed: {
    formTitle() {
      return `${this.$t("label.add")} ${this.$tc("label.incident", 1)}`;
    },

    me() {
      return this.$store.state.user.me || {};
    },
  },
  watch: {
    dialog(val) {
      this.resetForm();
    },
  },
  created() {
    this.rules = rules;
    this.capitalizeFirstLetter = helper.capitalizeFirstLetter;
  },
  methods: {
    close() {
      this.$emit("update:dialog", false);
      this.resetForm();
    },

    deleteSelected() {
      this.deleteDialog = true;
    },

    generateCurrentTimestamp() {
      const now = new Date().toISOString().slice(0, -5) + "+00:00";

      this.resolvedAt = now.toString()
    },

    resetForm() {
      this.error = null;
      this.isSaving = false;

      this.resolvedAt = null;
      this.selectedState = null;
      this.selectedSystems = [];

      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }

      if (this.dialog && this.object) {
        this.editedItem.id = this.object.id;
        this.editedItem.descriptionEn = this.object.descriptionEn;
        this.editedItem.descriptionNl = this.object.descriptionNl;
        this.editedItem.nameEn = this.object.nameEn;
        this.editedItem.nameNl = this.object.nameNl;

        this.resolvedAt = this.object.resolvedAt;

        this.selectedState = this.object.state.id;

        this.selectedSystems = this.object.systems
          ? this.object.systems.edges.map((item) => {
              return item.node.id;
            })
          : [];
      } else {
        this.editedItem = {};
      }
    },

    onDeleteConfirmed() {
      var ids = [this.object.id];

      const payload = {
        ids: ids,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteIncidents($input: DeleteIncidentsInput!) {
              deleteIncidents(input: $input) {
                deletionCount
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then((response) => {
          this.deleteDialog = false;

          const payload = {
            color: "success",
            message: `Incidents successfully deleted`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);

          this.$emit("changed", response);
          this.close();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.error = null;
      this.isSaving = true;

      var payload = {
        descriptionEn: this.editedItem.descriptionEn,
        descriptionNl: this.editedItem.descriptionNl,
        nameEn: this.editedItem.nameEn,
        nameNl: this.editedItem.nameNl,
        resolvedAt: this.resolvedAt,
        state: this.selectedState,
        systems: this.selectedSystems,
      };

      if (this.object) {
        payload.id = this.editedItem.id;

        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateIncident($input: UpdateIncidentInput!) {
                updateIncident(input: $input) {
                  clientMutationId
                }
              }
            `,
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            const payload = {
              color: "success",
              message: `Incident successfully updated`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);

            this.$emit("changed", response);
            this.close();
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.isSaving = false;
          });
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createIncident($input: CreateIncidentInput!) {
                createIncident(input: $input) {
                  clientMutationId
                }
              }
            `,
            variables: {
              input: payload,
            },
          })
          .then((response) => {
            const payload = {
              color: "success",
              message: `Incident successfully created`,
            };
            this.$store.dispatch("snackbar/showMessage", payload);

            this.$emit("changed", response);
            this.close();
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 1200px) {
  .my-stepper .v-stepper__step {
    flex-grow: 1;
  }
}
</style>

<i18n>
  {}
</i18n>

<template>
	<v-container fluid>
		<v-row class="align-center pl-3">
			<v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs>
			<v-spacer />
		</v-row>

		<v-container :fluid="$vuetify.breakpoint.lgAndDown">
			<v-row justify="center" align="center">
				<v-col cols="12">
					<v-tabs :disabled="editMode" v-model="tabs">
						<v-tabs-slider color="primary"></v-tabs-slider>

						<v-tab>{{ $t("label.profile") }}</v-tab>
						<v-spacer />
						<v-spacer />
						<v-btn
							@click="edit()"
							class="mt-2 ml-2"
							color="primary"
							dark
							outlined
							rounded
							small
							v-if="!editMode"
							>{{ $t("label.edit") }}</v-btn
						>
						<v-btn
							@click="cancel()"
							class="mt-2 ml-2"
							rounded
							small
							text
							v-if="editMode"
							>{{ $t("label.cancel") }}</v-btn
						>
						<v-btn
							@click="save()"
							class="mt-2 ml-2"
							color="primary"
							dark
							outlined
							rounded
							small
							v-if="editMode"
							>{{ $t("label.save") }}</v-btn
						>
					</v-tabs>
					<v-divider></v-divider>

					<v-tabs-items v-model="tabs">
						<v-tab-item>
							<ProfileTab
								ref="ProfileTab"
								:editMode.sync="editMode"
								v-on:changed="onChanged"
							/>
						</v-tab-item>
					</v-tabs-items>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import ProfileTab from "@/components/users/ProfileTab.vue";

export default {
	name: "settings",
	components: { ProfileTab },

	apollo: {},

	data: function () {
		return {
			editMode: false,
		};
	},

	computed: {
		breadcrumbs() {
			let breadcrumbs = [
				{
					text: this.$t("label.home"),
					exact: true,
					disable: false,
					to: {
						name: "home",
					},
				},
				{
					text: this.$tc("label.setting", 2),
					disabled: true,
				},
			];
			return breadcrumbs;
		},

		tabs: {
			get() {
				return this.$store.getters["settings/getTabs"];
			},
			set(value) {
				this.$store.dispatch("settings/setTabs", value);
			},
		},
	},

	watch: {},

	created() {},

	mounted() {},

	methods: {
		edit() {
			this.editMode = true;
		},

		cancel() {
			this.editMode = false;

			switch (this.tabs) {
				case 0:
					this.$refs.ProfileTab.resetForm();
			}
		},

		fetchMe() {
			this.$store
				.dispatch("user/fetchMe")
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		},

		save() {
			switch (this.tabs) {
				case 0:
					this.$refs.ProfileTab.save();
			}
		},

		onChanged() {
			this.fetchMe();
			this.editMode = false;
		},
	},
};
</script>

<template>
  <v-badge
    :class="['mb-5', 'pb-1']"
    content=""
    value=""
    color="success"
    overlap
    v-if="!$apollo.queries.webflowItem.loading"
  >
    <v-card @click="open()" class="my-0 pa-1 rounded-xl row-pointer">
      <v-card-title :class="['my-0']">
        <v-avatar size="80">
          <img :src="webflowItem?.fieldData?.icoontje?.url" />
        </v-avatar>

        <v-spacer />

        <v-chip :class="['mb-10']" outlined color="success" rounded>{{
          $t("label.request")
        }}</v-chip>
      </v-card-title>

      <v-card-title
        :class="['my-0', 'py-0', 'font-weight-bold', 'text-h5']"
        style="word-break: break-word"
      >
        {{ webflowItem?.fieldData?.name }}
        <v-spacer />
      </v-card-title>
      <v-list-item three-line :class="['mt-n5', 'my-0', 'py-0']">
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ webflowItem?.fieldData?.["tekst-add-on-portal"] }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <AddOnsEditDialog
      :dialog.sync="dialog"
      :addOn="addOn"
      :webflowItem="webflowItem"
      v-on:changed="onChanged"
    />
  </v-badge>

  <v-skeleton-loader
    type="list-item-avatar, article"
    v-else
  ></v-skeleton-loader>
</template>

<script>
import config from "@/utils/config.json";
import gql from "graphql-tag";
import AddOnsEditDialog from "@/components/integrations/addOns/AddOnsEditDialog.vue";

export default {
  name: "add-on-card",
  props: ["addOn"],
  components: {
    config,
    AddOnsEditDialog,
  },

  apollo: {
    webflowItem: {
      query: gql`
        query webflowItem($collectionId: String!, $itemId: String!) {
          webflowItem(collectionId: $collectionId, itemId: $itemId) {
            id
            cmsLocaleId
            createdOn
            fieldData
            isArchived
            isDraft
            lastPublished
            lastUpdated
          }
        }
      `,
      variables() {
        return {
          collectionId: "665439b505ad145e863f378a",
          itemId: this.addOn.externalId,
        };
      },
      update: (data) => data.webflowItem,
    },
  },

  data() {
    return {
      dialog: false,
      webflowItem: {
        id: null,
        fieldData: {
          logo: {},
          name: null,
          slug: null,
        },
      },
    };
  },
  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },
  watch: {},
  created() {},
  methods: {
    onChanged() {},

    open() {
      this.dialog = true;
    },
  },
};
</script>

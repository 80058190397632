<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>
    <v-col cols="12" md="2" sm="6">
      <v-text-field
        @click:append-outer="refresh()"
        @click:clear="clearSearch()"
        @keydown.enter="refresh()"
        clearable
        dense
        hide-details
        outlined
        required
        :label="$t('label.search')"
        v-model="search"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="2" sm="6" v-if="me.isSuperuser || me.isPartnerUser">
      <v-autocomplete
        :items="tenants.edges"
        :label="$tc('label.tenant', 1)"
        :loading="$apollo.queries.tenants.loading"
        @change="refresh()"
        clearable
        dense
        hide-details
        item-text="node.tenantName"
        item-value="node.id"
        outlined
        v-model="selectedTenant"
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="2" sm="6">
      <v-switch
        :label="$tc('label.group', 1)"
        class="mb-0 mt-2 py-0"
        hide-details
        v-if="allReportGroups.totalCount > 0"
        v-model="groupBy"
      ></v-switch>
    </v-col>
    <v-spacer />
    <v-col align="right" cols="12" md="2" sm="6">
      <v-btn @click="resetFilters()" color="primary" dark rounded small text>
        {{ $t("label.clearFilters") }}
      </v-btn>
    </v-col>
    <v-col cols="12" md="12">
      <v-data-table
        :footer-props="{
          'disable-pagination': $apollo.queries.reports.loading,
          'items-per-page-options': [10, 25, 50, 75, 100],
        }"
        :group-by="groupBy ? 'node.reportGroup.name' : null"
        :headers="
          me.isSuperuser || me.isPartnerUser ? headersSuperuser : headers
        "
        :items="reports.edges"
        :loading="$apollo.queries.reports.loading"
        :options.sync="dataTableOptions"
        :server-items-length="reports.totalCount"
        :show-select="me.isSuperuser ? 'show-select' : undefined"
        @click:row="(item) => goToDetail(item.node.id, item.node.reportType)"
        item-key="node.id"
        v-model="selectedItems"
      >
        <template v-slot:group.header="item">
          <td
            :colspan="
              me.isSuperuser || me.isPartnerUser
                ? headersSuperuser.length + 1
                : headers.length
            "
          >
            <v-btn icon @click="item.toggle">
              <v-icon>{{ item.isOpen ? "close" : "add" }}</v-icon>
            </v-btn>
            {{ item.group }}
          </td>
        </template>
        <template v-slot:[`item.node.reportType`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-badge
                color="green"
                :content="item.node?.connection?.remainingTrialPeriod"
                v-if="
                  item.node?.connection?.remainingTrialPeriod &&
                  item.node?.connection?.remainingTrialPeriod >= 0
                "
              >
                <v-icon
                  v-on="on"
                  :color="item.node.isStandard ? 'warning' : 'primary'"
                  >{{
                    item.node.reportType == "POWERBIREPORT"
                      ? "dashboard"
                      : "layers"
                  }}</v-icon
                >
              </v-badge>
              <v-icon
                v-on="on"
                :color="item.node.isStandard ? 'warning' : 'primary'"
                v-else
                >{{
                  item.node.reportType == "POWERBIREPORT"
                    ? "dashboard"
                    : "layers"
                }}</v-icon
              >
            </template>
            <span>
              {{
                item.node.reportType == "POWERBIREPORT" && item.node.isStandard
                  ? $tc("report.standardDashboard", 1)
                  : item.node.reportType == "POWERBIREPORT"
                  ? $tc("report.customDashboard", 1)
                  : $tc("report.paginatedReport", 1)
              }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.node.reportName`]="{ item }">
          <span>{{
            item.node.isStandard
              ? item.node.tenant.tenantName + " - " + item.node.reportName
              : item.node.reportName
          }}</span>
        </template>
        <template v-slot:[`item.node.lastRefreshStatus`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                :color="
                  item.node.lastRefreshStatus == 'Completed'
                    ? 'success'
                    : item.node.lastRefreshStatus == 'Failed'
                    ? 'error'
                    : item.node.lastRefreshStatus == 'Disabled'
                    ? 'error'
                    : item.node.lastRefreshStatus == 'Unknown'
                    ? 'warning'
                    : ''
                "
                class="mr-2"
                v-on="on"
                >{{
                  item.node.lastRefreshStatus == "Completed"
                    ? "check_circle"
                    : item.node.lastRefreshStatus == "Failed"
                    ? "cancel"
                    : item.node.lastRefreshStatus == "Disabled"
                    ? "cancel"
                    : item.node.lastRefreshStatus == "Unknown"
                    ? "error"
                    : ""
                }}</v-icon
              >
            </template>
            <span>
              {{
                item.node.lastRefreshStatus == "Unknown"
                  ? "Refreshing"
                  : item.node.lastRefreshStatus
              }}
            </span>
          </v-tooltip>
          <span v-if="item.node.lastRefreshEndTime">{{
            item.node.lastRefreshEndTime | moment("YYYY-MM-DD HH:mm")
          }}</span>
        </template>
        <template v-slot:[`item.node.refreshScheduleIsEnabled`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                :color="
                  item.node.isDirectQuery
                    ? 'info'
                    : item.node.refreshScheduleIsEnabled
                    ? 'success'
                    : 'error'
                "
                class="mr-2"
                v-on="on"
              >
                {{
                  item.node.isDirectQuery
                    ? "swap_horizontal_circle"
                    : item.node.refreshScheduleIsEnabled
                    ? "check_circle"
                    : "cancel"
                }}
              </v-icon>
            </template>
            <span>
              {{
                item.node.isDirectQuery
                  ? "Direct query"
                  : item.node.refreshScheduleIsEnabled
                  ? "Enabled"
                  : "Disabled"
              }}
            </span>
          </v-tooltip>
          <span v-if="item.node.isDirectQuery"></span>
          <span v-else-if="item.node?.refreshScheduleTimes?.length <= 54">{{
            item.node.refreshScheduleTimes
          }}</span>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{
                item.node?.refreshScheduleTimes?.substring(0, 54) + "..."
              }}</span>
            </template>
            <span>
              {{ item.node.refreshScheduleTimes }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <template v-if="item.node.favoriteSet.edges.length > 0">
            <template v-for="favorite in item.node.favoriteSet.edges">
              <v-btn
                :key="favorite.node.id"
                :loading="$apollo.queries.reports.loading"
                @click.stop="
                  (item) => goToDetail(item.node.id, item.node.reportType)
                "
                @click="deleteFavorite(favorite.node.id)"
                color="primary"
                icon
                v-if="favorite.node.isFavorite"
              >
                <v-icon>star</v-icon>
              </v-btn>
            </template>
          </template>
          <template v-else>
            <v-btn
              :loading="$apollo.queries.reports.loading"
              @click.stop="
                (item) => goToDetail(item.node.id, item.node.reportType)
              "
              @click="createFavorite(item.node.id)"
              color="primary"
              icon
            >
              <v-icon>star_border</v-icon>
            </v-btn>
          </template>
          <v-btn
            @click.stop="
              (item) => goToDetail(item.node.id, item.node.reportType)
            "
            @click="edit(item.node)"
            color="primary"
            icon
            v-if="hasPermission('dashboards.change_usermapping')"
          >
            <v-icon>edit</v-icon>
          </v-btn>
          <v-btn
            @click.stop="
              (item) => goToDetail(item.node.id, item.node.reportType)
            "
            @click="openPowerBIServiceReport(item.node)"
            color="primary"
            icon
            v-if="me.isSuperuser"
          >
            <v-icon>bar_chart</v-icon>
          </v-btn>
          <v-btn
            @click.stop="
              (item) => goToDetail(item.node.id, item.node.reportType)
            "
            @click="openPowerBIServiceDataset(item.node)"
            color="primary"
            icon
            v-if="me.isSuperuser"
          >
            <v-icon>account_tree</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>

    <DeleteDialog
      :dialog.sync="deleteDialog"
      v-on:confirmed="onDeleteConfirmed"
    />
    <ReportAddDialog :dialog.sync="addDialog" v-on:changed="onChanged" />
    <ReportEditDialog
      :dialog.sync="editDialog"
      :object.sync="editedItem"
      v-on:changed="onChanged"
    />
  </v-row>
</template>

<script>
import DeleteDialog from "@/components/base/DeleteDialog.vue";
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import ReportAddDialog from "@/components/reports/ReportAddDialog.vue";
import ReportEditDialog from "@/components/reports/ReportEditDialog.vue";

export default {
  name: "reports-list",
  components: {
    DeleteDialog,
    ReportAddDialog,
    ReportEditDialog,
  },

  apollo: {
    allReportGroups: {
      query: gql`
        query allReportGroups {
          allReportGroups {
            totalCount
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.allReportGroups,
    },

    reports: {
      query: gql`
        query reports(
          $first: Int
          $last: Int
          $before: String
          $after: String
          $search: String
          $orderBy: [String]
          $ownReports: Boolean!
          $tenant: ID
        ) {
          reports(
            first: $first
            last: $last
            before: $before
            after: $after
            search: $search
            orderBy: $orderBy
            ownReports: $ownReports
            tenant: $tenant
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                bindedDatasetId
                bindedGroupId
                datasetId
                groupId
                isActive
                isBinded
                isDirectQuery
                isEditable
                isStandard
                lastRefreshEndTime
                lastRefreshStartTime
                lastRefreshStatus
                lastRefreshType
                pbiDateColumn
                pbiDateTable
                refreshScheduleIsEnabled
                refreshScheduleTimes
                reportId
                reportName
                reportType
                tenant {
                  id
                  tenantName
                }
                favoriteSet {
                  edges {
                    node {
                      id
                      isFavorite
                    }
                  }
                }
                isStandard
                connection {
                  id
                  remainingTrialPeriod
                }
                reportGroup {
                  id
                  name
                }
              }
            }
          }
        }
      `,
      fetchPolicy: "cache-and-network",
      update: (data) => data.reports,
      skip: true,
      pollInterval: 60000, // ms
    },

    tenants: {
      query: gql`
        query tenants {
          tenants(orderBy: ["tenantName"]) {
            edges {
              node {
                id
                tenantName
              }
            }
          }
        }
      `,
      variables() {},
      fetchPolicy: "cache-and-network",
      update: (data) => data.tenants,
      skip: true,
    },
  },

  data: function () {
    return {
      addDialog: false,
      allReportGroups: {},
      deleteDialog: false,
      editDialog: false,
      editedItem: {},
      error: null,
      reports: {},
      selectedItems: [],
      tenants: {},
    };
  },

  computed: {
    dataTableOptions: {
      get() {
        return this.$store.getters["reportsList/getDataTableOptions"];
      },
      set(value) {
        this.$store.dispatch("reportsList/setDataTableOptions", value);
      },
    },

    me() {
      return this.$store.state.user.me;
    },

    groupBy: {
      get() {
        return this.$store.getters["reportsList/getGroupBy"];
      },
      set(value) {
        this.$store.dispatch("reportsList/setGroupBy", value);
      },
    },

    search: {
      get() {
        return this.$store.getters["reportsList/getSearch"];
      },
      set(value) {
        this.$store.dispatch("reportsList/setSearch", value);
      },
    },

    selectedTenant: {
      get() {
        return this.$store.getters["reportsList/getSelectedTenant"];
      },
      set(value) {
        this.$store.dispatch("reportsList/setSelectedTenant", value);
      },
    },

    headers() {
      return [
        {
          text: this.$tc("label.type", 1),
          value: "node.reportType",
          sortable: false,
        },
        {
          text: this.$tc("label.report", 1),
          value: "node.reportName",
          sortable: !this.groupBy,
        },
        {
          text: this.$tc("report.lastRefresh", 1),
          value: "node.lastRefreshStatus",
          sortable: false,
        },
        {
          text: this.$tc("report.refreshSchedule", 1),
          value: "node.refreshScheduleIsEnabled",
          sortable: false,
        },
        {
          text: this.$t("label.action"),
          align: "center",
          value: "action",
          sortable: false,
        },
      ];
    },

    headersSuperuser() {
      return [
        {
          text: this.$tc("label.type", 1),
          value: "node.reportType",
          sortable: false,
        },
        {
          text: this.$tc("label.tenant", 1),
          value: "node.tenant.tenantName",
          sortable: !this.groupBy,
        },
        {
          text: this.$tc("label.report", 1),
          value: "node.reportName",
          sortable: !this.groupBy,
        },
        {
          text: this.$tc("report.lastRefresh", 1),
          value: "node.lastRefreshStatus",
          sortable: false,
        },
        {
          text: this.$tc("report.refreshSchedule", 1),
          value: "node.refreshScheduleIsEnabled",
          sortable: false,
        },
        {
          text: this.$t("label.action"),
          align: "center",
          value: "action",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    dataTableOptions() {
      this.refresh();
    },

    selectedItems() {
      this.$emit("updateNrOfSelectedItems", this.selectedItems.length);
    },
  },

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;

    if (this.me.isSuperuser || this.me.isPartnerUser) {
      this.$apollo.queries.tenants.skip = false;
    }
  },

  mounted() {},

  methods: {
    add() {
      this.addDialog = true;
    },

    clearSearch() {
      this.search = null;
      this.refresh();
    },

    createFavorite(reportNodeId) {
      var payload = {
        reportNodeId: reportNodeId,
        isFavorite: true,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation createReportFavorite($input: CreateReportFavoriteInput!) {
              createReportFavorite(input: $input) {
                reportFavorite {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then(() => {
          this.$apollo.queries.reports.refresh();
        })
        .catch((error) => {
          this.error = error;
        });
    },

    deleteFavorite(reportFavoriteId) {
      var payload = {
        id: reportFavoriteId,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteReportFavorite($input: DeleteReportFavoriteInput!) {
              deleteReportFavorite(input: $input) {
                reportFavorite {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then(() => {
          this.$apollo.queries.reports.refresh();
        })
        .catch((error) => {
          this.error = error;
        });
    },

    deleteSelected() {
      this.deleteDialog = true;
    },

    edit(item) {
      this.editedItem = item;
      this.editDialog = true;
    },

    goToDetail(reportNodeId, reportType) {
      if (reportType === "POWERBIREPORT") {
        this.$router.push({
          name: "report-detail",
          params: {
            reportNodeId: reportNodeId,
          },
        });
      } else if (reportType === "PAGINATEDREPORT") {
        this.$router.push({
          name: "paginated-report-detail",
          params: {
            reportNodeId: reportNodeId,
          },
        });
      }
    },

    onChanged() {
      this.$apollo.queries.reports.refresh();
    },

    onDeleteConfirmed() {
      var ids = [];
      this.selectedItems.forEach(function (item, index) {
        ids.push(item.node.id);
      });

      const payload = {
        ids: ids,
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteReports($input: DeleteReportsInput!) {
              deleteReports(input: $input) {
                reports {
                  id
                }
              }
            }
          `,
          variables: {
            input: payload,
          },
        })
        .then(() => {
          this.$apollo.queries.reports.refresh();

          this.deleteDialog = false;
          this.selectedItems = [];

          const payload = {
            color: "success",
            message: `Reports successfully deleted`,
          };
          this.$store.dispatch("snackbar/showMessage", payload);
        })
        .catch((error) => {
          this.error = error;
        });
    },

    openPowerBIServiceReport(item) {
      var groupId = item.groupId;
      var reportId = item.reportId;
      if (item.reportType === "PAGINATEDREPORT") {
        var reportType = "rdlreports";
      } else {
        var reportType = "reports";
      }
      var url = `https://app.powerbi.com/groups/${groupId}/${reportType}/${reportId}`;

      window.open(url, "_blank");
    },

    openPowerBIServiceDataset(item) {
      var bindedDatasetId = item.bindedDatasetId;
      var bindedGroupId = item.bindedGroupId;
      var datasetId = item.datasetId;
      var groupId = item.groupId;
      var reportId = item.reportId;
      if (item.reportType === "PAGINATEDREPORT") {
        var uri = `groups/${groupId}/settings/reports/${reportId}`;
      } else if (item.isBinded) {
        var uri = `groups/${bindedGroupId}/datasets/${bindedDatasetId}/details`;
      } else {
        var uri = `groups/${groupId}/datasets/${datasetId}/details`;
      }
      var url = `https://app.powerbi.com/${uri}`;

      window.open(url, "_blank");
    },

    refresh() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.dataTableOptions;

      var _first = itemsPerPage;
      var _last = null;
      var _before = null;
      var _after = null;

      if (page < this.page) {
        _first = null;
        _last = itemsPerPage;
        _before = this.reports.pageInfo.startCursor;
        _after = null;
      }

      if (page > this.page) {
        _first = itemsPerPage;
        _last = null;
        _before = null;
        _after = this.reports.pageInfo.endCursor;
      }

      var orderByList = this.orderByList(sortBy, sortDesc);

      if (this.groupBy) {
        orderByList = ["reportGroup__name", "tenant__tenantName", "reportName"];
      }

      this.$apollo.queries.reports.setVariables({
        first: _first,
        last: _last,
        before: _before,
        after: _after,
        orderBy: orderByList,
        search: this.search,
        ownReports: false,
        tenant: this.selectedTenant,
      });

      this.$apollo.queries.reports.skip = false;
      this.$apollo.queries.reports.refresh();

      this.page = page;
    },

    resetFilters() {
      this.selectedTenant = null;
      this.clearSearch();
    },
  },
};
</script>

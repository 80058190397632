<template>
  <v-row class="mt-2">
    <v-col class="py-0" cols="12">
      <v-alert tile type="error" v-if="error">{{ error }}</v-alert>
    </v-col>

    <v-col :class="['my-0', 'py-0']" cols="12">
      <v-card flat>
        <v-card-title
          :class="['font-weight-bold', 'text-h4']"
          style="word-break: break-word"
        >
          {{ $t("title") }}
        </v-card-title>
        <v-card-text>
          {{ $t("subtitle") }}
        </v-card-text>
      </v-card>
    </v-col>

    <v-col :class="['my-0', 'py-0']" cols="12">
      <v-card flat>
        <v-card-title :class="['my-1', 'py-1']" style="word-break: break-word">
          {{ $t("label.open") }}
        </v-card-title>
        <v-card-text>
          <v-row v-if="allConnectionsWaiting?.edges?.length > 0">
            <v-col
              :key="connection?.node?.id"
              cols="12"
              md="4"
              v-for="connection in allConnectionsWaiting?.edges"
            >
              <TapCard
                :tap="connection?.node?.tap"
                :connection="connection?.node"
              />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-alert
              :class="['mt-3', 'ml-3']"
              border="left"
              color="info"
              colored-border
              dense
              elevation="2"
              icon="info"
            >
              <v-row align="center">
                <v-col class="grow"> {{ $t("noConnections") }} </v-col>
              </v-row>
            </v-alert>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col :class="['my-0', 'py-0']" cols="12">
      <v-card flat>
        <v-card-title :class="['my-1', 'py-1']" style="word-break: break-word">
          {{ $t("label.inProgress") }}
        </v-card-title>
        <v-card-text>
          <v-row v-if="allConnectionsInProgress?.edges?.length > 0">
            <v-col
              :key="connection?.node?.id"
              cols="12"
              md="4"
              v-for="connection in allConnectionsInProgress?.edges"
            >
              <TapCard
                :tap="connection?.node?.tap"
                :connection="connection?.node"
              />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-alert
              :class="['mt-3', 'ml-3']"
              border="left"
              color="info"
              colored-border
              dense
              elevation="2"
              icon="info"
            >
              <v-row align="center">
                <v-col class="grow"> {{ $t("noConnections") }} </v-col>
              </v-row>
            </v-alert>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col :class="['my-0', 'py-0']" cols="12">
      <v-card flat>
        <v-card-title :class="['my-1', 'py-1']" style="word-break: break-word">
          {{ $t("label.cancelled") }}
        </v-card-title>
        <v-card-text>
          <v-row v-if="allConnectionsCancelled?.edges?.length > 0">
            <v-col
              :key="connection?.node?.id"
              cols="12"
              md="4"
              v-for="connection in allConnectionsCancelled?.edges"
            >
              <TapCard
                :tap="connection?.node?.tap"
                :connection="connection?.node"
              />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-alert
              :class="['mt-3', 'ml-3']"
              border="left"
              color="info"
              colored-border
              dense
              elevation="2"
              icon="info"
            >
              <v-row align="center">
                <v-col class="grow"> {{ $t("noConnections") }} </v-col>
              </v-row>
            </v-alert>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col :class="['my-0', 'py-0']" cols="12">
      <v-card flat>
        <v-card-title :class="['my-1', 'py-1']" style="word-break: break-word">
          {{ $t("label.deleting") }}
        </v-card-title>
        <v-card-text>
          <v-row v-if="allConnectionsDeleting?.edges?.length > 0">
            <v-col
              :key="connection?.node?.id"
              cols="12"
              md="4"
              v-for="connection in allConnectionsDeleting?.edges"
            >
              <TapCard
                :tap="connection?.node?.tap"
                :connection="connection?.node"
              />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-alert
              :class="['mt-3', 'ml-3']"
              border="left"
              color="info"
              colored-border
              dense
              elevation="2"
              icon="info"
            >
              <v-row align="center">
                <v-col class="grow"> {{ $t("noConnections") }} </v-col>
              </v-row>
            </v-alert>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col :class="['my-0', 'py-0']" cols="12">
      <v-card flat>
        <v-card-title :class="['my-1', 'py-1']" style="word-break: break-word">
          {{ $t("label.connected") }}
        </v-card-title>
        <v-card-text>
          <v-row v-if="allConnectionsCompleted?.edges?.length > 0">
            <v-col
              :key="connection?.node?.id"
              cols="12"
              md="4"
              v-for="connection in allConnectionsCompleted?.edges"
            >
              <TapCard
                :tap="connection?.node?.tap"
                :connection="connection?.node"
              />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-alert
              :class="['mt-3', 'ml-3']"
              border="left"
              color="info"
              colored-border
              dense
              elevation="2"
              icon="info"
            >
              <v-row align="center">
                <v-col class="grow"> {{ $t("noConnections") }} </v-col>
              </v-row>
            </v-alert>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import gql from "graphql-tag";
import helper from "@/utils/helper.js";
import TapCard from "@/components/integrations/taps/TapCard.vue";

export default {
  name: "connections-in-progress-list",
  components: {
    TapCard,
  },

  apollo: {
    allConnectionsCompleted: {
      query: gql`
        query allConnectionsCompleted($first: Int, $status: String) {
          allConnections(
            first: $first
            status: $status
            orderBy: ["-completedAt"]
          ) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                approvedAt
                completedAt
                createdAt
                pk
                remainingTrialPeriod
                status
                tap {
                  id
                  externalId
                }
                tenant {
                  id
                  pk
                  tenantName
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          first: 12,
          status: "Connected",
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allConnections,
      pollInterval: 10000,
    },

    allConnectionsInProgress: {
      query: gql`
        query allConnectionsInProgress($first: Int, $status: String) {
          allConnections(first: $first, status: $status, orderBy: ["id"]) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                approvedAt
                completedAt
                createdAt
                pk
                remainingTrialPeriod
                status
                tap {
                  id
                  externalId
                }
                tenant {
                  id
                  pk
                  tenantName
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          first: 100,
          status: "In progress",
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allConnections,
      pollInterval: 10000,
    },

    allConnectionsWaiting: {
      query: gql`
        query allConnectionsWaiting($first: Int, $status: String) {
          allConnections(first: $first, status: $status, orderBy: ["id"]) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                approvedAt
                completedAt
                createdAt
                pk
                remainingTrialPeriod
                status
                tap {
                  id
                  externalId
                }
                tenant {
                  id
                  pk
                  tenantName
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          first: 100,
          status: "Waiting",
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allConnections,
      pollInterval: 10000,
    },

    allConnectionsCancelled: {
      query: gql`
        query allConnectionsCancelled($first: Int, $status: String) {
          allConnections(first: $first, status: $status, orderBy: ["id"]) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                approvedAt
                completedAt
                createdAt
                pk
                remainingTrialPeriod
                status
                tap {
                  id
                  externalId
                }
                tenant {
                  id
                  pk
                  tenantName
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          first: 100,
          status: "Cancelled",
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allConnections,
      pollInterval: 10000,
    },

    allConnectionsDeleting: {
      query: gql`
        query allConnectionsDeleting($first: Int, $status: String) {
          allConnections(first: $first, status: $status, orderBy: ["id"]) {
            edgeCount
            totalCount
            pageInfo {
              startCursor
              endCursor
              hasPreviousPage
              hasNextPage
            }
            edges {
              node {
                id
                approvedAt
                completedAt
                createdAt
                pk
                remainingTrialPeriod
                status
                tap {
                  id
                  externalId
                }
                tenant {
                  id
                  pk
                  tenantName
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          first: 100,
          status: "Deleting",
        };
      },
      fetchPolicy: "cache-and-network",
      update: (data) => data.allConnections,
      pollInterval: 10000,
    },
  },

  data: function () {
    return {
      allConnectionsCompleted: {
        edges: [],
      },
      allConnectionsFailed: {
        edges: [],
      },
      allConnectionsInProgress: {
        edges: [],
      },
      allConnectionsWaiting: {
        edges: [],
      },
      error: null,
    };
  },

  computed: {
    me() {
      return this.$store.state.user.me;
    },
  },

  watch: {},

  created() {
    this.hasPermission = helper.hasPermission;
    this.orderByList = helper.orderByList;
  },

  mounted() {},

  methods: {
    onChanged() {
      this.$apollo.queries.allConnections.refresh();
    },
  },
};
</script>

<i18n>
{
	"en": {
		"title": "Connections status",
		"subtitle": "Superusers can manage and monitor all Tap deployments in one place. View status, adjust settings and deploy new Taps.",
		"noConnections": "No connections"
	},
	"nl": {
		"title": "Connections status",
		"subtitle": "Superusers kunnen alle Tap deployments op één plek beheren en controleren. Bekijk de status, pas instellingen aan en deploy nieuwe Taps.",
		"noConnections": "Geen koppelingen"
	},
	"de": {
		"title": "Connections status",
		"subtitle": "Superbenutzer können alle Tap-Bereitstellungen an einem Ort verwalten und überwachen. Status anzeigen, Einstellungen anpassen und neue Taps bereitstellen.",
		"noConnections": "Keine Verbindungen"
	}
}
</i18n>

<template>
  <BaseDialog :error="error" :dialog="dialog" @close="close($event)">
    <template v-slot:title> {{ $tc("title") }} </template>
    <template v-slot:text>
      <div class="mb-5">
        {{ $tc("description") }}
      </div>

      <DynamicForm
        :fields="fields"
        :formData.sync="formData"
        @is-valid-changed="updateFormValid($event)"
        ref="DynamicForm"
      />
    </template>
    <template v-slot:actionsRight>
      <BaseButton
        :loading="isSaving"
        :text="true"
        @click="close()"
        color="black"
      >
        {{ $t("label.cancel") }}
      </BaseButton>
      <BaseButton :disabled="!formValid" :loading="isSaving" @click="save()">
        {{ $t("label.request") }}
      </BaseButton>
    </template>
  </BaseDialog>
</template>

<script>
import BaseButton from "@/components/base/BaseButton.vue";
import BaseDialog from "@/components/base/BaseDialog.vue";
import DynamicForm from "@/components/base/DynamicForm.vue";

const initialData = () => ({
  error: null,
  fields: [],
  formData: {},
});

export default {
  name: "connection-request-dialog",
  components: {
    BaseButton,
    BaseDialog,
    DynamicForm,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      isSaving: false,
      fields: [],
      formData: {},
      formValid: false,
    };
  },

  computed: {
    onBehalfOfTenant: {
      get() {
        return this.$store.getters["controlPanel/getOnBehalfOfTenant"];
      },
      set(value) {
        this.$store.dispatch("controlPanel/setOnBehalfOfTenant", value);
      },
    },
  },

  watch: {
    dialog(val) {
      // reset the form to it's original state and reset the validation when dialog closes
      if (!val) {
        this.$emit("update:item", null);
        this.resetData();
        this.$refs.DynamicForm.resetForm();
      } else {
        this.setFormFields();
        this.setFormData(this.item);
      }
    },
  },

  created() {},

  mounted() {},

  methods: {
    close() {
      this.$emit("update:dialog", false);
    },

    resetData() {
      // reset the data to it's initial state
      const data = initialData();
      Object.keys(data).forEach((k) => (this[k] = data[k]));
    },

    setFormFields() {
      // set the field from the config from the DB
      const fieldsJson = `
      [
          {
              "component": "v-autocomplete",
              "model": "tap",
              "label": "Tap",
              "cols": 12,
              "rules": [
                  "required"
              ],
              "query": "query allTaps { allTaps(orderBy: [\\"name\\"]) { edges { node { id externalId name } } } }",
              "itemKey": "id",
              "itemValue": "name",
              "resultKey": "allTaps",
              "multiple": false,
              "returnObject": true
          },
          {
              "component": "v-autocomplete",
              "model": "tenant",
              "label": "Tenant",
              "cols": 12,
              "rules": [
                  "required"
              ],
              "query": "query tenants { tenants(orderBy: [\\"tenantName\\"]) { edges { node { id tenantName } } } }",
              "itemKey": "id",
              "itemValue": "tenantName",
              "resultKey": "tenants",
              "multiple": false,
              "returnObject": true
          }
      ]
      `;
      this.fields = JSON.parse(fieldsJson);
    },

    setFormData(item) {
      // edit, not used since item is not used for add ons
      if (item) {
        this.formData = {};
      }
      // add
      else {
        const formDataJson = `
        {
          "tap": null,
          "tenant": null
        }
        `;
        this.formData = JSON.parse(formDataJson);
      }
    },

    updateFormValid(isValid) {
      this.formValid = isValid;
    },

    save() {
      if (!this.formValid) {
        return;
      }

      this.onBehalfOfTenant = this.formData.tenant;

      const url = `/taps/${this.formData.tap.externalId}/connect`;

      this.$router.push(url);
    },
  },
};
</script>

<i18n>
  {
    "en": {
      "title": "Tap for other tenant",
      "description": "By selecting a Tap and a tenant you can request the Tap for that specific tenant. You'll be forwarded to the connect screen of the Tap where you can complete the process."
    },
    "nl": {
      "title": "Tap voor andere tenant",
      "description": "Door een Tap en een tenant te selecteren, kun je de Tap voor die specifieke tenant koppelen. Je wordt doorgestuurd naar het verbindingsscherm van de Tap waar je het proces kunt voltooien."
    },
    "de": {
      "title": "Tap für einen anderen tenant",
      "description": "Durch die Auswahl eines Taps und eines tenants können Sie den Tap für diesen spezifischen tenant anfordern. Sie werden zum Verbindungsbildschirm des Taps weitergeleitet, wo Sie den Vorgang abschließen können."
    }
  }
</i18n>
